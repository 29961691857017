import { MouseEventHandler } from "react";

interface SortByDropDownProps {
  onClick: Function;
}

interface SortByCrossIconProps {
  fetchAssetData: () => Promise<void>;
  sortBy: Function;
}

interface SavedAssetIconProps {
  SavedAssetData: () => Promise<void>;
  handleClick: () => Promise<void>;
}

export const SortByDropDown: React.FC<SortByDropDownProps> = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      style={{ marginLeft: "5px", marginRight: "3px" }}
      className="big_svg"
      onClick={onClick as MouseEventHandler<SVGSVGElement>}
    >
      <path
        d="M16.25 6.25L10.0884 12.4116C10.0396 12.4604 9.96043 12.4604 9.91161 12.4116L3.75 6.25"
        stroke="#708090"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ReadMoreArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className="assetcard_big_icon"
    >
      <path
        d="M5 3L9.92929 7.92929C9.96834 7.96834 9.96834 8.03166 9.92929 8.07071L5 13"
        stroke="url(#paint0_linear_9882_5609)"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9882_5609"
          x1="4.42546"
          y1="-12"
          x2="15.5508"
          y2="-11.1958"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#119BFF" />
          <stop offset="1" stopColor="#4CCE1F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const SortByColor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.66602 8.125H24.666"
        stroke="#119BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66602 14H16.666"
        stroke="#119BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66602 19.875H9.66602"
        stroke="#119BFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SortByNormal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.66602 8.125H24.666"
        stroke="#708090"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66602 14H16.666"
        stroke="#708090"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.66602 19.875H9.66602"
        stroke="#708090"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SortByCrossIcon: React.FC<SortByCrossIconProps> = ({
  fetchAssetData,
  sortBy,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      style={{ marginLeft: "11px", marginRight: "3px" }}
      onClick={() => {
        fetchAssetData();
        sortBy();
      }}
      viewBox="0 0 20 20"
      fill="none"
      className="big_svg"
    >
      <path
        d="M14.1068 5.3548L4.81638 14.9186M4.67967 5.49151L14.2435 14.7819"
        stroke="#708090"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SavedAssetIcon: React.FC<SavedAssetIconProps> = ({
  SavedAssetData,
  handleClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#119BFF"
      className="assetcard_big_icon"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z"
        onClick={() => {
          SavedAssetData();
          handleClick();
        }}
      />
    </svg>
  );
};

export const UnSavedAssetIcon: React.FC<SavedAssetIconProps> = ({
  SavedAssetData,
  handleClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      className="assetcard_big_icon"
      onClick={() => {
        SavedAssetData();
        handleClick();
      }}
    >
      <path
        d="M6 19.5V5.615C6 5.155 6.15433 4.771 6.463 4.463C6.771 4.15433 7.155 4 7.615 4H13V5H7.615C7.46167 5 7.32067 5.064 7.192 5.192C7.064 5.32067 7 5.46167 7 5.615V17.95L12 15.8L17 17.95V11H18V19.5L12 16.923L6 19.5ZM17 9V7H15V6H17V4H18V6H20V7H18V9H17Z"
        fill="#708090"
      />
    </svg>
  );
};

export const TimeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      fill="none"
      className="assetcard_big_icon"
    >
      <path
        d="M11.5 3C14.0196 3 16.4359 4.00089 18.2175 5.78249C19.9991 7.56408 21 9.98044 21 12.5C21 15.0196 19.9991 17.4359 18.2175 19.2175C16.4359 20.9991 14.0196 22 11.5 22C8.98044 22 6.56408 20.9991 4.78249 19.2175C3.00089 17.4359 2 15.0196 2 12.5C2 9.98044 3.00089 7.56408 4.78249 5.78249C6.56408 4.00089 8.98044 3 11.5 3ZM11.5 4C9.24566 4 7.08365 4.89553 5.48959 6.48959C3.89553 8.08365 3 10.2457 3 12.5C3 14.7543 3.89553 16.9163 5.48959 18.5104C7.08365 20.1045 9.24566 21 11.5 21C12.6162 21 13.7215 20.7801 14.7528 20.353C15.7841 19.9258 16.7211 19.2997 17.5104 18.5104C18.2997 17.7211 18.9258 16.7841 19.353 15.7528C19.7801 14.7215 20 13.6162 20 12.5C20 10.2457 19.1045 8.08365 17.5104 6.48959C15.9163 4.89553 13.7543 4 11.5 4ZM11 7H12V12.42L16.7 15.13L16.2 16L11 13V7Z"
        fill="#708090"
      />
    </svg>
  );
};
