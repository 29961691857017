import React from "react";
import { subPath } from "../../../../../Constants/Global";
import {
  Grid,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../Constants/hooks";
import { theardshow } from "../../../../../Redux/GroupChat/action";
interface MessageTooltipProps {
  MouseEnter: () => void;
  EditClick: () => void;
  // ReplyMessage: () => void;
  DeleteMessage: () => void;
  forwardMessage: () => void;
  PinClick: () => void;
}
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,

    boxShadow: "0px 0px 8px 0px #0000001F",
    padding: "4px, 6px, 4px, 6px",
    fontSize: 14,
    color: "#000000",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
}));
const CircleMessageTooltip: React.FC<MessageTooltipProps> = ({ 
  MouseEnter,
  EditClick,
  // ReplyMessage,
  DeleteMessage,
  PinClick,
  forwardMessage,
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state: any) => state?.groupChat?.isOpen);
  console.log(isOpen, "isOpen");

  const handleToggleMediaView = () => {
    dispatch(theardshow(!isOpen));
  };
  return (
    <div>
      {" "}
      <Grid
        item
        onMouseEnter={MouseEnter}
        sx={{
          cursor: "pointer",
          position: "absolute",
          bottom: "-30px",
          display: "flex",
          gap: "10px",
          backgroundColor: "#fff",
          right: "0px",
          padding: "5px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1,
        }}
      >
        <LightTooltip title="Edit">
          <img
            src={`..${subPath}/assets/images/images/edit_chat.png`}
            alt="edit chat"
            width="25"
            height="25"
            onClick={EditClick}
          />
        </LightTooltip>

        <LightTooltip title="Forward">
          <img
            onClick={forwardMessage}
            src={`..${subPath}/assets/images/images/forward_chat.png`}
            alt="forward"
            width="25"
            height="25"
          />
        </LightTooltip>
        <LightTooltip title="Reply">
          <img
            onClick={handleToggleMediaView}
            src={`..${subPath}/assets/images/images/vuesaxlinear_icon.png`}
            alt="reply chat"
            width="25"
            height="25"
          />
        </LightTooltip>
        <LightTooltip title="Todo">
          <img
            src={`..${subPath}/assets/images/images/todo.png`}
            alt="todo"
            width="25"
            height="25"
          />
        </LightTooltip>
        <LightTooltip title="Pin">
          <img
            src={`..${subPath}/assets/images/images/pinned_chat.png`}
            alt="pinned chat"
            width="25"
            height="25"
            onClick={PinClick}
          />
        </LightTooltip>
        <LightTooltip title="Delete">
          <img
            style={{ paddingTop: "4px", marginRight: "2px" }}
            src={`..${subPath}/assets/images/images/trash.png`}
            alt="trash"
            width="18"
            height="18"
            onClick={DeleteMessage}
          />
        </LightTooltip>
      </Grid>
    </div>
  );
};
export default CircleMessageTooltip;
