import { Dispatch } from "redux";
import UseAxios from "../../Constants/UseAxios";
import axios from "axios";
import ChatAxios from "../../Constants/ChatAxios";
const api = UseAxios();
const apiChat = ChatAxios();
const MEMBER_SEARCH = "MEMBER_SEARCH";
const FIND_MEMBER_SEARCH = "FIND_MEMBER_SEARCH";
const FW_FIND_MEMBER_SEARCH = "FW_FIND_MEMBER_SEARCH";
const GROUP_SEARCH = "GROUP_SEARCH";
const ASSETS_SEARCH = "ASSETS_SEARCH";

interface Search {
  companyId: string | null;
  titles: string | null;
}

export interface MemberSearch {
  type: typeof MEMBER_SEARCH;
  payload: any;
}

export interface memberSearchFW {
  type: typeof FW_FIND_MEMBER_SEARCH;
  payload: any;
}
export interface MemberFind {
  type: typeof FIND_MEMBER_SEARCH;
  payload: any;
}
export interface MemberFindFw {
  type: typeof FW_FIND_MEMBER_SEARCH;
  payload: any;
}
export interface GroupSearch {
  type: typeof GROUP_SEARCH;
  payload: any;
}

export interface AssetSearch {
  type: typeof ASSETS_SEARCH;
  payload: any;
}

export const setItemName = (itemName: string) => ({
  type: "SET_ITEM_NAME",
  payload: itemName,
});

export const setSearchValue = (searchValue: string) => ({
  type: "SEARCH_VALUE",
  payload: searchValue,
});

export const setRelatedSearch = (relatedSearch: any) => ({
  type: "RELATED_SEARCH",
  payload: relatedSearch,
});

export const setSortBy = (sortBy: string) => ({
  type: "SORT_BY",
  payload: sortBy,
});

export const handleFindMember = (memberSearch: Search, signal: any) => {
  return (dispatch: Dispatch<MemberSearch>) => {
    apiChat
      .get(`/findAll`, { params: memberSearch, signal: signal })
      .then((res) => {
        const namesArray = res?.data?.data?.membersList?.map((person: any) => ({
          userId: person.id,
          role: person.role,
          fullname: person.fullname,
        }));

        console.log(namesArray);
        dispatch({
          type: FIND_MEMBER_SEARCH,
          payload: namesArray,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const handleFindMemberFW = (memberSearchFW: Search, signal: any) => {
  return (dispatch: Dispatch<memberSearchFW>) => {
    apiChat
      .get(`/findAllRecipientsAndGroups`, {
        params: memberSearchFW,
        signal: signal,
      })
      .then((res) => {
        const namesArray = res?.data?.data?.chatMembers?.map((person: any) => ({
          id: person.userId,
          deviceToken: person.deviceToken,
          type: 'private',
          userName: person?.userName
        }));

        dispatch({
          type: FW_FIND_MEMBER_SEARCH,
          payload: namesArray,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const setMemberSearch = (memberSearch: Search, signal: any) => {
  return (dispatch: Dispatch<MemberSearch>) => {
    api
      .get(`/filter/member`, { params: memberSearch, signal: signal })
      .then((res) => {
        dispatch({
          type: MEMBER_SEARCH,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const setGroupSearch = (groupSearch: Search, signal: any) => {
  return (dispatch: Dispatch<GroupSearch>) => {
    api
      .get(`/filter/group`, { params: groupSearch, signal: signal })
      .then((res) => {
        dispatch({
          type: GROUP_SEARCH,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const setAssetSearch = (assetSearch: Search, signal: any) => {
  return (dispatch: Dispatch<AssetSearch>) => {
    api
      .get(`/filter/asset`, {
        params: {
          companyId: assetSearch.companyId,
          titles: decodeURIComponent(assetSearch.titles ?? ""),
        },
        signal: signal,
      })
      .then((res) => {
        dispatch({
          type: ASSETS_SEARCH,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};
