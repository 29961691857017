import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Grid, Typography, List, ListItem } from "@mui/material";
import Footer from "../../Layout/Footer";
import { subPath } from "../../Constants/Global";
// import "../Help/Support.css";

//Componented Start
const HelpSupport: React.FC = () => {
  // Get the active value from backend api
  const [isDivVisible, setDivVisible] = useState(false);
  const [isDivVisible1, setDivVisible1] = useState(false);
  const [isDivVisible2, setDivVisible2] = useState(false);
  const [isDivVisible3, setDivVisible3] = useState(false);
  const [isDivVisible4, setDivVisible4] = useState(false);
  const [ready, setReady] = useState(false);
  //get started
  const [carddivisible, setcarddivisible] = useState(false);
  const togglecarddivisiblity = () => {
    setcarddivisible(!carddivisible);
  };
  //group
  const [carddivisibleGroup, setcarddivisibleGroup] = useState(false);
  const togglecarddivisiblityGroup = () => {
    setcarddivisibleGroup(!carddivisibleGroup);
  };
  const [carddivisibleGroupMember, setcarddivisibleGroupMember] =
    useState(false);
  const togglecarddivisiblityGroupMember = () => {
    setcarddivisibleGroupMember(!carddivisibleGroupMember);
  };
  const [carddivisibleGroupAdmin, setcarddivisibleGroupAdmin] = useState(false);
  const togglecarddivisiblityGroupAdmin = () => {
    setcarddivisibleGroupAdmin(!carddivisibleGroupAdmin);
  };
  const [carddivisibleGroupContributor, setcarddivisibleGroupContributor] =
    useState(false);
  const togglecarddivisiblityGroupContributor = () => {
    setcarddivisibleGroupContributor(!carddivisibleGroupContributor);
  };
  const [carddivisibleGroupviewer, setcarddivisibleGroupviewer] =
    useState(false);
  const togglecarddivisiblityGroupviewer = () => {
    setcarddivisibleGroupviewer(!carddivisibleGroupviewer);
  };
  //vaults
  const [carddivisibleVaults, setcarddivisibleVaults] = useState(false);
  const togglecarddivisiblityVaults = () => {
    setcarddivisibleVaults(!carddivisibleVaults);
  };
  const [carddivisibleCrtVaults, setcarddivisibleCrtVaults] = useState(false);
  const togglecarddivisiblityCrtVaults = () => {
    setcarddivisibleCrtVaults(!carddivisibleCrtVaults);
  };
  const [carddivisibleVaultSub, setcarddivisibleVaultSub] = useState(false);
  const togglecarddivisiblityVaultSub = () => {
    setcarddivisibleVaultSub(!carddivisibleVaultSub);
  };
  // asset
  const [carddivisibleAsset, setcarddivisibleAsset] = useState(false);
  const togglecarddivisiblityAsset = () => {
    setcarddivisibleAsset(!carddivisibleAsset);
  };
  const [carddivisibleAddAsset, setcarddivisibleAddAsset] = useState(false);
  const togglecarddivisiblityAddAsset = () => {
    setcarddivisibleAddAsset(!carddivisibleAddAsset);
  };
  const [carddivisibleAssetNotification, setcarddivisibleAssetNotification] =
    useState(false);
  const togglecarddivisiblityAssetNotification = () => {
    setcarddivisibleAssetNotification(!carddivisibleAssetNotification);
  };
  const [carddivisibleAssetManage, setcarddivisibleAssetManage] =
    useState(false);
  const togglecarddivisiblityAssetManage = () => {
    setcarddivisibleAssetManage(!carddivisibleAssetManage);
  };
  const [carddivisibleAssetEdit, setcarddivisibleAssetEdit] = useState(false);
  const togglecarddivisiblityAssetEdit = () => {
    setcarddivisibleAssetEdit(!carddivisibleAssetEdit);
  };
  //profile
  const [carddivisibleProfile, setcarddivisibleProfile] = useState(false);
  const togglecarddivisiblityProfile = () => {
    setcarddivisibleProfile(!carddivisibleProfile);
  };
  const [carddivisibleSrchMembr, setcarddivisibleSrchMembr] = useState(false);
  const togglecarddivisiblitySrchMembr = () => {
    setcarddivisibleSrchMembr(!carddivisibleSrchMembr);
  };
  const [carddivisibleMembrPrmsn, setcarddivisibleMembrPrmsn] = useState(false);
  const togglecarddivisiblityMembrPrmsn = () => {
    setcarddivisibleMembrPrmsn(!carddivisibleMembrPrmsn);
  };
  const [carddivisibleMembrAdminPrmsn, setcarddivisibleMembrAdminPrmsn] =
    useState(false);
  const togglecarddivisiblityMembrAdminPrmsn = () => {
    setcarddivisibleMembrAdminPrmsn(!carddivisibleMembrAdminPrmsn);
  };

  const toggleDivVisibility = () => {
    setDivVisible(!isDivVisible);
    // Hide the other div when the first div is clicked
    if (isDivVisible1 || isDivVisible2 || isDivVisible3 || isDivVisible4) {
      setDivVisible1(false);
      setDivVisible2(false);
      setDivVisible3(false);
      setDivVisible4(false);
    } else {
      setDivVisible(true);
    }
  };

  const toggleDivVisibility1 = () => {
    setDivVisible1(!isDivVisible1);
    // Hide the other div when the second div is clicked
    if (isDivVisible || isDivVisible2 || isDivVisible3 || isDivVisible4) {
      setDivVisible(false);
      setDivVisible2(false);
      setDivVisible3(false);
      setDivVisible4(false);
    } else {
      setDivVisible1(true);
    }
  };
  const toggleDivVisibility2 = () => {
    setDivVisible2(!isDivVisible2);
    // // Hide the other div when the second div is clicked
    if (isDivVisible || isDivVisible3 || isDivVisible1 || isDivVisible4) {
      setDivVisible(false);
      setDivVisible1(false);
      setDivVisible3(false);
      setDivVisible4(false);
    } else {
      setDivVisible2(true);
    }
  };
  const toggleDivVisibility3 = () => {
    setDivVisible3(!isDivVisible3);
    // // Hide the other div when the second div is clicked
    if (isDivVisible || isDivVisible2 || isDivVisible1 || isDivVisible4) {
      setDivVisible(false);
      setDivVisible1(false);
      setDivVisible2(false);
      setDivVisible4(false);
    } else {
      setDivVisible3(true);
    }
  };
  const toggleDivVisibility4 = () => {
    setDivVisible4(!isDivVisible4);
    // Hide the other div when the second div is clicked
    if (isDivVisible || isDivVisible2 || isDivVisible1 || isDivVisible3) {
      setDivVisible(false);
      setDivVisible1(false);
      setDivVisible2(false);
      setDivVisible3(false);
    } else {
      setDivVisible4(true);
    }
  };
  useEffect(() => {
    // Use this effect to watch for changes in the 'ready' variable
    if (ready) {
      // Toggle the visibility when 'ready' becomes true
      setDivVisible((prevVisible) => !prevVisible);
    }
  }, [ready]);

  useEffect(() => {
    // Simulate 'ready' becoming true after a delay (e.g., 3 seconds)
    const delay = 0;
    const timer = setTimeout(() => {
      setReady(true);
    }, delay);

    // Clear the timer if the component unmounts before it triggers
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          justifyContent="center"
          sx={{ padding: "12px 12px 0px 12px" }}
        >
          {/* sidebar */}
          <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
            <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
              <CardContent>
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    xl={12}
                    lg={12}
                    sm={12}
                    sx={{ display: "flex", alignItems: "self-end" }}
                  >
                    <Grid sx={{ display: "flex", alignItems: "unset" }}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "17px",
                          fontWeight: "550",
                          padding: "10px 10px 10px 10px",
                        }}
                      >
                        Help & support
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 0 }}>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: "#708090",
                            fontSize: "12px",
                            // fontWeight: "200",
                            // padding: "0px 0px 0px 10px",
                          }}
                        >
                          Don't worry We are here with you.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 1 }} onClick={toggleDivVisibility}>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        className={` ${
                          isDivVisible
                            ? "normalblue ptop-5 pbottom-5 br_5 padding_card"
                            : "row_hover ptop-5 pbottom-5 br_5 padding_card"
                        }`}
                      >
                        <Typography
                          sx={{
                            color: `${isDivVisible ? "white" : "#708090"}`,
                            fontSize: "14px",
                            padding: "10px 10px 10px 10px",
                          }}
                          className="hideDot"
                          // onClick={toggleDivVisibility}
                        >
                          Getting Started
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 1 }} onClick={toggleDivVisibility1}>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        className={` ${
                          isDivVisible1
                            ? "normalblue ptop-5 pbottom-5 br_5"
                            : "row_hover ptop-5 pbottom-5 br_5 padding_card"
                        }`}
                      >
                        <Typography
                          sx={{
                            color: `${isDivVisible1 ? "white" : "#708090"}`,
                            fontSize: "14px",
                            padding: "10px 10px 10px 10px",
                          }}
                          className="hideDot"
                          // onClick={toggleDivVisibility1}
                        >
                          Groups
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 1 }} onClick={toggleDivVisibility2}>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        className={` ${
                          isDivVisible2
                            ? "normalblue ptop-5 pbottom-5 br_5"
                            : "row_hover ptop-5 pbottom-5 br_5 padding_card"
                        }`}
                      >
                        <Typography
                          sx={{
                            color: `${isDivVisible2 ? "white" : "#708090"}`,
                            fontSize: "14px",
                            padding: "10px 10px 10px 10px",
                          }}
                          className="hideDot"
                          // onClick={toggleDivVisibility2}
                        >
                          Vaults
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 1 }}  onClick={toggleDivVisibility3}>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        className={` ${
                          isDivVisible3
                            ? "normalblue ptop-5 pbottom-5 br_5"
                            : "row_hover ptop-5 pbottom-5 br_5 padding_card"
                        }`}
                      >
                        <Typography
                          sx={{
                            color: `${isDivVisible3 ? "white" : "#708090"}`,
                            fontSize: "14px",
                            padding: "10px 10px 10px 10px",
                          }}
                          className="hideDot"
                          // onClick={toggleDivVisibility3}
                        >
                          Assets
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xl={12} lg={12} sm={12} sx={{ mt: 1 }}  onClick={toggleDivVisibility4}>
                      <Grid
                        sx={{ display: "flex", alignItems: "center" }}
                        className={` ${
                          isDivVisible4
                            ? "normalblue ptop-5 pbottom-5 br_5"
                            : "row_hover ptop-5 pbottom-5 br_5 padding_card"
                        }`}
                      >
                        <Typography
                          sx={{
                            color: `${isDivVisible4 ? "white" : "#708090"}`,
                            fontSize: "14px",
                            padding: "10px 10px 10px 10px",
                          }}
                          className="hideDot"
                          // onClick={toggleDivVisibility4}
                        >
                          Profile
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* main content */}
          <Grid item xs={12} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
            <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
              {/* start */}
              {isDivVisible && (
                <CardContent>
                  <Grid container>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "unset" }}
                      >
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "17px",
                            fontWeight: "550",
                          }}
                        >
                          Getting Started
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className="setting-notification"
                          sx={{ color: "#708090", fontSize: "15px" }}
                        >
                          {/* Lorem ipsum dofffd */}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblity}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisible ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          How Trove works?
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      ></Grid>

                      {carddivisible && (
                        <>
                          <Grid
                            item
                            xs={4}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                          </Grid>
                          <Grid item xs={4}>
                            <Box
                              component="img"
                              src={`..${subPath}/assets/images/support_image.png`}
                              width="250px"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {" "}
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              sx={{
                                color: "#708090",
                              }}
                              className="height_scroll"
                            >
                              <Box sx={{ m: 1, color: "#708090" }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "15px" }}
                                  gutterBottom
                                >
                                  <b>Organization User Access:</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "13px", my: 2 }}
                                  gutterBottom
                                >
                                  This means that people from different
                                  organizations can access and use a system or
                                  platform to share and gather knowledge. It's a
                                  way for different groups or companies to
                                  collaborate and exchange information.
                                </Typography>
                              </Box>
                              <Box sx={{ m: 1, color: "#708090", my: 4 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "15px" }}
                                  gutterBottom
                                >
                                  <b>Groups:</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "13px", my: 2 }}
                                  gutterBottom
                                >
                                  These are like clubs or teams within the
                                  system. Each group is a collection of people
                                  who want to share knowledge. People in the
                                  same group can access information and
                                  resources based on the permissions set for
                                  that group.
                                </Typography>
                              </Box>
                              <Box sx={{ m: 1, color: "#708090", my: 4 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "15px" }}
                                  gutterBottom
                                >
                                  <b>Vaults:</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "13px", my: 2 }}
                                  gutterBottom
                                >
                                  Think of these as folders or categories.
                                  Vaults help organize knowledge so that it's
                                  easy to find. Each group can have its own set
                                  of vaults, and the information inside is
                                  neatly sorted into these categories for easy
                                  access.
                                </Typography>
                              </Box>

                              <Box sx={{ m: 1, color: "#708090", my: 4 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "15px" }}
                                  gutterBottom
                                >
                                  <b>Asset:</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "13px", my: 2 }}
                                  gutterBottom
                                >
                                  This is the actual knowledge you're storing.
                                  It can be text (like documents or articles) or
                                  media (like pictures, audio, or videos). So,
                                  an asset is like a piece of information or
                                  content that you want to share with others.
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>{" "}
                        </>
                      )}
                      {carddivisible ? null : <hr style={{ width: "100%" }} />}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              {/* group */}
              {isDivVisible1 && (
                <CardContent sx={{ mt: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "unset" }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "550",
                      }}
                    >
                      Groups
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className="setting-notification"
                      sx={{ color: "#708090", fontSize: "15px" }}
                    >
                      {/* Lorem ipsum dofffd */}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      style={{ maxHeight: "680px", overflowY: "auto" }}
                    >
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      {/* <div className="height_scroll"> */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityGroup}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleGroup ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          How to create groups?
                        </Typography>
                      </Grid>

                      {carddivisibleGroup && (
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              color: "#708090",
                              width: "100%",
                              height: "60%",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                            >
                              On the homepage of the knowledge portal, look for
                              the "Create Group" option
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                              gutterBottom
                            >
                              Click on "Create Group," and a pop-up window will
                              appear where you can fill in the group details.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                              gutterBottom
                            >
                              Provide a name for the group and choose a profile
                              picture or icon if applicable.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                              gutterBottom
                            >
                              Select the members you want to add to the group.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px" }}
                              gutterBottom
                            >
                              Once you've filled in the details and selected
                              members, click the "Create Group" button.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleGroup ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* group and member*/}

                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityGroupMember}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleGroupMember ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          How to add members to the group?
                        </Typography>
                      </Grid>

                      {carddivisibleGroupMember && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{
                              color: "#708090",
                              width: "100%",
                            }}
                            className="right_scroll"
                          >
                            <Box sx={{ m: 1, color: "#708090", my: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Log In as a Group Administrator:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Make sure you are logged in with administrative
                                privileges for the knowledge portal..
                              </Typography>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Access Group Edit:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Go to the specific group where you want to add
                                members. Look for an option called "Group Edit"
                                or something similar.
                              </Typography>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Add Members:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Inside the "Group Edit" section, there should be
                                an option to add members. Click on it.
                              </Typography>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Fill in Member Details:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Provide the necessary information for the new
                                members you want to add, such as their names or
                                email addresses.
                              </Typography>
                            </Box>{" "}
                            <Box sx={{ m: 1, color: "#708090", my: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Save or Confirm:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                After adding the member details, save or confirm
                                your changes. The new members should now be
                                added to the group.
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleGroupMember ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* group admin */}

                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityGroupAdmin}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleGroupAdmin ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Who is admin/ organization admin?
                        </Typography>
                      </Grid>
                      {carddivisibleGroupAdmin && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{
                              color: "#708090",
                              width: "100%",
                            }}
                            className="right_scroll"
                          >
                            <Box sx={{ m: 1, color: "#708090" }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Adding and Removing Members:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Admins can usually add or remove members from
                                the group. They have the authority to manage the
                                group's membership.
                              </Typography>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090" }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Managing Group Settings:</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                Admins can usually add or remove members from
                                the group. They have the authority to manage the
                                group's membership.
                              </Typography>
                              <ul>
                                <li style={{ fontSize: "14px" }}>
                                  <b>Managing Groups:</b>
                                </li>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "13px" }}
                                    gutterBottom
                                  >
                                    Organization Admins have the authority to
                                    create, modify, and delete groups within the
                                    portal. Groups can be used to organize
                                    employees and control access to knowledge
                                  </Typography>
                                </ListItem>
                                <li style={{ fontSize: "14px" }}>
                                  <b>Assigning Permissions:</b>
                                </li>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px" }}
                                    gutterBottom
                                  >
                                    They can assign various permissions to
                                    employees within their organization,
                                    including the ability to create and manage
                                    groups, view specific groups, and edit
                                    knowledge articles. This delegation of
                                    permissions allows for fine-grained control
                                    over who can access and manage certain parts
                                    of the portal.
                                  </Typography>
                                </ListItem>
                                <li style={{ fontSize: "14px" }}>
                                  <b>Access to All Groups:</b>
                                </li>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px" }}
                                    gutterBottom
                                  >
                                    Organization Admins have the privilege to
                                    view and access all groups within the
                                    knowledge management portal. This allows
                                    them to oversee the entire organizational
                                    structure and ensure that knowledge is
                                    appropriately categorized and accessible.
                                  </Typography>
                                </ListItem>
                                <li style={{ fontSize: "14px" }}>
                                  <b>User Management:</b>
                                </li>
                                <ListItem>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "14px" }}
                                    gutterBottom
                                  >
                                    Organization Admins have the privilege to
                                    view and access all groups within the
                                    knowledge management portal. This allows
                                    them to oversee the entire organizational
                                    structure and ensure that knowledge is
                                    appropriately categorized and accessible.
                                  </Typography>
                                </ListItem>
                              </ul>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleGroupAdmin ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* group contibutor */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityGroupContributor}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleGroupContributor ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Who is the contributor?
                        </Typography>
                      </Grid>

                      {carddivisibleGroupContributor && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{
                              color: "#708090",
                              width: "100%",
                              height: "90%",
                            }}
                          >
                            <ul>
                              <li style={{ fontSize: "15px" }}>
                                <b>Creating Content:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                Contributors generate new knowledge articles,
                                documents, or other types of content within
                                their assigned group. This content can include
                                informative articles, tutorials, guides, or any
                                information relevant to the group's focus.
                              </ListItem>
                              <li style={{ fontSize: "15px" }}>
                                <b>Editing and Updating:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                Contributors are responsible for maintaining the
                                quality and accuracy of content by reviewing and
                                updating existing articles as needed. This
                                includes keeping information current and
                                relevant.
                              </ListItem>
                              <li style={{ fontSize: "15px" }}>
                                <b>Uploading Documents:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                They may upload documents, files, and other
                                resources that are valuable to the group's
                                objectives. These resources can be referenced by
                                other group members.
                              </ListItem>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleGroupContributor ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* group viewer. */}

                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityGroupviewer}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleGroupviewer ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Who is the viewer?
                        </Typography>
                      </Grid>

                      {carddivisibleGroupviewer && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Grid
                            sx={{
                              color: "#708090",
                              width: "100%",
                            }}
                          >
                            <ul>
                              <li style={{ fontSize: "15px" }}>
                                <b>Viewing Content:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                Viewers have the ability to access and read
                                content within the portal. They can view
                                articles, documents, discussions, and other
                                resources shared within the vault.
                              </ListItem>
                              <li style={{ fontSize: "15px" }}>
                                <b>Commenting and Feedback:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                In some cases, viewers may be allowed to leave
                                comments or provide feedback on the content they
                                view, enabling them to engage in discussions or
                                ask questions.
                              </ListItem>
                              <li style={{ fontSize: "15px" }}>
                                <b>No Content Creation:</b>
                              </li>
                              <ListItem sx={{ fontSize: "14px", mb: 1 }}>
                                Unlike contributors or editors, viewers
                                typically do not have the permission to create
                                new content, edit existing articles, or upload
                                documents.
                              </ListItem>
                            </ul>
                          </Grid>
                        </Grid>
                      )}
                      {carddivisibleGroupviewer ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* </div> */}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              {/* vault */}
              {isDivVisible2 && (
                <CardContent sx={{ mt: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "unset" }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "550",
                      }}
                    >
                      Vaults
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className="setting-notification"
                      sx={{ color: "#708090", fontSize: "15px" }}
                    >
                      {/* Lorem ipsum dofffd */}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      style={{ maxHeight: "680px", overflowY: "auto" }}
                    >
                      {/* what is vault */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityVaults}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleVaults ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          What is vaults?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleVaults && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "14px", my: 1 }}
                            >
                              <b>Vaults:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "14px", my: 1 }}
                              gutterBottom
                            >
                              Vaults help organize knowledge so that it's easy
                              to find. Each group can have its own set of
                              vaults, and the information inside is neatly
                              sorted into these categories for easy access.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleVaults ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* How to create vault. */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityCrtVaults}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleCrtVaults ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          How to create vault?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleCrtVaults && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090", width: "100%" }}>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Log In:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Start by logging into your knowledge management
                              portal using your credentials. You should be on
                              the homepage or dashboard.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Access the Vault Creation Option:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              As you mentioned, you can see an option for
                              creating vaults on the homepage. Look for a button
                              or link that says, “Create Vault”.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Provide Vault Details:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Click on the "Create Vault" option, and you will
                              likely add a group detail.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Vault Name:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Give your vault a descriptive and meaningful name.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Access Permissions:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Determine who can access the vault. You may have
                              options to make it private (accessible only to
                              you) or shared with specific individuals or
                              groups.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Save or Create:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              After entering the necessary details, there should
                              be a button or option to "Create" or "Save" the
                              vault. Click this button to proceed.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleCrtVaults ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* Who can create vaults */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityVaultSub}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleVaultSub ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Who can create vaults?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleVaultSub && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{ color: "#708090" }}
                            className="vault_scroll"
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                            >
                              Group members with the assigned role should now be
                              able to create vaults. They can typically do this
                              from the portal's interface, following the steps
                              mentioned earlier for creating a vault.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Add Assets to Vaults:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Once a vault is created, group members with the
                              assigned permissions can add assets to it. This
                              usually involves navigating to the specific vault,
                              selecting an "Add Asset" or similar option, and
                              then uploading or linking to the desired content
                              or documents
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>View:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              All user roles, including Group Admins,
                              Contributors, and Group Members, have the ability
                              to view content within the portal.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Comment:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              All user roles, including Group Admins,
                              Contributors, and Group Members, can leave
                              comments or provide feedback on content.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Edit:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Group Admins and Contributors have permission to
                              edit content within the portal. This includes the
                              ability to make changes to articles, documents,
                              and other resources.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Delete:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Group Admins and Contributors have permission to
                              delete content within the portal.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Group Admin:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Group Admins have additional administrative
                              privileges within the context of managing groups.
                              This may include creating, modifying, and deleting
                              groups, as well as controlling access to content
                              and features based on group membership.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Contributor:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Contributors have the ability to create and edit
                              content, leave comments, and have some control
                              over group-related activities. However, they do
                              not have permission to delete content or manage
                              groups.
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Group Members:</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                              gutterBottom
                            >
                              Group Members can view content, leave comments,
                              and participate in discussions. However, they do
                              not have permissions to edit or delete content,
                              nor do they have administrative control over group
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleVaultSub ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              {/* Asset */}
              {isDivVisible3 && (
                <CardContent sx={{ mt: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "unset" }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "550",
                      }}
                    >
                      Assets
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className="setting-notification"
                      sx={{ color: "#708090", fontSize: "15px" }}
                    >
                      {/* Lorem ipsum dofffd */}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      style={{ maxHeight: "680px", overflowY: "auto" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityAsset}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleAsset ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          What are assets?
                        </Typography>
                      </Grid>
                      {carddivisibleAsset ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleAsset && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "14px", my: 3 }}
                            >
                              This is the actual knowledge you're storing. It
                              can be text (like documents or articles) or media
                              (like pictures, audio, or videos). So, an asset is
                              like a piece of information or content that you
                              want to share with others.
                            </Typography>
                          </Box>
                        </Grid>
                      )}

                      {/*add Asset  */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityAddAsset}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleAddAsset ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          How to add asset?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}

                      {carddivisibleAddAsset && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "14px",
                                my: 3,
                                textAlign: "justify",
                              }}
                            >
                              Start by clicking the "Add assets" button located
                              on the home page. Afterward, you will be presented
                              with a list of templates to choose from, enabling
                              you to select the one that best aligns with your
                              specific requirements. Within these templates,
                              ensure that all mandatory information is
                              comprehensively filled out. Additionally, you have
                              the option to attach relevant files or documents
                              to the asset. Furthermore, you can conveniently
                              share these assets with multiple groups as needed.
                              Finally, after all the details have been provided
                              and the asset is ready for distribution, proceed
                              to submit it.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleAddAsset ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* notification in Asset  */}

                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityAssetNotification}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleAssetNotification ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Notification?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleAssetNotification && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <ul>
                              <li style={{ fontSize: "15px" }}>
                                <b> Log In: </b>
                              </li>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px", my: 1 }}
                              >
                                Ensure you are logged in to the platform or
                                application for which you want to view
                                notifications.
                              </Typography>
                              <li style={{ fontSize: "15px" }}>
                                <b>Website or Web Application: </b>
                              </li>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px", my: 1 }}
                              >
                                Look for a bell icon, a chat bubble icon, or a
                                dedicated "Notifications" tab or section usually
                                located in the top navigation bar or sidebar.
                              </Typography>
                            </ul>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                            >
                              <b>a. Uploading Assets: </b> You will receive
                              notifications when assets are uploaded to your
                              account
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                            >
                              <b>b. 'Grams' for Assets: </b> Notifications will
                              be sent when you receive 'grams' (appreciation)
                              for your assets.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                            >
                              <b> c. Comments: </b>Notifications will alert you
                              when others comment on your assets.
                            </Typography>

                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 3 }}
                            >
                              <b>d. Permissions: </b>Notifications will inform
                              you when permissions are granted or removed for
                              your assets.
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>
                                Organization Announcements and Security Alerts{" "}
                              </b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                            >
                              You will receive notifications for general
                              announcements within the organization and
                              important security alerts.
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Event and Conference Notifications</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                            >
                              After attending events or conferences, you will
                              receive special notifications with relevant
                              updates and information.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleAssetNotification ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* manage notification in Asset */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityAssetManage}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleAssetManage ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Managing Notifications?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}

                      {carddivisibleAssetManage && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{ color: "#708090" }}
                            className="Asset_scroll"
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Viewing Notifications</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                            >
                              You can view your notifications in the
                              Notification Center. Notifications will display
                              the name, profile, and timing of messages. Unread
                              messages will be marked with dots.
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Marking Notifications as Read</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                            >
                              To mark all notifications as read, click on 'Mark
                              All as Read' in the Notification Center. This
                              action will clear the unread status of all
                              notification messages.
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Email Notification Settings</b>
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              Muting/Unmuting Groups
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "13px" }}>
                              Users can control group-specific notifications by
                              muting or unmuting individual groups. Here's how:
                            </Typography>
                            <ul>
                              <li style={{ fontSize: "15px" }}>
                                <b>Mute a Group: </b>
                              </li>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "13px", my: 1 }}
                              >
                                Click on the three-dot menu on the group page
                                and select "Mute Group." This action will mute
                                notifications for that specific group.
                              </Typography>
                              <li style={{ fontSize: "15px" }}>
                                <b>Unmute a Group: </b>
                              </li>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "13px", my: 1 }}
                              >
                                Click on the three-dot menu again on the group
                                page and select "Unmute Group" to resume
                                receiving notifications from that group.
                              </Typography>
                            </ul>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Turning Off Group Notifications</b>
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "13px" }}>
                              Users have the option to turn off notifications
                              for all groups they are a part of. This means they
                              will not receive any notifications from any group.
                              To do this:
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Access your notification settings in your
                                profile or account settings.
                              </li>
                              <li className="fs-13 p-20">
                                Find the option to "Turn Off Group
                                Notifications" or a similar feature.
                              </li>
                              <li className="fs-13 p-20">
                                Toggle this option to disable group
                                notifications.
                              </li>
                            </ul>

                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Personal Notifications</b>
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "13px" }}>
                              Users can customize their notification preferences
                              for personal notifications. They can choose to
                              enable or disable notifications related to their
                              owned assets, comments, grams (appreciation), and
                              mentions. Here's how:
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Access your notification settings in your
                                profile or account settings.
                              </li>
                              <li className="fs-13 p-20">
                                Locate the "Personal Notifications" section.
                              </li>
                              <li className="fs-13 p-20">
                                Toggle the options for comments, grams, and
                                mentions based on your preferences
                              </li>
                            </ul>

                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 1 }}
                            >
                              <b>Admin-Defined Notification Frequency</b>
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "13px" }}>
                              Admin users have the capability to set the
                              frequency of notifications for all organization
                              employees. Employees can choose from daily,
                              weekly, or monthly notification preferences.
                              Here's how this works:
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Organization admins can access the admin panel
                                or settings.
                              </li>
                              <li className="fs-13 p-20">
                                Find the option "Set Notification Frequency."
                              </li>
                              <li className="fs-13 p-20">
                                Configure the available notification frequency
                                options (daily, weekly, monthly).
                              </li>
                              <li className="fs-13 p-20">
                                Employees can then select their preferred
                                notification frequency in their profile
                                oraccount settings.
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleAssetManage ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* Edit */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityAssetEdit}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleAssetEdit ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Edit?
                        </Typography>
                      </Grid>
                      {/* 
                      <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleAssetEdit && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{ color: "#708090" }}
                            className="AssetEdit_scroll"
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 2 }}
                            >
                              <b>Access the Group Page:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Log in to your organization's platform.
                              </li>
                              <li className="fs-13 p-20">
                                Navigate to the group you wish to edit by
                                clicking on the relevant section or menu item.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Locate the Three Dots:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                On the group page, look for the three dots (...)
                                associated with the group's name or icon. These
                                dots indicate additional options.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Click on the Three Dots:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Click on the three dots to open a dropdown menu
                                with various options.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Select 'Edit':</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                From the dropdown menu, click on the 'Edit'
                                option. This action will take you to the group
                                editing interface.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Edit Group Details:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Within the group editing interface, you will
                                find options to modify group details, such as
                                the group name, members, and group picture.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Make Desired Changes:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Edit the group information as needed. You can
                                change the group name, add or remove members, or
                                update the group picture.
                              </li>
                            </ul>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Review Changes:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Take a moment to review the changes you've made
                                to ensure they are accurate and reflect your
                                intentions.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Choose an Action:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                At the bottom of the editing interface, you will
                                typically have two options:
                              </li>
                              <li className="fs-13 p-20">
                                Click 'Update Changes' to save the
                                modifications.
                              </li>
                              <li className="fs-13 p-20">
                                Click 'Cancel' if you want to discard the
                                changes and exit the editing interface.
                              </li>
                            </ul>
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "15px", my: 2 }}
                            >
                              <b>Editing a Vault:</b>
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Access the View Vault Page:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Log in to your organization's platform.
                              </li>
                              <li className="fs-13 p-20">
                                Navigate to the specific vault you want to edit,
                                typically by clicking on the vault's name or
                                icon.
                              </li>
                            </ul>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Locate the Three Dots:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                On the view vault page, look for the three dots
                                (...) associated with the vault's name or icon.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Select 'Edit':</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                From the dropdown menu, choose the 'Edit'
                                option. This action will take you to the vault
                                editing interface.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Edit Vault Details:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Within the vault editing interface, you will
                                typically see options to modify the vault's name
                                and its associated group.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Make Desired Changes:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Edit the vault information as needed. You can
                                change the vault's name or select a different
                                group to associate with the vault.
                              </li>
                            </ul>

                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Review Changes:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Carefully review the changes you've made to
                                ensure they are correct.
                              </li>
                            </ul>
                            <Typography variant="h6" sx={{ fontSize: "15px" }}>
                              <b>Choose an Action:</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                At the bottom of the editing interface, you will
                                usually have two options:
                              </li>
                              <li className="fs-13 p-20">
                                Click 'Update Changes' to save the
                                modifications.
                              </li>
                              <li className="fs-13 p-20">
                                Click 'Cancel' if you want to discard the
                                changes and exit the editing interface.
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleAssetEdit ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              {/* profile */}
              {isDivVisible4 && (
                <CardContent sx={{ mt: 1 }}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "unset" }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "17px",
                        fontWeight: "550",
                      }}
                    >
                      Profile
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      className="setting-notification"
                      sx={{ color: "#708090", fontSize: "15px" }}
                    >
                      {/* Lorem ipsum dofffd */}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid
                      container
                      sx={{ mt: 1 }}
                      style={{ maxHeight: "680px", overflowY: "auto" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityProfile}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleProfile ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          View Profile Information?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleProfile && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{ color: "#708090" }}
                            className="right_scroll"
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "13px", my: 1 }}
                            >
                              On your Profile Page, you can view the following
                              information:
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                <b>Profile Image:</b> Your chosen profile
                                picture
                              </li>
                              <li className="fs-13 p-20">
                                <b>Job Description:</b> Your job title or
                                description.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Total Grams:</b> The total number of 'Grams'
                                you have earned.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Email Address:</b> Your registered email
                                address.
                              </li>
                              <li className="fs-13 p-20">
                                On this page, you can easily access all the
                                assets you've created within your respective
                                groups.
                              </li>
                              <li className="fs-13 p-20">
                                You can see the following details for each
                                asset:
                              </li>
                              <li className="fs-13 p-20">
                                <b>Grams Earned:</b>The number of 'Grams' you've
                                earned for that asset.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Views: </b> The total number of times your
                                asset has been viewed.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Comments: </b> How many comments your asset
                                has received.
                              </li>
                            </ul>

                            <Box sx={{ m: 1, color: "#708090", my: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Edit Profile</b>
                              </Typography>
                              <ul>
                                <li className="fs-13 p-20">
                                  To edit your profile, click on the 'Edit
                                  Profile' button.
                                </li>
                                <li className="fs-13 p-20">
                                  You can then update your:
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Name:</b> Change your displayed name.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Profile Picture:</b> Upload a new profile
                                  picture.
                                </li>
                                <li className="fs-13 p-20">
                                  Click 'Save' or 'Update' to save your changes.
                                </li>
                              </ul>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Your Groups</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                By switching to 'Your Groups,' you get an
                                overview of the groups you've created.
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                                gutterBottom
                              >
                                This section displays:
                              </Typography>
                              <ul>
                                <li className="fs-13 p-20">
                                  <b>Group Names:</b> Names of the groups you've
                                  established.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Total Assets:</b> The combined number of
                                  assets within each group.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Members:</b> The total number of members in
                                  each of your groups.
                                </li>
                              </ul>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Access the Organization Profile Page</b>
                              </Typography>
                              <ul>
                                <li className="fs-13 p-20">
                                  Log in to your account on our platform.
                                </li>
                                <li className="fs-13 p-20">
                                  Ensure that you have organization admin
                                  privileges to access this page.
                                </li>
                                <li className="fs-13 p-20">
                                  Navigate to the Organization Profile Page by
                                  selecting your organization's name or
                                </li>
                                <li className="fs-13 p-20">
                                  accessing it through the platform's menu.
                                </li>
                              </ul>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Profile Overview org admin</b>
                              </Typography>
                              <ul>
                                <li className="fs-13 p-20">
                                  On this page, you can view essential
                                  information about your organization:
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Organization Name:</b>The name of your
                                  organization.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Total Groups:</b>The number of groups
                                  within your organization.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Total Assets:</b>The number of assets
                                  available.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Total Members:</b> The total number of
                                  members in your organization.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Edit Profile:</b> Click this option to make
                                  changes to your organization's name and
                                  profile picture, or to delete the organization
                                </li>
                              </ul>
                            </Box>
                            <Box sx={{ m: 1, color: "#708090", my: 3 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: "15px" }}
                                gutterBottom
                              >
                                <b>Member List</b>
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "14px" }}
                                gutterBottom
                              >
                                You can see a list of all members within your
                                organization, including their:
                              </Typography>
                              <ul>
                                <li className="fs-13 p-20">
                                  <b>Names:</b>The names of organization
                                  members.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Employee IDs:</b> Unique identifiers for
                                  each member.
                                </li>
                                <li className="fs-13 p-20">
                                  <b>Job Positions:</b> The job positions or
                                  titles of members.
                                </li>
                              </ul>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleProfile ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* profile member */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblitySrchMembr}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleSrchMembr ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Search for Members?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleSrchMembr && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                            >
                              Use the search bar to quickly find specific
                              members within your organization.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 1 }}
                            >
                              Member Filters
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Narrow down your member search with filters:
                              </li>
                              <li className="fs-13 p-20">
                                <b> Create Group Option:</b> Filter members who
                                have permission to create groups.
                              </li>
                              <li className="fs-13 p-20">
                                <b> Create Vault Option: </b> Filter members who
                                have permission to create vaults.
                              </li>
                              <li className="fs-13 p-20">
                                <b> Recently Active:</b> Find the most recently
                                active members.
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleSrchMembr ? null : (
                        <hr style={{ width: "100%" }} />
                      )}

                      {/* Manage Member Permissions in Profile*/}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityMembrPrmsn}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleMembrPrmsn ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Manage Member Permissions?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleMembrPrmsn && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box sx={{ color: "#708090" }}>
                            <ul>
                              <li className="fs-13 p-20">
                                You can select one or multiple members using
                                checkboxes
                              </li>
                              <li className="fs-13 p-20">
                                After selection, click on a popup menu to:
                              </li>
                              <li className="fs-13 p-20">
                                <b>Grant Permissions:</b> Assign or withdraw
                                permission for creating groups and vaults.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Deactivate Members:</b> Temporarily
                                deactivate members within your organization.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Apply for Changes:</b>Confirm and apply the
                                changes you've made
                              </li>
                            </ul>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "14px", my: 3 }}
                            >
                              <b>Edit Organization Details</b>
                            </Typography>
                            <ul>
                              <li className="fs-13 p-20">
                                Click 'Edit Organization' to update your
                                organization's information:
                              </li>
                              <li className="fs-13 p-20">
                                <b> Change Name:</b> Modify the organization's
                                name.
                              </li>
                              <li className="fs-13 p-20">
                                <b>Profile Picture:</b> Update the
                                organization's profile picture.
                              </li>

                              <li className="fs-13 p-20">
                                <b>Delete Organization:</b> Delete your
                                organization if needed.
                              </li>
                              <li className="fs-13 p-20">
                                Confirm changes by clicking 'Update Details' or
                                cancel by clicking 'Cancel.'
                              </li>
                            </ul>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleMembrPrmsn ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                      {/* Withdraw Group Admin Permissions */}
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{ color: "#119bff", mt: 2, fontSize: "15px" }}
                          onClick={togglecarddivisiblityMembrAdminPrmsn}
                          style={{ cursor: "pointer" }}
                        >
                          {carddivisibleMembrAdminPrmsn ? (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              -
                            </span>
                          ) : (
                            <span
                              style={{ fontSize: "20px", paddingRight: "5px" }}
                            >
                              +
                            </span>
                          )}
                          Withdraw Group Admin Permissions?
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {" "}
                      </Grid> */}
                      {carddivisibleMembrAdminPrmsn && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: "flex", textAlign: "justify" }}
                        >
                          <Box
                            sx={{ color: "#708090" }}
                            className="AssetEdit_scroll"
                          >
                            <ul>
                              <li className="fs-13 p-20">
                                To withdraw permissions for creating groups from
                                a member:
                              </li>
                              <li className="fs-13 p-20">
                                Click the checkbox next to the member's name.
                              </li>
                              <li className="fs-13 p-20">
                                A popup will appear listing groups where they
                                are administrators.
                              </li>
                              <li className="fs-13 p-20">
                                Click on each group to view the list of members.
                              </li>
                              <li className="fs-13 p-20">
                                Choose to make someone else an admin if needed.
                              </li>
                              <li className="fs-13 p-20">
                                Admins can also search for members within the
                                popup.
                              </li>
                              <li className="fs-13 p-20">
                                This process applies when a group has only one
                                admin.
                              </li>
                            </ul>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              Start by logging into your account on the Trove
                              using your login credentials.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 2:</b> Once you're logged in, locate your
                              profile picture in the top right corner of the
                              portal's interface.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 3:</b>Click on your profile picture. This
                              action will trigger a popup menu to appear.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 4:</b> In the popup menu, look for and
                              click on the option labeled "My Profile." Clicking
                              on this option will take you to your personal
                              profile page.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 5:</b> On your personal profile page, you
                              can view and manage various aspects of your
                              individual profile, such as your name, profile
                              picture, and other relevant details.
                            </Typography>

                            <Typography
                              variant="body1"
                              sx={{ fontSize: "15px", my: 3 }}
                              gutterBottom
                            >
                              <b>Org admin</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 1:</b>If you have organization admin
                              privileges, you will notice an additional option
                              in the same popup menu," Organization" Click on
                              this option to proceed to your organization's
                              dedicated page.
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "13px", my: 2 }}
                              gutterBottom
                            >
                              <b>Step 2:</b>On the organization page, you can
                              access and manage information related to your
                              organization. This includes details about the
                              user's role and permissions.
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {carddivisibleMembrAdminPrmsn ? null : (
                        <hr style={{ width: "100%" }} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default HelpSupport;
