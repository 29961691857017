const initialState = {
  itemName: "",
  searchValue: "",
  memberSearch: [],
  groupSearch: [],
  assetsSearch: {
    results: [],
    hashTags: [],
  },
  relatedSearch: [],
  sortByValue: "Recent",
};

const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ITEM_NAME":
      return {
        ...state,
        itemName: action.payload,
      };
    case "SEARCH_VALUE":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "RELATED_SEARCH":
      return {
        ...state,
        relatedSearch: action.payload,
      };
    case "MEMBER_SEARCH":
      return {
        ...state,
        memberSearch: action.payload,
      };
    case "FIND_MEMBER_SEARCH":
      return {
        ...state,
        memberSearch: action.payload,
      };
    case "FW_FIND_MEMBER_SEARCH":

      return {
        ...state,
        memberSearchFw: action.payload,
      };
    case "GROUP_SEARCH":
      return {
        ...state,
        groupSearch: action.payload,
      };
    case "ASSETS_SEARCH":
      return {
        ...state,
        assetsSearch: action.payload,
      };
    case "SORT_BY":
      return {
        ...state,
        sortByValue: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
