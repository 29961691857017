import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../Views/Components/Settings/Settings.css";
import { Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BiEnvelope } from "react-icons/bi";
import { subPath } from "../Constants/Global";

//Componented Start
const OrgSidebar: React.FC = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<boolean>(true);

  const handleMenuClick = () => {
    navigate("/org-settings");
  };

  const handleMenuClick1 = () => {
    navigate("/listofDynamicTemplate");
  };

  // set the value of the tab
  useEffect(() => {
    const path = window.location.pathname;
    if (path === subPath + "/org-settings" || path === "/org-settings") {
      setActiveMenu(true);
    } else if (
      path === subPath + "/listofDynamicTemplate" ||
      path === "/listofDynamicTemplate"
    ) {
      setActiveMenu(false);
    }
  }, []);

  return (
    <>
      <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
        <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
          <CardContent>
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid sx={{ display: "flex", alignItems: "self-end", mt: 1 }}>
                <Grid sx={{ display: "flex", alignItems: "unset" }}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Organisation Settings
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box className="sidebar_scroll">
              <Grid container>
                <Grid xl={12} lg={12} sm={12} sx={{ mt: 3 }}>
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      className="color"
                      sx={{
                        fontSize: "13px",
                        lineHeight: "2px",
                      }}
                    >
                      Manage your Organisation
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3.5 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  onClick={handleMenuClick}
                  className={` ${activeMenu ? "active" : "inactive"}`}
                >
                  <Grid
                    container
                    sx={{ marginLeft: "10px" }}
                    alignItems="center"
                  >
                    <BiEnvelope
                      style={{
                        fontSize: "18px",
                        color: activeMenu ? "white" : "#708090",
                      }}
                    />
                    <Button
                      className={`fw-500 fs-13 capitalize  ${
                        activeMenu ? "activeBtn" : "color_7080"
                      }`}
                    >
                      Mail Notification
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={` ${activeMenu ? "inactive" : "active"}`}
                  onClick={handleMenuClick1}
                >
                  <Grid
                    container
                    alignItems="center"
                    sx={{ marginLeft: "10px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_6958_24150)">
                        <path
                          d="M20 0H4C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2V22C2 22.5304 2.21071 23.0391 2.58579 23.4142C2.96086 23.7893 3.46957 24 4 24H20C20.5304 24 21.0391 23.7893 21.4142 23.4142C21.7893 23.0391 22 22.5304 22 22V2C22 1.46957 21.7893 0.960859 21.4142 0.585786C21.0391 0.210714 20.5304 0 20 0ZM20 22H4V2H20V22Z"
                          fill={`${activeMenu ? "#708090" : "white"}`}
                        />
                        <path
                          d="M6 4H18V6H6V4ZM6 8H13V10H6V8ZM8 20H16L12 17L8 20ZM19 19V13L14 16L19 19ZM6 13V19L10 16L6 13ZM16 12H8L12 15L16 12Z"
                          fill={`${activeMenu ? "#708090" : "white"}`}
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6958_24150">
                          <rect
                            width="24"
                            height="24"
                            fill={`${activeMenu ? "#708090" : "white"}`}
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <Button
                      sx={{
                        fontWeight: "500",
                        color: "#708090",
                        textTransform: "capitalize",
                      }}
                      className={` ${activeMenu ? "color_7080" : "activeBtn"}`}
                    >
                      Templates
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default OrgSidebar;
