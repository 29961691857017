// import { Box, Button, Grid, InputBase, Typography } from "@mui/material";
// import React, { useState } from "react";
// import { subPath } from "../../../../../Constants/Global";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { decodeToken, subPath } from "../../../../../Constants/Global";
import { useAppDispatch, useAppSelector } from "../../../../../Constants/hooks";
import {
  Autocomplete,
  Avatar,
  Grid,
  InputAdornment,
  InputBase,
  TextField,
} from "@mui/material";
import { handleFindMember } from "../../../../../Redux/Search/action";
import { createCircleGroup } from "../../../../../Redux/circle/action";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 1.2,
  borderRadius: "4px",
};
interface UserDetails {
  src: string;
  PersonName: string;
  Department: string;
  Organisation: string;
}

const userDetails: UserDetails[] = [
  {
    src: "/assets/images/images/view_img1.png",
    PersonName: "Prem",
    Department: "IOT",
    Organisation: "CITPL",
  },
  {
    src: "/assets/images/images/view_img1.png",
    PersonName: "Ragul",
    Department: "IOT",
    Organisation: "CITPL",
  },
  {
    src: "/assets/images/images/view_img1.png",
    PersonName: "Arjun",
    Department: "IOT",
    Organisation: "CITPL",
  },
];

const CircleCreate: React.FC<any> = () => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const dispatch = useAppDispatch();
  const [abortController, setAbortController] = React.useState(new AbortController());
  const userId: string | null = decodeToken()?.id ?? null;
  const memberList = useAppSelector(
    (state: any) => state?.search?.memberSearch
  );
  const [inputFields, setinputFields] = React.useState({
    groupName: '',
    members: [],
    createdBy: userId,
    profilePicture: "https://images.unsplash.com/photo-1620163280053-68782bd98475?q=80&w=2865&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description: 'test'
  })
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showToast, setShowToast] = React.useState(false);

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const src = URL.createObjectURL(e.target.files[0]);
      setinputFields({
        ...inputFields,
        ['profilePicture']: src,
      });
      displayToast();
    }
  };

  const displayToast = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleUserSelection = (event: any, newValues: any) => {
    setinputFields({
      ...inputFields,
      ['members']: newValues,
    });
  };
  const handleRemoveUser = (selectedUser: object) => {

    const newSelectedUsers = inputFields.members.filter(user => user !== selectedUser);
    setinputFields({
      ...inputFields,
      ['members']: newSelectedUsers,
    });

  };

  const handleChange = (name: any, value: any) => {
    setinputFields({
      ...inputFields,
      [name]: value,
    });

  }

  const handleInputChange = (event: any, newInputValue: any) => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const { signal } = newAbortController;
    console.log(newInputValue, 'newInputValue')
    const searchData: any = {
      userId: userId,
      searchValue: newInputValue,
      pageNo: '1',
      rowPerPage: '40',
    }
    setInputValue(newInputValue);
    // Trigger API call after 3 characters
    dispatch(handleFindMember(searchData, signal));


  };

  const fetchOptions = async (query: any) => {
    // Replace with your actual API call
    const response = await fetch(`https://api.example.com/search?q=${query}`);
    const data = await response.json();
    setOptions(data.results);

  };

  const handleSubmit = () => {
    const formData = new FormData();

    Object.entries(inputFields).forEach(([key, value]) => {
      formData.append(key, JSON.stringify(value));
    });

    handleClose()
    dispatch(createCircleGroup(formData))
    const groupDetails = {
      groupId: '',
      userId: userData.id,
      pageNo: 1, // Add this property
      rowPerPage: 30, // Add this property
    };
    dispatch(getMessagesGroupChat(groupDetails, abortController))


  }

  return (
    <div>
      <Button onClick={handleOpen}>
        <Grid
          item
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor: "#17A0EDBF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4.5px",
            boxShadow: "0px 3.75px 7px 0px #B7B7B7",
            border: "0.75px solid #FFFFFF",
          }}
        >
          <img
            //   src="../beta/assets/images/search.svg"
            src={`..${subPath}/assets/images/images/ic_round_plus.png`}
            alt="Add"
            style={{ width: "18px", height: "18px" }}
          />
        </Grid>
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          <Grid container>
            <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
              <Typography className="Create_crl_lable">
                Create Circle
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <img
                onClick={handleClose}
                //   src="../beta/assets/images/search.svg"
                src={`..${subPath}/assets/images/images/Close_Icon.png`}
                alt="Search"
                style={{ width: "11px", height: "11px" }}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ paddingTop: "25px" }}>
            <Typography className="Circle_name_crt">Circle Name *</Typography>
          </Grid>
          <Grid item>
            <InputBase
              name={'groupName'}
              value={inputFields.groupName}
              onChange={(event: any) => {
                handleChange(event.target.name, event.target.value)
              }}
              sx={{
                width: "98%",
                height: "42px",
                borderRadius: "6px",
                background: "#EDEDEE",
                color: "#101012",
                fontStyle: "Montserrat",
                fontWeight: "400",
                fontSize: "14px",
                border: "2px solid #DCDCDE",
                marginTop: "10px",
                paddingLeft: "5px",
                marginLeft: "0px",
              }}
              className=" input_search_circle"
            />
          </Grid>
          <Grid item sx={{ paddingTop: "15px" }}>
            <Typography className="Circle_name_crt">Photo</Typography>
          </Grid>
          <Grid container sx={{ paddingTop: "10px" }}>
            <label
              htmlFor="file-input"
              style={{ display: "flex", alignItems: "end" }}
            >
              <Grid item>
                <input
                  id="file-input"
                  type="file"
                  className="inputBox_imgUpload"
                  onChange={handleImageChange}
                />
                <img
                  className="image_upload"
                  id="img-preview"
                  src={inputFields.profilePicture}
                  alt="Upload Preview"
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: " 1.25px solid #00000066",
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-21px",
                }}
              >
                <img
                  src={`..${subPath}/assets/images/images/camera_img.png`}
                  alt="Search"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </label>
            {showToast && (
              <div className="toast">Image uploaded successfully!</div>
            )}
          </Grid>
          <Grid item>
            <Typography className="Circle_name_crt">Add members</Typography>
          </Grid>
          <Grid item sx={{ paddingTop: "15px" }}>
            <Box className=" ">
              <div>
                <Autocomplete
                  className="search_box_mem d-flex"
                  sx={{
                    alignItems: "center",
                    "& .MuiAutocomplete-inputRoot": {
                      "&:hover": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  }}
                  multiple
                  options={memberList || []}
                  value={inputFields.members}
                  onChange={handleUserSelection}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option: any) => option.fullname}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search members"
                      variant="outlined"
                      size="small"
                      sx={{
                        background: "#F5F5F5",
                        boxSizing: "border-box",
                        height: "35px",
                        borderRadius: "5px",
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={`..${subPath}/assets/images/images/searchIocn_chart.png`}
                              alt="Search"
                              style={{ width: "18px", height: "18px" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </Box>
          </Grid>
          <Box
            sx={{
              height: "120px",
              border: "2px dashed #777777",
              marginTop: "20px",
            }}
          >
            <Grid
              item
              sx={{ height: "80px", overflowY: "auto", paddingBottom: "15px" }}
            >
              {inputFields.members.map((selectedUser: any, index: number) => (
                <Grid container mt={1.8}>
                  <Grid
                    item
                    xs={3}
                    sm={1.5}
                    lg={1.5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      alt="profile"
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                      }}
                      src={selectedUser.profilePicture}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={9.5}
                    lg={9.5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      {selectedUser.fullname}
                      <span
                        style={{
                          color: "#777777",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        /
                      </span>
                      {selectedUser.department}
                      <span
                        style={{
                          color: "#777777",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      >
                        /
                      </span>
                      {selectedUser.designation}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} lg={1}>
                    <img
                      onClick={() => handleRemoveUser(selectedUser)}
                      src={`/assets/images/images/Cancle_icon.png`}
                      alt="Cancel"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid
            container
            sx={{
              marginTop: "-45px",
              height: "45px",
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  color: "#000000",
                }}
              >
                {inputFields?.members?.length} Selected
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <Button onClick={() => handleSubmit()} className="Send_button_circle">Create</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default CircleCreate;
