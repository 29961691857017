import { Avatar } from "antd";

export const DefaultImg: React.FC<{ imageUrl: string }> = ({ imageUrl }) => {
  //Random color
  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };
  return (
    <Avatar
      className="profile_imglist"
      style={{
        backgroundColor: getRandomColor(imageUrl),
        minWidth: "50px",
        height: "50px",
        borderRadius: "17px",
        verticalAlign: "middle",
        marginRight: "5px",
      }}
    >
      {imageUrl
        .split(" ")
        .map((word: any, index: number) =>
          index < 2 ? word.charAt(0).toUpperCase() : ""
        )
        .join("")}
    </Avatar>
  );
};
