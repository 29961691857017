import React from "react";

const NotFound: React.FC = () => {

    return (
        <div style={{ textAlign: "center" }}>
            <h1 style={{ color: "green" }}>
                Trove
            </h1>
            <h3 >
                404 Page not found
            </h3>
        </div>
    );
};
export default NotFound;
