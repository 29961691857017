import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import "../Homepage/Home.css";
import CardActions from "@mui/material/CardActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decodeToken, subPath } from "../../../Constants/Global";
import "../../../Layout/sidebar.css";
import { InputBase, Skeleton, styled } from "@mui/material";
import "../../../Layout/header.css";
import { useNavigate } from "react-router-dom";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName } from "../../../Redux/Search/action";
interface TemplateData {
  id: string;
  title: string;
  description: string;
  favotite: boolean;
}

interface StyledTableRowProps {
  isSelected: boolean;
}

const Search = styled("div")(({ theme }) => ({
  justifyContent: "flex-end",
  borderRadius: "6px",
  background: "#F5F5F5",
  maxHeight: "29px",
}));

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected ? "#A0D7FF" : "inherit",
    color: isSelected ? "white" : "inherit",
    "&:last-child td, &:last-child th": {
      borderBottom: "0px",
    },
  })
);
// export const MyContext = createContext<AssetData | undefined>(undefined);

const TemplateList: React.FC = () => {
  const api = UseAxios();
  const renderAfterCalled = useRef(false);
  const [temData, setTemData] = useState<TemplateData[]>([]);
  const [addAsset, setAddAsset] = useState<string[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<TemplateData[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userid = decodeToken().id;
  const dispatch = useAppDispatch();

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  const fixedArrayLength = 10;
  const placeholderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setItemName(""));
    if (!renderAfterCalled.current) {
      fetchTemplateData();
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, []);

  const fetchTemplateData = async () => {
    try {
      const response = await api.get(`template/favorite?userId=${userid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        const newData = response.data.data;
        setTemData(newData);
        setFilteredData(newData);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);

    // Use the filter method to filter the array
    const filteredArray = filteredData.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setTemData(filteredArray);
  };

  const handleTableRowClick = async (id: string) => {
    setTemplateId(id);
    setSelectedRow(id);
    try {
      const res = await api.get(`template/get?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAddAsset(res.data.data.formData);
    } catch (error) {}
  };

  const handleCreateKnowledgeClick = () => {
    if (addAsset.length > 0 || templateId) {
      navigate(`/staticAsset?state2=${encodeURIComponent(templateId)}`);
    } else {
      toast.error("Please Select Template", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeOnClick: false,
      });
    }
  };

  return (
    <>
      {/* <Box sx={{ flexGrow: 1, mt: 5 }} className="home_center">
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          justifyContent="center"
          sx={{ padding: "20px" }}
          className="page-grid"
        >
          <Sidebar /> */}
      <Grid
        item
        container
        direction="column"
        className="center-grid "
        xs={12}
        sm={7}
        md={9}
        lg={9}
        xl={10}
        spacing={2}
      >
        {isLoading ? (
          <Grid
            item
            container
            className="center_card_background justify-content-between p-0"
            sx={{}}
          >
            <Grid
              container
              className="justify-content-between borderbottom p-10 "
              sx={{ maxHeight: "55px" }}
            >
              <Skeleton
                width={140}
                height={30}
                sx={{ ...SkeletonColor, borderRadius: "10px" }}
              />

              <Skeleton
                width={140}
                height={38}
                sx={{ ...SkeletonColor, borderRadius: "10px" }}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                typography: "body1",
                marginTop: "10px",
              }}
              id=""
              className="plr-10"
            >
              <TableContainer className="template_table">
                <Table sx={{ border: "none" }} aria-label="simple table">
                  <TableBody sx={{ border: "none", cursor: "pointer" }}>
                    {placeholderArray.map((result, index) => (
                      <>
                        <StyledTableRow
                          isSelected={false}
                          sx={{ cursor: "pointer", border: "none" }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="star"
                            sx={{ width: "1%", border: "none" }}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={24}
                              height={24}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="temp-name"
                          >
                            <Skeleton
                              width={220}
                              height={30}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            className="temp-desc"
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Skeleton
                              width={220}
                              height={30}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                        </StyledTableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <CardActions className="align-right">
                <Skeleton
                  width={180}
                  height={45}
                  sx={{ ...SkeletonColor, borderRadius: "8px" }}
                ></Skeleton>
              </CardActions>
            </Box>
          </Grid>
        ) : (
          <Grid
            item
            container
            className="center_card justify-content-between p-0"
          >
            <Grid
              container
              className="font_family  borderbottom p-10 d-flex align-items-center justify-content-between"
              sx={{ maxHeight: "55px" }}
            >
              <Typography
                className="font_family"
                sx={{ color: "#000", fontWeight: "600" }}
              >
                Templates
              </Typography>
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Search>
                  <div className="d-flex align-items-center ">
                    <img
                      src={`..${subPath}/assets/images/search.svg`}
                      className="search mt-5 pl-5 font_family"
                      width="13px"
                      height="13px"
                      style={{ position: "relative", top: "-3px" }}
                    />
                    <InputBase
                      className="fs-12 pl-5 l-5"
                      placeholder="Search Template"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                  </div>
                </Search>
                <CardActions>
                  <button
                    type="submit"
                    className="m-button sidebar-c-b"
                    style={{ cursor: "pointer" }}
                    onClick={handleCreateKnowledgeClick}
                  >
                    Create Knowledge
                  </button>
                </CardActions>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "100%",
                typography: "body1",
                marginTop: "10px",
              }}
              id=""
              className="plr-10"
            >
              <TableContainer className="template_table">
                <Table sx={{ border: "none" }} aria-label="simple table">
                  <TableBody sx={{ border: "none", cursor: "pointer" }}>
                    {temData && temData.length === 0 ? (
                      <TableCell
                        sx={{ border: "none" }}
                        component="th"
                        scope="row"
                        align="center"
                      >
                        No data found...
                      </TableCell>
                    ) : (
                      temData?.map((row: TemplateData, index) => (
                        <StyledTableRow
                          sx={{ cursor: "pointer", border: "none" }}
                          key={row.id}
                          isSelected={selectedRow === row.id}
                          onClick={() => handleTableRowClick(row.id)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="star"
                            sx={{ width: "1%", border: "none" }}
                          >
                            {row.favotite === false ? (
                              <img
                                src={`..${subPath}/assets/images/star.svg`}
                              />
                            ) : (
                              <img
                                src={`..${subPath}/assets/images/fav_star.svg`}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="temp-name font_family"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell
                            align="right"
                            className="temp-desc font_family"
                            title={row.description}
                            style={{
                              maxWidth: "100px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {row.description}
                          </TableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* </Grid>
      </Box> */}
    </>
  );
};

export default TemplateList;
