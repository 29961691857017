import {
  Box,
  Grid,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { subPath } from "../../../Constants/Global";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

const SkeletonColor = {
  backgroundColor: "#fff",
};
const fixedArrayLength = 6;
const placeholderArray = Array.from(
  { length: fixedArrayLength },
  (_, index) => index
);

export const AssetsTable = () => {
  return (
    <Table aria-label="simple table ">
      <TableBody>
        {placeholderArray.map((index) => (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            className="cur-pointer row-hover-vault"
          >
            <TableCell
              sx={{ border: "none" }}
              component="th"
              scope="row"
              className="cur-pointer color_7080 fs-12"
            >
              <Skeleton
                variant="rectangular"
                width={70}
                height={40}
                sx={{ borderRadius: "6px" }}
              />
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Skeleton
                variant="rectangular"
                width={160}
                height={40}
                sx={{ borderRadius: "6px" }}
              />
            </TableCell>
            <TableCell sx={{ border: "none" }}>
              <Skeleton
                variant="rectangular"
                width={90}
                height={40}
                sx={{ borderRadius: "6px" }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const GroupView = () => {
  return (
    <Box className="center-grid group_card_view_loader bg-right">
      <Grid item container className=" justify-content-between p-10 d-unset ">
        <Grid container className="justify-content-between">
          {/* Breadcrumbs */}

          <Grid
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={20}
              height={20}
              sx={{
                ...SkeletonColor,
                marginRight: "5px",
                borderRadius: "5px",
              }}
            />

            <Link
              underline="none"
              color="inherit"
              className="d-flex"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={20}
                height={20}
                sx={{
                  ...SkeletonColor,
                  marginRight: "5px",
                  borderRadius: "5px",
                }}
              />

              <Typography
                className="fs-16  smallBold hideDotMedium"
                color="text.primary"
                sx={{
                  paddingLeft: "5px",
                  border: "none",
                }}
              >
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  sx={{
                    ...SkeletonColor,
                    marginRight: "5px",
                    borderRadius: "5px",
                  }}
                />
              </Typography>
            </Link>
          </Grid>
          <div className="d-flex cur-pointer position-relative">
            <Box
              className="removeAll all_btn mlr-5"
              style={{ visibility: "hidden" }}
            >
              All
            </Box>
            <Box className="  mlr-5" style={{ visibility: "hidden" }}>
              <img
                className="removeAll "
                src={`..${subPath}/assets/images/fluent_mail-unread-48-regular.svg`}
                alt=""
              ></img>
            </Box>
            <Box className="  mlr-5" style={{ visibility: "hidden" }}>
              <img
                className="removeAll"
                src={`..${subPath}/assets/images/fluent_mail-read-20-regular.svg`}
                alt=""
              ></img>
            </Box>
            <Box className="  mlr-5">
              <Link
                underline="hover"
                color="inherit"
                className="d-flex  align-items-center cur-pointer"
              >
                <Skeleton
                  variant="text"
                  width={7}
                  height={30}
                  sx={{ ...SkeletonColor }}
                />
              </Link>
            </Box>
          </div>
          {/* // )} */}
        </Grid>
        <div>
          <Typography
            sx={{
              color: "#000",
              fontWeight: "600",
              fontSize: "18px",
              width: "90%",
            }}
            className="hideDotSingleLine cur-pointer"
          >
            <Skeleton
              variant="text"
              width={80}
              height={25}
              sx={{ ...SkeletonColor, borderRadius: "8px" }}
            />
          </Typography>
          <Typography className="color_7080 fs-13  cur-pointer">
            <Skeleton
              variant="text"
              width={200}
              height={25}
              sx={{ ...SkeletonColor, borderRadius: "8px" }}
            />
          </Typography>
        </div>
        <Box className="sup_tab w-100">
          <TabContext value={"1"}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="mt-5 mb-15 d-flex"
            >
              <Tabs value={1}>
                <Skeleton
                  variant="text"
                  width={50}
                  height={25}
                  sx={{
                    ...SkeletonColor,
                    borderRadius: "8px",
                    marginRight: "60px",
                  }}
                />
                <Skeleton
                  variant="text"
                  width={50}
                  height={25}
                  sx={{
                    ...SkeletonColor,
                    borderRadius: "8px",
                    marginRight: "60px",
                  }}
                />

                <Skeleton
                  variant="text"
                  width={50}
                  height={25}
                  sx={{
                    ...SkeletonColor,
                    borderRadius: "8px",
                    marginRight: "37px",
                  }}
                />
              </Tabs>
              <span className="createVaultBtn d-flex cur-pointer">
                <Skeleton
                  variant="text"
                  width={130}
                  height={25}
                  sx={{
                    ...SkeletonColor,
                    borderRadius: "8px",
                    marginRight: "37px",
                  }}
                />
              </span>
            </Box>

            <TabPanel value="1" className="p-0 pt-0">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="box-shadow"
                >
                  <TableContainer className="nowrap bg_F5" component={Paper}>
                    <Table aria-label="simple table">
                      <TableBody>
                        {placeholderArray.map((index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Skeleton
                                variant="text"
                                width={60}
                                height={30}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ display: "flex" }}>
                              <Skeleton
                                variant="rectangular"
                                width={30}
                                height={30}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                  marginRight: "5px",
                                }}
                              />
                              <Skeleton
                                variant="text"
                                width={120}
                                height={25}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Skeleton
                                variant="text"
                                width={100}
                                height={25}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="pi_right"
                >
                  {/* Breadcrumbs */}
                  <Grid item className="d-flex">
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={20}
                          height={20}
                          sx={{
                            marginRight: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        <Link underline="none" color="inherit cur-pointer">
                          <Skeleton
                            variant="rectangular"
                            width={20}
                            height={20}
                            sx={{
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        </Link>
                        <Link
                          underline="none"
                          color="inherit"
                          className="d-flex"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={20}
                            height={20}
                            sx={{
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />

                          <Typography
                            className="fs-16  smallBold hideDotMedium"
                            color="text.primary"
                            sx={{
                              paddingLeft: "5px",
                              border: "none",
                            }}
                          >
                            <Skeleton
                              variant="text"
                              width={60}
                              height={20}
                              sx={{
                                marginRight: "5px",
                                borderRadius: "5px",
                              }}
                            />
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      xl={4}
                      className="position-relative justifyContentCenter cur-pointer "
                    >
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width={20}
                          height={20}
                          sx={{
                            marginRight: "5px",
                            borderRadius: "5px",
                          }}
                        />
                      </Box>
                      {/* <Box>
                                <img src="../beta/assets/images/Vector.png"></img>
                              </Box> */}
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width={20}
                          height={20}
                          sx={{
                            marginRight: "5px",
                            borderRadius: "5px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width={20}
                          height={20}
                          sx={{
                            marginRight: "5px",
                            borderRadius: "5px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Link
                          underline="hover"
                          color="inherit"
                          className="d-flex  align-items-center cur-pointer"
                        >
                          <Skeleton
                            variant="rectangular"
                            width={7}
                            height={20}
                            sx={{
                              marginRight: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Box>
  );
};

interface AssetTabProps {
  assetArray: any;
}

export const AssetTab: React.FC<AssetTabProps> = ({ assetArray }) => {
  return (
    <TableBody>
      {assetArray.map(() => (
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
            paddingBottom: "10px",
          }}
          className="cur-pointer row_hover_group"
        >
          <TableCell
            sx={{
              border: "none",
              color: "#708090",
            }}
            component="th"
            scope="row"
          >
            <Skeleton
              variant="rectangular"
              width={70}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
          <TableCell
            className="vault-div-gv"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            width="100%"
          >
            <Skeleton
              variant="rectangular"
              width={290}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              verticalAlign: "middle",
            }}
            width="5%"
          >
            <Skeleton
              variant="rectangular"
              width={140}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>

          <TableCell
            width="15%"
            sx={{
              border: "none",
              verticalAlign: "middle",
              paddingLeft: "0px",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={100}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
          <TableCell
            width="10%"
            sx={{
              border: "none",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={70}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
          <TableCell
            width="10%"
            sx={{
              border: "none",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={70}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
          <TableCell
            width="10%"
            sx={{
              border: "none",
              verticalAlign: "middle",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={70}
              height={40}
              sx={{ borderRadius: "6px" }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
