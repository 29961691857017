export const GramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      className="assetcard_big_icon"
    >
      <path
        d="M13.3309 9.09717H6.52313L2.85742 11.1919L5.99945 13.2865H14.3782L16.9966 11.1919L13.3309 9.09717Z"
        fill="#F5F5F5"
      />
      <path
        d="M16.9966 11.1919L14.3782 13.2865V16.4286L16.9966 14.3339V11.1919Z"
        fill="#708090"
      />
      <path
        d="M5.99945 13.2865L2.85742 11.1919V14.3339L5.99945 16.4286V13.2865Z"
        fill="#708090"
      />
      <path
        d="M14.3782 13.2865H5.99945V16.4286H14.3782V13.2865Z"
        fill="#708090"
      />
      <path
        d="M16.9966 11.1919L13.3309 9.09717H6.52313L2.85742 11.1919M16.9966 11.1919L14.3782 13.2865M16.9966 11.1919V14.3339L14.3782 16.4286M14.3782 13.2865H5.99945M14.3782 13.2865V16.4286M5.99945 13.2865L2.85742 11.1919M5.99945 13.2865V16.4286M2.85742 11.1919V14.3339L5.99945 16.4286M5.99945 16.4286H14.3782"
        stroke="#708090"
        strokeWidth="0.523672"
      />
      <path
        d="M12.7115 6.26465H7.60572L4.85645 7.83566L7.21296 9.40668H13.497L15.4608 7.83566L12.7115 6.26465Z"
        fill="#F5F5F5"
      />
      <path
        d="M15.4608 7.83566L13.497 9.40668V11.7632L15.4608 10.1922V7.83566Z"
        fill="#708090"
      />
      <path
        d="M7.21296 9.40668L4.85645 7.83566V10.1922L7.21296 11.7632V9.40668Z"
        fill="#708090"
      />
      <path
        d="M13.497 9.40668H7.21296V11.7632H13.497V9.40668Z"
        fill="#708090"
      />
      <path
        d="M15.4608 7.83566L12.7115 6.26465H7.60572L4.85645 7.83566M15.4608 7.83566L13.497 9.40668M15.4608 7.83566V10.1922L13.497 11.7632M13.497 9.40668H7.21296M13.497 9.40668V11.7632M7.21296 9.40668L4.85645 7.83566M7.21296 9.40668V11.7632M4.85645 7.83566V10.1922L7.21296 11.7632M7.21296 11.7632H13.497"
        stroke="#708090"
        strokeWidth="0.392754"
      />
      <path
        d="M11.9586 4.22559H8.20864L6.18945 5.37941L7.92019 6.53324H12.5355L13.9778 5.37941L11.9586 4.22559Z"
        fill="#F5F5F5"
      />
      <path
        d="M13.9778 5.37941L12.5355 6.53324V8.26398L13.9778 7.11016V5.37941Z"
        fill="#708090"
      />
      <path
        d="M7.92019 6.53324L6.18945 5.37941V7.11016L7.92019 8.26398V6.53324Z"
        fill="#708090"
      />
      <path
        d="M12.5355 6.53324H7.92019V8.26398H12.5355V6.53324Z"
        fill="#708090"
      />
      <path
        d="M13.9778 5.37941L11.9586 4.22559H8.20864L6.18945 5.37941M13.9778 5.37941L12.5355 6.53324M13.9778 5.37941V7.11016L12.5355 8.26398M12.5355 6.53324H7.92019M12.5355 6.53324V8.26398M7.92019 6.53324L6.18945 5.37941M7.92019 6.53324V8.26398M6.18945 5.37941V7.11016L7.92019 8.26398M7.92019 8.26398H12.5355"
        stroke="#708090"
        strokeWidth="0.288456"
      />
      <path
        d="M11.45 2.14282H8.89709L7.52246 2.92833L8.70072 3.71384H11.8427L12.8246 2.92833L11.45 2.14282Z"
        fill="#F5F5F5"
      />
      <path
        d="M12.8246 2.92833L11.8427 3.71384V4.8921L12.8246 4.10659V2.92833Z"
        fill="#708090"
      />
      <path
        d="M8.70072 3.71384L7.52246 2.92833V4.10659L8.70072 4.8921V3.71384Z"
        fill="#708090"
      />
      <path
        d="M11.8427 3.71384H8.70072V4.8921H11.8427V3.71384Z"
        fill="#708090"
      />
      <path
        d="M12.8246 2.92833L11.45 2.14282H8.89709L7.52246 2.92833M12.8246 2.92833L11.8427 3.71384M12.8246 2.92833V4.10659L11.8427 4.8921M11.8427 3.71384H8.70072M11.8427 3.71384V4.8921M8.70072 3.71384L7.52246 2.92833M8.70072 3.71384V4.8921M7.52246 2.92833V4.10659L8.70072 4.8921M8.70072 4.8921H11.8427"
        stroke="#708090"
        strokeWidth="0.196377"
      />
    </svg>
  );
};

export const ViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      className="assetcard_big_icon"
    >
      <path
        d="M9.99967 3.75C5.83301 3.75 2.27467 6.34167 0.833008 10C2.27467 13.6583 5.83301 16.25 9.99967 16.25C14.1663 16.25 17.7247 13.6583 19.1663 10C17.7247 6.34167 14.1663 3.75 9.99967 3.75ZM9.99967 14.1667C7.69967 14.1667 5.83301 12.3 5.83301 10C5.83301 7.7 7.69967 5.83333 9.99967 5.83333C12.2997 5.83333 14.1663 7.7 14.1663 10C14.1663 12.3 12.2997 14.1667 9.99967 14.1667ZM9.99967 7.5C8.61634 7.5 7.49967 8.61667 7.49967 10C7.49967 11.3833 8.61634 12.5 9.99967 12.5C11.383 12.5 12.4997 11.3833 12.4997 10C12.4997 8.61667 11.383 7.5 9.99967 7.5Z"
        fill="#708090"
      />
    </svg>
  );
};

export const CommentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      className="assetcard_big_icon"
    >
      <path
        d="M16.666 1.6665H3.33268C2.41602 1.6665 1.67435 2.4165 1.67435 3.33317L1.66602 18.3332L4.99935 14.9998H16.666C17.5827 14.9998 18.3327 14.2498 18.3327 13.3332V3.33317C18.3327 2.4165 17.5827 1.6665 16.666 1.6665ZM4.99935 7.49984H14.9993V9.1665H4.99935V7.49984ZM11.666 11.6665H4.99935V9.99984H11.666V11.6665ZM14.9993 6.6665H4.99935V4.99984H14.9993V6.6665Z"
        fill="#708090"
      />
    </svg>
  );
};
