import { Box, CardContent, Grid, Skeleton, Typography } from "@mui/material";

export const ViewSimilar = () => {
  const SkeletonColor = {
    backgroundColor: "#fff",
  };
  const fixedArrayLength = 3;
  const placeholderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  return (
    <CardContent className="as-r-card" sx={{ backgroundColor: "#E6E6E6" }}>
      <Grid container>
        <Grid item xl={12} lg={12} sm={12}>
          <Box>
            <Grid container item sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xl={12} lg={12} sm={12}>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                >
                  <Skeleton width={80} height={20} sx={{ ...SkeletonColor }} />
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              {placeholderArray.map(() => (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={30}
                      height={30}
                      sx={{ ...SkeletonColor }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={9} className="cur-pointer">
                    <Skeleton
                      variant="text"
                      width="80%"
                      height={25}
                      sx={{ ...SkeletonColor, marginBottom: "5px" }}
                    />
                    <Skeleton
                      variant="text"
                      width="60%"
                      height={25}
                      sx={{ ...SkeletonColor }}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
};
