import React, { useState, useEffect, useRef } from "react";
import "../../../Layout/sidebar.css";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { Breadcrumbs, CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import "./asset.css";
import "../Homepage/Home.css";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Avatar } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { ReactFormGenerator } from "react-form-builder2";
import { imagehttp, subPath, decodeToken } from "../../../Constants/Global";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { InputBase } from "@mui/material";
import queryString from "query-string";
import "./asset.css";
import * as Yup from "yup";
import getOrgImage from "../../Fetch/Organization";
import "./form.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import InfiniteScroll from "react-infinite-scroll-component";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName, setSearchValue } from "../../../Redux/Search/action";

interface ChildProps {
  value: string[];
}
interface DemobarState {
  assetDataParam: any[];
}
interface FormField {
  id: string;
  canHaveAlternateForm: boolean;
  canHaveAnswer: null;
  canHaveDisplayHorizontal: boolean;
  canHaveOptionCorrect: boolean;
  canHaveOptionValue: boolean;
  canHavePageBreakBefore: boolean;
  canPopulateFromApi: boolean;
  dirty: null;
  element: string;
  field_name: null;
  label: null;
  required: boolean;
  text: boolean;
  options: null;
}

interface GroupInfo {
  id: string;
  name: string;
  groupIcon: File;
}
interface MemberInfo {
  id: string;
  name: string;
}
interface sortGroupInfo {
  id: string;
  name: string;
  groupIcon: File;
}
//Select Group dropdown
interface GroupInfo {
  id: string;
  name: string;
}

const quillStyle = {
  height: "200px",
};

const quillContainerData = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-4" }, { indent: "+4" }],
  ["link", "image", "video"],
  [{ color: [] }],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" },
  ],
  ["clean"],
];

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "align",
];

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "#f5f5f5",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "93%",
  margin: "10px 0",
}));

const StaticAsset: React.FC<ChildProps & DemobarState> = (props) => {
  const api = UseAxios();
  const navigate = useNavigate();
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  //pdf
  const documentTypes = [
    ".pdf",
    ".doc",
    ".docx",
    ".rtf",
    ".txt",
    ".ppt",
    ".pptx",
    ".html",
    ".csv",
    ".xlsx",
    ".xls",
  ];
  //image
  const imageTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/heif",
    "image/ldr",
    "image/vnd.radiance", // HDR
    ".hdr",
    "image/x-icon", // ICO
    "image/svg+xml",
    ".pdf",
    ".doc",
    ".docx",
    ".rtf",
    ".txt",
    ".ppt",
    ".pptx",
    ".html",
    ".csv",
    ".xlsx",
    ".xls",
    "video/mp4",
    "video/avi",
    "video/quicktime", //for MOV
    "video/mpeg",
    "video/x-flv", // for FLV
    ".flv",
    "audio/mp3",
    "audio/wav",
    "audio/mpeg3",
    "audio/mpeg",
    ".aac",
    ".aacp",
    ".m4a", // Additional MIME type for AAC
  ];
  //media feild
  const imageCheckTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/heif",
    "image/ldr",
    "image/vnd.radiance", // HDR
    ".hdr",
    "image/x-icon", // ICO
    "image/svg+xml",
  ];
  const acceptedMediaTypes = [
    "video/mp4",
    "video/avi",
    "video/quicktime", // for MOV
    "video/mpeg",
    "video/x-flv",
    ".flv",
  ];

  //audio feild
  const acceptedAudioTypes = [
    "audio/mp3",
    "audio/wav",
    "audio/mpeg3",
    "audio/mpeg",
    ".aac",
    ".aacp",
    ".m4a", // Additional MIME type for AAC
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [tempId, setTempId] = useState<string>();
  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblevalut, setIsVisibleValut] = useState(false);
  const [isVisibleValutAbove, setisVisibleValutAbove] = useState(false);
  const [isVisibleGroupAbove, setisVisibleGroupAbove] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchQueryVault, setSearchQueryVault] = useState<string>("");
  const [selectGroup, setSelectGroup] = useState<GroupInfo[]>([]);
  const [selectMember, setSelectMember] = useState<MemberInfo[]>([]);

  const [selectedValutSub, setSelectedvalutSub] = useState<string>();
  //mutiple group select
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [selectedGrp, setSelectedGrp] = useState<string>();
  const [vaultName, setVaultName] = useState<string>();
  const [selectedValutSubID, setSelectedvalutSubID] = useState<string>();
  const [textQuillStandart, setTextQuillStandart] = useState("");
  const [selectedVaultId, setSelectedVaultId] = useState<string>();
  const [selectedValue, setSelectedValue] = useState<string>();
  const [selectedVal, setSelectedVal] = useState<string>();
  const [filterOptions, setFilteredOptions] = useState<sortGroupInfo[]>([]);
  const [formBuilderRequiredData, setFormBuilderRequiredData] = useState<
    FormField[]
  >([]);

  const [filterMemeber, setFilteredmember] = useState<sortGroupInfo[]>([]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  const [hasMore, setHasMore] = useState(true);
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  //attachment
  const [title, settitle] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [hashTag, setHashTag] = useState<string>("");
  const concatenatedGroupValue = `${selectedGroupId || ""},${
    selectedGrp || ""
  }`;
  const groupValueArray = concatenatedGroupValue.split(",");
  const concatenatedVaultValue = `${selectedVaultId || ""},${
    selectedValutSubID || ""
  }`;
  const valutValueArray = concatenatedVaultValue.split(",");

  const combined = groupValueArray.map((group, index) => ({
    groupId: group,
    vaultId: valutValueArray[index],
  }));
  const companyId = decodeToken().companyId;
  const location = useLocation();

  let TemplateId = new URLSearchParams(location.search).get("state2");
  let checkTemplateChoose = new URLSearchParams(location.search).get("state2");

  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };

  useEffect(() => {
    dispatch(setItemName(""));
    dispatch(setSearchValue(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchOrgData();
  }, []);

  //modal visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsVisibleValut(false);
    setisVisibleValutAbove(false);
    setisVisibleGroupAbove(false);

    setSearchQuery("");
    setSearchQueryVault("");

    setSelectedvalutSub("");
    setSelectedvalutSubID("");
  };
  const toggleVisibilityVault = () => {
    setIsVisibleValut(!isVisiblevalut);
    setisVisibleValutAbove(false);
    setisVisibleGroupAbove(false);
    setIsVisible(false);
    setSearchQuery("");
    setSearchQueryVault("");
  };
  const toggleVisibilityGroup1 = () => {
    setisVisibleGroupAbove(!isVisibleGroupAbove);
    setIsVisibleValut(false);
    setisVisibleValutAbove(false);
    setIsVisible(false);
    setSearchQuery("");
    setSearchQueryVault("");
  };
  const toggleVisibilityVault1 = () => {
    setisVisibleValutAbove(!isVisibleValutAbove);
    setIsVisible(false);
    setisVisibleGroupAbove(false);
    setIsVisibleValut(false);
    setSearchQuery("");
    setSearchQueryVault("");
  };

  const [initialImageFile, setInitialImageFile] = useState<File[]>([]);
  const [additionalImageFilePopUp, setAdditionalImageFilePopUp] = useState<
    File[]
  >([]);
  const pre = additionalImageFilePopUp[0]
    ? additionalImageFilePopUp[0].name
    : null;
  const [activeFileIndex, setActiveFileIndex] = useState<number | null>(0);
  const [samplePreview, setSamplePreview] = useState<number | null>(0);
  const [selectedFilePreview, setSelectedFilePreview] = useState<string | null>(
    null
  );

  const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
  const [SelectedFileTypeDoc, setSelectedFileTypeDoc] = useState<string | null>(
    null
  );
  const [fileName, setFileName] = useState<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name
          .substring(file.name.lastIndexOf("."))
          .toLowerCase();
        const fileType = file.type.toLowerCase();

        return (
          (fileType && imageTypes.includes(fileType)) ||
          (fileExtension &&
            (imageTypes.includes(fileExtension) || fileExtension === ".hdr"))
        );
      });

      // Check if the file is valid image file if not then throw error
      if (validFiles.length === 0) {
        toast.error("Please select valid file", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (initialImageFile.length === 0) {
        setInitialImageFile(validFiles);
        setAdditionalImageFilePopUp((prevAdditionalFiles) => [
          ...prevAdditionalFiles,
          ...validFiles,
        ]);

        // Set the first file name to the state
        const selectedFile = validFiles[0];
        setFileName(selectedFile?.name);
      } else {
        setAdditionalImageFilePopUp((prevAdditionalFiles) => [
          ...prevAdditionalFiles,
          ...validFiles,
        ]);
      }
    }
  };

  const handleButtonClick = () => {
    navigate(-1);
  };

  const handleFileClick = (fileIndex: number, isAdditionalFile: boolean) => {
    if (isAdditionalFile === true) {
      setSamplePreview(fileIndex);
      setActiveFileIndex(fileIndex);

      const selectedFile = additionalImageFilePopUp[fileIndex];

      if (selectedFile) {
        setSelectedFilePreview(URL.createObjectURL(selectedFile));
        setSelectedFileType(selectedFile.type);

        setFileName(selectedFile.name);
        const fileExtension = selectedFile.name
          .substring(selectedFile.name.lastIndexOf("."))
          .toLowerCase();

        setSelectedFileTypeDoc(fileExtension);
      }
    } else if (isAdditionalFile === false) {
      if (fileIndex !== 0 && activeFileIndex !== null) {
        if (fileIndex === activeFileIndex) {
          const selectedFile = additionalImageFilePopUp[fileIndex - 1];

          setSelectedFilePreview(URL.createObjectURL(selectedFile));

          setSelectedFileType(selectedFile.type);

          setFileName(selectedFile.name);
          setActiveFileIndex(fileIndex - 1);

          const fileExtension = selectedFile.name
            .substring(selectedFile.name.lastIndexOf("."))
            .toLowerCase();

          setSelectedFileTypeDoc(fileExtension);
        } else if (fileIndex < activeFileIndex) {
          setSelectedFilePreview(selectedFilePreview);
          const selectedFile = additionalImageFilePopUp[activeFileIndex];
          // setSelectedFilePreview(URL.createObjectURL(selectedFile));
          setSelectedFileType(selectedFile.type);

          setFileName(selectedFile.name);
          setActiveFileIndex(activeFileIndex - 1);
          const fileExtension = selectedFile.name
            .substring(selectedFile.name.lastIndexOf("."))
            .toLowerCase();

          setSelectedFileTypeDoc(fileExtension);
        } else if (fileIndex > activeFileIndex) {
          setSelectedFilePreview(selectedFilePreview);

          const selectedFile = additionalImageFilePopUp[activeFileIndex];
          // setSelectedFilePreview(URL.createObjectURL(selectedFile));
          setSelectedFileType(selectedFile.type);

          setFileName(selectedFile.name);
          setActiveFileIndex(activeFileIndex);
          const fileExtension = selectedFile.name
            .substring(selectedFile.name.lastIndexOf("."))
            .toLowerCase();

          setSelectedFileTypeDoc(fileExtension);
        }
      } else {
        if (activeFileIndex !== null && fileIndex < activeFileIndex) {
          const selectedFile = additionalImageFilePopUp[activeFileIndex];
          setSelectedFilePreview(selectedFilePreview);
          // setSelectedFilePreview(URL.createObjectURL(selectedFile));
          setSelectedFileType(selectedFile.type);

          setFileName(selectedFile.name);
          setActiveFileIndex(activeFileIndex - 1);
          const fileExtension = selectedFile.name
            .substring(selectedFile.name.lastIndexOf("."))
            .toLowerCase();

          setSelectedFileTypeDoc(fileExtension);
        } else {
          const selectedFile = additionalImageFilePopUp[fileIndex + 1];
          setFileName(selectedFile.name);
          setSelectedFilePreview(URL.createObjectURL(selectedFile));
          setSelectedFileType(selectedFile.type);
          const fileExtension = selectedFile.name
            .substring(selectedFile.name.lastIndexOf("."))
            .toLowerCase();

          setSelectedFileTypeDoc(fileExtension);

          setActiveFileIndex(0);
        }
      }
    } else {
      setSamplePreview(0);
      setActiveFileIndex(0);
    }
  };

  //remove file

  const handleCloseIconClick = (fileIndex: number) => {
    setActiveFileIndex(0);
    const updatedAdditionalFiles = [...additionalImageFilePopUp];
    updatedAdditionalFiles.splice(fileIndex, 1);
    setAdditionalImageFilePopUp(updatedAdditionalFiles);

    const updatedInitialFiles = [...initialImageFile];
    updatedInitialFiles.splice(fileIndex, 1);
    setInitialImageFile(updatedInitialFiles);

    const selectedFile = updatedAdditionalFiles[0];
    if (selectedFile?.name) {
      setSelectedFilePreview(URL.createObjectURL(selectedFile));
      setSelectedFileType(selectedFile.type);
      const fileExtension = selectedFile.name
        .substring(selectedFile.name.lastIndexOf("."))
        .toLowerCase();
      setSelectedFileTypeDoc(fileExtension);
    } else {
      setSamplePreview(0);
      setActiveFileIndex(0);
      setSelectedFilePreview(null);
      setSelectedFileType(null);
      setSelectedFileTypeDoc(null);
      setInitialImageFile([]);
      setAdditionalImageFilePopUp([]);
      setFileName(null);
    }
  };

  const handleChangetitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = event.target.value;
    settitle(newData);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
  };

  const [hashColor, setHashColor] = useState<string>("0");

  const handleChangeHashtag = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hashtag = event.target.value;

    if (hashtag.trim().length > 0) {
      setHashColor("1");
    } else {
      setHashColor("0");
    }

    setHashTag(hashtag);
  };

  // Event handler to update the search query state
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
    fetchSelectgroup(event.target.value, "Search");
  };
  const handleSearchInputChangeVault = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQueryVault(event.target.value);
  };

  //BreadCrumbs
  const showBreadcrumbs = () => {
    setIsContentVisible(true);
    setisVisibleValutAbove(false);
    setIsVisible(false);
    setisVisibleGroupAbove(false);
    setIsVisibleValut(false);
  };
  const hideBreadcrumbs = () => {
    setIsContentVisible(false);
    setSelectedvalutSub("");
    setSelectedVal("");
    setisVisibleValutAbove(false);
    setIsVisible(false);
    setisVisibleGroupAbove(false);
    setIsVisibleValut(false);
  };

  const handleItemClick = (name: string, id: string) => {
    setSelectedVal(name);
    setSelectedGrp(id);
  };
  const handleItemClickVaultSub = (name: string, id: string) => {
    setSelectedvalutSub(name);
    setSelectedvalutSubID(id);
  };
  const handleItemClickVault = (name: string, id: string) => {
    setSelectedVaultId(id);
    setVaultName(name);
    const filteredOptions = selectGroup.filter(
      (option) => option.id !== selectedGroupId
    );
    setFilteredOptions(filteredOptions);
  };

  //Select group
  const fetchSelectgroup = async (searchText: string, type: String) => {
    abortController.abort();
    // Create a new abort controller for the current request
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    let pageCount = page;
    if (type === "Search" && searchText.length === 0) {
      setPage(0);
      setHasMore(true);
      pageCount = 0;
    }

    try {
      const response = await api.get(
        `vault/viewgroup?companyId=${companyId}&page=${pageCount}&size=${size}&search=${searchText}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: newAbortController.signal,
        }
      );
      if (response.data.status === true) {
        const newData = response.data.data;
        let filteredOptions = [];
        if (pageCount === 0 || type === "Search") {
          setSelectGroup(newData);
          filteredOptions = newData.filter(
            (option: any) => option.id !== selectedGroupId
          );
        } else {
          setSelectGroup((prevSelectGroup) => [...prevSelectGroup, ...newData]);
          const mergedData = [...selectGroup, ...newData];
          filteredOptions = mergedData.filter(
            (option: any) => option.id !== selectedGroupId
          );
        }
        if (selectedVaultId !== undefined) {
          setFilteredOptions(filteredOptions);
        }

        if (
          newData.length < size ||
          (type === "Search" && searchText.length > 0)
        ) {
          setHasMore(false);
        }

        setPage(pageCount + 1);
      }
    } catch (error) {}
  };

  //Select vault
  const fetchSelectMember = async (id: string) => {
    const url = `vault/group-id`;
    const params = {
      id: id,
    };
    await api
      .post(url, queryString.stringify(params))
      .then((res) => {
        setSelectMember(res.data.data);
      })
      .catch(function (error) {
        toast.error(error.res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleItemGroupClick = (name: string, id: string) => {
    setSelectedValue(name);
    setSelectedGroupId(id);

    setVaultName("");

    setSelectedVaultId("");

    setSelectedvalutSub("");
    setSelectedVal("");
  };

  // set Org Image
  const [orgImageData, setOrgImageData] = useState(null);
  const fetchOrgData = async () => {
    try {
      const data = await getOrgImage();
      setOrgImageData(data[0].logo);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching organization image:", error);
    }
  };
  const fetchSubVault = async (id: string) => {
    const url = `vault/group-id`;
    const params = {
      id: id,
    };

    await api
      .post(url, queryString.stringify(params))
      .then((res) => {
        setFilteredmember(res.data.data);
      })
      .catch(function (error) {
        toast.error(error.res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const dynamicValueRef = useRef([]);
  const handleFormChange = (updatedData: any) => {
    dynamicValueRef.current = updatedData;
  };

  const handleChangeQuillStandart = (textQuillStandart: any) => {
    setTextQuillStandart(textQuillStandart);
  };

  useEffect(() => {
    getTemplateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [assetDataArray, setAssetDataArrayState] = useState<any[]>([]);
  // Get template require data
  const getTemplateData = async () => {
    // Remove Required element using label-required class
    const requiredElements = document.getElementsByClassName("label-required");
    for (let i = 0; i < requiredElements.length; i++) {
      requiredElements[i].remove();
    }

    try {
      const response = await api.get(
        `template/get?id=${TemplateId}&companyId=${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        let newData = response.data.data.formData;

        setTempId(response.data.data.id);

        setAssetDataArrayState(newData);

        setFormBuilderRequiredData(newData);

        newData.forEach((item: any) => {
          if (
            item.field_name &&
            item.text !== "Tags" &&
            item.text !== "Checkboxes" &&
            item.text !== "Multiple Choice"
          ) {
            const fieldsName = document.getElementsByName(item.field_name)?.[0];
            if (fieldsName && item.required.toString() === "true") {
              if (fieldsName.parentNode) {
                const firstChild = fieldsName.parentNode.firstChild;
                if (firstChild) {
                  const span = document.createElement("span");
                  span.innerHTML = "Required";
                  span.classList.add("label-required");
                  span.classList.add("badge");
                  span.classList.add("badge-danger");
                  firstChild.appendChild(span);
                }
              }
            }
          } else {
            if (item.text === "Tags") {
              const fieldsName = document.getElementsByName(
                item.field_name
              )?.[0];
              if (fieldsName && item.required.toString() === "true") {
                if (fieldsName.parentNode?.parentNode?.parentNode) {
                  const firstChild =
                    fieldsName.parentNode?.parentNode?.parentNode.firstChild;
                  if (firstChild) {
                    const span = document.createElement("span");
                    span.innerHTML = "Required";
                    span.classList.add("label-required");
                    span.classList.add("badge");
                    span.classList.add("badge-danger");
                    firstChild.appendChild(span);
                  }
                }
              }
            }
            if (item.text === "Multiple Choice" || item.text === "Checkboxes") {
              let fieldsName;
              if (item.text === "Multiple Choice") {
                fieldsName = document.getElementsByName(item.field_name)?.[0];
              } else {
                fieldsName = document.getElementsByName(
                  "option_" + item.options[0].key
                )?.[0];
              }
              if (fieldsName && item.required.toString() === "true") {
                if (fieldsName.parentNode?.parentNode) {
                  const firstChild =
                    fieldsName.parentNode?.parentNode?.firstChild;
                  if (firstChild) {
                    const span = document.createElement("span");
                    span.innerHTML = "Required";
                    span.classList.add("label-required");
                    span.classList.add("badge");
                    span.classList.add("badge-danger");
                    firstChild.appendChild(span);
                  }
                }
              }
            }
          }
        });
      }
    } catch (error) {}
  };

  const handleSubmit = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    let validattionOccur = false;

    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }

    const removeBorder = document.getElementsByClassName("form-control");
    for (let i = 0; i < removeBorder.length; i++) {
      removeBorder[i].classList.remove("borderRed");
    }

    // Click the btn-big button to submit the form
    const submitButton = document.getElementsByClassName("btn-big");
    const submitButtonElement = submitButton[0] as HTMLButtonElement;
    if (TemplateId) {
      submitButtonElement.click();
    }

    // Dynamic form validation map the formBuilderRequiredData
    // eslint-disable-next-line array-callback-return
    formBuilderRequiredData.map((item: any) => {
      const fieldsName = document.getElementsByName(item.field_name);
      const inputElement = fieldsName?.[0] as HTMLInputElement;
      if (
        fieldsName &&
        item.required.toString() === "true" &&
        item.text !== "Checkboxes"
      ) {
        if (!inputElement.value) {
          fieldsName?.[0]?.classList.add("borderRed");
          validattionOccur = true;
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");

          if (item.text === "Tags" && item.text !== "Multiple Choice") {
            (
              fieldsName?.[0]?.parentNode?.parentNode?.firstChild as Element
            )?.classList.add("borderRed");
            validattionOccur = true;
          } else {
            if (item.text !== "Multiple Choice") {
              fieldsName?.[0]?.classList.add("borderRed");
              validattionOccur = true;
            }
          }
          fieldsName?.[0]?.parentNode?.insertBefore(
            span,
            fieldsName[0].nextSibling
          );
        } else {
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");
          if (item.text === "Multiple Choice") {
            // eslint-disable-next-line array-callback-return
            dynamicValueRef.current.map((item1: any) => {
              if (item1.name === item.field_name) {
                if (item1.value.length === 0) {
                  validattionOccur = true;
                  fieldsName?.[0]?.parentNode?.parentNode?.appendChild(span);
                }
              }
            });
          } else if (item.text === "Dropdown") {
            // eslint-disable-next-line array-callback-return
            dynamicValueRef.current.map((item1: any) => {
              if (item1.name === item.field_name) {
                if (item1.value === null || item1.value === "") {
                  fieldsName?.[0]?.classList.add("borderRed");
                  validattionOccur = true;
                  fieldsName?.[0]?.parentNode?.insertBefore(
                    span,
                    fieldsName[0].nextSibling
                  );
                }
              }
            });
          }
        }
      } else {
        if (
          item.text === "Checkboxes" &&
          fieldsName &&
          item.required.toString() === "true"
        ) {
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");
          // eslint-disable-next-line array-callback-return
          dynamicValueRef.current.map((item1: any) => {
            if (item1.name === item.field_name) {
              if (item1.value.length === 0) {
                const optionName = document.getElementsByName(
                  "option_" + item.options[0].key
                );
                validattionOccur = true;
                optionName?.[0]?.parentNode?.parentNode?.appendChild(span);
              }
            }
          });
        }
      }
    });

    if (selectedVal && !selectedValutSub) {
      toast.error("Please select a vault", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
    // If there is no group selected show error
    if (!selectedGroupId || !selectedVaultId) {
      toast.error("Please select a group and vault", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }

    // Validate Before Submitting
    const schema = Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().required(),
    });

    const dataToValidate = {
      title,
      description,
    };

    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        // If group selected but no vault selected then show error

        const formData = new FormData();

        dynamicValueRef.current.forEach((item: any, index) => {
          formData.append(`dynamic[${index}].custom_name`, item.custom_name);
          formData.append(`dynamic[${index}].id`, item.id);
          formData.append(`dynamic[${index}].name`, item.name);

          if (typeof item.value === "string") {
            formData.append(`dynamic[${index}].value[0]`, item.value);
          } else if (Array.isArray(item.value)) {
            item.value.forEach((value: any, valueIndex: any) => {
              if (typeof value === "object" && value.key) {
                formData.append(
                  `dynamic[${index}].value[${valueIndex}]`,
                  value.key
                );
              } else {
                formData.append(
                  `dynamic[${index}].value[${valueIndex}]`,
                  value
                );
              }
            });
          } else if (typeof item.value === "object") {
            formData.append(`dynamic[${index}].value`, item.value);
          }
        });
        if (title) {
          formData.append("title", title);
        }
        if (description) {
          formData.append("description", description);
        }
        if (hashTag) {
          formData.append("hashTag", hashTag);
        } else {
          formData.append("hashTag", "");
        }
        if (combined) {
          combined.forEach((item, index) => {
            formData.append(`groups[${index}].groupId`, item.groupId);
            formData.append(`groups[${index}].vaultId`, item.vaultId);
          });
        }
        if (tempId) {
          formData.append("templateId", tempId);
        }
        if (additionalImageFilePopUp && additionalImageFilePopUp.length > 0) {
          additionalImageFilePopUp.forEach((file, index) => {
            formData.append("uploads", file);
          });
        } else {
          // If the array is empty, you can omit this part or append an empty value
          formData.append("uploads", new Blob());
        }
        if (textQuillStandart) {
          formData.append("textAreaValue", textQuillStandart);
        }
        if (
          (selectedGroupId &&
            selectedVaultId &&
            !selectedVal &&
            !selectedValutSub) ||
          (selectedGroupId &&
            selectedVaultId &&
            selectedVal &&
            selectedValutSub)
        ) {
          if (!validattionOccur) {
            try {
              const response = await api.post(`asset/create`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },

                onUploadProgress: (progressEvent) => {
                  if (progressEvent.total) {
                    Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                  }
                },
              });
              if (response.data.status === true) {
                setIsLoading(false);
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });

                setTimeout(() => {
                  window.location.href = subPath + "/landingAssetView";
                }, 1500);

                settitle("");
                setDescription("");
              }
            } catch (error: any) {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((validationErrors) => {
        setIsLoading(false);
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "Error"
          );
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };

  const templateNavigate = () => {
    navigate(`/previewTemplate`);
  };

  const textColor = description.startsWith("#") ? "#66e13b" : "inherit";

  return (
    <div className="assetStyles mar-centre ">
      <Box className="Card_box_asset col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 h-cardbox">
        <Box className="pad-lt">
          <Grid className="a-title m-0" sx={{ marginBottom: "0px" }}>
            <InputBase
              placeholder="Add Asset Title"
              className="a-title m-0"
              name="title"
              sx={{ width: "100%" }}
              style={{
                color: "#353535",
                fontSize: "20px",
                fontWeight: "700",
              }}
              value={title}
              onChange={handleChangetitle}
            />
          </Grid>
          <span
            id="titleError"
            style={{ color: "red" }}
            className="error"
          ></span>
          {/* saran  */}
          <Grid>
            <Grid sx={{ marginBottom: "0px" }}>
              <TextField
                sx={{
                  background: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  // fontWeight: 700,

                  "&:hover": {
                    border: "1px solid transparent", // Remove the border on hover
                  },
                  color: textColor,
                }}
                fullWidth
                name="description"
                variant="standard"
                margin="normal"
                multiline
                rows={2}
                placeholder="Add Description "
                InputProps={{
                  disableUnderline: true,
                }}
                // className="p-0 m-0 description"
                className={`p-0 m-0 description `}
                value={description}
                onChange={handleChangeDescription}
              ></TextField>
              <span
                id="descriptionError"
                style={{ color: "red" }}
                className="error"
              ></span>
              <TextField
                sx={{
                  background: "#fff",
                  border: "1px solid #fff",
                  borderRadius: "8px",
                  fontSize: "14px",
                  "&:hover": {
                    border: "1px solid transparent", // Remove the border on hover
                  },
                  // color: textColor,
                }}
                fullWidth
                name="hashtag"
                variant="standard"
                margin="normal"
                multiline
                placeholder="Add Tags "
                InputProps={{
                  disableUnderline: true,
                }}
                id={`hashtag_${hashColor}`}
                className={`p-0 m-0 description `}
                value={hashTag}
                onChange={handleChangeHashtag}
                title="Hash Tags"
              ></TextField>
            </Grid>

            <Grid container className="justify-content-between mt-15">
              <Grid>
                <Typography className="fs-16 fw-600 color-00">
                  Share With
                </Typography>

                <Breadcrumbs
                  aria-label="breadcrumb"
                  sx={{ marginBottom: "0px" }}
                >
                  <img
                    alt="logo-bread"
                    className="logo-bread "
                    src={`${imagehttp}${orgImageData}`}
                  />
                  {/* </Link> */}
                  <Link
                    onClick={() => {
                      toggleVisibilityGroup1();
                      fetchSelectgroup("", "Search");
                    }}
                    id="myLink"
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center "
                  >
                    <img
                      className="bread-link"
                      src={`..${subPath}/assets/images/group_img.svg`}
                      alt="group"
                    />
                    <Typography
                      className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                        selectedValue ? "hideDotSideBar" : ""
                      }`}
                    >
                      {selectedValue ? `${selectedValue}` : "Select Group"}
                    </Typography>
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center  "
                    onClick={toggleVisibilityVault1}
                  >
                    <img
                      className="bread-link"
                      src={`..${subPath}/assets/images/md_vault_img.svg`}
                      alt="vault"
                    />
                    <Typography
                      className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                        vaultName ? "hideDotSideBar" : ""
                      }`}
                    >
                      {vaultName ? `${vaultName}` : "Select Vault"}
                    </Typography>
                  </Link>
                  {!isContentVisible && (
                    <Button disabled={!selectedGroupId || !selectedVaultId}>
                      <img
                        className=""
                        onClick={showBreadcrumbs}
                        src={`..${subPath}/assets/images/ic_round-plus.svg`}
                        alt="plus"
                      ></img>
                    </Button>
                  )}
                </Breadcrumbs>
                {/* Select 1 Group Modal */}

                <div>
                  {isVisibleGroupAbove && (
                    <Box className="grp_modal">
                      <Paper elevation={3} />
                      <Grid
                        className="justify-content-between"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #dddd",
                          padding: "0 0 7px 0",
                        }}
                      >
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="select_grp fs-16"
                        >
                          Select Group
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="close"
                          onClick={toggleVisibilityGroup1}
                        >
                          <img
                            src={`..${subPath}/assets/images/close.svg`}
                            className="search"
                            alt="close"
                          />
                        </Grid>
                      </Grid>
                      <Search>
                        <img
                          src={`..${subPath}/assets/images/search.svg`}
                          className="search"
                          alt="search"
                        />
                        <InputBase
                          className=" fs-14 pl-5"
                          placeholder="Search Group"
                          value={searchQuery}
                          onChange={handleSearchInputChange}
                        />
                      </Search>
                      <Grid item id="assetGroup1Search">
                        <InfiniteScroll
                          dataLength={selectGroup.length}
                          next={() => fetchSelectgroup("", "Normal")}
                          hasMore={hasMore}
                          scrollableTarget="assetGroup1Search"
                          loader={
                            <h6
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <CircularProgress
                                style={{
                                  color: "#119BFF",
                                }}
                                size={15}
                              />
                            </h6>
                          }
                          endMessage={
                            selectGroup.length !== 0 && (
                              <p className="fs-10 noMore">
                                <b>No more data found...</b>
                              </p>
                            )
                          }
                        >
                          {selectGroup
                            .filter((result) =>
                              result.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((result) => {
                              const imageUrl = result.groupIcon;
                              const expectedFileName =
                                "20231024153135_no_image.jpg";

                              let fileName = "";
                              if (typeof imageUrl === "string" && imageUrl) {
                                const url = new URL(imageUrl);
                                const pathParts = url.pathname.split("/");
                                fileName = pathParts[pathParts.length - 1];
                              }

                              const randomColor = getRandomColor(result.name);
                              return (
                                <Grid
                                  className="d-flex grphover fs-14"
                                  sx={{ padding: "13px 0" }}
                                  key={result.id}
                                  onClick={() => {
                                    fetchSelectMember(result.id);

                                    handleItemGroupClick(
                                      result.name,
                                      result.id
                                    );
                                    toggleVisibilityGroup1();
                                  }}
                                >
                                  {fileName === expectedFileName ? (
                                    <>
                                      <Avatar
                                        className=" grpimg"
                                        style={{
                                          backgroundColor: randomColor,
                                          width: "24px",
                                          height: "24px",
                                          marginRight: "8px",
                                          borderRadius: "6%",
                                        }}
                                      >
                                        {result.name
                                          ? result.name
                                              .split(" ")
                                              .map((word: any, index: number) =>
                                                index < 2
                                                  ? word.charAt(0).toUpperCase()
                                                  : ""
                                              )
                                              .join("")
                                          : ""}
                                      </Avatar>
                                    </>
                                  ) : (
                                    <img
                                      src={`${imagehttp}${result.groupIcon}`}
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        marginRight: "8px",
                                      }}
                                      alt="group"
                                    />
                                  )}
                                  {result.name}
                                </Grid>
                              );
                            })}
                        </InfiniteScroll>
                      </Grid>
                    </Box>
                  )}
                </div>
                {/* Select 1 Vault Modal  */}
                <div>
                  {isVisibleValutAbove && (
                    <Box className="valut_modal">
                      <Paper elevation={3} />
                      <Grid
                        className="justify-content-between"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #dddd",
                          padding: "0 0 7px 0",
                        }}
                      >
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="select_grp fs-16"
                        >
                          Select Vault
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="close"
                          onClick={toggleVisibilityVault1}
                        >
                          <img
                            src={`..${subPath}/assets/images/close.svg`}
                            className="search"
                            alt="close"
                          />
                        </Grid>
                      </Grid>
                      <Search>
                        <img
                          src={`..${subPath}/assets/images/search.svg`}
                          className="search"
                          alt="search"
                        />
                        <InputBase
                          className="fs-14 pl-5"
                          placeholder="Search Vault"
                          value={searchQueryVault}
                          onChange={handleSearchInputChangeVault}
                        />
                      </Search>
                      <Box className="asset_search">
                        {selectMember
                          .filter((results) =>
                            results.name
                              .toLowerCase()
                              .includes(searchQueryVault.toLowerCase())
                          )
                          .map((results) => {
                            return (
                              <Grid
                                className="d-flex memhover fs-14"
                                sx={{ padding: "13px 0" }}
                                onClick={() => {
                                  handleItemClickVault(
                                    results.name,
                                    results.id
                                  );
                                  toggleVisibilityVault1();
                                }}
                              >
                                <img
                                  src={`..${subPath}/assets/images/sidbaricon.png`}
                                  style={{
                                    width: "24px",
                                    marginRight: "8px",
                                  }}
                                  alt="vault"
                                />
                                {results.name}
                              </Grid>
                            );
                          })}
                        {selectMember.length === 0 && (
                          <Grid
                            className="d-flex memhover mt-12"
                            sx={{ justifyContent: "center", color: "#708090" }}
                          >
                            No data found
                          </Grid>
                        )}
                      </Box>
                    </Box>
                  )}
                </div>

                {/* GROUP SELECT  */}
                <div>
                  {isVisible && (
                    <Box className="selectgrp_modal">
                      <Paper elevation={3} />
                      <Grid
                        className="justify-content-between"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #dddd",
                          padding: "0 0 7px 0",
                        }}
                      >
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="select_grp fs-14"
                        >
                          Select Group
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="close"
                          onClick={toggleVisibility}
                        >
                          <img
                            src={`..${subPath}/assets/images/close.svg`}
                            className="search"
                            alt="close"
                          />
                        </Grid>
                      </Grid>
                      <Search>
                        <img
                          src={`..${subPath}/assets/images/search.svg`}
                          className="search"
                          alt="search"
                        />
                        <InputBase
                          className="fs-14 pl-5"
                          placeholder="Search Group"
                          value={searchQuery}
                          onChange={handleSearchInputChange}
                        />
                      </Search>
                      <Grid item id="assetGroup2Search">
                        <InfiniteScroll
                          dataLength={filterOptions.length}
                          next={() => fetchSelectgroup("", "Normal")}
                          hasMore={hasMore}
                          scrollableTarget="assetGroup2Search"
                          loader={
                            selectedVaultId !== undefined ? (
                              <h6
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <CircularProgress
                                  style={{
                                    color: "#119BFF",
                                  }}
                                  size={15}
                                />
                              </h6>
                            ) : (
                              <Grid
                                className="d-flex memhover mt-12"
                                sx={{
                                  justifyContent: "center",
                                  color: "#708090",
                                }}
                              >
                                No data found
                              </Grid>
                            )
                          }
                          endMessage={
                            filterOptions.length !== 0 && (
                              <p className="fs-10 noMore">
                                <b>No more data found...</b>
                              </p>
                            )
                          }
                        >
                          {filterOptions
                            .filter((result) =>
                              result.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((result) => {
                              const imageUrl = result.groupIcon;
                              const expectedFileName =
                                "20231024153135_no_image.jpg";
                              let fileName = "";
                              if (typeof imageUrl === "string" && imageUrl) {
                                const url = new URL(imageUrl);
                                const pathParts = url.pathname.split("/");
                                fileName = pathParts[pathParts.length - 1];
                              }
                              const randomColor = getRandomColor(result.name);
                              return (
                                <Grid
                                  className="d-flex grphover"
                                  sx={{ padding: "13px 0" }}
                                  key={result.id}
                                  onClick={() => {
                                    fetchSubVault(result.id);

                                    handleItemClick(result.name, result.id);
                                    toggleVisibility();
                                  }}
                                >
                                  {fileName === expectedFileName ? (
                                    <>
                                      <Avatar
                                        className=" grpimg"
                                        style={{
                                          backgroundColor: randomColor,
                                          width: "24px",
                                          height: "24px",
                                          marginRight: "8px",
                                          borderRadius: "6%",
                                        }}
                                      >
                                        {result.name
                                          ? result.name
                                              .split(" ")
                                              .map((word: any, index: number) =>
                                                index < 2
                                                  ? word.charAt(0).toUpperCase()
                                                  : ""
                                              )
                                              .join("")
                                          : ""}
                                      </Avatar>
                                    </>
                                  ) : (
                                    <img
                                      src={`${imagehttp}${result.groupIcon}`}
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        marginRight: "8px",
                                      }}
                                      alt="group_img"
                                    />
                                  )}
                                  {result.name}
                                </Grid>
                              );
                            })}
                        </InfiniteScroll>
                      </Grid>
                    </Box>
                  )}
                </div>
                {/* VAULT SELECT  */}
                <div>
                  {isVisiblevalut && selectMember && (
                    <Box className="selectvalut_modal">
                      <Paper elevation={3} />
                      <Grid
                        className="justify-content-between"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #dddd",
                          padding: "0 0 7px 0",
                        }}
                      >
                        <Grid
                          xs={10}
                          sm={10}
                          md={10}
                          lg={10}
                          xl={10}
                          className="select_grp fs-14"
                        >
                          Select Vault
                        </Grid>
                        <Grid
                          xs={2}
                          sm={2}
                          md={2}
                          lg={2}
                          xl={2}
                          className="close"
                          onClick={toggleVisibilityVault}
                        >
                          <img
                            src={`..${subPath}/assets/images/close.svg`}
                            className="search"
                            alt="close"
                          />
                        </Grid>
                      </Grid>
                      <Search>
                        <img
                          src={`..${subPath}/assets/images/search.svg`}
                          className="search"
                          alt="search"
                        />
                        <InputBase
                          className="fs-14 pl-5"
                          placeholder="Search Vault"
                          value={searchQueryVault}
                          onChange={handleSearchInputChangeVault}
                        />
                      </Search>
                      <Box className="asset_search">
                        {filterMemeber
                          .filter((results) =>
                            results.name
                              .toLowerCase()
                              .includes(searchQueryVault.toLowerCase())
                          )
                          .map((results) => (
                            <Grid
                              className="d-flex memhover"
                              sx={{ padding: "13px 0" }}
                              onClick={() => {
                                handleItemClickVaultSub(
                                  results.name,
                                  results.id
                                );
                                toggleVisibilityVault();
                              }}
                            >
                              <img
                                src={`..${subPath}/assets/images/sidbaricon.png`}
                                style={{
                                  width: "24px",
                                  marginRight: "8px",
                                }}
                                alt="vault_img"
                              />
                              {results.name}
                            </Grid>
                          ))}
                        {filterMemeber.length === 0 && (
                          <Grid
                            className="d-flex memhover mt-12"
                            sx={{ justifyContent: "center", color: "#708090" }}
                          >
                            No data found
                          </Grid>
                        )}
                      </Box>
                    </Box>
                  )}
                </div>
                {isContentVisible ? (
                  <Grid>
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{ marginBottom: "0px" }}
                    >
                      {/* <Link underline="hover" color="inherit"> */}
                      <img
                        alt="logo-bread"
                        className="logo-bread"
                        src={`${imagehttp}${orgImageData}`}
                      />
                      {/* </Link> */}
                      <Link
                        onClick={() => {
                          toggleVisibility();
                          if (
                            selectedVaultId !== undefined &&
                            selectedVaultId !== null
                          ) {
                            fetchSelectgroup("", "Search");
                          }
                        }}
                        id="myLink"
                        underline="hover"
                        color="inherit"
                        className="d-flex  align-items-center "
                        // sx={modalStyle}
                      >
                        <img
                          alt="group_img"
                          className="bread-link"
                          src={`..${subPath}/assets/images/group_img.svg`}
                        />
                        <Typography
                          className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                            selectedVal ? "hideDotSideBar" : ""
                          }`}
                        >
                          {selectedVal ? `${selectedVal}` : "Select Group"}
                        </Typography>
                      </Link>
                      <Link
                        underline="hover"
                        color="inherit"
                        className="d-flex  align-items-center "
                        onClick={toggleVisibilityVault}
                      >
                        <img
                          className="bread-link"
                          src={`..${subPath}/assets/images/md_vault_img.svg`}
                          alt="vault_img"
                        />
                        <Typography
                          className={`fs-12 textdecoration_unterline pl-5 cur-pointer  ${
                            selectedValutSub ? "hideDotSideBar" : ""
                          }`}
                        >
                          {selectedValutSub
                            ? `${selectedValutSub}`
                            : "Select Vault"}
                        </Typography>
                      </Link>
                      <Button>
                        <img
                          alt="minus"
                          onClick={hideBreadcrumbs}
                          src={`..${subPath}/assets/images/ic_round-minus.png`}
                        ></img>
                      </Button>
                    </Breadcrumbs>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid container className="justify-content-between mt-15">
              {checkTemplateChoose ? null : (
                <span
                  className="cur-pointer createVaultText"
                  style={{ fontSize: "14px" }}
                  onClick={templateNavigate}
                >
                  Choose Template
                </span>
              )}
            </Grid>
          </Grid>
          <Grid>
            {additionalImageFilePopUp[0] ? (
              <>
                <Grid style={{ flexDirection: "column" }}>
                  <Grid className="attach-file " sx={{ paddingTop: "10px" }}>
                    <Grid>
                      <span className="fs-14 fw-500  color hideDot-filename">
                        {fileName === null ? pre : fileName}
                      </span>
                    </Grid>
                    <Grid>
                      <span></span>
                      <span
                        className="createVaultBtn d-flex cur-pointer"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6 12H18M12 18V6"
                            stroke="url(#paint0_linear_8999_6214)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_8999_6214"
                              x1="19.3789"
                              y1="-12"
                              x2="-6.91182"
                              y2="-8.19899"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#119BFF" />
                              <stop offset="1" stopColor="#4CCE1F" />
                            </linearGradient>
                          </defs>
                        </svg>
                        &nbsp;{" "}
                        <span className="createVaultText fs-14">
                          Add More Attachment
                        </span>
                      </span>
                      <input
                        type="file"
                        multiple
                        name="upload[]"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                      />
                    </Grid>
                  </Grid>
                  <Box className="mt-5">
                    <Grid item className="preview-display">
                      {samplePreview === 0 && (
                        <>
                          {/* eslint-disable-next-line array-callback-return */}
                          {additionalImageFilePopUp.map((file, index) => {
                            if (index === 0) {
                              const fileExtension = file.name
                                .substring(file.name.lastIndexOf("."))
                                .toLowerCase();

                              if (
                                imageCheckTypes.includes(file.type) ||
                                [".hdr", ".svg"].includes(fileExtension)
                              ) {
                                if (imageCheckTypes.includes(file.type)) {
                                  return (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt="Preview"
                                      style={{
                                        borderRadius: "16px 16px 16px 16px",
                                      }}
                                      key={index}
                                      width="100%"
                                      height="100%"
                                    />
                                  );
                                } else if (
                                  [".hdr", ".svg"].includes(fileExtension)
                                ) {
                                  return (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt="Preview"
                                      style={{
                                        borderRadius: "16px 16px 16px 16px",
                                      }}
                                      key={index}
                                      width="100%"
                                      height="100%"
                                    />
                                  );
                                }
                              } else if (
                                acceptedMediaTypes.includes(file.type) ||
                                acceptedMediaTypes.includes(fileExtension)
                              ) {
                                return (
                                  <div className="video_content">
                                    <video
                                      controls
                                      width="100%"
                                      height="100%"
                                      src={URL.createObjectURL(file)}
                                      key={index}
                                    ></video>
                                  </div>
                                );
                              } else if (
                                acceptedAudioTypes.includes(file.type) ||
                                acceptedAudioTypes.includes(fileExtension)
                              ) {
                                return (
                                  <div>
                                    <audio
                                      controls
                                      key={index}
                                      src={URL.createObjectURL(file)}
                                    ></audio>
                                  </div>
                                );
                              } else if (
                                documentTypes.includes(fileExtension) ||
                                documentTypes.includes(file.type)
                              ) {
                                return (
                                  <div className="fileExtension">
                                    <CardActions key={index} className="org_bg">
                                      <CardMedia
                                        sx={{ borderRadius: "7px" }}
                                        component="img"
                                        image={`..${subPath}/assets/images/documentimage.svg`}
                                        alt=""
                                        className="CardMedia-width-height"
                                        title={file.name}
                                      />
                                      <Typography className="audioDoc hideDotSingleLine">
                                        {file.name}
                                      </Typography>
                                    </CardActions>
                                  </div>
                                );
                              }
                            }
                          })}
                        </>
                      )}

                      {samplePreview !== 0 && selectedFilePreview && (
                        <>
                          {selectedFileType &&
                            imageCheckTypes.includes(selectedFileType) && (
                              <>
                                <img
                                  src={selectedFilePreview}
                                  alt="Preview"
                                  style={{
                                    borderRadius: "16px 16px 16px 16px",
                                  }}
                                  width="100%"
                                  height="100%"
                                />
                              </>
                            )}
                          {selectedFileType &&
                            acceptedMediaTypes.includes(selectedFileType) && (
                              <div className="video_content">
                                <video
                                  controls
                                  width="100%"
                                  height="100%"
                                  src={selectedFilePreview}
                                ></video>
                              </div>
                            )}
                          {selectedFileType &&
                            acceptedAudioTypes.includes(selectedFileType) && (
                              <audio controls>
                                <source
                                  src={selectedFilePreview}
                                  type={selectedFileType}
                                />
                              </audio>
                            )}
                          {SelectedFileTypeDoc &&
                            documentTypes.includes(SelectedFileTypeDoc) && (
                              <div className="fileExtension">
                                <CardActions className="org_bg">
                                  <CardMedia
                                    sx={{ borderRadius: "7px" }}
                                    component="img"
                                    image={`..${subPath}/assets/images/documentimage.svg`}
                                    alt="img"
                                    className="CardMedia-width-height"
                                  />
                                  <Typography
                                    className="audioDoc hideDotSingleLine"
                                    title={`${fileName}`}
                                  >
                                    {fileName}
                                  </Typography>
                                </CardActions>
                              </div>
                            )}
                        </>
                      )}
                    </Grid>
                  </Box>
                  <Grid className="selectedDocsScroll pt-15">
                    {additionalImageFilePopUp && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                          marginBottom: "10px",
                        }}
                      >
                        {/* eslint-disable-next-line array-callback-return */}
                        {additionalImageFilePopUp.map((file, index) => {
                          const fileExtension = file.name
                            .substring(file.name.lastIndexOf("."))
                            .toLowerCase();
                          if (
                            imageCheckTypes.includes(file.type) ||
                            [".hdr", ".svg"].includes(fileExtension)
                          ) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="close"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                    handleFileClick(index, false);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index, true)}
                                >
                                  <img
                                    src={URL.createObjectURL(file)}
                                    height="45px"
                                    style={{
                                      borderRadius: "5px",
                                      margin: "5px",
                                    }}
                                    alt="Preview"
                                  />
                                </div>
                              </div>
                            );
                          } else if (
                            acceptedMediaTypes.includes(file.type) ||
                            acceptedMediaTypes.includes(fileExtension)
                          ) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                    handleFileClick(index, false);
                                  }}
                                  alt="close"
                                />
                                <div
                                  style={{ padding: "8px 8px 1px 8px" }}
                                  onClick={() => handleFileClick(index, true)}
                                >
                                  <video
                                    height="45px"
                                    style={{
                                      borderRadius: "5px",
                                    }}
                                    autoPlay
                                    muted
                                    src={URL.createObjectURL(file)}
                                  >
                                    {/* <source
                                      src={URL.createObjectURL(file)}
                                      type={file.type}
                                    /> */}
                                  </video>
                                </div>
                              </div>
                            );
                          } else if (
                            acceptedAudioTypes.includes(file.type) ||
                            acceptedAudioTypes.includes(fileExtension)
                          ) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="close"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                    handleFileClick(index, false);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index, true)}
                                >
                                  <img
                                    alt="Audio icon"
                                    src={`..${subPath}/assets/images/Audio_light.svg`}
                                    height="55px"
                                  ></img>
                                </div>
                              </div>
                            );
                          } else if (documentTypes.includes(fileExtension)) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="close"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                    handleFileClick(index, false);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index, true)}
                                >
                                  <img
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    alt="Document icon"
                                    height="55px"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid>
                <div className="inputDnD" style={{ position: "relative" }}>
                  <input
                    id="inputFile"
                    type="file"
                    title=""
                    multiple
                    name="upload[]"
                    onChange={handleImageUpload}
                    onClick={() => fileInputRef.current?.click()}
                    className="form-control-file font-weight-bold"
                    data-title="Choose Cover or Drop Files Here"
                    aria-label="Choose Cover or Drop Files Here"
                  />
                  <h4 className="dropHeader gradientText">
                    Choose Cover or Drop Files Here
                  </h4>
                  <br />

                  <p className="assetFileSub">Click here to add Attachment.</p>

                  {/* <label htmlFor="inputFile" className="inputLabel label-stl">Click here to add Attachment</label> */}
                </div>
              </Grid>
            )}
          </Grid>

          {/* <Grid>
            <ReactQuill  theme="snow"
            className="mb-4"
            formats={quillFormats}
            modules={{
              toolbar: {
                container: quillContainerData,
               
              }
           }}
            preserveWhitespace={true} 
          />
          </Grid> */}
          {/* {assetData.map((field: FormField) => ( */}
          {TemplateId ? (
            <>
              <Grid className="addasset_Scroll">
                <ReactFormGenerator
                  form_action="/"
                  form_method="POST"
                  action_name="Save"
                  onSubmit={handleFormChange}
                  data={assetDataArray}
                  skip_validations={true}
                  // handleMultiselectChange={handleMultiselectChange}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid className="SortableItem rfb-item">
                <ReactQuill
                  theme="snow"
                  className="mb-4 asset_box_hgt"
                  formats={quillFormats}
                  modules={{
                    toolbar: {
                      container: quillContainerData,
                    },
                  }}
                  onChange={handleChangeQuillStandart}
                  preserveWhitespace={true}
                  placeholder="Start writing here...."
                  style={quillStyle}
                />
              </Grid>
            </>
          )}

          {/* ))}  */}
        </Box>
        <Grid
          sx={{
            marginBottom: "5%",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <>
            <span
              className="sidebar-c-b smallAshBold cur-pointer"
              style={{ marginRight: "10px", marginTop: "6px" }}
              onClick={handleButtonClick}
            >
              Cancel
            </span>
            <span
              className="m-button sidebar-c-b "
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "auto",
              }}
              onClick={handleSubmit}
            >
              {isLoading ? "Loading..." : "Submit Content"}
            </span>
          </>
        </Grid>
      </Box>
    </div>
  );
};
export default StaticAsset;
