import { Tooltip } from "antd";

export const GoldIcon = () => {
  return (
    <div>
      <div
        style={{
          position: "relative",
          zIndex: "10",
          bottom: "-16px",
        }}
      >
        <Tooltip title="Gold">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 27"
            fill="none"
          >
            <path
              d="M27.5168 10.332L18.6018 8.44711L14.0509 0.5L9.49999 8.44711L0.584961 10.332L6.7151 17.0735L5.74719 26.1413L14.0509 22.4055L22.3546 26.1413L21.3867 17.0735L27.5168 10.332Z"
              fill="url(#paint0_linear_5551_23163)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5551_23163"
                x1="14.0084"
                y1="0.814148"
                x2="14.0084"
                y2="26.7102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD700" />
                <stop offset="0.5" stopColor="#FFE591" />
                <stop offset="1" stopColor="#FFD700" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "-4px",
          position: "relative",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 12 21"
          fill="none"
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};

export const SilverIcon = () => {
  return (
    <div>
      <div
        style={{
          position: "relative",
          zIndex: "10",
          bottom: "-16px",
        }}
      >
        <Tooltip title="Silver">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 26"
            fill="none"
          >
            <path
              d="M27.5168 9.832L18.6018 7.94711L14.0509 0L9.49999 7.94711L0.584961 9.832L6.7151 16.5735L5.74719 25.6413L14.0509 21.9055L22.3546 25.6413L21.3867 16.5735L27.5168 9.832Z"
              fill="url(#paint0_linear_520_14006)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_520_14006"
                x1="14.0084"
                y1="0.314148"
                x2="14.0084"
                y2="26.2102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B7B7B7" />
                <stop offset="0.5" stopColor="white" />
                <stop offset="1" stopColor="#CCCBC8" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "-4px",
          position: "relative",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 12 21"
          fill="none"
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};

export const BronzeIcon = () => {
  return (
    <div>
      <div
        style={{
          position: "relative",
          zIndex: "10",
          bottom: "-16px",
        }}
      >
        <Tooltip title="Bronze">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 26"
            fill="none"
          >
            <path
              d="M27.5168 9.832L18.6018 7.94711L14.0509 0L9.49999 7.94711L0.584961 9.832L6.7151 16.5735L5.74719 25.6413L14.0509 21.9055L22.3546 25.6413L21.3867 16.5735L27.5168 9.832Z"
              fill="url(#paint0_linear_7363_8804)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7363_8804"
                x1="14.0084"
                y1="0.314148"
                x2="14.0084"
                y2="26.2102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CB6F1B" />
                <stop offset="0.5" stopColor="#E8B481" />
                <stop offset="1" stopColor="#CD7F32" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "-4px",
          position: "relative",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 12 21"
          fill="none"
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};

export const CreateVault = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 12H18M12 18V6"
        stroke="url(#paint0_linear_8999_6214)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8999_6214"
          x1="19.3789"
          y1="-12"
          x2="-6.91182"
          y2="-8.19899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#119BFF" />
          <stop offset="1" stopColor="#4CCE1F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const VaultSelected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 41 40"
      fill="none"
    >
      <rect
        x="6.33333"
        y="6.83333"
        width="28.3333"
        height="25"
        rx="0.833333"
        stroke="black"
        stroke-width="1.66667"
      />
      <rect
        x="9.66634"
        y="10.1693"
        width="21.6667"
        height="18.3333"
        rx="0.833333"
        stroke="black"
        stroke-width="1.66667"
      />
      <circle
        cx="20.4997"
        cy="19.3337"
        r="6.04167"
        stroke="black"
        stroke-width="1.25"
      />
      <circle cx="20.4997" cy="19.3337" r="1.66667" fill="black" />
      <path
        d="M15.5 18.7109H14.875V19.9609H15.5V18.7109ZM16.3333 19.9609C16.6785 19.9609 16.9583 19.6811 16.9583 19.3359C16.9583 18.9908 16.6785 18.7109 16.3333 18.7109V19.9609ZM15.5 19.9609H16.3333V18.7109H15.5V19.9609Z"
        fill="black"
      />
      <path
        d="M21.125 14.3906L21.125 13.7656L19.875 13.7656L19.875 14.3906L21.125 14.3906ZM19.875 15.224C19.875 15.5691 20.1548 15.849 20.5 15.849C20.8452 15.849 21.125 15.5691 21.125 15.224L19.875 15.224ZM19.875 14.3906L19.875 15.224L21.125 15.224L21.125 14.3906L19.875 14.3906Z"
        fill="black"
      />
      <path
        d="M19.875 24.3359L19.875 24.9609L21.125 24.9609L21.125 24.3359L19.875 24.3359ZM21.125 23.5026C21.125 23.1574 20.8452 22.8776 20.5 22.8776C20.1548 22.8776 19.875 23.1574 19.875 23.5026L21.125 23.5026ZM21.125 24.3359L21.125 23.5026L19.875 23.5026L19.875 24.3359L21.125 24.3359Z"
        fill="black"
      />
      <path
        d="M25.5 19.9609L26.125 19.9609L26.125 18.7109L25.5 18.7109L25.5 19.9609ZM24.6667 18.7109C24.3215 18.7109 24.0417 18.9908 24.0417 19.3359C24.0417 19.6811 24.3215 19.9609 24.6667 19.9609L24.6667 18.7109ZM25.5 18.7109L24.6667 18.7109L24.6667 19.9609L25.5 19.9609L25.5 18.7109Z"
        fill="black"
      />
      <path
        d="M17.4263 15.3627L16.9844 14.9208L16.1005 15.8047L16.5424 16.2466L17.4263 15.3627ZM17.1317 16.8359C17.3758 17.08 17.7715 17.08 18.0156 16.8359C18.2597 16.5918 18.2597 16.1961 18.0156 15.952L17.1317 16.8359ZM16.5424 16.2466L17.1317 16.8359L18.0156 15.952L17.4263 15.3627L16.5424 16.2466Z"
        fill="black"
      />
      <path
        d="M24.4576 16.2857L24.8995 15.8438L24.0156 14.9599L23.5737 15.4018L24.4576 16.2857ZM22.9844 15.9911C22.7403 16.2351 22.7403 16.6309 22.9844 16.8749C23.2285 17.119 23.6242 17.119 23.8683 16.8749L22.9844 15.9911ZM23.5737 15.4018L22.9844 15.9911L23.8683 16.8749L24.4576 16.2857L23.5737 15.4018Z"
        fill="black"
      />
      <path
        d="M16.5424 22.4379L16.1005 22.8799L16.9844 23.7638L17.4263 23.3218L16.5424 22.4379ZM18.0156 22.7326C18.2597 22.4885 18.2597 22.0928 18.0156 21.8487C17.7715 21.6046 17.3758 21.6046 17.1317 21.8487L18.0156 22.7326ZM17.4263 23.3218L18.0156 22.7326L17.1317 21.8487L16.5424 22.4379L17.4263 23.3218Z"
        fill="black"
      />
      <path
        d="M23.6127 23.3218L24.0547 23.7638L24.9386 22.8799L24.4966 22.4379L23.6127 23.3218ZM23.9074 21.8487C23.6633 21.6046 23.2676 21.6046 23.0235 21.8487C22.7794 22.0928 22.7794 22.4885 23.0235 22.7326L23.9074 21.8487ZM24.4966 22.4379L23.9074 21.8487L23.0235 22.7326L23.6127 23.3218L24.4966 22.4379Z"
        fill="black"
      />
      <rect x="8.83301" y="31" width="8.33333" height="2.5" fill="black" />
      <rect x="23.833" y="31" width="8.33333" height="2.5" fill="black" />
      <rect
        x="7.16406"
        y="14.3359"
        width="1.66667"
        height="1.66667"
        fill="black"
      />
      <rect
        x="7.16406"
        y="22.667"
        width="1.66667"
        height="1.66667"
        fill="black"
      />
    </svg>
  );
};

export const VaultUnSelected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 41 41"
      fill="none"
    >
      <rect
        x="6.33333"
        y="7.33333"
        width="28.3333"
        height="25"
        rx="0.833333"
        stroke="#708090"
        stroke-width="1.66667"
      />
      <rect
        x="9.66634"
        y="10.6693"
        width="21.6667"
        height="18.3333"
        rx="0.833333"
        stroke="#708090"
        stroke-width="1.66667"
      />
      <circle
        cx="20.4997"
        cy="19.8337"
        r="6.04167"
        stroke="#708090"
        stroke-width="1.25"
      />
      <circle cx="20.4997" cy="19.8337" r="1.66667" fill="#708090" />
      <path
        d="M15.5 19.2109H14.875V20.4609H15.5V19.2109ZM16.3333 20.4609C16.6785 20.4609 16.9583 20.1811 16.9583 19.8359C16.9583 19.4908 16.6785 19.2109 16.3333 19.2109V20.4609ZM15.5 20.4609H16.3333V19.2109H15.5V20.4609Z"
        fill="#708090"
      />
      <path
        d="M21.125 14.8906L21.125 14.2656L19.875 14.2656L19.875 14.8906L21.125 14.8906ZM19.875 15.724C19.875 16.0691 20.1548 16.349 20.5 16.349C20.8452 16.349 21.125 16.0691 21.125 15.724L19.875 15.724ZM19.875 14.8906L19.875 15.724L21.125 15.724L21.125 14.8906L19.875 14.8906Z"
        fill="#708090"
      />
      <path
        d="M19.875 24.8359L19.875 25.4609L21.125 25.4609L21.125 24.8359L19.875 24.8359ZM21.125 24.0026C21.125 23.6574 20.8452 23.3776 20.5 23.3776C20.1548 23.3776 19.875 23.6574 19.875 24.0026L21.125 24.0026ZM21.125 24.8359L21.125 24.0026L19.875 24.0026L19.875 24.8359L21.125 24.8359Z"
        fill="#708090"
      />
      <path
        d="M25.5 20.4609L26.125 20.4609L26.125 19.2109L25.5 19.2109L25.5 20.4609ZM24.6667 19.2109C24.3215 19.2109 24.0417 19.4908 24.0417 19.8359C24.0417 20.1811 24.3215 20.4609 24.6667 20.4609L24.6667 19.2109ZM25.5 19.2109L24.6667 19.2109L24.6667 20.4609L25.5 20.4609L25.5 19.2109Z"
        fill="#708090"
      />
      <path
        d="M17.4263 15.8627L16.9844 15.4208L16.1005 16.3047L16.5424 16.7466L17.4263 15.8627ZM17.1317 17.3359C17.3758 17.58 17.7715 17.58 18.0156 17.3359C18.2597 17.0918 18.2597 16.6961 18.0156 16.452L17.1317 17.3359ZM16.5424 16.7466L17.1317 17.3359L18.0156 16.452L17.4263 15.8627L16.5424 16.7466Z"
        fill="#708090"
      />
      <path
        d="M24.4576 16.7857L24.8995 16.3438L24.0156 15.4599L23.5737 15.9018L24.4576 16.7857ZM22.9844 16.4911C22.7403 16.7351 22.7403 17.1309 22.9844 17.3749C23.2285 17.619 23.6242 17.619 23.8683 17.3749L22.9844 16.4911ZM23.5737 15.9018L22.9844 16.4911L23.8683 17.3749L24.4576 16.7857L23.5737 15.9018Z"
        fill="#708090"
      />
      <path
        d="M16.5424 22.9379L16.1005 23.3799L16.9844 24.2638L17.4263 23.8218L16.5424 22.9379ZM18.0156 23.2326C18.2597 22.9885 18.2597 22.5928 18.0156 22.3487C17.7715 22.1046 17.3758 22.1046 17.1317 22.3487L18.0156 23.2326ZM17.4263 23.8218L18.0156 23.2326L17.1317 22.3487L16.5424 22.9379L17.4263 23.8218Z"
        fill="#708090"
      />
      <path
        d="M23.6127 23.8218L24.0547 24.2638L24.9386 23.3799L24.4966 22.9379L23.6127 23.8218ZM23.9074 22.3487C23.6633 22.1046 23.2676 22.1046 23.0235 22.3487C22.7794 22.5928 22.7794 22.9885 23.0235 23.2326L23.9074 22.3487ZM24.4966 22.9379L23.9074 22.3487L23.0235 23.2326L23.6127 23.8218L24.4966 22.9379Z"
        fill="#708090"
      />
      <rect x="8.83301" y="31.5" width="8.33333" height="2.5" fill="#708090" />
      <rect x="23.833" y="31.5" width="8.33333" height="2.5" fill="#708090" />
      <rect
        x="7.16406"
        y="14.8359"
        width="1.66667"
        height="1.66667"
        fill="#708090"
      />
      <rect
        x="7.16406"
        y="23.167"
        width="1.66667"
        height="1.66667"
        fill="#708090"
      />
    </svg>
  );
};
