import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, InputBase, Tooltip } from "@mui/material";
import { subPath } from "../../../../Constants/Global";
import "../ChatHeader/Chatheader.css";

const ChatHeader: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        height: "60px",
        backgroundColor: "#F5F5F5",
        width: "100%",
        position: "sticky",
        top: "0px",
        zIndex: 1,
      }}
    >
      <Grid
        item
        xs={12}
        sm={8.5}
        sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
      >
        <Box className="search_wid_div_chat " style={{ cursor: "text" }}>
          <div
            className="search_header_chart d-flex"
            style={{ alignItems: "center" }}
          >
            <img
              //   src="../beta/assets/images/search.svg"
              src={`..${subPath}/assets/images/images/searchIocn_chart.png`}
              alt="Search"
              style={{ width: "18px", height: "18px" }}
            />
            <InputBase
              placeholder="Search"
              className="ml-15 input_search"
              sx={{ background: "#fffff", boxSizing: "border-box" }}
              id="searchText"
              autoComplete="off"
            />
          </div>
        </Box>
      </Grid>
      <Grid
        xs={12}
        sm={3.5}
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          paddingRight: "30px",
        }}
      >
        <Button
          className="nodification  ml-10"
          id="notification"
          aria-label="Notification"
        >
          <Tooltip title="Notifications">
            <img
              src={`..${subPath}/assets/images/images/notification_chart_Icon.png`}
              alt=""
              style={{
                height: "1.9rem",
              }}
            />
          </Tooltip>
        </Button>
        <Button
          className="nodification  ml-20"
          id="notification"
          aria-label="Notification"
        >
          <Tooltip title="">
            <img
              src={`..${subPath}/assets/images/images/Person_Icon_chat.png`}
              alt=""
              style={{
                height: "1.9rem",
              }}
            />
          </Tooltip>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChatHeader;