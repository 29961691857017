import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FiUpload } from "react-icons/fi";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { Avatar, Tooltip, Form, Input } from "antd";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InfiniteScroll from "react-infinite-scroll-component";
import axios, { AxiosError } from "axios";
import { imagehttp, decodeToken, subPath } from "../../Constants/Global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import eventEmitter from "../../Constants/EventEmiter";
import GroupEditModal from "../Modal/GroupEditModal";
import Skeleton from "@mui/material/Skeleton";
import {
  Popover,
  MenuList,
  Paper,
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { RowProfile, RowGroup } from "./Row";
import UseAxios from "../../Constants/UseAxios";
import {
  BronzeIcon,
  DeleteIcon,
  EditIcon,
  GoldIcon,
  MuteIcon,
  SilverIcon,
  UnMuteIcon,
  VaultIcon,
} from "../../Utils/ProfilePage";
import { DefaultImg } from "../../Utils/DefaultImg";
import { useAppDispatch } from "../../Constants/hooks";
import { setItemName, setSearchValue } from "../../Redux/Search/action";

interface UserInfo {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profilePicture: File | null;
  roles: string | null;
  designation: string | null;
  assetCount: number | null;
  groupCount: number | null;
  adminCount: number | null;
  contributorcount: number | null;
  viewercount: number | null;
  gram_count: number | null;
}
interface GroupsInfo {
  description: string | null;
  groupIcon: File | null;
  groupId: string | null;
  muteStatus: boolean | null;
  id: string | null;
  name: string | null;
  asset_count: number | null;
  propilePic: File | null;
  hoverlist: any | null;
  username: string | null;
}

interface AssetsInfo {
  assetId: string | null;
  totalGram: number | null;
  commentCount: number | null;
  viewCount: number | null;
  hashTag: string | null;
  description: string | null;
  title: string | null;
  asset: any;
  groupicon: File;
  groupDetail: any;
  groupname: string | null;
  vaultname: string | null;
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
interface SkeletonTabProps {
  width: number;
  height: number;
}

const SkeletonTab: React.FC<SkeletonTabProps> = ({ width, height }) => {
  return (
    <Skeleton width={width} height={height} sx={{ borderRadius: "8px" }} />
  );
};
const View: React.FC = () => {
  let api = UseAxios();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState("1");

  const [createdBy, setCreatedBy] = useState<string>();
  const [assetCounts, setAssetCounts] = useState<UserInfo>();
  const [groupCounts, setGroupCounts] = useState<number>(0);

  const maxSize = 2 * 1024 * 1024;
  const [errorMessage, setErrorMessage] = useState("");
  const [groups, setGroups] = useState<GroupsInfo[]>([]);
  const [assets, setAssets] = useState<AssetsInfo[]>([]);
  const [groupsViewerInfo, setGroupsViewerInfo] = useState<GroupsInfo[]>([]);
  const [groupsContributorInfo, setGroupsContributorInfo] = useState<
    GroupsInfo[]
  >([]);
  const [badge, setBadge] = useState<string | null>();
  const [gramCount, setGramCount] = useState<string | null>();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTwo, setAnchorElTwo] = useState(null);
  const [anchorElThree, setAnchorElThree] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [userFirstName, setUserFirstName] = useState<string>();
  const [userLastName, setUserLastName] = useState<string>();

  const [editName, setEditName] = useState<string>();
  const [editLastName, setEditLastName] = useState<string>();
  const [userProfileInfo, setUserProfileInfo] = useState<UserInfo>();
  const [userEditProfile, setUserProfile] = useState<File | null>();
  const [userEditProfileInfo, setUserEditProfileInfo] = useState<File | null>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [id, setId] = useState<string | null>();
  const [Organisation, setOrganisationOpen] = React.useState(false);

  const [deleteId, setDeleteId] = useState<string | null>();

  const selectedId = useRef<string | null>(null);
  const isMuted = useRef<boolean | null>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(true);
  const [isUserPageIsLoading, setUserPageIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const size = 15;
  const [hasMore, setHasMore] = useState(true);
  const userRole = decodeToken().role;

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  //Noimage
  const getFileNameFromImageUrl = (imageUrl: any) => {
    if (typeof imageUrl === "string" && imageUrl) {
      const url = new URL(imageUrl);
      const pathParts = url.pathname.split("/");
      return pathParts[pathParts.length - 1];
    }
    return "";
  };
  const maxCount = 4;
  //tab
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //Groups  modal
  const orgClickOpen = () => {
    setOrganisationOpen(true);
  };
  const orgClose = () => {
    setOrganisationOpen(false);
    setEditName("");
    setUserEditProfileInfo(null);
    setErrorMessage("");
  };

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenuContribute = (event: any) => {
    setAnchorElTwo(event.currentTarget);
  };

  const handleCloseMenuTwo = () => {
    setAnchorElTwo(null);
  };

  const handleOpenMenuThree = (event: any) => {
    setAnchorElThree(event.currentTarget);
  };

  const handleCloseMenuThree = () => {
    setAnchorElThree(null);
  };

  const showModalGroup = (event: React.SyntheticEvent) => {
    setIsModalVisible(true);
    handleCloseMenu();
    handleCloseMenuTwo();
    handleCloseMenuThree();
    eventEmitter.emit("triggerEditGrp", selectedId.current);
  };

  const deletePopOpen = (id: any) => {
    setOpenDelete(true);
    handleCloseMenu();
    handleCloseMenuTwo();
    handleCloseMenuThree();
    setDeleteId(id);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const MuteandUnmute = async (id: any) => {
    const requestBody = {
      groupId: id,
    };
    try {
      await api
        .post("mail-notification/group-mute", requestBody)
        .then((response) => {
          if (response.data.status === true) {
            toast.success(response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 500,
            });
            handleCloseMenu();
            handleCloseMenuTwo();
            handleCloseMenuThree();
            handleClickGroup(1);
          }
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    const userId = decodeToken()?.id;
    if (userId !== null) {
      setCreatedBy(userId);
    }
    profileDetail();
    handleClickAsset(0);
    getGroupCount();
    dispatch(setItemName(""));
  }, [createdBy]);

  const profileDetail = async () => {
    if (createdBy) {
      try {
        const response = await api.get(`group/user/details?id=${createdBy}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.data.status === true) {
          setUserProfileInfo(response.data.data.user);
          setUserFirstName(response.data.data.user.firstName);
          setUserLastName(response.data.data.user.lastName);
          setUserProfile(response.data.data.user.profilePicture);
          setId(response.data.data.user.id);
          setBadge(response.data.data.user.badge);
          setGramCount(response.data.data.gramCount);
          setUserPageIsLoading(false);
        }
      } catch (error) {
        setUserPageIsLoading(false);
      }
    }
  };

  //get asset and group details
  const handleClickAsset = async (type: number) => {
    let pageCount = page;
    if (type === 1) {
      setHasMore(true);
      pageCount = 0;
      setPage(0);
      window.scrollTo(0, 0);
    }

    try {
      const response = await api.get(
        `group/user-based-list?userId=${
          decodeToken().id
        }&page=${pageCount}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        const assetData = response.data.data.assertList;
        if (assetData.length > 0) {
          // Reorder the asset content for each element in assetData
          const typeOrder = ["Video", "Image", "Audio"];
          // Reorder the asset content for each element in assetData
          const customSort = (a: any, b: any) => {
            const typeAIndex = typeOrder.indexOf(a.fieldType);
            const typeBIndex = typeOrder.indexOf(b.fieldType);
            // Move types not in the order to the end
            if (typeAIndex === -1) return 1;
            if (typeBIndex === -1) return -1;
            return typeAIndex - typeBIndex;
          };
          assetData.forEach((assetElement: any) => {
            if (
              assetElement.asset.assetContent &&
              assetElement.asset.assetContent.length > 0
            ) {
              assetElement.asset.assetContent.sort(customSort);
            }
          });
        }

        if (assetData.length < size) {
          setHasMore(false);
        }

        if (pageCount === 0) {
          setAssets(assetData);
          setAssetCounts(response.data.data.assetCount);
        } else {
          setAssets((prevAssets) => [...prevAssets, ...assetData]);
        }

        setIsLoading(false);
        setPage(pageCount + 1);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getGroupCount = async () => {
    try {
      const response = await api.get(
        `group/get-group-count?userId=${decodeToken().id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setGroupCounts(response.data.data.groupCount);
      }
    } catch (error) {}
  };

  const handleClickGroup = async (type: number) => {
    let pageCount = page;
    if (type === 1) {
      setHasMore(true);
      setPage(0);
      pageCount = 0;
      window.scrollTo(0, 0);
    }

    try {
      const response = await api.get(
        `group/user/based/group?userId=${
          decodeToken().id
        }&page=${pageCount}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        const groupData = response.data.data.categorizedGroups;
        setGroupCounts(response.data.data.groupCount);
        let gLength = 0;
        if (pageCount === 0) {
          setGroups(groupData.admin);
          setGroupsViewerInfo(groupData.viewer);
          setGroupsContributorInfo(groupData.contributor);
          gLength = size;
        } else {
          setGroups((prevGroups) => [...prevGroups, ...groupData.admin]);
          setGroupsViewerInfo((prevGroups) => [
            ...prevGroups,
            ...groupData.viewer,
          ]);
          setGroupsContributorInfo((prevGroups) => [
            ...prevGroups,
            ...groupData.contributor,
          ]);
          gLength = size * (pageCount + 1);
        }
        setIsLoadingGroup(false);
        const count =
          groupData.admin.length +
          groupData.viewer.length +
          groupData.contributor.length;
        if (count < size || gLength >= groupCounts) {
          setHasMore(false);
        }
        setPage(pageCount + 1);
      }
    } catch (error) {
      setIsLoadingGroup(false);
    }
  };
  // Delete Group
  const handleDelete = async () => {
    const url = `group/delete?id=${deleteId}`;
    try {
      const res = await api.get(url);
      if (res.data.status === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenDelete(false);
        handleClickGroup(1);
        eventEmitter.emit("groupUpdated");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshSidebarGroup = () => {
    handleClickGroup(1);
  };

  //edit Profile
  const handleChangeEditFristname = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditName(event.target.value);
  };
  const handleChangeEditLastname = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditLastName(event.target.value);
  };
  const clearUpload = () => {
    setUserEditProfileInfo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const selectedFile = e.target.files[0];
      const acceptedTypes = [
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".bmp",
        ".heif",
        ".svg",
        ".hdr",
      ];
      const isValidFileType = acceptedTypes.some((type) =>
        selectedFile.name.toLowerCase().endsWith(type)
      );

      if (isValidFileType && selectedFile.size <= maxSize) {
        setUserEditProfileInfo(e.target.files[0]);
        setErrorMessage("");
      } else {
        if (!isValidFileType) {
          setErrorMessage(`Accepted types are: ${acceptedTypes.join(", ")}`);
        } else {
          setErrorMessage(
            `File size must be less than ${maxSize / (1024 * 1024)} MB`
          );
        }
      }
    }
  };

  // update Profile Info
  const onFinish = async (values: any) => {
    setErrorMessage("");
    // Empty the error messages and red borders
    // const errorFields = document.getElementsByClassName("error");
    // errorFields[0].innerHTML = "";

    // const inputFields = document.getElementsByClassName("field");
    // inputFields?.[0]?.classList.remove("borderRed");

    const errorFields = document.getElementsByClassName("error");
    console.log(errorFields, "errorFields.length");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("field");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields[i].classList.remove("borderRed");
    }
    // Validate the input fields
    const schema = Yup.object().shape({
      name: Yup.string().required("FirstName is a required field."),
      lname: Yup.string().required("LastName is a required field."),
    });

    // Data to validate
    const dataToValidate = {
      name: editName,
      lname: editLastName,
    };

    // Validate the data against the schema
    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        const formData = new FormData();
        if (editName) {
          formData.append("firstName", editName);
        }
        if (editLastName) {
          formData.append("lastName", editLastName);
        }
        if (id) {
          formData.append("id", id);
        }
        if (userEditProfileInfo === null) {
          formData.append("file", "null");
          formData.append("remove", "true");
        } else if (userEditProfileInfo instanceof File) {
          formData.append("file", userEditProfileInfo);
          formData.append("remove", "false");
        }
        const updateUrl = `user/update-profile`;
        try {
          const response = await api
            .put(updateUrl, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              setOrganisationOpen(false);
              toast.success("Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              profileDetail();
              eventEmitter.emit("profileUpdated");
            });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              for (const key in errorArray) {
                var keyVal = errorArray[key].trim().split(":");
                const errorField = document.getElementById(keyVal[0] + "Error");
                const inputField = document.getElementsByClassName(
                  keyVal[0].trim()
                );
                inputField?.[0]?.classList.add("borderRed");
                if (errorField) {
                  errorField.innerHTML =
                    keyVal[1].charAt(0).toUpperCase() + keyVal[1].slice(1);
                }
              }
            } else {
              toast.error(error.response?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.error((error as AxiosError).message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "Error"
          );
          const inputField = document.getElementsByClassName(
            validationErrors.inner[i].path
          );
          inputField?.[0]?.classList.add("borderRed");
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };

  const handleTableRowClick = (newId: string) => {
    setTimeout(() => {
      navigate(`/groupsView`, { state: { id: newId } });
      eventEmitter.emit("SidebarNavigation");
      eventEmitter.emit("idReceived", newId);
    }, 100);
  };

  const getAssetId = async (
    id: any,
    assetId: any,
    title: any,
    hashTag: any
  ) => {
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };

  const handleImageHover = (index: number, image: number) => {
    const groupContent = document.getElementsByClassName(
      "groupContent" + image + index
    );
    groupContent?.[0]?.classList.remove("hideContent");
    if (image === 1) {
      const groupImage = document.getElementById("groupImage2" + index);
      groupImage?.classList.add("hideContent");
    } else {
      const groupImage = document.getElementById("groupImage1" + index);
      groupImage?.classList.add("hideContent");
    }
  };

  const handleImageLeave = (index: number, image: number) => {
    const groupContent = document.getElementsByClassName(
      "groupContent" + image + index
    );
    groupContent?.[0]?.classList.add("hideContent");
    if (image === 1) {
      const groupImage = document.getElementById("groupImage2" + index);
      groupImage?.classList.remove("hideContent");
    } else {
      const groupImage = document.getElementById("groupImage1" + index);
      groupImage?.classList.remove("hideContent");
    }
  };
  const numberOfRows = 3;

  const renderedRowsGroups = [...Array(numberOfRows)].map((e, i) => (
    <div>
      <RowGroup />
    </div>
  ));
  const numberOfRowsProfile = 12;

  const renderedRowsProfile = [...Array(numberOfRowsProfile)].map((e, i) => (
    <div>
      <RowProfile />
    </div>
  ));
  const headerSearch = (itemTitle: any) => {
    navigate("/landingAssetView");
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
  };
  return (
    <>
      <Box className="center-grid  center_card ">
        <Grid item container className=" justify-content-between d-unset p-10 ">
          <div>
            <Typography sx={{ height: "0px" }}>&nbsp;</Typography>

            {isUserPageIsLoading ? (
              <Grid container className="org_bg_load p-10 br_8">
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Grid container className=" align-items-center">
                    <Grid
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      className="sup_img"
                    >
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={100}
                        className="br_5"
                        sx={{ ...SkeletonColor, borderRadius: "50%" }}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={10}
                          lg={8}
                          xl={8}
                          sx={{ paddingLeft: "20px" }}
                          className="p-name_div"
                        >
                          <Grid className="d-flex align-items-center">
                            <Skeleton
                              width={160}
                              height={25}
                              sx={{
                                ...SkeletonColor,
                                borderRadius: "8px",
                              }}
                            />
                          </Grid>
                          <Typography className="fs-13 smallBold m-t capitalize">
                            <Skeleton
                              width={160}
                              height={25}
                              sx={{
                                ...SkeletonColor,
                                borderRadius: "8px",
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={4} xl={4}>
                          <Grid
                            className="d-flex"
                            style={{ margin: "5px 0 15px 0" }}
                          >
                            {/* <img
                                        className="img-18-px "
                                        src="../beta/assets/images/GramIcon.svg"
                                      /> */}
                            <Typography className=" fs-13 m-l  smallBold">
                              <Skeleton
                                width={50}
                                height={25}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                }}
                              />
                            </Typography>
                          </Grid>
                          <Grid className="d-flex m-t">
                            {/* <img
                                        className="img-18-px"
                                        src="../beta/assets/images/ic_outline-email.svg"
                                      /> */}
                            <Typography className=" fs-13 m-l smallBold">
                              <Skeleton
                                width={150}
                                height={25}
                                sx={{
                                  ...SkeletonColor,
                                  borderRadius: "8px",
                                }}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    className="float_right color_7080 fs-11 cur-pointer mt-5 mright-5"
                    sx={{ float: "right" }}
                  >
                    <Skeleton
                      width={100}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container className=" sup_bg p-10 br_8">
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Grid container className=" align-items-center">
                    <Grid
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      className="sup_img"
                    >
                      <CardMedia
                        component="img"
                        image={
                          `${imagehttp}${userProfileInfo?.profilePicture}` ||
                          `..${subPath}/assets/images/Layer_1.png`
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={10}
                          lg={8}
                          xl={8}
                          sx={{ paddingLeft: "20px" }}
                          className="p-name_div"
                        >
                          <Grid className="d-flex align-items-center">
                            <Typography
                              title={`${userProfileInfo?.firstName}${" "}${
                                userProfileInfo?.lastName
                              }`}
                              className="fs-18 fw-600 hideDotSideBar"
                              style={{
                                minWidth: "auto",
                                width: "fit-content",
                              }}
                            >{`${userProfileInfo?.firstName}${" "}${
                              userProfileInfo?.lastName
                            }`}</Typography>
                            {badge == "Gold" ? (
                              <GoldIcon />
                            ) : badge == "Silver" ? (
                              <SilverIcon />
                            ) : (
                              <BronzeIcon />
                            )}
                          </Grid>
                          <Typography className="fs-13 smallBold m-t capitalize">
                            {userProfileInfo?.roles} /{" "}
                            <span className="fs-14 colorBlackB">
                              {userProfileInfo?.designation}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={4} xl={4}>
                          <Grid
                            className="d-flex"
                            style={{ margin: "15px 0 5px 0" }}
                          >
                            <img
                              className="img-18-px "
                              src={`..${subPath}/assets/images/GramIcon.svg`}
                            />
                            <Typography className=" fs-13 m-l  smallBold">
                              {gramCount}
                            </Typography>
                          </Grid>
                          <Grid
                            className="d-flex"
                            style={{ margin: "25px 0 5px 0" }}
                          >
                            <img
                              className="img-18-px"
                              src={`..${subPath}/assets/images/ic_outline-email.svg`}
                            />
                            <Typography className=" fs-13 m-l smallBold">
                              {userProfileInfo?.email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    className="float_right color_7080 fs-11 cur-pointer mt-5 mright-5"
                    onClick={() => {
                      orgClickOpen();
                      setEditName(userFirstName);
                      setUserEditProfileInfo(userEditProfile);
                      setEditLastName(userLastName);
                    }}
                    sx={{ float: "right", textDecoration: "underline" }}
                  >
                    Edit Profile
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Box
              sx={{ width: "100%", typography: "body1" }}
              className="mt-15 sup_tab"
            >
              <TabContext value={value}>
                {isLoading ? (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList aria-label="lab API tabs example">
                      <SkeletonTab width={150} height={30} />
                      <Box sx={{ marginLeft: 3 }}>
                        <SkeletonTab width={150} height={30} />
                      </Box>
                    </TabList>
                  </Box>
                ) : (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label={`Your assets (${assetCounts ? assetCounts : 0})`}
                        value="1"
                        onClick={() => {
                          if (value === "2") {
                            handleClickAsset(1);
                          }
                        }}
                      />
                      <Tab
                        label={`Groups (${groupCounts ? groupCounts : 0})`}
                        value="2"
                        onClick={() => {
                          if (value === "1") {
                            handleClickGroup(1);
                          }
                        }}
                      />
                    </TabList>
                  </Box>
                )}
                {isLoading ? (
                  <TabPanel value="1" className="p-0 mt-5">
                    <TableContainer
                      className="nowrap profile_asset"
                      component={Paper}
                      id="Profile_scroll"
                    >
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table "
                      >
                        <TableBody>{renderedRowsProfile}</TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                ) : (
                  <TabPanel value="1" className="p-0 mt-5">
                    <TableContainer
                      className="nowrap "
                      component={Paper}
                      id="Profile_scroll"
                    >
                      <InfiniteScroll
                        dataLength={assets.length}
                        next={() => handleClickAsset(0)}
                        hasMore={hasMore}
                        scrollableTarget="Profile_scroll"
                        className=""
                        loader={
                          <h6
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <CircularProgress
                              style={{
                                color: "#119BFF",
                              }}
                              size={20}
                            />
                          </h6>
                        }
                        endMessage={
                          assets.length !== 0 && (
                            <p className="fs-12 noMore">
                              <b>No more data found...</b>
                            </p>
                          )
                        }
                      >
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table "
                        >
                          <TableBody>
                            {assets.map((result: AssetsInfo, index) => {
                              const imageUrl =
                                result.groupDetail?.[0]?.groupImage;
                              const expectedFileName =
                                "20231024153135_no_image.jpg";
                              const fileName =
                                getFileNameFromImageUrl(imageUrl);

                              return (
                                <TableRow
                                  onClick={(event) => {
                                    if (
                                      event.target instanceof HTMLElement &&
                                      event.target.id === "hashtag_id"
                                    ) {
                                      console.log(
                                        "Clicked on Video cell. Navigation prevented."
                                      );
                                    } else {
                                      getAssetId(
                                        result?.asset?.id,
                                        result?.asset?.assetId,
                                        result?.asset?.title,
                                        result?.asset?.hashTag
                                      );
                                    }
                                  }}
                                  className="cur-pointer row_hover_group"
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    width="5%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                    component="th"
                                    scope="row"
                                    className="font_family"
                                  >
                                    {" "}
                                    {result?.asset?.assetId}
                                  </TableCell>
                                  <TableCell
                                    width="5%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {result.asset.assetContent[0] ? (
                                      // If result.assetContent[0] is not empty
                                      <>
                                        {result.asset.assetContent[0]
                                          .fieldType === "Video" && (
                                          <img
                                            src={`..${subPath}/assets/images/User_light.svg`}
                                            alt="Video"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType === "Audio" && (
                                          <img
                                            src={`..${subPath}/assets/images/Audio_light.svg`}
                                            alt="Audio"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType === "Document" && (
                                          <img
                                            src={`..${subPath}/assets/images/Document_line.svg`}
                                            alt="Document"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType === "Contact" && (
                                          <img
                                            src={`..${subPath}/assets/images/User_face.svg`}
                                            alt="Contact"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType === "url" && (
                                          <img
                                            src={`..${subPath}/assets/images/link_light.svg`}
                                            alt="URL"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType === "Image" && (
                                          <img
                                            src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                            alt="Image"
                                          />
                                        )}
                                        {result.asset.assetContent[0]
                                          .fieldType !== "Video" &&
                                          result.asset.assetContent[0]
                                            .fieldType !== "Audio" &&
                                          result.asset.assetContent[0]
                                            .fieldType !== "Document" &&
                                          result.asset.assetContent[0]
                                            .fieldType !== "Contact" &&
                                          result.asset.assetContent[0]
                                            .fieldType !== "url" &&
                                          result.asset.assetContent[0]
                                            .fieldType !== "Image" && (
                                            <img
                                              src={`..${subPath}/assets/images/Document_line.svg`}
                                              alt="Document"
                                            />
                                          )}
                                      </>
                                    ) : (
                                      // If result.asset.assetContent[0]  is empty, render something else here
                                      <img
                                        src={`..${subPath}/assets/images/Document_line.svg`}
                                        alt="Document"
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    width="40%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                      paddingLeft: "5px",
                                      maxWidth: "250px",
                                    }}
                                  >
                                    <span
                                      className="hideDotMedium colorBlackB font_family"
                                      style={{ width: "100%" }}
                                      title={result?.asset?.title}
                                    >
                                      {result?.asset?.title}
                                    </span>
                                    {result?.asset?.description && (
                                      <span
                                        // title={result.description}
                                        className="hideDotMedium font_family"
                                        style={{
                                          width: "100%",
                                          marginTop: "5px",
                                          color: "#708090",
                                        }}
                                        title={result?.asset?.description}
                                      >
                                        {result?.asset?.description}
                                      </span>
                                    )}
                                    {/* {result?.asset?.hashTag && ( */}
                                    <div
                                      className="hideDotTag"
                                      style={{ width: "100%" }}
                                    >
                                      {result?.asset?.hashTag !== "" &&
                                        result?.asset?.hashTag
                                          .toString()
                                          .split(",")
                                          .map(
                                            (hashTag: any, index: number) => {
                                              return (
                                                <span
                                                  title={hashTag}
                                                  className=" font_family"
                                                  style={{
                                                    color: "#008cff",
                                                  }}
                                                  id="hashtag_id"
                                                  onClick={() =>
                                                    headerSearch(hashTag)
                                                  }
                                                >
                                                  {hashTag}
                                                </span>
                                                // )}
                                              );
                                            }
                                          )}
                                    </div>
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                    width="15%"
                                  >
                                    {result.groupDetail.length === 1 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {fileName === expectedFileName ? (
                                          <DefaultImg
                                            imageUrl={
                                              result?.groupDetail?.[0]
                                                ?.groupName
                                            }
                                          />
                                        ) : (
                                          <CardMedia
                                            component="img"
                                            sx={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "17px",
                                              verticalAlign: "middle",
                                            }}
                                            image={
                                              `${imagehttp}${result?.groupDetail?.[0]?.groupImage}` ||
                                              `..${subPath}/assets/images/Ellipse 3.svg`
                                            }
                                          />
                                        )}

                                        <div style={{ marginLeft: "10px" }}>
                                          <span
                                            className="colorBlackB hideDotSingleLine"
                                            style={{ width: "100%" }}
                                            title={
                                              result?.groupDetail?.[0]
                                                ?.groupName
                                            }
                                          >
                                            {
                                              result?.groupDetail?.[0]
                                                ?.groupName
                                            }
                                          </span>
                                          <span
                                            style={{
                                              color: "#708090",
                                              display: "flex",
                                              alignItems: "center",
                                              // width: "100%",
                                            }}
                                            // className="hideDotSingleLine"
                                          >
                                            <VaultIcon />{" "}
                                            {
                                              result?.groupDetail?.[0]
                                                ?.vaultName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {fileName === expectedFileName ? (
                                            <div
                                              className="profile_imglist"
                                              onMouseEnter={() =>
                                                handleImageHover(index, 1)
                                              }
                                              onMouseLeave={() =>
                                                handleImageLeave(index, 1)
                                              }
                                              id={`${"groupImage1" + index}`}
                                            >
                                              <DefaultImg
                                                imageUrl={
                                                  result?.groupDetail?.[0]
                                                    ?.groupName
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <CardMedia
                                              onMouseEnter={() =>
                                                handleImageHover(index, 1)
                                              }
                                              onMouseLeave={() =>
                                                handleImageLeave(index, 1)
                                              }
                                              component="img"
                                              id={`${"groupImage1" + index}`}
                                              sx={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "17px",
                                                verticalAlign: "middle",
                                              }}
                                              image={
                                                `${imagehttp}${result?.groupDetail?.[0]?.groupImage}` ||
                                                `..${subPath}/assets/images/Ellipse 3.svg`
                                              }
                                            />
                                          )}
                                          <div
                                            style={{ marginLeft: "10px" }}
                                            className={`hideContent ${
                                              "groupContent1" + index
                                            }`}
                                          >
                                            <span
                                              className="colorBlackB"
                                              style={{ width: "100%" }}
                                            >
                                              {
                                                result?.groupDetail?.[0]
                                                  ?.groupName
                                              }
                                            </span>
                                            <span
                                              style={{
                                                color: "#708090",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <VaultIcon />{" "}
                                              {
                                                result?.groupDetail?.[0]
                                                  ?.vaultName
                                              }
                                            </span>
                                          </div>
                                          {getFileNameFromImageUrl(
                                            result.groupDetail?.[1]?.groupImage
                                          ) === expectedFileName ? (
                                            <div
                                              className="profile_imglist"
                                              onMouseEnter={() =>
                                                handleImageHover(index, 2)
                                              }
                                              onMouseLeave={() =>
                                                handleImageLeave(index, 2)
                                              }
                                              id={`${"groupImage2" + index}`}
                                            >
                                              <DefaultImg
                                                imageUrl={
                                                  result?.groupDetail?.[1]
                                                    ?.groupName
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <CardMedia
                                              onMouseEnter={() =>
                                                handleImageHover(index, 2)
                                              }
                                              onMouseLeave={() =>
                                                handleImageLeave(index, 2)
                                              }
                                              id={`${"groupImage2" + index}`}
                                              component="img"
                                              sx={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "17px",
                                                verticalAlign: "middle",
                                                marginLeft: "5px",
                                              }}
                                              image={
                                                `${imagehttp}${result?.groupDetail?.[1]?.groupImage}` ||
                                                `..${subPath}/assets/images/Ellipse 3.svg`
                                              }
                                            />
                                          )}
                                          <div
                                            style={{ marginLeft: "10px" }}
                                            className={`hideContent ${
                                              "groupContent2" + index
                                            }`}
                                          >
                                            <span
                                              className="colorBlackB"
                                              style={{ width: "100%" }}
                                            >
                                              {
                                                result?.groupDetail?.[1]
                                                  ?.groupName
                                              }
                                            </span>
                                            <span
                                              style={{
                                                color: "#708090",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <VaultIcon />{" "}
                                              {
                                                result?.groupDetail?.[1]
                                                  ?.vaultName
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    width="10%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <img
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                      src={`..${subPath}/assets/images/GramIcon.svg`}
                                      alt="GramIcon"
                                    ></img>
                                    <span
                                      style={{
                                        verticalAlign: "middle",
                                        marginLeft: "5px",
                                      }}
                                      className="colorBlackB"
                                    >
                                      {result?.totalGram}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    width="10%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <img
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                      src={`..${subPath}/assets/images/eye_img.svg`}
                                      alt="eye_img"
                                    ></img>{" "}
                                    <span
                                      style={{
                                        verticalAlign: "middle",
                                        marginLeft: "5px",
                                      }}
                                      className="colorBlackB"
                                    >
                                      {result?.asset?.viewCount}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    width="10%"
                                    sx={{
                                      border: "none",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <img
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                      src={`..${subPath}/assets/images/oldchat.svg`}
                                      alt="oldchat"
                                    ></img>{" "}
                                    <span
                                      style={{
                                        verticalAlign: "middle",
                                        marginLeft: "5px",
                                      }}
                                      className="colorBlackB"
                                    >
                                      {result?.asset?.commentCount}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {/* Show no data found */}
                            {assets.length === 0 && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                  colSpan={8}
                                >
                                  <Typography
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "#708090",
                                    }}
                                    className="fs-12"
                                  >
                                    <p>No Data Found</p>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </InfiniteScroll>
                    </TableContainer>
                  </TabPanel>
                )}

                {isLoadingGroup ? (
                  <TabPanel value="2" className="p-0   mt-5">
                    <TableContainer
                      className="nowrap "
                      component={Paper}
                      id="Profile_scroll"
                    >
                      <Typography className="color_119B ml-15 fw-700">
                        <Skeleton
                          width={160}
                          height={30}
                          sx={{ borderRadius: "8px" }}
                        />
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <>
                            <TableBody>{renderedRowsGroups}</TableBody>
                          </>
                        </Table>
                      </TableContainer>
                      <Typography className="color_119B ml-15 fw-700">
                        <Skeleton
                          width={160}
                          height={30}
                          sx={{ borderRadius: "8px" }}
                        />
                      </Typography>
                      <TableContainer className="" component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <>
                            <TableBody>{renderedRowsGroups}</TableBody>
                          </>
                        </Table>
                      </TableContainer>
                      <Typography className="color_119B ml-15 fw-700">
                        <Skeleton
                          width={160}
                          height={30}
                          sx={{ borderRadius: "8px" }}
                        />
                      </Typography>
                      <TableContainer className="" component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <>
                            <TableBody>{renderedRowsGroups}</TableBody>
                          </>
                        </Table>
                      </TableContainer>
                    </TableContainer>
                  </TabPanel>
                ) : (
                  <TabPanel value="2" className="p-0   mt-5">
                    <TableContainer
                      className="nowrap "
                      component={Paper}
                      id="Profile_scroll"
                    >
                      {groups.length > 0 && (
                        <Typography className="color_119B ml-15 fw-700">
                          Group Admin
                        </Typography>
                      )}
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          {groups.length > 0 ? (
                            <>
                              <TableBody>
                                {groups.map((result: GroupsInfo) => {
                                  const imageUrl = result.groupIcon;
                                  const expectedFileName =
                                    "20231024153135_no_image.jpg";

                                  const fileName =
                                    getFileNameFromImageUrl(imageUrl);

                                  return (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      className="cur-pointer row_hover_group"
                                      onClick={() => {
                                        if (result?.id) {
                                          selectedId.current = result.id;
                                          isMuted.current = result.muteStatus;
                                        }
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          // color: "#708090",
                                          width: "15%",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="colorBlack fw-500"
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        {result?.groupId}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "50%",
                                        }}
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        <TableRow className="fs-14">
                                          <TableCell sx={{ border: "none" }}>
                                            {fileName === expectedFileName ? (
                                              <DefaultImg
                                                imageUrl={result.name ?? ""}
                                              />
                                            ) : (
                                              <img
                                                src={`${imagehttp}${result.groupIcon}`}
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "17px",
                                                }}
                                              ></img>
                                            )}
                                          </TableCell>
                                          <TableRow
                                            sx={{
                                              color: "color: #353535",
                                              width: "90%",
                                            }}
                                            className="colorBlackB hideDotSingleLine"
                                          >
                                            {result?.name}
                                          </TableRow>
                                          <TableRow
                                            className="hideDotDouble"
                                            sx={{ color: "#708090" }}
                                          >
                                            {result?.description}{" "}
                                          </TableRow>
                                        </TableRow>
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "15%",
                                        }}
                                        // style="width: 100px;"
                                        component="th"
                                        scope="row"
                                        className="colorBlack fw-500"
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        {result?.asset_count} Assets
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          color: "#708090",
                                          width: "20%",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="avatarName"
                                      >
                                        <Avatar.Group
                                          maxCount={4}
                                          maxPopoverTrigger="click"
                                          size="large"
                                          maxStyle={{
                                            color: "#fff",
                                            backgroundColor: "#A0D7FF",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {Array.isArray(result.hoverlist) &&
                                            Array.from(result.hoverlist)
                                              .slice(0, maxCount)
                                              .map((pic, index) => (
                                                <Tooltip
                                                  title={pic.username}
                                                  key={index}
                                                >
                                                  <Avatar
                                                    key={index}
                                                    src={`${imagehttp}${pic.profile_pic}`}
                                                  />
                                                </Tooltip>
                                              ))}
                                        </Avatar.Group>
                                        <Avatar.Group size="large">
                                          {Array.isArray(result.hoverlist) && (
                                            <>
                                              {result.hoverlist.length >
                                                maxCount && (
                                                <Avatar
                                                  style={{
                                                    backgroundColor: "#A0D7FF",
                                                    marginLeft: "-5px",
                                                  }}
                                                  className="colorBlackB"
                                                >
                                                  +
                                                  {result.hoverlist.length -
                                                    maxCount}
                                                </Avatar>
                                              )}
                                            </>
                                          )}
                                        </Avatar.Group>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "10%",
                                        }}
                                        onClick={handleOpenMenu}
                                      >
                                        <img
                                          src={`..${subPath}/assets/images/menu_3.svg`}
                                        ></img>{" "}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                              <div>
                                <GroupEditModal
                                  isOpen={isModalVisible}
                                  closeModal={closeModal}
                                  refreshSidebarGroup={refreshSidebarGroup}
                                ></GroupEditModal>
                              </div>
                              {/*Group Delete Confirmation Model */}
                              <div>
                                <BootstrapDialog
                                  className="delete_modal"
                                  onClose={handleCloseDelete}
                                  aria-labelledby="customized-dialog-title"
                                  open={openDelete}
                                >
                                  <DialogTitle
                                    id="customized-dialog-title"
                                    className="fs-16 modal-headtext"
                                  >
                                    Delete Confirmation
                                  </DialogTitle>
                                  <DialogContent dividers>
                                    <Typography className="fs-14">
                                      Are you sure you want to delete?
                                    </Typography>
                                  </DialogContent>
                                  <DialogActions>
                                    <button
                                      type="button"
                                      onClick={handleCloseDelete}
                                      className="cancel_btn"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleDelete}
                                      className="m-button"
                                      autoFocus
                                    >
                                      Delete
                                    </button>
                                  </DialogActions>
                                </BootstrapDialog>
                              </div>
                            </>
                          ) : null}
                        </Table>
                      </TableContainer>
                      {groupsContributorInfo.length > 0 && (
                        <Typography className="color_119B ml-15 fw-700">
                          Contributing Groups
                        </Typography>
                      )}
                      <TableContainer className="" component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          {groupsContributorInfo.length > 0 ? (
                            <TableBody>
                              {groupsContributorInfo.map(
                                (result: GroupsInfo, index) => {
                                  const imageUrl = result.groupIcon;
                                  const expectedFileName =
                                    "20231024153135_no_image.jpg";

                                  const fileName =
                                    getFileNameFromImageUrl(imageUrl);

                                  return (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      className="cur-pointer row_hover_group"
                                      onClick={() => {
                                        if (result?.id) {
                                          // handleTableRowClick(result.id);
                                          selectedId.current = result.id;
                                          isMuted.current = result.muteStatus;
                                        }
                                      }}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          color: "#708090",
                                          width: "15%",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="colorBlack fw-500"
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        {result?.groupId}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "50%",
                                        }}
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        <TableRow className="fs-16">
                                          <TableCell sx={{ border: "none" }}>
                                            {fileName === expectedFileName ? (
                                              <DefaultImg
                                                imageUrl={result.name ?? ""}
                                              />
                                            ) : (
                                              <img
                                                src={`${imagehttp}${result?.groupIcon}`}
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "17px",
                                                }}
                                              ></img>
                                            )}
                                          </TableCell>
                                          <TableRow
                                            sx={{
                                              color: "color: #353535",
                                              width: "90%",
                                            }}
                                            className="colorBlackB hideDotSingleLine"
                                          >
                                            {result?.name}
                                          </TableRow>
                                          <TableRow
                                            className="hideDotDouble"
                                            sx={{ color: "#708090" }}
                                          >
                                            {result?.description}
                                          </TableRow>
                                        </TableRow>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "15%",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="colorBlack fw-500"
                                        onClick={() => {
                                          if (result?.id) {
                                            handleTableRowClick(result.id);
                                          }
                                        }}
                                      >
                                        {result?.asset_count} Assets
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          color: "#708090",
                                          width: "20%",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="avatarName"
                                      >
                                        <Avatar.Group
                                          maxCount={4}
                                          maxPopoverTrigger="click"
                                          size="large"
                                          maxStyle={{
                                            color: "#fff",
                                            backgroundColor: "#A0D7FF",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {Array.isArray(result.hoverlist) &&
                                            Array.from(result.hoverlist)
                                              .slice(0, maxCount)
                                              .map((pic, index) => (
                                                <Tooltip
                                                  title={pic.username}
                                                  key={index}
                                                >
                                                  <Avatar
                                                    key={index}
                                                    src={`${imagehttp}${pic.profile_pic}`}
                                                  />
                                                </Tooltip>
                                              ))}
                                        </Avatar.Group>
                                        <Avatar.Group size="large">
                                          {Array.isArray(result.hoverlist) && (
                                            <>
                                              {result.hoverlist.length >
                                                maxCount && (
                                                <Avatar
                                                  style={{
                                                    backgroundColor: "#A0D7FF",
                                                    marginLeft: "-5px",
                                                  }}
                                                  className="colorBlackB"
                                                >
                                                  +
                                                  {result.hoverlist.length -
                                                    maxCount}
                                                </Avatar>
                                              )}
                                            </>
                                          )}
                                        </Avatar.Group>
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          width: "10%",
                                        }}
                                        onClick={handleOpenMenuContribute}
                                      >
                                        <img
                                          src={`..${subPath}/assets/images/menu_3.svg`}
                                        ></img>{" "}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          ) : null}
                        </Table>
                      </TableContainer>
                      {groupsViewerInfo.length > 0 && (
                        <Typography className="color_119B ml-15 fw-700">
                          Only View Groups
                        </Typography>
                      )}
                      <TableContainer className="" component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          {groupsViewerInfo.length > 0 ? (
                            <TableBody>
                              {groupsViewerInfo.map((result: GroupsInfo) => {
                                const imageUrl = result.groupIcon;
                                const expectedFileName =
                                  "20231024153135_no_image.jpg";

                                const fileName =
                                  getFileNameFromImageUrl(imageUrl);

                                return (
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    className="cur-pointer row_hover_group"
                                    onClick={() => {
                                      if (result?.id) {
                                        // handleTableRowClick(result.id);
                                        selectedId.current = result.id;
                                        isMuted.current = result.muteStatus;
                                      }
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        color: "#708090",
                                        width: "15%",
                                      }}
                                      component="th"
                                      scope="row"
                                      className="colorBlack fw-500"
                                      onClick={() => {
                                        if (result?.id) {
                                          handleTableRowClick(result.id);
                                        }
                                      }}
                                    >
                                      {result?.groupId}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        width: "50%",
                                      }}
                                      onClick={() => {
                                        if (result?.id) {
                                          handleTableRowClick(result.id);
                                        }
                                      }}
                                    >
                                      <TableRow className="fs-14">
                                        <TableCell sx={{ border: "none" }}>
                                          {fileName === expectedFileName ? (
                                            <DefaultImg
                                              imageUrl={result.name ?? ""}
                                            />
                                          ) : (
                                            <img
                                              src={`${imagehttp}${result.groupIcon}`}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                                borderRadius: "17px",
                                              }}
                                            ></img>
                                          )}
                                        </TableCell>
                                        <TableRow
                                          sx={{
                                            color: "color: #353535",
                                            width: "90%",
                                          }}
                                          className="colorBlackB hideDotSingleLine"
                                        >
                                          {result?.name}
                                        </TableRow>
                                        <TableRow
                                          className="hideDotDouble"
                                          sx={{ color: "#708090" }}
                                        >
                                          {result.description}
                                        </TableRow>
                                      </TableRow>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        width: "15%",
                                      }}
                                      component="th"
                                      scope="row"
                                      className="colorBlack fw-500"
                                      onClick={() => {
                                        if (result?.id) {
                                          handleTableRowClick(result.id);
                                        }
                                      }}
                                    >
                                      {result?.asset_count} Assets
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        color: "#708090",
                                        width: "20%",
                                      }}
                                      component="th"
                                      scope="row"
                                      className="avatarName"
                                    >
                                      <Avatar.Group
                                        maxCount={4}
                                        maxPopoverTrigger="click"
                                        size="large"
                                        maxStyle={{
                                          color: "#fff",
                                          backgroundColor: "#A0D7FF",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {Array.isArray(result.hoverlist) &&
                                          Array.from(result.hoverlist)
                                            .slice(0, maxCount)
                                            .map((pic, index) => (
                                              <Tooltip
                                                title={pic.username}
                                                key={index}
                                              >
                                                <Avatar
                                                  key={index}
                                                  src={`${imagehttp}${pic.profile_pic}`}
                                                />
                                              </Tooltip>
                                            ))}
                                      </Avatar.Group>
                                      <Avatar.Group size="large">
                                        {Array.isArray(result.hoverlist) && (
                                          <>
                                            {result.hoverlist.length >
                                              maxCount && (
                                              <Avatar
                                                style={{
                                                  backgroundColor: "#A0D7FF",
                                                  marginLeft: "-5px",
                                                }}
                                                className="colorBlackB"
                                              >
                                                +
                                                {result.hoverlist.length -
                                                  maxCount}
                                              </Avatar>
                                            )}
                                          </>
                                        )}
                                      </Avatar.Group>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                        width: "10%",
                                      }}
                                      onClick={handleOpenMenuThree}
                                    >
                                      <img
                                        src={`..${subPath}/assets/images/menu_3.svg`}
                                      ></img>{" "}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          ) : null}
                        </Table>
                      </TableContainer>

                      {groups.length === 0 &&
                        groupsContributorInfo.length === 0 &&
                        groupsViewerInfo.length === 0 && (
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#708090",
                            }}
                            className="fs-12"
                          >
                            <p>No Data Found</p>
                          </Typography>
                        )}
                    </TableContainer>
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </div>
        </Grid>
      </Box>
      {/* </Grid>
        </Box> */}

      <BootstrapDialog
        onClose={orgClose}
        aria-labelledby="customized-dialog-title"
        open={Organisation}
        className="edit_profile"
      >
        <Grid className="d-flex justify-content-between">
          <DialogTitle id="customized-dialog-title" className="fs-18 fw-600">
            Edit profile
          </DialogTitle>
          <DialogTitle className="cur-pointer">
            <img
              src={`..${subPath}/assets/images/close_b.svg`}
              width="20"
              onClick={orgClose}
            />
          </DialogTitle>
        </Grid>

        <DialogContent dividers className="popup_design">
          <Form name="form-name" layout="vertical" onFinish={onFinish}>
            <Form.Item name="name" className="m-text ">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label htmlFor="name">
                    <span className="fw-600">First Name</span>
                    <span style={{ color: "var(--1, #119BFF)" }}>*</span>
                  </label>

                  <Input
                    id="name"
                    placeholder="Enter Your FirstName"
                    value={editName}
                    // maxLength={maxLengthvalidation}
                    onChange={handleChangeEditFristname}
                    title="Name"
                    className="name field colorBlack  fs-14 fw-500 "
                  />
                  <span
                    id="nameError"
                    style={{ color: "red" }}
                    className="error"
                  ></span>
                </Grid>

                <Grid item xs={6}>
                  <label htmlFor="lname">
                    <span className="fw-600">Last Name</span>
                    <span style={{ color: "var(--1, #119BFF)" }}>*</span>
                  </label>

                  <Input
                    id="lname"
                    placeholder="Enter Your LastName"
                    value={editLastName}
                    // maxLength={maxLengthvalidation}
                    onChange={handleChangeEditLastname}
                    title="Last Name"
                    className="lname field colorBlack  fs-14 fw-500 "
                  />
                  <span
                    id="lnameError"
                    style={{ color: "red" }}
                    className="error"
                  ></span>
                </Grid>
              </Grid>
              <div className="mt-15">
                <label htmlFor="name" className="fw-600">
                  Profile Picture
                </label>
                {userEditProfileInfo ? (
                  <div>
                    {typeof userEditProfileInfo === "string" ? (
                      <img
                        src={
                          (userEditProfileInfo as string).startsWith("http")
                            ? userEditProfileInfo
                            : `${imagehttp}${userEditProfileInfo}`
                        }
                        style={{
                          borderRadius: "50%",
                          // maxHeight: "100px",
                          // maxWidth: "100px",
                          width: "100px",
                          height: "100px",
                          marginTop: "10px",
                        }}
                        alt="Profile Picture"
                      />
                    ) : (
                      // Handle the File type here, for example, display a preview.
                      <img
                        src={URL.createObjectURL(userEditProfileInfo)}
                        style={{
                          borderRadius: "50%",
                          // maxHeight: "100px",
                          // maxWidth: "100px",
                          width: "100px",
                          height: "100px",
                          marginTop: "10px",
                        }}
                        alt="Default Picture"
                      />
                    )}
                  </div>
                ) : null}
              </div>
              <input
                type="file"
                // accept="image/*"
                accept=".jpeg, .png, .gif, .bmp, .heif, .svg,.hdr,.jpg"
                multiple
                name="upload"
                style={{ display: "none" }}
                onChange={handleImageChange}
                ref={fileInputRef}
              />
              {userEditProfileInfo ? (
                <img
                  src={`..${subPath}/assets/images/solar_trash-bin-2-broken.png`}
                  onClick={clearUpload}
                  className="cur-pointer"
                />
              ) : (
                <FiUpload
                  color="#119BFF"
                  size="23px"
                  onClick={() => fileInputRef.current?.click()}
                  style={{ cursor: "pointer" }}
                />
              )}
              {errorMessage ? (
                <>
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {errorMessage}
                  </div>
                </>
              ) : (
                ""
              )}
            </Form.Item>
            <DialogActions>
              {/* <Form.Item className="button_profile"> */}
              <Button
                variant="outlined"
                onClick={orgClose}
                style={{ textTransform: "none" }}
                className="cancel_btn"
              >
                Cancel
              </Button>
              {/* </Form.Item> */}
              <button type="submit" className="m-button sidebar-c-b">
                Update Profile
              </button>
            </DialogActions>
          </Form>
        </DialogContent>
      </BootstrapDialog>
      {/* </div> */}
      {/* Popup start */}
      <div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <MenuList>
                <MenuItem
                  onClick={showModalGroup}
                  style={{
                    color: "var(--components-switch-slide-fill, #000)",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  Edit Group
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    MuteandUnmute(selectedId.current);
                    handleCloseMenu();
                  }}
                  style={{
                    color: "var(--components-switch-slide-fill, #000)",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ListItemIcon>
                    {isMuted.current ? <UnMuteIcon /> : <MuteIcon />}
                  </ListItemIcon>
                  {isMuted.current ? "Unmute Group" : "Mute Group"}
                </MenuItem>
                <MenuItem
                  style={{
                    color: "#EC174F",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                  onClick={() => deletePopOpen(selectedId.current)}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
      <div>
        <Popover
          open={Boolean(anchorElTwo)}
          anchorEl={anchorElTwo}
          onClose={handleCloseMenuTwo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenuTwo}>
              <MenuList>
                {userRole?.toLowerCase() === "admin" && (
                  <MenuItem
                    onClick={showModalGroup}
                    style={{
                      color: "var(--components-switch-slide-fill, #000)",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    Edit Group
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    MuteandUnmute(selectedId.current);
                    handleCloseMenuTwo();
                  }}
                  style={{
                    color: "var(--components-switch-slide-fill, #000)",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ListItemIcon>
                    {isMuted.current ? <UnMuteIcon /> : <MuteIcon />}
                  </ListItemIcon>
                  {isMuted.current ? "Unmute Group" : "Mute Group"}
                </MenuItem>
                {userRole?.toLowerCase() === "admin" && (
                  <MenuItem
                    style={{
                      color: "#EC174F",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                    onClick={() => deletePopOpen(selectedId.current)}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
      <div>
        <Popover
          open={Boolean(anchorElThree)}
          anchorEl={anchorElThree}
          onClose={handleCloseMenuThree}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseMenuThree}>
              <MenuList>
                {userRole?.toLowerCase() === "admin" && (
                  <MenuItem
                    onClick={showModalGroup}
                    style={{
                      color: "var(--components-switch-slide-fill, #000)",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    Edit Group
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    MuteandUnmute(selectedId.current);
                    handleCloseMenuThree();
                  }}
                  style={{
                    color: "var(--components-switch-slide-fill, #000)",
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  <ListItemIcon>
                    {isMuted.current ? <UnMuteIcon /> : <MuteIcon />}
                  </ListItemIcon>
                  {isMuted.current ? "Unmute Group" : "Mute Group"}
                </MenuItem>
                {userRole?.toLowerCase() === "admin" && (
                  <MenuItem
                    style={{
                      color: "#EC174F",
                      fontWeight: "bold",
                      fontSize: "13px",
                    }}
                    onClick={() => deletePopOpen(selectedId.current)}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
      {/* POpup End */}
    </>
  );
};

export default View;
