import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Skeleton } from "@mui/material";
import OrgSidebar from "../../../Layout/OrgSidebar";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "../../../Constants/Global";
import { styled } from "@mui/material";
import eventEmitter from "../../../Constants/EventEmiter";
import { subPath } from "../../../Constants/Global";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName } from "../../../Redux/Search/action";

interface TemplateData {
  id: string;
  title: string;
  description: string;
  favotite: boolean;
}
interface StyledTableRowProps {
  isSelected: boolean;
}
const SkeletonColor = {
  backgroundColor: "#fff",
};

const fixedArrayLength = 10;
const placeholderArray = Array.from(
  { length: fixedArrayLength },
  (_, index) => index
);

const StyledTableRow = styled(TableRow)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected ? "#C6E7FF" : "inherit",
    color: isSelected ? "white" : "inherit",
    borderRadius: "20px",
    "&:last-child td, &:last-child th": {
      borderBottom: 0,
    },

    "&:hover": {
      backgroundColor: "#C6E7FF",
      borderRadius: "20px",
    },
  })
);
const ListDynamicTemplate: React.FC = () => {
  const api = UseAxios();
  const [temData, setTemData] = useState<TemplateData[]>([]);
  const selectedRow = null;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const userid = decodeToken().id;
  const navigate = useNavigate();
  const createTemplate = () => {
    navigate("/dynamicForm");
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchTemplateData();
    dispatch(setItemName(""));
  }, []);
  const fetchTemplateData = async () => {
    try {
      const response = await api.get(`template/favorite?userId=${userid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        const newData = response.data.data;
        setTemData(newData);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const addToFavorite = async (id: string, type: string) => {
    const requestBody = {
      userId: decodeToken().id,
      favoriteId: id,
      type: type,
    };
    const url = `favorite/add`;
    try {
      const res = await api.post(url, requestBody);
      if (res.data.status === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchTemplateData();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="home_center">
        <Grid
          container
          //   spacing={2}
          alignItems="baseline"
          justifyContent="center"
          style={{ padding: "12px 12px 0px 12px" }}
        >
          <OrgSidebar />

          <Grid item xs={12} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
            {isLoading ? (
              <Card
                sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}
                className="backColor"
              >
                <CardContent>
                  <Grid container>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item xs={10} sm={10} md={9} lg={9} xl={9}>
                        <Grid
                          item
                          sx={{ display: "flex", alignItems: "unset" }}
                        >
                          <Skeleton
                            width={90}
                            height={27}
                            sx={{ ...SkeletonColor }}
                          />
                        </Grid>

                        <Grid
                          item
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className="setting-notification"
                            sx={{ color: "#708090", fontSize: "15px" }}
                          >
                            <Skeleton
                              width={90}
                              height={17}
                              sx={{ ...SkeletonColor }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid xs={2} sm={2} md={3} lg={3} xl={3}>
                        <Skeleton
                          width={160}
                          height={40}
                          className="float-right "
                          sx={{ ...SkeletonColor }}
                        />
                      </Grid>

                      <Box
                        sx={{
                          width: "100%",
                          typography: "body1",
                          marginTop: "10px",
                        }}
                        id=""
                        className="plr-10"
                      >
                        <TableContainer className="template_table">
                          <Table
                            sx={{ border: "none" }}
                            aria-label="simple table"
                          >
                            <TableBody
                              sx={{ border: "none", cursor: "pointer" }}
                            >
                              {placeholderArray.map((result, index) => (
                                <>
                                  <StyledTableRow
                                    isSelected={false}
                                    sx={{ cursor: "pointer", border: "none" }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="star"
                                      sx={{ width: "1%", border: "none" }}
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        width={24}
                                        height={24}
                                        sx={{
                                          ...SkeletonColor,
                                          borderRadius: "6px",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="temp-name"
                                    >
                                      <Skeleton
                                        width={220}
                                        height={30}
                                        sx={{
                                          ...SkeletonColor,
                                          borderRadius: "6px",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="temp-desc"
                                      style={{
                                        maxWidth: "100px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Skeleton
                                        width={220}
                                        height={30}
                                        sx={{
                                          ...SkeletonColor,
                                          borderRadius: "6px",
                                        }}
                                      />
                                    </TableCell>
                                  </StyledTableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
                <CardContent>
                  <Grid container>
                    <Grid container sx={{ mt: 1 }}>
                      <Grid item xs={10} sm={10} md={9} lg={9} xl={9}>
                        <Grid
                          item
                          sx={{ display: "flex", alignItems: "unset" }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "18px",
                              fontWeight: "700",
                            }}
                          >
                            Templates
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Typography
                            className="setting-notification"
                            sx={{
                              color: "#708090",
                              fontSize: "15px",
                              display: "none",
                            }}
                          >
                            Lorem ipsum
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={2}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={3}
                        sx={{ textAlign: "end" }}
                      >
                        <button
                          type="button"
                          className="m-button  float-right "
                          style={{
                            cursor: "pointer",
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                          onClick={createTemplate}
                        >
                          Create new template
                        </button>
                      </Grid>

                      <Box
                        sx={{
                          width: "100%",
                          typography: "body1",
                          marginTop: "10px",
                        }}
                        id=""
                        className="plr-10"
                      >
                        <TableContainer className="template">
                          <Table
                            sx={{ border: "none" }}
                            aria-label="simple table"
                          >
                            <TableBody
                              sx={{ border: "none", cursor: "pointer" }}
                            >
                              {temData.map((row: TemplateData, index) => (
                                <StyledTableRow
                                  sx={{ cursor: "pointer" }}
                                  key={row.id}
                                  isSelected={selectedRow === row.id}
                                >
                                  <TableCell
                                    component="th"
                                    className="star"
                                    scope="row"
                                    sx={{ width: "1%", border: "none" }}
                                    onClick={() => {
                                      addToFavorite(row.id, "template");
                                    }}
                                  >
                                    {row.favotite === false ? (
                                      <img
                                        src={`..${subPath}/assets/images/star.svg`}
                                      />
                                    ) : (
                                      <img
                                        src={`..${subPath}/assets/images/fav_star.svg`}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className="colorBlackB fw-700 fs-17 temp-name"
                                    sx={{
                                      paddingLeft: "0%",
                                      border: "none",
                                      paddingBottom: "13px",
                                      paddingTop: "13px",
                                    }}
                                  >
                                    {row.title}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className="temp-desc fw-500 fs-17 "
                                    title={row.description}
                                    style={{
                                      maxWidth: "100px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      paddingBottom: "13px",
                                      paddingTop: "13px",
                                    }}
                                  >
                                    {row.description}
                                  </TableCell>
                                </StyledTableRow>
                              ))}

                              {/* Display no data found */}
                              {temData.length === 0 ? (
                                <Typography className="noMoreDataFound">
                                  <p>No Data Found...</p>
                                </Typography>
                              ) : (
                                ""
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ListDynamicTemplate;
