// reducer.js
import { TOGGLE_CHAT_VIEW, TOGGLE_SINGLE_CHAT_VIEW } from "./action";

const initialState = {
  isChatView: false,
  isSingleChatView: false,
};

const chatReducerCard = (state = initialState, action: { type: string }) => {
  switch (action.type) {
    case TOGGLE_CHAT_VIEW:
      return {
        ...state,
        isChatView: !state.isChatView,
      };
    case TOGGLE_SINGLE_CHAT_VIEW:
      return {
        ...state,
        isSingleChatView: !state.isSingleChatView,
      };
    default:
      return state;
  }
};

export default chatReducerCard;
