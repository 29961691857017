import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import axios, { AxiosError } from "axios";
import { imagehttp, decodeToken, subPath } from "../../Constants/Global";
import { Table, Checkbox, Button } from "antd";
import { FiUpload } from "react-icons/fi";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import type { RadioChangeEvent } from "antd";
import { Input, Radio, Space, Form } from "antd";
import { Avatar, Tooltip } from "antd";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import eventEmitter from "../../Constants/EventEmiter";
import Skeleton from "@mui/material/Skeleton";
import { Row } from "./Row";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Divider, Stack } from "@mui/material";
import UseAxios from "../../Constants/UseAxios";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import { useAppDispatch } from "../../Constants/hooks";
import { setItemName } from "../../Redux/Search/action";

const SkeletonColor = {
  backgroundColor: "#fff",
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface memberInfo {
  name: string;
  profilepicture: File;
  userId: string;
}
interface DataType {
  empId: string;
  key: React.Key;
  name: string;
  createGroup: boolean;
  createVault: boolean;
  lastActive: string;
  popup: string;
  companyId: string;
  designation: string;
  email: string;
  firstName: string;
  groupAccess: boolean;
  id: string;
  lastName: string;
  mobile: string;
  mobileVerify: string;
  password: string;
  profilePicture: string;
  roles: string;
  status: string;
  vaultAccess: string;
  lastActivityStatus: any;
}

interface grpDetails {
  name: string | null;
  groupIcon: File | null;
  id: string | null;
}
interface countInfo {
  asset_count: number | null;
  group_count: number | null;
  users_count: number | null;
}
interface RowsInfo {
  id: string | null;
  profilePicture: File | string | null;
  name: string | null;
}
interface ListofGroupInfo {
  name: string;
  groupName: string;
  groupIcon: File;
  id: string;
}
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 80,
        height: 80,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const View: React.FC = () => {
  const api = UseAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const renderAfterCalled = useRef(false);
  const companyIdformToken = decodeToken().companyId;
  const [createdBy, setCreatedBy] = useState<string | null>(null);
  const [organisation, setOrganisation] = useState<DataType[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [count, setCount] = useState<countInfo>();
  // Edit company state
  const [companyName, setCompayName] = useState<string>();
  const [companyNameEdit, setCompayNameEdit] = useState<string>();
  const [companyProfile, setCompanyProfile] = useState<File | null>();
  const [companyProfilePop, setCompanyProfilePop] = useState<File | null>();
  const [CompanyEditProfile, setCompanyEditProfile] = useState<File | null>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [companyId, setCompanyId] = useState<string>();
  //Organisation  modal
  const [Organisation, setOrganisationOpen] = React.useState(false);
  //Organisation  modal
  const [Vault, setVaultOpen] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //hide and show valut
  const [vaultHidden, setvaultHidden] = useState(true);
  const [isCatbonVisible, setIsCarbonMember] = useState(false);
  //groupfetch in modal
  const [groupAdminCount, setGroupAdminCount] = useState<number>();
  const [listofGroups, SetListofGroups] = useState<ListofGroupInfo[]>([]);
  const [userMemberId, setUserMemberId] = useState<string>();
  //particular grp member info
  const [memberDetails, setMemberDetails] = useState<memberInfo[]>([]);
  const [groupRowDetails, setGroupRowDetails] = useState<grpDetails>();

  const [grpId, setgrpId] = useState<string>("");
  //Radio button in modal
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isPageLoading, setPageIsLoading] = useState<boolean>(true);
  const [pageCompanyIsLoading, setPageCompanyIsLoading] =
    useState<boolean>(true);
  const [abortController, setAbortController] = useState(new AbortController());

  //crop image
  const defaultCropState: Crop = {
    x: 0,
    y: 0,
    unit: "px",
    width: 50,
    height: 50,
  };
  const [crop, setCrop] = useState<Crop>(defaultCropState);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const previewCanvasRef = useRef<HTMLInputElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [imgdup, setImgdup] = useState<string | null>(null);
  const [handleCroppedImage, setHandleCroppedImage] = useState<string | null>(
    null
  );

  const scale = 1;
  const rotate = 0;
  const aspect = 16 / 16;

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);
  // Organisation Fetch API
  const size = 10;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [groupFilter, setGroupFilter] = useState<string>("all");
  const groupsSize = 6;
  const [groupPage, setGroupPage] = useState(0);
  const [groupHasMore, setGroupHasMore] = useState(true);
  const [memberPage, setMemberPage] = useState(0);
  const [memberHasMore, setMemberHasMore] = useState(true);

  const maxSize = 2 * 1024 * 1024;
  // const [errorMessage, setErrorMessage] = useState("");

  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };

  const expectedFileName = "20231024153135_no_image.jpg";

  let fileName = "";
  if (
    typeof groupRowDetails?.groupIcon === "string" &&
    groupRowDetails?.groupIcon
  ) {
    const url = new URL(groupRowDetails?.groupIcon);
    const pathParts = url.pathname.split("/");
    fileName = pathParts[pathParts.length - 1];
  }
  const randomColor = getRandomColor(
    groupRowDetails?.name ? groupRowDetails.name : ""
  );

  const handleRadioChange = (newId: string) => {
    if (selectedId === newId) {
      setSelectedId(null);
    } else {
      setSelectedId(newId);
    }
  };

  useEffect(() => {
    dispatch(setItemName(""));
    const Token = decodeToken();
    const userId = Token.id;
    setCreatedBy(userId);
    if (!renderAfterCalled.current) {
      fetchOrganisation(0, "all", search);
      CompanyDetails();
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, [createdBy, refresh]);

  const loginAdmin = (createdBy: any) => {
    if (createdBy) {
      setSelectedId(String(createdBy));
    }
  };

  // Edit company
  const clearUpload = () => {
    setCompanyEditProfile(null);
    setCroppedImageUrl(null);
    setImgdup(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (croppedImageUrl && imgRef.current && previewCanvasRef.current) {
        const image = new Image();
        image.src = croppedImageUrl;

        image.onload = () => {
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            image,
            scale,
            rotate
          );
        };
      }
    },
    100,
    [croppedImageUrl, scale, rotate]
  );

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const selectedFile = e.target.files[0];
      const reader = new FileReader();
      const acceptedTypes = [
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".bmp",
        ".heif",
        ".svg",
        ".hdr",
      ];
      const isValidFileType = acceptedTypes.some((type) =>
        selectedFile.name.toLowerCase().endsWith(type)
      );

      if (isValidFileType && selectedFile.size <= maxSize) {
        setCompanyEditProfile(e.target.files[0]);
        setErrorMessage("");
      } else {
        if (!isValidFileType) {
          setErrorMessage(`Accepted types are: ${acceptedTypes.join(", ")}`);
        } else {
          setErrorMessage(
            `File size must be less than ${maxSize / (1024 * 1024)} MB`
          );
        }
      }
      reader.onload = () => {
        setImgdup(reader.result as string);
        setCroppedImageUrl(reader.result as string);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleCropComplete = async (completedCrop: Crop) => {
    if (completedCrop.width && completedCrop.height && imgRef.current) {
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

      if (!scaleX || !scaleY) {
        return; // Exit if scaleX or scaleY is null or undefined
      }

      const canvas = document.createElement("canvas");
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = croppedImageUrl!; // Use the croppedImageUrl from your state

      img.onload = () => {
        if (ctx) {
          // Clear the canvas before rendering the cropped image
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          ctx.drawImage(
            img,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            completedCrop.width,
            completedCrop.height
          );

          // Convert canvas to a blob
          canvas.toBlob((blob) => {
            if (blob) {
              // Create a URL for the blob
              const croppedImageUrl = URL.createObjectURL(blob);

              // Update the state with the cropped image URL
              setHandleCroppedImage(croppedImageUrl);
            }
          }, "image/jpeg");
        }
      };
    }
  };

  //organization edit
  // const onFinish = async (values: any) => {
  //   setErrorMessage(" ");
  //   setIsLoading(true);
  //   const errorFields = document.getElementsByClassName("error");
  //   errorFields[0].innerHTML = "";
  //   const inputFields = document.getElementsByClassName("field");
  //   inputFields?.[0]?.classList.remove("borderRed");

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Name is a required field."),
  //   });
  //   const dataToValidate = {
  //     name: companyNameEdit,
  //   };

  //   schema
  //     .validate(dataToValidate, { abortEarly: false })
  //     .then(async () => {
  //       const formData = new FormData();
  //       if (companyNameEdit) {
  //         formData.append("name", companyNameEdit);
  //       }
  //       if (CompanyEditProfile === null) {
  //         formData.append("file", "null");
  //         formData.append("remove", "true");
  //       }
  //       else {
  //         if (imgdup) {
  //           // If there's an uploaded image but no cropped image, prompt the user to crop
  //           const img = new Image();
  //           img.src = imgdup;

  //           img.onload = () => {
  //             const scaleX = img.naturalWidth / img.width;
  //             const scaleY = img.naturalHeight / img.height;
  //             const canvas = document.createElement('canvas');
  //             canvas.width = crop.width;
  //             canvas.height = crop.height;
  //             const ctx = canvas.getContext('2d');

  //             if (ctx) {
  //               ctx.clearRect(0, 0, canvas.width, canvas.height);
  //               ctx.drawImage(
  //                 img,
  //                 crop.x * scaleX,
  //                 crop.y * scaleY,
  //                 crop.width * scaleX,
  //                 crop.height * scaleY,
  //                 0,
  //                 0,
  //                 crop.width,
  //                 crop.height,
  //               );

  //               // Convert canvas to blob
  //               canvas.toBlob((blob) => {
  //                 if (blob) {
  //                   // Append the cropped image blob to form data
  //                   formData.append("file", blob);
  //                   formData.append("remove", "false");
  //                   console.log("blob-direct-update",blob);
  //                 }
  //               }, 'image/jpeg');

  //             }
  //           };
  //         }

  //         else {
  //           if (handleCroppedImage) {
  //             // If there's a cropped image, append it
  //             const croppedImageBlob = await fetch(handleCroppedImage).then((res) =>
  //               res.blob()
  //             );
  //             formData.append("file", croppedImageBlob);
  //             formData.append("remove", "false");
  //             console.log("croppedImageBlob",croppedImageBlob);
  //           }
  //         }
  //       }
  //       if (companyId) {
  //         formData.append("companyId", companyId);
  //       }

  //       const updateUrl = `companies/profile-picture`;

  //       try {
  //         await api
  //           .put(updateUrl, formData, {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           })
  //           .then((res) => {
  //             setIsLoading(false);

  //             eventEmitter.emit("orgUpdate");
  //             CompanyDetails();
  //             setCroppedImageUrl("");
  //             setImgdup("");
  //             toast.success("Updated Successfully", {
  //               position: toast.POSITION.TOP_RIGHT,
  //               autoClose: 1000,
  //             });
  //             setOrganisationOpen(false);
  //           });
  //       } catch (error) {
  //         setIsLoading(false);
  //         if (axios.isAxiosError(error)) {
  //           if (error.response?.status === 422) {
  //             var errors = error.response?.data.message;
  //             const errorArray = errors.split(";");
  //             for (const key in errorArray) {
  //               var keyVal = errorArray[key].trim().split(":");
  //               const errorField = document.getElementById(keyVal[0] + "Error");
  //               const inputField = document.getElementsByClassName(
  //                 keyVal[0].trim()
  //               );
  //               inputField?.[0]?.classList.add("borderRed");
  //               if (errorField) {
  //                 errorField.innerHTML =
  //                   keyVal[1].charAt(0).toUpperCase() + keyVal[1].slice(1);
  //               }
  //             }
  //           } else {
  //             toast.error(error.response?.data.message, {
  //               position: toast.POSITION.TOP_RIGHT,
  //               autoClose: 5000,
  //               closeOnClick: true,
  //             });
  //           }
  //         } else {
  //           toast.error((error as AxiosError).message, {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 5000,
  //             closeOnClick: true,
  //           });
  //         }
  //       }
  //     })
  //     .catch((validationErrors) => {
  //       setIsLoading(false);
  //       const errorField = document.getElementById(
  //         validationErrors.inner[0].path + "Error"
  //       );
  //       const inputField = document.getElementsByClassName(
  //         validationErrors.inner[0].path
  //       );
  //       inputField?.[0]?.classList.add("borderRed");
  //       if (errorField) {
  //         errorField.innerHTML =
  //           validationErrors.inner[0].message.charAt(0).toUpperCase() +
  //           validationErrors.inner[0].message.slice(1);
  //       }
  //     });
  // };
  const onFinish = async (values: any) => {
    try {
      setErrorMessage("");
      setIsLoading(true);

      // Clear error messages and red borders
      const errorFields = document.getElementsByClassName("error");
      errorFields[0].innerHTML = "";

      const inputFields = document.getElementsByClassName("field");
      inputFields?.[0]?.classList.remove("borderRed");

      // Validate form data using Yup schema
      const schema = Yup.object().shape({
        name: Yup.string().required("Name is a required field."),
      });
      await schema.validate({ name: companyNameEdit }, { abortEarly: false });

      const formData = new FormData();

      // Append name
      if (companyNameEdit) {
        formData.append("name", companyNameEdit);
      }

      // Append file data
      if (CompanyEditProfile === null) {
        formData.append("file", "null");
        formData.append("remove", "true");
      } else {
        if (!handleCroppedImage && imgdup) {
          const croppedBlob = await getCroppedImageBlob(imgdup);
          formData.append("file", croppedBlob);
          formData.append("remove", "false");
          console.log("croppedBlob", croppedBlob);
        } else if (handleCroppedImage) {
          const croppedImageBlob = await fetch(handleCroppedImage).then((res) =>
            res.blob()
          );
          formData.append("file", croppedImageBlob);
          formData.append("remove", "false");
          console.log("croppedImageBlob", croppedImageBlob);
        }
      }

      // Append companyId if available
      if (companyId) {
        formData.append("companyId", companyId);
      }

      // Make API call to update profile picture
      const updateUrl = `companies/profile-picture`;
      await api.put(updateUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Update UI after successful update
      setIsLoading(false);
      eventEmitter.emit("orgUpdate");
      CompanyDetails();
      setCroppedImageUrl("");
      setImgdup("");
      toast.success("Updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setOrganisationOpen(false);
    } catch (error) {
      // Handle errors
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          handleValidationErrors(error.response?.data.message);
        } else {
          toast.error(error.response?.data.message || error.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            closeOnClick: true,
          });
        }
      } else {
        toast.error((error as AxiosError).message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          closeOnClick: true,
        });
      }
    }
  };

  const getCroppedImageBlob = async (imageData: string): Promise<Blob> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        const canvas = document.createElement("canvas");

        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            img,
            0, // No cropping, draw full image
            0,
            img.width * scaleX,
            img.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
          );

          // Convert canvas to blob
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            }
          }, "image/jpeg");
        }
      };
      img.src = imageData;
    });
  };

  // Function to handle validation errors
  const handleValidationErrors = (errorMessages: string) => {
    const errors = errorMessages.split(";");
    for (const error of errors) {
      const [field, message] = error.trim().split(":");
      const errorField = document.getElementById(`${field}Error`);
      const inputField = document.getElementsByClassName(field.trim());
      inputField?.[0]?.classList.add("borderRed");
      if (errorField) {
        errorField.innerHTML = `${message
          .charAt(0)
          .toUpperCase()}${message.slice(1)}`;
      }
    }
  };

  const toggleCarbonVisibility = () => {
    if (countSelectedRows !== null) {
      setIsCarbonMember(!isCatbonVisible);
    } else {
      toast.error("Please select atleast one member", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };
  // Handle cancel button for " source" section
  const closebtn = () => {
    setIsCarbonMember(false);
  };
  // company Details/
  const CompanyDetails = async () => {
    const url = `member/show_orgnazation_data?id=${companyIdformToken}`;
    try {
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setCount(response.data.data);
      setCompanyProfile(response.data.data.Company.logo);
      setCompanyProfilePop(response.data.data.Company.logo);
      setCompayName(response.data.data.Company.name);
      setCompanyId(response.data.data.Company.id);
      setPageCompanyIsLoading(false);
    } catch (error) {
      // setPageIsLoading(false);
      // Handle any errors here
      console.error("Error in userProfile request:", error);
    }
  };
  const sortOrderRef = useRef<string>("ascending");

  const handleRadioChangeTable = (e: any, confirm: Function) => {
    sortOrderRef.current = e.target.value;
    if (groupFilter) fetchOrganisation(1, groupFilter, search);
    confirm();
  };
  //Fetch TABLE DATA
  const fetchOrganisation = async (
    type: number,
    groupFilter: string,
    searchVal: any
  ) => {
    setHasMore(true);
    let pageCount = page;
    let newAbortController;
    if (type) {
      setPage(0);
      pageCount = 0;
      abortController.abort();
      // Create a new abort controller for the current request
      newAbortController = new AbortController();
      setAbortController(newAbortController);
    }

    const url = `member/show_all_member?id=${companyIdformToken}&sortBy=${sortOrderRef.current.trim()}&page=${pageCount}&size=${size}&title=${encodeURIComponent(
      searchVal.trim()
    )}&groupAccess=${groupFilter}`;
    try {
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        signal: newAbortController?.signal,
      });
      const newDataSubset = response.data.data.map((user: any) => ({
        id: user.id,
        groupAccess: user.groupAccess,
      }));

      if (type) {
        setOrganisation(response.data.data);
        setAaccess(newDataSubset);
      } else {
        setOrganisation((prevOrganisation) => [
          ...prevOrganisation,
          ...response.data.data,
        ]);
        setAaccess((prevAaccess) => [...prevAaccess, ...newDataSubset]);
      }

      if (size > response.data.data.length) {
        setHasMore(false);
      }

      setPage(pageCount + 1);
    } catch (error) {
      console.error("Error in userProfile request:", error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const ongroupradioChange = (e: RadioChangeEvent, confirm: Function) => {
    const value = e.target.value;
    setGroupFilter(value);
    fetchOrganisation(1, value, search);
    confirm();
  };

  const [isCheckedGroup, setIsCheckedGroup] = useState(false);

  const handleCheckboxGrp = () => {
    setIsCheckedGroup(!isCheckedGroup);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowsId, setSelectedRowsId] = useState<RowsInfo[]>([]);

  const countSelectedRows =
    selectedRowKeys.length > 0 ? selectedRowKeys.length : null;

  const [id, setId] = useState(""); // Assuming id is a state
  const [groupAccess, setGroupAccess] = useState(false);

  // over all checkbox in a table

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    const dataSourceId = selectedRows.map((item: DataType) => ({
      id: item.id,
      profilePicture: item.profilePicture,
      name: item.firstName + " " + item.lastName,
      roles: item.roles,
    }));
    setSelectedRowsId(dataSourceId);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Group and vault Changes
  const userIds = selectedRowsId.map((record) => record.id);

  const changingGrpVault = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const requestBody = {
      users: userIds,
      groupAccess: isCheckedGroup,
      vaultAccess: isCheckedGroup,
    };
    try {
      const response = await api.post(`user/permission`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        setIsLoading(false);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchOrganisation(1, "all", search);
        setIsCarbonMember(false);
        setSelectedRowKeys([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Update the group access and vault access
  const updateGroupVault = async (id: string, groupAccess: boolean) => {
    const chkboxClickSingleUser = groupAccess;
    const requestBody = {
      users: [id],
      groupAccess: chkboxClickSingleUser,
      vaultAccess: chkboxClickSingleUser,
    };
    try {
      const response = await api.post(`user/permission`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsCarbonMember(false);
        setSelectedRowKeys([]);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  const [currecntId, setCurrentId] = useState("");
  const [currentAccess, setCurrentAccess] = useState("");

  // list of group fetch in modal
  const userGroupFetch = async (
    userid: string,
    groupAccess: any,
    type: number
  ) => {
    setGroupHasMore(true);
    setCurrentId(userid);
    setCurrentAccess(groupAccess);

    let pageCount = groupPage;
    if (type) {
      setIsLoading(true);
      setGroupPage(0);
      pageCount = 0;
    }
    const booleanValue = groupAccess;
    setId(userid);
    setGroupAccess(booleanValue);
    setUserMemberId(userid);
    const index = Aaccess.findIndex((user: any) => user.id === userid);

    // Create a new array with the updated item

    const url = `group/admin-change?id=${userid}&page=${pageCount}&size=${groupsSize}`;

    try {
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        setIsLoading(false);
        const responseData = response.data.data;
        if (!pageCount) {
          if (responseData.data) {
            vaultClickOpen();
            SetListofGroups(responseData.data);
            setGroupAdminCount(responseData.count);
          } else {
            SetListofGroups([]);
            setGroupAdminCount(0);
            updateGroupVault(userid, groupAccess);
            const updatedDataSubset = [...Aaccess];
            updatedDataSubset[index] = { id: userid, groupAccess: groupAccess };
            setAaccess(updatedDataSubset);
          }
        } else {
          if (responseData.data) {
            SetListofGroups((prevListofGroups) => [
              ...prevListofGroups,
              ...responseData.data,
            ]);
          } else {
            setGroupHasMore(false);
          }
        }

        if (groupsSize > responseData.data.length) {
          setGroupHasMore(false);
        }
        setGroupPage(pageCount + 1);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // Particular grp member info
  const userMemberInfo = async (
    id: string,
    type: number,
    searchVal: string
  ) => {
    setgrpId(id);

    let pageCount = memberPage;
    let newAbortController;

    if (type) {
      setMemberHasMore(true);
      setMemberPage(0);
      pageCount = 0;
      abortController.abort();
      // Create a new abort controller for the current request
      newAbortController = new AbortController();
      setAbortController(newAbortController);
    }

    const url = `group/groupuserdata?id=${id}&userId=${userMemberId}&page=${pageCount}&size=${groupsSize}&title=${encodeURIComponent(
      searchVal.trim()
    )}`;
    try {
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        signal: newAbortController?.signal,
      });
      if (response.data.status === true) {
        if (!pageCount) {
          setMemberDetails(response.data.data.memberDetailsList);
          if (response.data.data.groupDetails) {
            setGroupRowDetails(response.data.data.groupDetails);
          }
        } else {
          setMemberDetails((prevMemberDetails) => [
            ...prevMemberDetails,
            ...response.data.data.memberDetailsList,
          ]);
        }
        if (groupsSize > response.data.data.memberDetailsList.length + 1) {
          setMemberHasMore(false);
        }
        setMemberPage(pageCount + 1);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  // Admin chnage
  const SelectAdmin = () => {
    setIsLoading(true);
    const roles = "admin";
    const url = `group/update-admin?id=${grpId}&userId=${userMemberId}&role=${roles}&memberId=${selectedId}`;
    api
      .put(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchOrganisation(1, "all", search);
        orgMangetoggle();
        setVaultOpen(false);
        setrefresh(true);
        setIsLoading(false);
        setSelectedId("");
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: DataType) => ({
      disabled: record.roles === "admin",
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });

          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const orgClickOpen = () => {
    setOrganisationOpen(true);
  };
  const orgClose = () => {
    setOrganisationOpen(false);
    setCompayNameEdit("");
    setErrorMessage("");
    setCroppedImageUrl("");
    setImgdup("");
  };

  const vaultClickOpen = () => {
    setVaultOpen(true);
  };
  const vaultClose = () => {
    setVaultOpen(false);
    setSelectedId("");
  };

  const orgMangetoggle = () => {
    setvaultHidden(!vaultHidden);
  };
  const [Aaccess, setAaccess] = useState<
    Array<{ id: string; groupAccess: any }>
  >([]);

  //Table Columns
  const columns: ColumnsType<DataType> = [
    {
      title: <b>Name</b>,
      sorter: (a, b) => {
        const aValue = a.firstName + " " + a.lastName;
        const bValue = b.firstName + " " + b.lastName;

        if (sortOrderRef.current === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      },

      dataIndex: "name",
      key: "name",
      render: (text: string, record: DataType) => (
        <div className="d-flex" style={{ alignItems: "center" }}>
          <img
            src={`${imagehttp}${record.profilePicture}`}
            style={{
              marginRight: "8px",
              borderRadius: "50%",
              minWidth: "40px",
              maxWidth: "40px",
              height: "40px",
            }}
            alt="Profile Picture"
          />
          <table>
            <tr>
              <td
                title={record.firstName + " " + record.lastName}
                className="fs-16 fw-600 hideDotSingleLine cur-pointer"
              >
                {`${record.firstName}  ${record.lastName}`}
              </td>
            </tr>
            <tr>
              <td className="color_7080 fs-14">
                {record.empId} &nbsp;<span className="fs-14">.</span>&nbsp;
                {record.designation}
              </td>
            </tr>
          </table>
        </div>
      ),
    },
    {
      title: <b>Create Group</b>,
      dataIndex: "createGroup",
      render: (createGroup: boolean, record: DataType) => (
        <div className="cur-pointer text-center">
          <Checkbox
            disabled={record.roles.toLowerCase() === "admin"}
            onClick={(e) => {
              userGroupFetch(
                record.id,
                !Aaccess.find((user) => user.id === record.id)?.groupAccess,
                1
              );
            }}
            // checked={Aaccess.groupAccess === 'true'}
            checked={
              Aaccess.find((user) => user.id === record.id)?.groupAccess ===
              true
            }
          />
        </div>
      ),

      filterIcon: (filtered: boolean) => (
        <Space direction="vertical" size={12}>
          {filtered ? (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          ) : (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          )}
        </Space>
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <div className="p-10">
          <Radio.Group
            className="radio-seleted"
            onChange={(e) => ongroupradioChange(e, confirm)}
            value={groupFilter}
          >
            <Space direction="vertical">
              <Radio value="all">All</Radio>
              <Radio value="selected">Selected</Radio>
              <Radio value="unselected">Unselected</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      onFilter: (value, record) => {
        return record.groupAccess === value;
      },
    },
    {
      title: <b>Create Vault</b>,
      dataIndex: "createVault",
      render: (createVault: boolean, record: DataType) => (
        <div className="cur-pointer text-center">
          <Checkbox
            disabled={record.roles.toLowerCase() === "admin"}
            checked={
              Aaccess.find((user) => user.id === record.id)?.groupAccess ===
              true
            }
          />
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <Space direction="vertical" size={12}>
          {filtered ? (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          ) : (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          )}
        </Space>
      ),
      onFilter: (value, record) => {
        return record.createGroup === value;
      },
    },
    {
      title: <b>Last Active</b>,
      dataIndex: "lastActivityStatus",
      render: (text: string) => (
        <p className="fs-14 fw-500 colorBlack">{text}</p>
      ),
      filterIcon: (filtered: boolean) => (
        <Space direction="vertical" size={12}>
          {filtered ? (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          ) : (
            <div className="cu-pointer">
              {" "}
              <img
                src={`..${subPath}/assets/images/dropdown_img.svg`}
                className="mt-10"
                alt="Dropdown"
              />
            </div>
          )}
        </Space>
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <div className="p-10">
          <Radio.Group
            onChange={(e) => handleRadioChangeTable(e, confirm)}
            value={sortOrderRef.current}
            className="radio-seleted"
          >
            <Space direction="vertical">
              <Radio value="ascending">Ascending</Radio>
              <Radio value="descending">Descending</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
      onFilter: (value, record) => {
        return record.lastActivityStatus === value;
      },
    },

    {
      title: (
        <img
          src={`..${subPath}/assets/images/carbon_popup.svg`}
          className="cur-pointer"
          onClick={toggleCarbonVisibility}
        />
      ),
      dataIndex: "popup",
      render: (popup: string, record: DataType) => (
        <img
          className="text-center"
          src={`..${subPath}/assets/images/menu_3.svg`}
          alt={`Image for ${popup}`}
          style={{ width: "24px", marginRight: "8px" }}
        />
      ),
    },
  ];

  //Table data
  const dataSource = organisation.map((item: DataType, index) => ({
    ...item,
    key: index,
  }));

  const orgSettings = () => {
    navigate(`/org-settings`);
  };

  const maxCount = 4;
  const remainingAvatars = selectedRowsId.slice(0, maxCount);
  const hiddenCount = selectedRowsId.length - maxCount;

  //Skeleton

  const numberOfRows = 6;

  const renderedRows = [...Array(numberOfRows)].map((e, i) => (
    <div>
      <Row />
    </div>
  ));
  return (
    <>
      <Box
        // item
        // container
        // direction="column"
        className="center-grid "
        // xs={12}
        // sm={7}
        // md={9}
        // lg={9}
        // xl={10}
        // spacing={2}
      >
        <Grid item container className=" justify-content-between d-unset p-0">
          <Typography sx={{ height: "0px" }}>&nbsp;</Typography>

          {pageCompanyIsLoading ? (
            <Grid
              container
              className=" p-10 org_bg_load"
              sx={{ marginTop: "-14px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                className=" align-items-center"
              >
                <Grid></Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={100}
                    className="br_5"
                    sx={{ ...SkeletonColor }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography className="5 fs-18 fw-600 mb-15">
                    <Skeleton
                      width={130}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>

                  <Typography className="color_7080 fs-12">
                    <Skeleton
                      width={120}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>

                  <Typography className="fs-12 fw-600">
                    <Skeleton
                      width={130}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>

                  <Typography className="fs-12 fw-600">
                    <Skeleton
                      width={130}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>

                  <Typography className="fs-12 fw-600">
                    <Skeleton
                      width={130}
                      height={25}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="text-center org_settings"
                  style={{
                    paddingTop: "15px",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={24}
                    height={24}
                    sx={{ ...SkeletonColor }}
                  />{" "}
                  {/* Adjust width and height as needed */}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              className=" p-10 org_bg"
              sx={{ marginTop: "-14px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                className=" align-items-center"
              >
                <Grid></Grid>

                <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
                  <Typography sx={{ lineHeight: "0" }}>&nbsp;</Typography>

                  <img
                    src={`${imagehttp}${companyProfilePop}`}
                    className="br_5"
                    width="100px"
                    height="100px"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  <Typography
                    title={companyName}
                    className="color_35 fs-18 fw-600 mb-15 hideDotOrg"
                  >
                    {companyName}
                  </Typography>

                  <Typography
                    className="color_7080 fs-12 textdecoration_unterline cur-pointer"
                    onClick={() => {
                      orgClickOpen();
                      setCompayNameEdit(companyName);
                      setCompanyEditProfile(companyProfile);
                    }}
                  >
                    Edit Organisation details
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    {count?.group_count}
                  </Typography>

                  <Typography className="fs-12 fw-600">Total Groups</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    {count?.asset_count}
                  </Typography>

                  <Typography className="fs-12 fw-600">Total Assets</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="text-center m_textstart"
                >
                  <Typography className="fs-18 fw-600">
                    {count?.users_count}
                  </Typography>

                  <Typography className="fs-12 fw-600">
                    {" "}
                    Total Members
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="text-center org_settings"
                  onClick={orgSettings}
                  style={{
                    paddingTop: "15px",
                  }}
                >
                  <img
                    src={`..${subPath}/assets/images/carbon_settings.svg`}
                    className=""
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {isPageLoading ? (
            <Grid
              item
              className="br_16 org_card back_load_color mt-15 position-relative p-15"
              sx={{ background: "#fff", overflowX: "auto" }}
            >
              <Grid item className="d-flex justify-content-between">
                <Skeleton
                  width={130}
                  height={29}
                  sx={{ ...SkeletonColor, borderRadius: "8px" }}
                />

                <div className="d-flex ogr_search">
                  <Skeleton
                    variant="rectangular"
                    width={150}
                    height={24}
                    style={{
                      ...SkeletonColor,
                      marginLeft: 8,
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </Grid>
              <div className="table-container fs-16 mt-15">{renderedRows}</div>
            </Grid>
          ) : (
            <Grid
              item
              className="br_16  org_card mt-15 position-relative p-15"
              sx={{ background: "#fff", overflowX: "auto" }}
            >
              <Grid item className="d-flex justify-content-between">
                <Typography className="fs-18 fw-600">Members</Typography>

                <div className="d-flex ogr_search">
                  <img
                    src={`..${subPath}/assets/images/search.svg`}
                    className="search_sm"
                    alt="Search"
                  />

                  <Input
                    id="search"
                    placeholder="Search Member"
                    title="search"
                    className="position-relative"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      fetchOrganisation(1, groupFilter, e.target.value);
                    }}
                  />
                </div>
              </Grid>

              <div
                id="orgTableScroll"
                className="table-container orgTableScroll fs-16 mt-15"
              >
                <InfiniteScroll
                  dataLength={organisation.length}
                  next={() => fetchOrganisation(0, groupFilter, search)}
                  hasMore={hasMore}
                  scrollableTarget="orgTableScroll"
                  className=""
                  loader={
                    <h6
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <CircularProgress
                        style={{
                          color: "#119BFF",
                        }}
                        size={20}
                      />
                    </h6>
                  }
                  endMessage={
                    organisation.length !== 0 && (
                      <p className="fs-12 noMore">
                        <b>No more data found...</b>
                      </p>
                    )
                  }
                >
                  <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    className="fs-16 table_scroll"
                  />
                </InfiniteScroll>
                <div>
                  {isCatbonVisible && countSelectedRows !== null && (
                    <form onSubmit={changingGrpVault}>
                      <Box className="carbon_modal">
                        <Grid
                          item
                          container
                          className="justify-content-between popup-header"
                        >
                          <label htmlFor="name" className="fs-18 fw-600">
                            <span>Selected Members</span>

                            <span
                              style={{ color: "var(--1, #119BFF)" }}
                              className="pl-5"
                            >
                              {countSelectedRows !== null ? (
                                <>({countSelectedRows})</>
                              ) : (
                                <>(0)</>
                              )}
                            </span>
                          </label>

                          <img
                            src={`..${subPath}/assets/images/close_b.svg`}
                            width="20"
                            className="cur-pointer"
                            onClick={closebtn}
                            alt="Close"
                          />
                        </Grid>

                        <Grid
                          item
                          className="text-center mt-15"
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Avatar.Group
                            maxCount={4}
                            maxPopoverTrigger="click"
                            size="large"
                            maxStyle={{
                              color: "#fff",

                              backgroundColor: "#A0D7FF",

                              cursor: "pointer",
                            }}
                          >
                            {remainingAvatars.map((result: RowsInfo, index) => {
                              return (
                                <Tooltip title={result.name} key={index}>
                                  <Avatar
                                    key={index}
                                    src={`${imagehttp}${result.profilePicture}`}
                                  />
                                </Tooltip>
                              );
                            })}
                          </Avatar.Group>
                          <Avatar.Group size="large">
                            {hiddenCount > 0 && (
                              <Avatar
                                style={{
                                  backgroundColor: "#A0D7FF",
                                  marginLeft: "-5px",
                                }}
                              >
                                +{hiddenCount}
                              </Avatar>
                            )}
                          </Avatar.Group>
                        </Grid>

                        <Grid
                          container
                          className="justify-content-between mt-15"
                        >
                          <Typography className="fs-18 fw-500 colorBlack">
                            Create Group
                          </Typography>

                          <Checkbox
                            defaultChecked={isCheckedGroup}
                            onChange={handleCheckboxGrp}
                          />
                        </Grid>

                        <Grid
                          container
                          className="justify-content-between mt-15"
                        >
                          <Typography className="fs-18 fw-500 colorBlack">
                            Create Vault
                          </Typography>

                          <Checkbox checked={isCheckedGroup === true} />
                        </Grid>

                        <Grid
                          container
                          className="justify-content-between mt-15"
                        >
                          <Typography className="color_EC174F fs-16">
                            Deactivate
                          </Typography>
                        </Grid>

                        <Grid item sx={{ float: "right", marginTop: "40px" }}>
                          <button
                            type="button"
                            onClick={closebtn}
                            className="cancel_btn fs-14"
                          >
                            Cancel
                          </button>

                          <button
                            disabled={isLoading}
                            className="m-button fs-13 "
                            type="submit"
                            style={{ width: "140px", fontWeight: "600" }}
                          >
                            {isLoading ? "Loading..." : "Apply Changes"}
                          </button>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* </Grid>
      </Box> */}

      {/* Edit Organisation details*/}

      <div>
        <BootstrapDialog
          onClose={orgClose}
          aria-labelledby="customized-dialog-title"
          open={Organisation}
        >
          <Grid item className="d-flex justify-content-between">
            <DialogTitle id="customized-dialog-title" className="fw-600">
              Edit Organisation details
            </DialogTitle>

            <DialogTitle className="cur-pointer">
              <img
                src={`..${subPath}/assets/images/close_b.svg`}
                width="20"
                onClick={orgClose}
                alt="Close"
              />
            </DialogTitle>
          </Grid>

          <DialogContent dividers className="popup_design">
            <Form name="form-name" layout="vertical" onFinish={onFinish}>
              <Form.Item name="name" className="m-text ">
                <label htmlFor="name">
                  <span className="fw-600">Name</span>

                  <span style={{ color: "var(--1, #119BFF)" }}>*</span>
                </label>

                <Input
                  id="name"
                  title="Name"
                  name="companyName "
                  value={companyNameEdit}
                  onChange={(event) => setCompayNameEdit(event.target.value)}
                  className="name field colorBlack  fs-14 fw-500 "
                />
                <span
                  id="nameError"
                  style={{ color: "red" }}
                  className="error"
                ></span>
              </Form.Item>

              <Form.Item name="name" className="m-text mt-15">
                <label htmlFor="name">
                  <span className="fw-600">Profile Picture</span>
                </label>

                <div>
                  {imgdup ? (
                    <div>
                      <ReactCrop
                        crop={crop}
                        onChange={(_, newCrop) => setCrop(newCrop)}
                        onComplete={(cropResult) =>
                          handleCropComplete(cropResult)
                        }
                        aspect={aspect}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={imgdup}
                          style={{
                            transform: `scale(${scale}) rotate(${rotate}deg)`,
                          }}
                          className="br_5 crop_style"
                          onLoad={onImageLoad}
                        />
                      </ReactCrop>
                    </div>
                  ) : (
                    <>
                      {CompanyEditProfile ? (
                        <img
                          src={`${imagehttp}${CompanyEditProfile}`}
                          width="100px"
                          height="100px"
                          className="br_5"
                          alt="Profile Picture"
                        />
                      ) : null}
                    </>
                  )}
                </div>
                <input
                  type="file"
                  accept=".jpeg, .png, .gif, .bmp, .heif, .svg, .hdr,.jpg"
                  name="upload"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  ref={fileInputRef}
                />

                {CompanyEditProfile ? (
                  <img
                    src={`..${subPath}/assets/images/solar_trash-bin-2-broken.png`}
                    onClick={clearUpload}
                    alt="Clear"
                    className="cur-pointer"
                  />
                ) : (
                  <FiUpload
                    color="#119BFF"
                    size="23px"
                    onClick={() => fileInputRef.current?.click()}
                    style={{ cursor: "pointer" }}
                  />
                )}

                {errorMessage && (
                  <div id="haserror" style={{ color: "red", marginTop: "5px" }}>
                    {errorMessage}
                  </div>
                )}
                <input
                  type="file"
                  accept=".jpeg, .png, .gif, .bmp, .heif, .svg, .hdr,.jpg"
                  id="fileInput"
                  style={{ display: "none" }}
                />
              </Form.Item>

              <DialogActions>
                <Form.Item>
                  <Button autoFocus onClick={orgClose} className="cancel_btn">
                    Cancel
                  </Button>

                  <Button
                    disabled={isLoading}
                    autoFocus
                    className="m-button fs-13"
                    htmlType="submit"
                    style={{ fontWeight: "600" }}
                  >
                    {isLoading ? "Loading..." : "Update Details"}
                  </Button>
                </Form.Item>
              </DialogActions>
            </Form>
          </DialogContent>
        </BootstrapDialog>
      </div>

      {/* Create Vault modal*/}

      <div>
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={Vault}>
          <Grid item className="d-flex justify-content-between ">
            <DialogTitle id="customized-dialog-title" className="fs-18 fw-600">
              <span>Manage Group Admin</span>
              <span style={{ color: "var(--1, #119BFF)" }}>
                {" (" + groupAdminCount?.toString() + ")" ?? "(0)"}
              </span>
            </DialogTitle>

            <DialogTitle className="cur-pointer">
              <img
                alt="Close"
                src={`..${subPath}/assets/images/close_b.svg`}
                onClick={() => {
                  vaultClose();
                  setvaultHidden(true);
                }}
                className="min-height-20"
              />
            </DialogTitle>
          </Grid>

          <Form onFinish={SelectAdmin}>
            <Divider />

            {!vaultHidden && (
              <Grid
                item
                className="d-flex justify-content-between align-items-center cur-pointer headerPadding"
              >
                <Stack
                  onClick={() => orgMangetoggle()}
                  spacing={2}
                  direction="row"
                  alignItems="center"
                >
                  <Stack>
                    <img
                      src={`..${subPath}/assets/images/prv-arrow.svg`}
                      alt="Previous"
                    />
                  </Stack>
                  <Stack style={{ marginLeft: "10px" }}>
                    {fileName === expectedFileName ? (
                      <Avatar
                        className="sidebarimg"
                        style={{
                          backgroundColor: randomColor,
                          width: "24px",
                          height: "24px",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {groupRowDetails?.name
                          ? groupRowDetails?.name
                              .split(" ")
                              .map((word: any, index: number) =>
                                index < 2 ? word.charAt(0).toUpperCase() : ""
                              )
                              .join("")
                          : ""}
                      </Avatar>
                    ) : (
                      <img
                        src={`${imagehttp}${groupRowDetails?.groupIcon}`}
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "4px",
                        }}
                        alt="Group Icon"
                      />
                    )}
                  </Stack>
                  <Stack sx={{ minWidth: 0 }}>
                    <Typography noWrap className=" fontW550">
                      {groupRowDetails?.name}
                    </Typography>
                  </Stack>
                </Stack>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div
                    className="d-flex ogr_search"
                    style={{
                      borderRadius: "6px",
                      marginLeft: "0px",
                    }}
                  >
                    <img
                      src={`..${subPath}/assets/images/search.svg`}
                      className="search_sm"
                      alt="Search"
                    />

                    <Input
                      style={{ paddingLeft: "27px", fontSize: "small" }}
                      id="search"
                      placeholder="Search Member"
                      title="search"
                      type="text"
                      className="position-relative"
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                        userMemberInfo(grpId, 1, e.target.value);
                      }}
                      autoComplete="off"
                      name={`random-${Math.random()}`}
                    />
                  </div>
                </Grid>
              </Grid>
            )}

            <div id={`${!vaultHidden ? "orgPopUp235" : "orgPopUp"}`}>
              {!vaultHidden ? (
                <DialogContent>
                  <InfiniteScroll
                    dataLength={memberDetails.length}
                    next={() => userMemberInfo(grpId, 0, searchInput)}
                    hasMore={memberHasMore}
                    scrollableTarget="orgPopUp235"
                    className=""
                    loader={
                      <h6
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <CircularProgress
                          style={{
                            color: "#119BFF",
                          }}
                          size={20}
                        />
                      </h6>
                    }
                    endMessage={
                      memberDetails.length !== 0 && (
                        <p className="noMore">
                          <b>No more data found...</b>
                        </p>
                      )
                    }
                  >
                    {memberDetails
                      .filter((result) =>
                        result.name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      )
                      .map((result) => {
                        return (
                          <Box className="marginLB-20">
                            <Grid
                              item
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                                xl={10}
                              >
                                <Grid
                                  item
                                  className="d-flex align-items-center  cur-pointer"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    sx={{
                                      minWidth: "32px",
                                      minHeight: "32px",
                                    }}
                                    style={{ display: "contents" }}
                                  >
                                    <img
                                      src={`${imagehttp}${result.profilepicture}`}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50px",
                                      }}
                                      alt="Profile Picture"
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                  >
                                    <Typography
                                      style={{
                                        width: "200px",
                                        fontSize: "13px",
                                      }}
                                      className="pl-10 hideDotSingleLine colorBlackB capitalize"
                                      title={result.name}
                                    >
                                      {result.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                xl={2}
                                className="textEnd"
                              >
                                <Radio
                                  value={result.userId}
                                  className="radio-seleted"
                                  checked={selectedId === result.userId}
                                  onChange={(e) =>
                                    handleRadioChange(e.target.value)
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                  </InfiniteScroll>
                </DialogContent>
              ) : (
                <InfiniteScroll
                  dataLength={listofGroups.length}
                  next={() => userGroupFetch(currecntId, currentAccess, 0)}
                  hasMore={groupHasMore}
                  scrollableTarget="orgPopUp"
                  className=""
                  loader={
                    <h6
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <CircularProgress
                        style={{
                          color: "#119BFF",
                        }}
                        size={20}
                      />
                    </h6>
                  }
                  endMessage={
                    listofGroups.length !== 0 && (
                      <p className="noMore">
                        <b>No more data found...</b>
                      </p>
                    )
                  }
                >
                  <div onClick={orgMangetoggle}>
                    <DialogContent>
                      {listofGroups.map((result: ListofGroupInfo, index) => {
                        const imageUrl = result.groupIcon;
                        const expectedFileName = "20231024153135_no_image.jpg";

                        let fileName = "";
                        if (typeof imageUrl === "string" && imageUrl) {
                          const url = new URL(imageUrl);
                          const pathParts = url.pathname.split("/");
                          fileName = pathParts[pathParts.length - 1];
                        }

                        const randomColor = getRandomColor(result.name);
                        return (
                          <Grid
                            onClick={() => userMemberInfo(result.id, 1, "")}
                            item
                            className="d-flex justify-content-between align-items-center cur-pointer mb-19"
                          >
                            <Stack
                              spacing={2}
                              direction="row"
                              alignItems="center"
                            >
                              <Stack>
                                {fileName === expectedFileName ? (
                                  <>
                                    <div className="orgimg mt-5 ">
                                      <Avatar
                                        style={{
                                          backgroundColor: randomColor,
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "6px",
                                          marginBottom: "7px",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {result.name
                                          ? result.name
                                              .split(" ")
                                              .map((word: any, index: number) =>
                                                index < 2
                                                  ? word.charAt(0).toUpperCase()
                                                  : ""
                                              )
                                              .join("")
                                          : ""}
                                      </Avatar>
                                    </div>
                                  </>
                                ) : (
                                  <img
                                    className="hw50"
                                    src={`${imagehttp}${result.groupIcon}`}
                                    style={{
                                      borderRadius: "6px",
                                    }}
                                    alt="Group Icon"
                                  />
                                )}
                              </Stack>
                              <Stack sx={{ minWidth: 0 }}>
                                <Typography noWrap className=" fontW550">
                                  {result.name}
                                </Typography>
                              </Stack>
                            </Stack>

                            <img
                              style={{ height: "20px", marginTop: "-16px" }}
                              src={`..${subPath}/assets/images/next-arrow.svg`}
                              alt="Next"
                            />
                          </Grid>
                        );
                      })}
                    </DialogContent>
                  </div>
                </InfiniteScroll>
              )}
            </div>

            {vaultHidden ? (
              <DialogActions>
                <Button autoFocus onClick={vaultClose} className="cancel_btn">
                  Cancel
                </Button>

                <Button
                  autoFocus
                  disabled={isLoading}
                  className="m-button"
                  onClick={() => {
                    updateGroupVault(id, groupAccess);
                    vaultClose();
                  }}
                  style={{ fontWeight: "600" }}
                >
                  {isLoading ? "Loading..." : "Apply Changes"}
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() => {
                    vaultClose();
                    loginAdmin(createdBy);
                  }}
                  className="cancel_btn"
                  htmlType="submit"
                >
                  <span className="textdecoration_unterline fs-13">
                    Make you as admin
                  </span>
                </Button>

                <Button
                  autoFocus
                  className="m-button fs-13"
                  disabled={isLoading}
                  htmlType="submit"
                  style={{ width: "140px", fontWeight: "600" }}
                >
                  {isLoading ? "Loading..." : "Change Admin"}
                </Button>
              </DialogActions>
            )}
          </Form>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default View;
