const initialState = {
  chatView:[],
  seenChat:[],
  pinnedMessage:[]
 };
 const chatViewReducer = (state = initialState, action: any) => {
   switch (action.type) {
     case "CHAT_VIEW":
       return {
         ...state,
         chatView: action.payload,
       };
       case "CHAT_SEEN":
       return {
         ...state,
         seenChat: action.payload,
       };
       case "PINNED_MESSAGE":
       return {
         ...state,
         pinnedMessage: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default chatViewReducer;