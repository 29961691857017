import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { subPath } from "../../../../Constants/Global";
import MoreIcon from "@mui/icons-material/MoreVert";
import { deletePrivateChat, pinRecipientsOrGroupsForUser, unPinChat } from "../../../../Redux/Chatview/action";
import { useAppDispatch } from "../../../../Constants/hooks";

const ChatMenu = ({ userData, chatId, senderId }: any) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleDeletePrivateChat = () => {
    const data = {
      senderId: senderId,
      chatId: chatId,
    };
    dispatch(deletePrivateChat(data));
  };

  const handleSetPinChat = () => {
    const data = {
      recipientId: senderId,
      userId: userData.id,
      groupId: ''
    };
    dispatch(pinRecipientsOrGroupsForUser(data));
  }
  const handleUnPinChat = () => {
    const data = {
      recipientId: senderId,
      userId: userData.id,
      groupId: ''
    };
    dispatch(unPinChat(data));
  }

  return (
    <div>
      <IconButton
        className="iconbutton_chat"
        sx={{ paddingRight: "0px" }}
        size="large"
        edge="end"
        color="inherit"
        aria-label="user menu"
        onClick={handleOpenUserMenu}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={handleCloseUserMenu}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/chat_notification.png`}
              alt="Mute Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary="Mute Chat" className="moreitem_fs" />
        </MenuItem>
        <MenuItem onClick={handleUnPinChat}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/pin-angle.png`}
              alt="Unpin Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary="Unpin Chat" className="moreitem_fs" />
        </MenuItem>
        <MenuItem onClick={handleDeletePrivateChat}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/trash.png`}
              alt="Delete Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete Chat"
            className="moreitem_fs"
            style={{ color: "#E7554A" }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
export default ChatMenu;
