import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { subPath } from "../../../../Constants/Global";

import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";

import PreviewAttachment from "../ChatMessage/PreviewAttachment";
import ThumbnailCarousel from "../ChatMessage/ThumbnailCarousel";
import { isMediaShow } from "../../../../Redux/chatList/action";
import { sendMedia } from "../../../../Redux/circle/action";
type ChatMediaViewProps = {
  imageUrl: string;
  onSend: () => void;
};
const ChatMediaView: React.FC<any> = (chatDetails) => {
  console.log("chatDetailszz ", chatDetails);


  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [files, setFiles] = useState([]);
  const dispatch = useAppDispatch();

  const scroll = (direction: string) => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollAmount =
        direction === "left" ? -container.clientWidth : container.clientWidth;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleThumbnailClick = (image: File) => {
    console.log(image);
    setSelectedImage(image);
  };

  const onImageSelected = () => {
    const formData = new FormData();

    formData.append("senderId", chatDetails.chatDetails?.senderId || "");
    formData.append("senderName", chatDetails?.chatDetails?.senderName || "");
    formData.append("recipientId", chatDetails?.chatDetails?.recipientId || "");
    formData.append(
      "recipientName",
      chatDetails?.chatDetails?.recipientName || ""
    );
    formData.append("department", "");
    formData.append("designation", "");
    formData.append("groupId", "");
    formData.append("groupName", "");
    formData.append("groupProfilePicture", "");
    formData.append("senderProfilePicture", "");
    formData.append("colorCode", "");
    formData.append("deviceToken", "");
    formData.append("replyTo", "");

    files.forEach((fileObj: { file: File }, index) => {
      const file = fileObj.file;
      if (file.type === "image/png") {
        formData.append(`images[${index}].media`, file);
        formData.append(`images[${index}].id`, `file_${index}`);
        formData.append(`images[${index}].caption`, "");
      } else if (file.type === "application/pdf") {
        formData.append(`documents[${index}].media`, file);
        formData.append(`documents[${index}].id`, `file_${index}`);
        formData.append(`documents[${index}].caption`, "");
      }
      // Add similar blocks for videos and audios if needed
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    // axios.post('https://trovekafkachat.ckdigital.in/api/media/post', formData, config)
    //   .then((res) => {
    //     const value: any = msgchat.privateChatMessages;

    //     res.data.data.forEach((imageData: any) => {
    //       value.push(imageData);
    //     });

    //     setMsgChat((prevMsgChat) => ({
    //       ...prevMsgChat,
    //       privateChatMessages: value,
    //     }));
    //     setImagePreview(false);
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  };

  const handleViewFile = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCloseView = () => {
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const isMediaView = useAppSelector(
    (state: any) => state?.chatListData?.isMediaView
  );
  
  const imageURL = useAppSelector(
    (state: any) => state?.chatListData?.imageURL
  );

  console.log("isMediaView ==> ", isMediaView);

  const handleCloseMedia = () => {
    dispatch(isMediaShow({ isMediaView: !isMediaView, imageURL: "" }));
  };
  const handleToggleMediaView = (image) => {
    setSelectedImage(image);
  };
  const viewmessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.chatView?.privateChatMessages || []
  );
  
  
console.log("viewmessage", viewmessage);

  return (
    <div>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#ffffff",
        }}
      >
        <Grid
          container
        
        >
          <Grid
            item
            sm={2}
            md={0.6}
            lg={0.6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img
              onClick={handleCloseMedia}
              src={`..${subPath}/assets/images/images/Back_Button.png`}
              alt="back Arrow"
              style={{ width: "18px", height: "18px", cursor: "pointer" }}
            /> */}
      
          </Grid>
          <Grid
            item
            sm={8}
            md={11.4}
            lg={11.4}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {/* <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
              }}
            >
              Image.png
            </Typography> */}
          </Grid>
        </Grid>
        <>
          <div style={{ display: "flex" }}>
            <Button className="prev-icon">
              {/* &#8249;  */}
              <img
                src={`..${subPath}/assets/images/images/Back_Button.png`}
                alt="back Arrow"
                onClick={handleCloseMedia}
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
              />
            </Button>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
                position: "relative",
                top: ".4rem",
              }}
            >
              {/* { imageURL.name} */}
              Image.png
            </Typography>
          </div>
          <Grid item sx={{ backgroundColor: "#E5E5E5", height: "80vh " ,     display:'flex',
                justifyContent:'center',
                alignItems:'center', }}>
            {/* {selectedImage && <PreviewAttachment image={selectedImage} />} */}
            <img
              src={imageURL}
              alt="Preview"
              style={{
                paddingBottom:'50px',
              height: "400px",
              }}
              onClick={() => handleToggleMediaView(selectedImage)}
            />
          </Grid>

          <Grid
            item
            sx={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              height: "80px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           
            <ThumbnailCarousel
              images={files}
              onThumbnailClick={handleThumbnailClick}
            />
          </Grid>
        </>
      </Box>
    </div>
  );
};

export default ChatMediaView;
