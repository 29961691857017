import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal, Form, Input } from "antd";
import "react-toastify/dist/ReactToastify.css";
import axios, { AxiosError } from "axios";
import { decodeToken } from "../../Constants/Global";
import "../Modal/Modal.css";
import * as Yup from "yup";
import eventEmitter from "../../Constants/EventEmiter";
import UseAxios from "../../Constants/UseAxios";
import { Divider } from "@mui/material";
//modal open props
interface VaultModalProps {
  isOpen: boolean;
  closeVaultModal: () => void;
  refreshSidebarVault: () => void;
  GrpId: string;
}

const Vault: React.FC<VaultModalProps> = ({
  isOpen,
  closeVaultModal: closeModalFromProps,
  refreshSidebarVault,
  GrpId,
}) => {
  const api = UseAxios();
  const [form] = Form.useForm();
  const maxLengthvalidation = 25;
  const [name, setName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const companyId = decodeToken().companyId;

  useEffect(() => {
    eventEmitter.on("sidebar", fetchSelectGroup);
    return () => {
      eventEmitter.off("sidebar", fetchSelectGroup);
    };
  }, []);

  useEffect(() => {
    eventEmitter.on("triggerViewGroup", fetchSelectGroup);
    return () => {
      eventEmitter.off("triggerViewGroup", fetchSelectGroup);
    };
  }, []);

  //Select Group
  const fetchSelectGroup = async () => {
    try {
      const response = await api.get(`vault/viewgroup?companyId=${companyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        refreshSidebarVault();
      }
    } catch (error) {}
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setName(value);
  };

  //Create Vault Submition
  const onFinish = async () => {
    setIsLoading(true);
    // Empty the error messages and red borders
    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("field");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields?.[i]?.classList.remove("borderRed");
    }

    // Validate Before Submitting
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      group: Yup.string().required(),
    });

    // Data to validate
    const dataToValidate = {
      name,
      group: GrpId,
    };

    // Validate the data against the schema
    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("groupId", GrpId);
        try {
          const response = await api.post("vault/create", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            },
          });
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          eventEmitter.emit("vaultinsert");
          refreshSidebarVault();
          form.resetFields();
          setName("");
          // setSelectGroup("");
          closeVaultModal();
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          if (axios.isAxiosError(error)) {
            //Set the Validation error under the fields
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              for (const key in errorArray) {
                var keyVal = errorArray[key].trim().split(":");
                const errorField = document.getElementById(
                  keyVal[0] + "VaultError"
                );
                // Add Red Border to the field
                const inputField = document.getElementsByClassName(
                  keyVal[0].trim() + "Vault"
                );
                inputField?.[0]?.classList.add("borderRed");
                if (errorField) {
                  errorField.innerHTML =
                    keyVal[1].charAt(0).toUpperCase() +
                    keyVal[1].slice(1) +
                    ".";
                }
              }
            } else {
              // Set the timeout to 3 seconds, after that, remove the error message
              toast.error(error.response?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.error((error as AxiosError).message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        setIsLoading(false);
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "VaultError"
          );
          const inputField = document.getElementsByClassName(
            validationErrors.inner[i].path + "Vault"
          );

          inputField?.[0]?.classList.add("borderRed");

          if (errorField) {
            // First Letter should be caps
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };
  const closeVaultModal = () => {
    setName("");
    form.resetFields();
    closeModalFromProps();
  };

  return (
    <div>
      <Modal
        width={500}
        title="Create Vault"
        className="modal-headtext modal-border"
        open={isOpen}
        keyboard={false}
        maskClosable={false}
        onCancel={closeVaultModal}
        footer={[
          <span
            key="submit"
            tabIndex={2}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.5 : 1,
              pointerEvents: isLoading ? "none" : "auto",
            }}
            className="m-button"
            onClick={form.submit}
          >
            {isLoading ? "Loading..." : "Create Vault"}
          </span>,
        ]}
        centered={true}
      >
        {/* <Divider /> */}
        <Form
          form={form}
          name="form-name"
          layout="vertical"
          onFinish={onFinish}
          className="c_vault_form"
        >
          <Form.Item name="name" className="m-text">
            <label htmlFor="name">
              <span className="m-lbl">Name of Vault </span>
              <span className="lbl-man" style={{ color: "var(--1, #119BFF)" }}>
                *
              </span>
            </label>
            <Input
              id="name"
              placeholder="Type Here"
              title="Name"
              name="name"
              onChange={handleInputChange}
              maxLength={maxLengthvalidation}
              className="field nameVault colorBlack"
              style={{
                fontSize: "14px",

                fontWeight: "600",
              }}
              tabIndex={1}
            />
            <span
              id="nameVaultError"
              style={{ color: "red" }}
              className="error"
              tabIndex={-1}
            ></span>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Vault;
