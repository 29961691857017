import React, { useState, useEffect } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import eventEmitter from "../../../Constants/EventEmiter";
import { Avatar, Tooltip } from "antd";
import Card from "@mui/material/Card";
import { Box, CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { imagehttp, decodeToken } from "../../../Constants/Global";
import "../../../Layout/sidebar.css";
import "../Homepage/Home.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { subPath } from "../../../Constants/Global";
import InfiniteScroll from "react-infinite-scroll-component";
import UseAxios from "../../../Constants/UseAxios";
import { useAppSelector } from "../../../Constants/hooks";

//Componented Start
const GroupView: React.FC = () => {
  const api = UseAxios();
  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };

  const navigate = useNavigate();
  //states are Here
  const [group, setGroup] = useState<any[]>([]);
  const [groupUser, setGroupUser] = useState<any[]>([]);
  const [groupCount, setGroupCount] = useState<number | null>(0);
  useState<boolean>(true);
  const [isLoadingGroupList, setIsLoadingGroupList] = useState<boolean>(true);
  const [isLoadingGroupView, setIsLoadingGroupView] = useState<boolean>(true);
  const companyId = decodeToken().companyId;
  const [page, setPage] = useState(0);
  const size = 20;
  const [pageList, setPageList] = useState(0);
  const [isGridView, setIsGridView] = useState(true);
  const { itemName } = useAppSelector((state: any) => state.search);

  useEffect(() => {
    const handleGroupInsert = async () => {
      await fetchGroupData("1");
      await getGrpWithUser("1");
    };
    eventEmitter.on("groupList", handleGroupInsert);
    return () => {
      eventEmitter.off("groupList", handleGroupInsert);
    };
    // Load the welcome modal when the component is mounted
  }, []);

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  const fixedArrayLength = 18;
  const placeholderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  const handleGridViewClick = () => {
    setIsGridView(true);
    fetchGroupData("1");
  };
  const handleListViewClick = () => {
    setIsGridView(false);
    getGrpWithUser("1");
  };

  useEffect(() => {
    if (isGridView) {
      fetchGroupData("1");
    } else {
      getGrpWithUser("1");
    }
  }, [itemName]);

  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchGroupData = async (check: String) => {
    let pageCount = page;
    if (check === "1") {
      pageCount = 0;
      setPage(0);
      setGroup([]);
      setIsLoadingGroupView(true);
      setHasMore(true);
    }

    try {
      const response = await api.get(
        `group/view?companyId=${companyId}&page=${pageCount}&size=${size}&filter=${
          itemName != "" ? "true" : "false"
        }&title=${itemName}`
      );
      if (response.data && response.data.status === true) {
        const newData = response.data.data;
        const grpData = newData.groupResponse || [];

        if (grpData.length < size) {
          setHasMore(false);
        }

        // Append the group data to the existing group state
        setGroup((prevGroup) => [...prevGroup, ...grpData]);
        setPage(pageCount + 1);
        setGroupCount(newData.groupCount ? newData.groupCount : 0);
        setIsLoadingGroupView(false);
      }
    } catch (error) {
      console.error("Error fetching group data:", error);
    }
  };

  //hover card
  const [hoveredStates, setHoveredStates] = useState(
    new Array(group.length).fill(false)
  );

  // Function to handle mouse enter for a specific card
  const handleCardMouseEnter = (index: number) => {
    setHoveredStates((prevHoveredStates) => {
      const newHoveredStates = [...prevHoveredStates];
      newHoveredStates[index] = true;
      return newHoveredStates;
    });
  };

  // Function to handle mouse leave for a specific card
  const handleCardMouseLeave = (index: number) => {
    setHoveredStates((prevHoveredStates) => {
      const newHoveredStates = [...prevHoveredStates];
      newHoveredStates[index] = false;
      return newHoveredStates;
    });
  };

  const handleTableRowClick = (id: string) => {
    navigate(`/groupsView`, { state: { id } });
  };

  //groupList
  const getGrpWithUser = async (check: string) => {
    let pageCount = pageList;

    if (check === "1") {
      pageCount = 0;
      setPageList(0);
      setGroupUser([]);
      setIsLoadingGroupList(true);
      setHasMore(true);
    }

    try {
      const response = await api.get(
        `group/list?companyId=${companyId}&page=${pageCount}&size=${size}&filter=${
          itemName != "" ? "true" : "false"
        }&title=${itemName}`
      );
      if (response.data.status === true) {
        setIsLoadingGroupList(false);
        const newData = response.data.data;
        const grpData = newData.groupResponse || [];

        if (grpData.length < size) {
          setHasMore(false);
        }

        setGroupUser((prevGroup) => [...prevGroup, ...grpData]);
        setPageList(pageCount + 1);
        setGroupCount(newData.groupCount ? newData.groupCount : 0);
      }
    } catch (error) {}
  };

  const handleScrollGroup = () => {
    // Check if the user has scrolled to the bottom of the page
    if (isGridView) {
      fetchGroupData("0");
    } else {
      getGrpWithUser("0");
    }
  };

  const maxCount = 4;
  return (
    <>
      <Box className="center-grid ">
        <Grid container justifyContent="space-between">
          {isLoadingGroupView ? (
            <>
              <Skeleton
                variant="rectangular"
                width={90}
                height={17}
                sx={{ borderRadius: "7px" }}
              />
              <Grid className="grid_viewtab" sx={{ display: "flex" }}>
                <Skeleton
                  variant="rectangular"
                  width={20}
                  height={20}
                  style={{ marginRight: "15px" }}
                />

                <Skeleton
                  variant="rectangular"
                  width={20}
                  height={20}
                  style={{ marginRight: "15px" }}
                />
              </Grid>
            </>
          ) : (
            <>
              <Typography className="grpHeading">
                Groups
                {`(${groupCount})`}
              </Typography>
              <Grid className="grid_viewtab">
                <Button
                  variant="contained"
                  onClick={handleGridViewClick}
                  startIcon={<GridViewIcon />}
                  style={{
                    backgroundColor: isGridView ? "#119BFF" : "transparent",
                    color: isGridView ? "white" : "#708090",
                    boxShadow: "unset",
                    minWidth: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2px 0 2px 2px",
                    marginRight: "15px",
                  }}
                ></Button>

                <Button
                  variant="contained"
                  onClick={handleListViewClick}
                  startIcon={<FormatListBulletedIcon />}
                  style={{
                    backgroundColor: !isGridView ? "#119BFF" : "transparent",
                    color: !isGridView ? "white" : "#708090",
                    boxShadow: "unset",
                    minWidth: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "2px 0 2px 2px",
                  }}
                ></Button>
              </Grid>
            </>
          )}
        </Grid>

        {isLoadingGroupView ? (
          <Grid container className="mt-1" spacing={2}>
            {isGridView ? (
              <>
                {placeholderArray.map((result, index) => {
                  return (
                    <Grid item key={index} xs={12} sm={6} md={3} lg={2} xl={2}>
                      <Card className="cardGroup_shadow_loader">
                        <CardActionArea
                          className="skeleton_card_hover"
                          sx={{ padding: "10px 20px" }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={140}
                            height={140}
                            className="group_img "
                            style={{
                              ...SkeletonColor,
                              marginBottom: "5px",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          />

                          <CardContent
                            className="text-center"
                            sx={{ height: "15px", padding: "14px" }}
                          >
                            <Skeleton
                              className="group_blw_name m-0 hideDot-h-gn  groupName"
                              variant="text"
                              width={110}
                              height={21}
                              sx={{ ...SkeletonColor, borderRadius: "8px" }}
                            />
                            <Skeleton
                              variant="text"
                              width={82}
                              height={21}
                              sx={{ ...SkeletonColor, borderRadius: "8px" }}
                            />
                          </CardContent>

                          <CardContent className="text-center">
                            <Skeleton
                              variant="text"
                              width={75}
                              height={23}
                              sx={{
                                ...SkeletonColor,
                                marginLeft: "15px",
                                borderRadius: "8px",
                              }}
                            />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12} // Each card takes up 2 out of 12 columns for medium screens
                lg={12}
                xl={12}
                sx={{ paddingLeft: "2%" }}
              >
                <Grid>
                  <TableContainer className=" " component={Paper}>
                    <Table
                      sx={{ minWidth: 500 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {groupUser && groupUser.length > 0 ? (
                          groupUser.map((result, index) => {
                            const imageUrl = result.groupIcon;
                            const expectedFileName =
                              "20231024153135_no_image.jpg";
                            const url = new URL(imageUrl);
                            // Extract the filename from the pathname
                            const pathParts = url.pathname.split("/");
                            const fileName = pathParts[pathParts.length - 1];
                            const randomColor = getRandomColor(result.name);
                            return (
                              <Card sx={{ marginBottom: "16px" }}>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                  onClick={() => handleTableRowClick(result.id)}
                                  className="row_hover_group cur-pointer"
                                >
                                  <TableCell sx={{ border: "none" }}>
                                    <TableRow className="fs-14 ">
                                      <TableCell
                                        className="group_img"
                                        sx={{ border: "none" }}
                                      >
                                        {fileName === expectedFileName ? (
                                          <>
                                            <Avatar
                                              className="home_imglist"
                                              style={{
                                                width: "58px",
                                                borderRadius: "9px",
                                                height: "58px",
                                                fontSize: "10px",
                                                backgroundColor: randomColor,
                                                display: "flex",
                                                alignItems: "center",
                                                border: hoveredStates[index]
                                                  ? "2px solid white"
                                                  : "",
                                                boxShadow: hoveredStates[index]
                                                  ? "0px 0px 2px 1px #white"
                                                  : "",
                                              }}
                                            >
                                              {" "}
                                              {result.name
                                                ? result.name
                                                    .split(" ")
                                                    .map(
                                                      (
                                                        word: any,
                                                        index: number
                                                      ) =>
                                                        index < 2
                                                          ? word
                                                              .charAt(0)
                                                              .toUpperCase()
                                                          : ""
                                                    )
                                                    .join("")
                                                : ""}
                                            </Avatar>
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              alt="image"
                                              style={{
                                                width: "58px",
                                                borderRadius: "9px",
                                                height: "58px",
                                                // Background glow while hovering
                                                border: hoveredStates[index]
                                                  ? "2px solid white"
                                                  : "",
                                                boxShadow: hoveredStates[index]
                                                  ? "0px 0px 2px 1px #white"
                                                  : "",
                                              }}
                                              src={
                                                result.groupIcon
                                                  ? `${imagehttp}${result.groupIcon}`
                                                  : `..${subPath}/assets/images/Layer_1.png`
                                              }
                                            />
                                          </>
                                        )}
                                      </TableCell>

                                      <TableRow
                                        sx={{
                                          color: "color: #353535",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {result.name}
                                      </TableRow>

                                      <TableRow
                                        className="hideDotDouble"
                                        sx={{
                                          color: "#708090",
                                          fontSize: "13px",
                                          width: "400px",
                                          marginTop: "5px",
                                        }}
                                        title={result.description}
                                      >
                                        {result.description}
                                      </TableRow>
                                    </TableRow>
                                  </TableCell>

                                  <TableCell
                                    className="colorBlack assetFF"
                                    sx={{
                                      border: "none",
                                      fontSize: "13px",
                                      fontWeight: "600px",
                                      color:
                                        "var(--components-switch-slide-fill, #000)",
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    {result.assetCount} Assets
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      border: "none",

                                      color: "#708090",
                                    }}
                                    component="th"
                                    scope="row"
                                    className="avatar"
                                  >
                                    <Avatar.Group
                                      maxCount={4}
                                      maxPopoverTrigger="click"
                                      size="large"
                                      maxStyle={{
                                        color: "#fff",

                                        backgroundColor: "#A0D7FF",

                                        cursor: "pointer",
                                      }}
                                    >
                                      {result.members
                                        .slice(0, maxCount)
                                        .map(
                                          (
                                            memberProfile: any,
                                            index: number
                                          ) => (
                                            <Tooltip
                                              title={
                                                memberProfile.firstName +
                                                " " +
                                                memberProfile.lastName
                                              }
                                              key={index}
                                            >
                                              <Avatar
                                                alt={memberProfile.name}
                                                key={index} // Ensure that a unique key is provided
                                                src={`${imagehttp}${memberProfile.profilePicture}`}
                                              />
                                            </Tooltip>
                                          )
                                        )}
                                    </Avatar.Group>
                                    <Avatar.Group size="large">
                                      {/* Use hiddenCount within the map function */}
                                      {result.members.length > maxCount && (
                                        <Avatar
                                          style={{
                                            backgroundColor: "#A0D7FF",
                                            color:
                                              "var(--components-switch-slide-fill, #000)",
                                            marginLeft: "-5px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          +{result.members.length - maxCount}
                                        </Avatar>
                                      )}
                                    </Avatar.Group>
                                  </TableCell>
                                </TableRow>
                              </Card>
                            );
                          })
                        ) : (
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#708090",
                            }}
                          >
                            <p>No Data Found</p>
                          </Typography>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : (
          <Box id="groupScroll">
            <InfiniteScroll
              dataLength={isGridView ? group.length : groupUser.length}
              next={handleScrollGroup}
              hasMore={hasMore}
              scrollableTarget="groupScroll"
              className=""
              loader={
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#119BFF",
                    }}
                    size={20}
                  />
                </h6>
              }
              endMessage={
                <p className="fs-12 noMore">
                  <b>No more data found...</b>
                </p>
              }
            >
              <Grid container className="mt-1 " spacing={2}>
                {isGridView ? (
                  <>
                    {group.map((result, index) => {
                      const imageUrl = result.groupIcon;
                      const expectedFileName = "20231024153135_no_image.jpg";
                      const url = new URL(imageUrl);
                      // Extract the filename from the pathname
                      const pathParts = url.pathname.split("/");
                      const fileName = pathParts[pathParts.length - 1];
                      const randomColor = getRandomColor(result.name);

                      return (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={6}
                          md={3}
                          lg={2}
                          xl={2}
                        >
                          <Card className="cardGroup_shadow">
                            <CardActionArea
                              onClick={() => handleTableRowClick(result.id)}
                              className={`card_hover  ${
                                hoveredStates[index] ? "hovered" : ""
                              }`}
                              onMouseEnter={() => handleCardMouseEnter(index)}
                              onMouseLeave={() => handleCardMouseLeave(index)}
                              sx={{
                                padding: "10px 20px",
                              }}
                            >
                              {fileName === expectedFileName ? (
                                <>
                                  <Avatar
                                    className="home_img "
                                    style={{
                                      width: "100%",
                                      backgroundColor: randomColor,
                                      display: "flex",
                                      alignItems: "center",
                                      fontSize: "50px",
                                      border: hoveredStates[index]
                                        ? "2px solid white"
                                        : "",
                                      boxShadow: hoveredStates[index]
                                        ? "0px 0px 2px 1px #white"
                                        : "",
                                    }}
                                  >
                                    {" "}
                                    {result.name
                                      ? result.name
                                          .split(" ")
                                          .map((word: any, index: number) =>
                                            index < 2
                                              ? word.charAt(0).toUpperCase()
                                              : ""
                                          )
                                          .join("")
                                      : ""}
                                  </Avatar>
                                </>
                              ) : (
                                <>
                                  <CardMedia
                                    component="img"
                                    className="home_img"
                                    style={{
                                      // Background glow while hovering
                                      border: hoveredStates[index]
                                        ? "2px solid white"
                                        : "",
                                      boxShadow: hoveredStates[index]
                                        ? "0px 0px 2px 1px #white"
                                        : "",
                                    }}
                                    src={
                                      result.groupIcon
                                        ? `${imagehttp}${result.groupIcon}`
                                        : `..${subPath}/assets/images/Layer_1.png`
                                    }
                                  />
                                </>
                              )}
                              <CardContent
                                className="text-center grpView_cnetre groupcard"
                                sx={{
                                  height: "30px",
                                  padding: "8px",
                                }}
                              >
                                <Typography
                                  className={`group_blw_name m-0 hideDot-h-gn  groupName 
                                                    ${
                                                      hoveredStates[index]
                                                        ? "hoverTextIn"
                                                        : "hoverTextOut"
                                                    }`}
                                  sx={{
                                    color: "#353535",

                                    padding: "0px",
                                  }}
                                >
                                  {result.name}
                                </Typography>
                              </CardContent>
                              <CardContent className="text-center grpView_cnetre">
                                <button
                                  className={`asset_home nowrap fs-14  `}
                                  style={{ fontWeight: 500 }}
                                >
                                  {result.assetCount} Assets
                                </button>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ paddingLeft: "2%", width: "100%" }}
                  >
                    <Grid sx={{ width: "100%" }}>
                      <TableContainer className=" " component={Paper}>
                        <Table
                          sx={{
                            width: "100%",
                          }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableBody sx={{ width: "100%" }}>
                            {isLoadingGroupList ? (
                              <>
                                {placeholderArray.map((result, index) => (
                                  <Card
                                    key={index}
                                    sx={{
                                      marginBottom: "16px",
                                      width: "100%",
                                      boxShadow: "none",
                                      backgroundColor: "#E6E6E6",
                                    }}
                                    className="row_hover_groupList"
                                  >
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        width: "100%",
                                      }}
                                      className=" cur-pointer"
                                    >
                                      <TableCell
                                        sx={{ border: "none" }}
                                        className="td1width"
                                      >
                                        <Skeleton
                                          variant="circular"
                                          width={58}
                                          height={58}
                                          style={{
                                            ...SkeletonColor,
                                            borderRadius: "2px",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell className="d-flex align-items-center td2width">
                                        <Skeleton
                                          variant="text"
                                          width={120}
                                          height={20}
                                          style={{
                                            ...SkeletonColor,
                                            marginBottom: "10px",
                                            borderRadius: "8px",
                                          }}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width={200}
                                          height={20}
                                          style={{
                                            ...SkeletonColor,
                                            borderRadius: "5px",
                                          }}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width={180}
                                          height={20}
                                          style={{
                                            ...SkeletonColor,
                                            borderRadius: "5px",
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        className="colorBlack assetFF td3width"
                                        sx={{
                                          minWidth: "70%",
                                          maxWidth: "70%",
                                          border: "none",
                                          fontSize: "13px",
                                          fontWeight: "600px",
                                          color:
                                            "var(--components-switch-slide-fill, #000)",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        <Skeleton
                                          variant="text"
                                          width={50}
                                          height={20}
                                          sx={{ ...SkeletonColor }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          minWidth: "15%",
                                          color: "#708090",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="avatar td4width"
                                      >
                                        <Avatar.Group
                                          maxCount={4}
                                          maxPopoverTrigger="click"
                                          size="large"
                                          maxStyle={{
                                            color: "#fff",
                                            backgroundColor: "#A0D7FF",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {[1, 2, 3, 4].map((index) => (
                                            <Skeleton
                                              key={index}
                                              variant="circular"
                                              width={40}
                                              height={40}
                                              style={{
                                                ...SkeletonColor,
                                                marginLeft: "-9px",
                                              }}
                                            />
                                          ))}
                                        </Avatar.Group>
                                        <Avatar.Group size="large">
                                          <Skeleton
                                            variant="circular"
                                            width={40}
                                            height={40}
                                            style={{
                                              ...SkeletonColor,
                                              marginLeft: "-9px",
                                            }}
                                          />
                                        </Avatar.Group>
                                      </TableCell>
                                    </TableRow>
                                  </Card>
                                ))}
                              </>
                            ) : (
                              groupUser.map((result, index) => {
                                const imageUrl = result.groupIcon;
                                const expectedFileName =
                                  "20231024153135_no_image.jpg";
                                const url = new URL(imageUrl);
                                const pathParts = url.pathname.split("/");
                                const fileName =
                                  pathParts[pathParts.length - 1];
                                const randomColor = getRandomColor(result.name);
                                return (
                                  <Card
                                    sx={{
                                      marginBottom: "16px",
                                      width: "100%",
                                      boxShadow: "none",
                                    }}
                                    className="row_hover_groupList"
                                  >
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        width: "100%",
                                        height: "85px",
                                      }}
                                      onClick={() =>
                                        handleTableRowClick(result.id)
                                      }
                                      className=" cur-pointer"
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                        }}
                                        className="td1width"
                                      >
                                        {fileName === expectedFileName ? (
                                          <>
                                            <Avatar
                                              className="home_imglist"
                                              style={{
                                                width: "58px",
                                                borderRadius: "9px",
                                                height: "58px",
                                                fontSize: "10px",
                                                backgroundColor: randomColor,
                                                display: "flex",
                                                alignItems: "center",
                                                border: hoveredStates[index]
                                                  ? "2px solid white"
                                                  : "",
                                                boxShadow: hoveredStates[index]
                                                  ? "0px 0px 2px 1px #white"
                                                  : "",
                                              }}
                                            >
                                              {" "}
                                              {result.name
                                                ? result.name
                                                    .split(" ")
                                                    .map(
                                                      (
                                                        word: any,
                                                        index: number
                                                      ) =>
                                                        index < 2
                                                          ? word
                                                              .charAt(0)
                                                              .toUpperCase()
                                                          : ""
                                                    )
                                                    .join("")
                                                : ""}
                                            </Avatar>
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              style={{
                                                width: "58px",
                                                borderRadius: "9px",
                                                height: "58px",
                                                // Background glow while hovering
                                                border: hoveredStates[index]
                                                  ? "2px solid white"
                                                  : "",
                                                boxShadow: hoveredStates[index]
                                                  ? "0px 0px 2px 1px #white"
                                                  : "",
                                              }}
                                              src={
                                                result.groupIcon
                                                  ? `${imagehttp}${result.groupIcon}`
                                                  : `..${subPath}/assets/images/Layer_1.png`
                                              }
                                            />
                                          </>
                                        )}
                                      </TableCell>
                                      <TableCell className="d-flex align-items-center td2width grplistname">
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "16px",
                                            minWidth: "40%",
                                          }}
                                          className="hideDotSingleLine"
                                        >
                                          {result.name}
                                        </span>

                                        <span
                                          className="hideDotDouble grplistname"
                                          style={{
                                            color: "#708090",
                                            fontSize: "14px",
                                            width: "500px",
                                            marginTop: "5px",
                                          }}
                                          title={result.description}
                                        >
                                          {result.description}
                                        </span>
                                      </TableCell>

                                      <TableCell
                                        className="colorBlack assetFF grplistname td3width"
                                        sx={{
                                          minWidth: "125px",
                                          border: "none",
                                          fontSize: "14px",
                                          fontWeight: "600px",
                                          color:
                                            "var(--components-switch-slide-fill, #000)",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        {result.assetCount} Assets
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          border: "none",
                                          minWidth: "15%",
                                          color: "#708090",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="avatar avatarName td4width"
                                      >
                                        <Avatar.Group
                                          maxCount={4}
                                          maxPopoverTrigger="click"
                                          size="large"
                                          maxStyle={{
                                            color: "#fff",

                                            backgroundColor: "#A0D7FF",

                                            cursor: "pointer",
                                          }}
                                        >
                                          {result.members
                                            .slice(0, maxCount)
                                            .map(
                                              (
                                                memberProfile: any,
                                                index: number
                                              ) => (
                                                <Tooltip
                                                  title={
                                                    memberProfile.firstName +
                                                    " " +
                                                    memberProfile.lastName
                                                  }
                                                  key={index}
                                                >
                                                  <Avatar
                                                    alt={memberProfile.name}
                                                    key={index} // Ensure that a unique key is provided
                                                    src={`${imagehttp}${memberProfile.profilePicture}`}
                                                  />
                                                </Tooltip>
                                              )
                                            )}
                                        </Avatar.Group>
                                        <Avatar.Group size="large">
                                          {/* Use hiddenCount within the map function */}
                                          {result.members.length > maxCount && (
                                            <Avatar
                                              style={{
                                                backgroundColor: "#A0D7FF",
                                                color:
                                                  "var(--components-switch-slide-fill, #000)",
                                                marginLeft: "-5px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              +
                                              {result.members.length - maxCount}
                                            </Avatar>
                                          )}
                                        </Avatar.Group>
                                      </TableCell>
                                    </TableRow>
                                  </Card>
                                );
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </InfiniteScroll>
          </Box>
        )}
      </Box>
      {/* </Grid>
      </Box> */}
    </>
  );
};

export default GroupView;
