import { decodeToken } from "../../Constants/Global";
import { toast } from "react-toastify";
import UseAxios from "../../Constants/UseAxios";

const getOrgImage = async () => {
  const api = UseAxios();
  try {
    return await api
      .get(`companies/getall?companyId=${decodeToken().companyId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response.data.data; // Assuming you want to return the data
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  } catch (error) {}
};

export default getOrgImage;
