import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
// import "./sidebar.css";

const Sidebar: React.FC = () => {
  const isLoadingGroup = true;

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  return (
    <>
      <Grid item xs={12} sm={5} md={3} lg={3} xl={2} className="sidebar">
        {isLoadingGroup ? (
          <Card
            className="sidebarcard loader_hgt"
            sx={{ backgroundColor: "#E6E6E6" }}
          >
            <CardContent
              className="sidecard-scroll GroupScroll b-l-div "
              sx={{ borderRadius: "8px" }}
            >
              <Grid
                container
                className="fav-d "
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  sx={{ display: "flex", alignItems: "self-end" }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "unset",
                      paddingBottom: "18px",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={50}
                      height={24}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xl={6} lg={6} sm={6} sx={{ paddingBottom: "18px" }}>
                  <Skeleton
                    variant="text"
                    width={40}
                    height={12}
                    sx={{
                      ...SkeletonColor,
                      float: "right",
                      margin: "0",
                      padding: "5px 0 0 5px",
                      borderRadius: "7px",
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="groupScroll">
                <Grid container className="row_hover cur-pointer">
                  <Grid item xl={12} lg={12} sm={12}>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "25px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={70}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "25px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={30}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "25px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={100}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "26px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={80}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "26px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={80}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "4px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="circular"
                        width={20}
                        height={20}
                        style={{ ...SkeletonColor, marginRight: "8px" }}
                      />
                      <Skeleton
                        variant="text"
                        width={120}
                        height={22}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardActions>
              <Skeleton
                variant="rectangular"
                width={225}
                height={25}
                sx={{
                  ...SkeletonColor,
                  marginLeft: "5px",
                  borderRadius: "5px",
                }}
              />
            </CardActions>
          </Card>
        ) : null}
        {isLoadingGroup ? (
          <Card
            sx={{ mt: 2, backgroundColor: "#E6E6E6" }}
            className="sidecard-scroll-o-b sidecard-tag-load"
          >
            <CardContent className="sidecard-scroll hashTagScroll t-l-div">
              <Grid
                container
                className="fav-d"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  sx={{ display: "flex", alignItems: "self-end" }}
                >
                  <Grid sx={{ display: "flex", alignItems: "unset" }}>
                    <Skeleton
                      variant="text"
                      width={45}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "8px" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xl={6} lg={6} sm={6}>
                  <Skeleton
                    variant="text"
                    width={40}
                    height={12}
                    sx={{
                      ...SkeletonColor,
                      float: "right",
                      margin: "0",
                      padding: "5px 0 0 5px",
                      borderRadius: "7px",
                    }}
                  />
                </Grid>
              </Grid>
              <Box className="hashTagScroll">
                <Grid container className="row_hover cur-pointer">
                  <Grid item xl={12} lg={12} sm={12}>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "60px",
                        paddingTop: "35px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={170}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "23px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={170}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "23px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={170}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "23px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={90}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "15px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={190}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "15px",
                      }}
                      className=" br_5 "
                    >
                      <Skeleton
                        variant="text"
                        width={150}
                        height={18}
                        style={{ ...SkeletonColor, borderRadius: "7px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        ) : null}
      </Grid>
    </>
  );
};

export default Sidebar;
