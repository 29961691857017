import React, { ChangeEvent } from "react";
import { ElementStore, ReactFormBuilder } from "react-form-builder2";
import { Box, Grid, InputBase, TextField } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { subPath } from "../../../Constants/Global";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
import "./form.css";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import UseAxios from "../../../Constants/UseAxios";

interface DemobarProps {
  variables: any; // Change 'any' to the appropriate type as needed
}
interface DemobarState {
  data: any[];
  previewVisible: boolean;
  shortPreviewVisible: boolean;
  roPreviewVisible: boolean;
  title: string;
  description: string;
  user: null;
  maxLengthvalidation: number;
  maxLengthDescription: number;
}

export default class Demobar extends React.Component<
  DemobarProps,
  DemobarState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
      title: "",
      description: "",
      user: null,
      maxLengthvalidation: 32,
      maxLengthDescription: 150,
    };

    this._onUpdate = this._onChange.bind(this);
  }
  _onUpdate(data: any) {
    this.setState({ data });
  }

  componentDidMount() {
    (ElementStore as any).subscribe((state: any) => this._onUpdate(state.data));
  }

  handleChangetitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { maxLengthvalidation } = this.state;

    if (value.length <= maxLengthvalidation) {
      this.setState({ title: value });
    }
  };

  handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ description: e.target.value });
  };
  showPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  showShortPreview() {
    this.setState({
      shortPreviewVisible: true,
    });
  }

  showRoPreview() {
    this.setState({
      roPreviewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    });
  }

  _onChange(data: any) {
    console.log("onchange", data);
    this.setState({
      data,
    });
  }

  _onSubmit = async (data: any) => {
    let api = UseAxios();

    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }

    // Yup Validation
    const schema = Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().required(),
    });

    // Data to validate
    const { title } = this.state;
    const { description } = this.state;
    const dataToValidate = {
      title,
      description,
    };

    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async (response) => {
        try {
          const { title, description, data } = this.state;

          const requestData = {
            title: title,
            description: description,
            formData: data,
          };
          let response = await api.post(
            `template/create-template  `,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            // background: toast.error,
            autoClose: 1000,
          });
          setTimeout(() => {
            this.setState({ user: response.data });
          }, 1000);

          window.location.href = subPath + "/listofDynamicTemplate";

          // You can log the response from the API here
        } catch (error) {
          console.error("Error occurred during POST request:", error);
        }
      })
      .catch((validationErrors) => {
        for (let i = 0; i < validationErrors.inner.length; i++) {
          toast.error(
            validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        }
      });
  };

  render() {
    let { user } = this.state;
    const { title, description } = this.state;
    let modalClass = "modal";
    if (this.state.previewVisible) {
      modalClass += " show d-block";
    }

    let shortModalClass = "modal short-modal";
    if (this.state.shortPreviewVisible) {
      shortModalClass += " show d-block";
    }

    let roModalClass = "modal ro-modal";
    if (this.state.roPreviewVisible) {
      roModalClass += " show d-block";
    }
    const maxLength = 32;

    return (
      <DndProvider backend={HTML5Backend}>
        {user && <Navigate to="/listofDynamicTemplate" replace={true} />}
        <Helmet></Helmet>
        <Box className="Card_box assetStyles">
          <Box className="p-25">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <>
                  <InputBase
                    placeholder="Add Template Title"
                    className="a-title m-0"
                    name="title"
                    value={title}
                    inputProps={{
                      maxLength: maxLength,
                    }}
                    onChange={this.handleChangetitle}
                    sx={{ width: "100%" }}
                    style={{
                      color: "#708090",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  />
                  <span
                    id="titleError"
                    style={{ color: "red" }}
                    className="error"
                  ></span>
                  <TextField
                    fullWidth
                    name="description"
                    variant="standard"
                    margin="normal"
                    multiline
                    rows={2}
                    placeholder="Add Description"
                    value={description}
                    onChange={this.handleChangeDescription}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className="p-0 m-0"
                    sx={{
                      background: "#fff",
                      border: "1px solid #fff",
                      padding: "10px",
                      borderRadius: "8px",
                      "&:hover": {
                        border: "1px solid transparent", // Remove the border on hover
                      },
                    }}
                  />
                  <span
                    id="descriptionError"
                    style={{ color: "red" }}
                    className="error"
                  ></span>
                </>
              </Grid>

              <Grid item xs={4}>
                <button
                  type="button"
                  className="m-button  float-right"
                  style={{ cursor: "pointer" }}
                  // onClick={this.showPreview.bind(this)}

                  onClick={this._onSubmit}
                >
                  Create Template
                </button>
                <Link
                  to="/listofDynamicTemplate"
                  className="float-right cur-pointer"
                  style={{
                    // marginRight: "-80px",
                    border: "none",
                    background: "none",
                    verticalAlign: "middle",
                    marginTop: "5px",
                    color: "#708090",
                    fontSize: "15px",
                    fontWeight: "600",
                    textDecoration: "none",
                    marginRight: "50px",
                  }}
                >
                  Cancel
                </Link>
              </Grid>
            </Grid>
            {this.state.previewVisible && (
              <div className={modalClass}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <ReactFormGenerator
                      back_action="/"
                      back_name="Back"
                      action_name="Save"
                      form_action="/"
                      form_method="POST"
                      onSubmit={this._onSubmit}
                      variables={this.props.variables}
                      data={this.state.data}
                    /> */}

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                        onClick={this.closePreview.bind(this)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Grid>
              <ReactFormBuilder saveAlways={false} />
            </Grid>
          </Box>
        </Box>
      </DndProvider>
    );
  }
}
