export const TOGGLE_CHAT_VIEW = "TOGGLE_CHAT_VIEW";
export const TOGGLE_SINGLE_CHAT_VIEW = "TOGGLE_SINGLE_CHAT_VIEW";

export const toggleMultiChatView = () => ({
  type: TOGGLE_CHAT_VIEW,
});

export const toggleSingleChatView = () => ({
  type: TOGGLE_SINGLE_CHAT_VIEW,
});
