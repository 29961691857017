import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import { CardActionArea, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../../Layout/sidebar.css";
import "../Homepage/Home.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { decodeToken, imagehttp } from "../../../Constants/Global";
import CircularProgress from "@mui/material/CircularProgress";
import UseAxios from "../../../Constants/UseAxios";
import { Bronze, Gold, Silver } from "../../../Utils/LandingPageBadge";
import { useAppSelector } from "../../../Constants/hooks";

//Componented Start
const GroupView: React.FC = () => {
  const api = UseAxios();
  const companyId = decodeToken().companyId;
  const navigate = useNavigate();
  //states are Here
  const [members, setMembers] = useState<any[]>([]);
  const [memberCount, setMemberCount] = useState<number | null>(0);
  const [isLoadingMember, setIsLoadingMember] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const size = 20;
  const [hasMore, setHasMore] = useState(true);

  const { itemName } = useAppSelector((state: any) => state.search);

  useEffect(() => {
    fetchMemberData(1);
  }, [itemName]);

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  const fixedArrayLength = 18;
  const loaderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  const profileAsset = (id: string, assetStatus: string) => {
    navigate(`/profileAsset`, { state: { id, assetStatus } });
  };

  const fetchMemberData = async (type: number) => {
    let pageCount = page;
    if (type) {
      setIsLoadingMember(true);
      pageCount = 0;
      setPage(0);
      setHasMore(true);
    }

    try {
      const response = await api.get(
        `member/view?filter=${
          itemName != "" ? "true" : "false"
        }&title=${itemName}&companyId=${companyId}&page=${pageCount}&size=${size}`
      );
      if (response.data.status === true) {
        const newData = response.data.data;

        if (pageCount === 0) {
          setMembers(newData.memberResponse);
          setMemberCount(newData.memberCount ? newData.memberCount : 0);
          window.scrollTo(0, 0);
        } else {
          setMembers([...members, ...newData.memberResponse]);
        }
        setIsLoadingMember(false);
        if (size > newData.memberResponse.length) {
          setHasMore(false);
        }
        setPage(pageCount + 1);
      }
    } catch (error) {}
  };

  return (
    <Box className="center-grid ">
      {isLoadingMember ? (
        <Grid container justifyContent="space-between">
          <Skeleton variant="text" width={120} height={20} />
        </Grid>
      ) : (
        <Grid container justifyContent="space-between">
          <Typography className="grpHeading">
            Members {`(${memberCount})`}
          </Typography>
        </Grid>
      )}

      {isLoadingMember ? (
        <Grid container className="mt-1" spacing={2} id="grp_scroll">
          {loaderArray.map((index) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                xl={2}
                style={{ marginBottom: "10px" }}
                key={"load" + index}
              >
                <Card
                  sx={{ maxWidth: 345, borderRadius: "8px" }}
                  className="cardGroup_shadow_loader"
                >
                  <CardActionArea
                    sx={{
                      padding: "10px",
                    }}
                    className="memcard"
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "17px",
                        marginTop: "13px",
                      }}
                    >
                      <Skeleton
                        variant="circular"
                        className="member_img_load"
                        width={82}
                        height={82}
                        sx={{ ...SkeletonColor }}
                      />
                    </Grid>

                    <CardContent className="text-center member-card-data">
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width={128}
                          height={23}
                          sx={{ ...SkeletonColor, borderRadius: "8px" }}
                        />
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          paddingBottom: "5px",
                          marginTop: "-21px",
                          marginBottom: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="member-div"
                      >
                        <Skeleton
                          variant="text"
                          width={100}
                          height={23}
                          sx={{ ...SkeletonColor, borderRadius: "8px" }}
                        />
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Skeleton
                          variant="text"
                          width={110}
                          height={45}
                          sx={{ ...SkeletonColor, borderRadius: "18px" }}
                        />
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Grid id="grp_scroll">
          <InfiniteScroll
            dataLength={members.length}
            next={() => fetchMemberData(0)}
            hasMore={hasMore}
            scrollableTarget="grp_scroll"
            loader={
              <h6
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} />
              </h6>
            }
            endMessage={
              <p className="noMore fs-12">
                <b>No more results Found...</b>
              </p>
            }
          >
            <Grid container spacing={2} className="mt-5">
              {members && members.length > 0
                ? members.map((result) => {
                    const longText = `${result.firstName} ${result.lastName}`;
                    const maxLength = 10;

                    const shouldTruncate = longText.length > maxLength;
                    const fullName = shouldTruncate
                      ? `${longText.slice(0, maxLength)}...`
                      : longText;

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        xl={2}
                        style={{ marginBottom: "10px" }}
                        key={result.id}
                      >
                        <Card
                          sx={{ maxWidth: 345, borderRadius: "8px" }}
                          className="card_members"
                        >
                          <CardActionArea
                            sx={{
                              padding: "10px",
                            }}
                            className="memcard"
                          >
                            <CardMedia
                              component="img"
                              className="member_img"
                              image={`${imagehttp}${result.profilePicture}`}
                            />
                            <Box className="member_batch_s">
                              {(() => {
                                switch (result.badge) {
                                  case "Gold":
                                    return <Gold />;
                                  case "Silver":
                                    return <Silver />;
                                  default:
                                    return <Bronze />;
                                }
                              })()}
                            </Box>
                            <CardContent className="text-center member-card-data">
                              <Typography
                                gutterBottom
                                component="div"
                                title={shouldTruncate ? longText : ""}
                                sx={{
                                  color: "#353535",
                                  fontSize: "16px",
                                  width: "100%",
                                  marginTop: "-10px",
                                }}
                                className="hideDot colorBlackB mem-name"
                              >
                                {fullName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                className="member-role hideDotName"
                                sx={{
                                  fontSize: "13px",
                                  maxHeight: "7vh",
                                  minHeight: "7vh",
                                }}
                                title={result.designation}
                              >
                                {result.designation}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "15px",
                                  paddingBottom: "5px",
                                  color:
                                    "var(--components-switch-slide-fill, #000)",
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  marginTop: "-21px",
                                }}
                                className="member-div"
                              >
                                {result.roles}
                                {/* Marketing */}
                              </Typography>
                              <Typography
                                className="color_119B fs-12 m-as-btn"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                  textDecoration: "underline",
                                  color: "#708090",
                                }}
                                onClick={() =>
                                  profileAsset(result.id, result.assetStatus)
                                }
                              >
                                {result.assetStatus === "unread" ? (
                                  <span>
                                    <button
                                      className=" roundedBtn r-ur-btn"
                                      aria-label="Unread"
                                    >
                                      {result.assetCount < 10 &&
                                      result.assetCount !== 0
                                        ? "0"
                                        : ""}
                                      {result.assetCount} Unread
                                    </button>
                                  </span>
                                ) : (
                                  <span>
                                    <button
                                      className="roundedBtn r-ur-btn"
                                      aria-label="Assets"
                                    >
                                      {result.assetCount < 10 &&
                                      result.assetCount !== 0
                                        ? "0"
                                        : ""}
                                      {result.assetCount} Assets
                                    </button>
                                  </span>
                                )}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })
                : []}
            </Grid>
          </InfiniteScroll>
        </Grid>
      )}
    </Box>
  );
};

export default GroupView;
