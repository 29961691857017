import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { subPath } from "../../../../../Constants/Global";

interface Person {
  id: number;
  src: string;
  UserName: string;
  Department: string;
  Organisation: string;
}

const CircleSelectPerson: React.FC = () => {
  const initialPersons: Person[] = [
    {
      id: 1,
      UserName: "Alice",
      Department: "IOT",
      Organisation: "CITPL",
      src: "/assets/images/images/view_img1.png",
    },
    {
      id: 2,
      UserName: "Bob",
      Department: "IOT",
      Organisation: "CITPL",
      src: "/assets/images/images/view_img2.png",
    },
    {
      id: 3,
      UserName: "Charlie",
      Organisation: "CITPL",
      Department: "IOT",
      src: "/assets/images/images/user_profile_img.png",
    },
  ];

  const [selectedPersons, setSelectedPersons] = useState<Person[]>([]);
  const [unselectedPersons, setUnselectedPersons] =
    useState<Person[]>(initialPersons);

  const handleSelectPerson = (person: Person) => {
    const updatedSelected = [...selectedPersons, person];
    setSelectedPersons(updatedSelected);

    const updatedUnselected = unselectedPersons.filter(
      (p) => p.id !== person.id
    );
    setUnselectedPersons(updatedUnselected);
  };

  const handleDeselectPerson = (person: Person) => {
    const updatedUnselected = [...unselectedPersons, person];
    setUnselectedPersons(updatedUnselected);

    const updatedSelected = selectedPersons.filter((p) => p.id !== person.id);
    setSelectedPersons(updatedSelected);
  };

  const handleUserSelection = (event: any, newValue: Person[]) => {
    setSelectedPersons(newValue);
    setUnselectedPersons(
      initialPersons.filter((person) => !newValue.includes(person))
    );
  };

  return (
    <>
      <Grid item>
        <Grid item sx={{ paddingTop: "15px" }}>
          <Box className=" ">
            <div>
              <Autocomplete
                className="search_box_mem d-flex"
                sx={{
                  alignItems: "center",
                  "& .MuiAutocomplete-inputRoot": {
                    "&:hover": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                }}
                multiple
                options={unselectedPersons}
                value={selectedPersons}
                onChange={handleUserSelection}
                getOptionLabel={(option) => option.UserName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search members"
                    variant="outlined"
                    size="small"
                    sx={{
                      background: "#F5F5F5",
                      boxSizing: "border-box",
                      height: "35px",
                      borderRadius: "5px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={`..${subPath}/assets/images/images/searchIocn_chart.png`}
                            alt="Search"
                            style={{ width: "18px", height: "18px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid
        mt={1}
        container
        sx={{
          height: "45px",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            03 Selected
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          <button className="add_button_circle">Add</button>
        </Grid>
      </Grid>
      <Grid item>
        {selectedPersons.map((person) => (
          <Grid container sx={{ marginTop: "10px" }} key={person.id}>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Avatar
                alt="profile"
                style={{ width: "33px", height: "33px", borderRadius: "6px" }}
                src={person.src}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={7}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                className="User_Details_circle"
                title={`${person.UserName} / ${person.Department} / ${person.Organisation}`}
              >
                {person.UserName}
                <span className="special_char">/</span>
                {person.Department}
                <span className="special_char">/</span>
                {person.Organisation}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              onClick={() => handleDeselectPerson(person)}
            >
              <div className="CheckBox_container">
                <div className="round_box">
                  <input
                    className="checkBox_input"
                    type="checkbox"
                    checked
                    id="checkbox"
                  />
                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          className="Recents_lable"
          sx={{ margin: "10px 0px 10px 0px" }}
        >
          Suggested
        </Grid>
        {unselectedPersons.map((person) => (
          <Grid container sx={{ marginTop: "10px" }} key={person.id}>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                alt="profile"
                style={{ width: "33px", height: "33px", borderRadius: "6px" }}
                src={person.src}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={7}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography className="User_Details_circle">
                {person.UserName}
                <span className="special_char">/</span>
                {person.Department}
                <span className="special_char">/</span>
                {person.Organisation}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              onClick={() => handleSelectPerson(person)}
            >
              <div className="CheckBox_container">
                <div className="roundBox_unselect">
                  <input
                    className="checkBoxinput_unselect"
                    type="checkbox"
                    id={`checkbox-${person.id}`}
                  />
                  <label
                    className="Checkboxinput_lable"
                    htmlFor={`checkbox-${person.id}`}
                  ></label>
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CircleSelectPerson;
