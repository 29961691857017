import { Tooltip } from "@mui/material";

export const Bronze = () => {
  return (
    <div style={{ padding: "5px" }}>
      <div
        style={{
          position: "relative",
          bottom: "63px",
          float: "right",
          left: "4px",
          zIndex: "10",
        }}
      >
        <Tooltip title="Bronze">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 26"
            fill="none"
            style={{
              filter: "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.3))",
            }}
          >
            <path
              d="M27.5168 9.832L18.6018 7.94711L14.0509 0L9.49999 7.94711L0.584961 9.832L6.7151 16.5735L5.74719 25.6413L14.0509 21.9055L22.3546 25.6413L21.3867 16.5735L27.5168 9.832Z"
              fill="url(#paint0_linear_7363_8804)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7363_8804"
                x1="14.0084"
                y1="0.314148"
                x2="14.0084"
                y2="26.2102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CB6F1B" />
                <stop offset="0.5" stopColor="#E8B481" />
                <stop offset="1" stopColor="#CD7F32" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "53px",
          position: "relative",
          float: "right",
          left: "18.5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="20"
          viewBox="0 0 12 21"
          fill="none"
          style={{
            filter: "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.3))",
          }}
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};

export const Silver = () => {
  return (
    <div style={{ padding: "5px" }}>
      <div
        style={{
          position: "relative",
          bottom: "63px",
          float: "right",
          left: "4px",
          zIndex: "10",
        }}
      >
        <Tooltip title="Silver">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 26"
            fill="none"
          >
            <path
              d="M27.5168 9.832L18.6018 7.94711L14.0509 0L9.49999 7.94711L0.584961 9.832L6.7151 16.5735L5.74719 25.6413L14.0509 21.9055L22.3546 25.6413L21.3867 16.5735L27.5168 9.832Z"
              fill="url(#paint0_linear_520_14006)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_520_14006"
                x1="14.0084"
                y1="0.314148"
                x2="14.0084"
                y2="26.2102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B7B7B7" />
                <stop offset="0.5" stopColor="white" />
                <stop offset="1" stopColor="#CCCBC8" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "53px",
          position: "relative",
          float: "right",
          left: "18.5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="20"
          viewBox="0 0 12 21"
          fill="none"
          style={{
            marginLeft: "3px",
          }}
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};

export const Gold = () => {
  return (
    <div style={{ padding: "5px" }}>
      <div
        className="memb1111"
        style={{
          position: "relative",
          bottom: "63px",
          float: "right",
          left: "4px",
          zIndex: "10",
        }}
      >
        <Tooltip title="Gold">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 28 27"
            fill="none"
          >
            <path
              d="M27.5168 10.332L18.6018 8.44711L14.0509 0.5L9.49999 8.44711L0.584961 10.332L6.7151 17.0735L5.74719 26.1413L14.0509 22.4055L22.3546 26.1413L21.3867 17.0735L27.5168 10.332Z"
              fill="url(#paint0_linear_5551_23163)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5551_23163"
                x1="14.0084"
                y1="0.814148"
                x2="14.0084"
                y2="26.7102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD700" />
                <stop offset="0.5" stopColor="#FFE591" />
                <stop offset="1" stopColor="#FFD700" />
              </linearGradient>
            </defs>
          </svg>
        </Tooltip>
      </div>

      <div
        style={{
          bottom: "53px",
          position: "relative",
          float: "right",
          left: "18.5px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="20"
          viewBox="0 0 12 21"
          fill="none"
          style={{
            marginLeft: "3px",
          }}
        >
          <path
            d="M11.4334 3.74756V20.9493L7.15417 16.2116L6.08437 12.8664L5.01456 16.2116L0.735352 20.9493V3.74756H11.4334Z"
            fill="#9CC8F1"
          />
          <path
            d="M11.4339 3.74756V20.9493L7.0188 16.2116L5.91504 12.8664V3.74756H11.4339Z"
            fill="#119BFF"
          />
          <path
            d="M7.44406 0.690918V19.1832L6.05162 17.4172L4.65918 19.1832V0.690918H7.44406Z"
            fill="#119BFF"
          />
          <path
            d="M7.44284 0.453125V19.1832L6.06738 17.4002V0.453125H7.44284Z"
            fill="#9CC8F1"
          />
        </svg>
      </div>
    </div>
  );
};
