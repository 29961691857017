import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import TableBody from "@mui/material/TableBody";
import { imagehttp, subPath } from "../../../Constants/Global";
import { Breadcrumbs } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import getOrgImage from "../../Fetch/Organization";
import "../Homepage/Home.css";
import Typography from "@mui/material/Typography";
import eventEmitter from "../../../Constants/EventEmiter";
import { CardMedia, CircularProgress } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import UseAxios from "../../../Constants/UseAxios";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { VaultIcon } from "../../../Utils/ProfilePage";
import { DefaultImg } from "../../../Utils/DefaultImg";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName, setSearchValue } from "../../../Redux/Search/action";
interface assetsInfo {
  assetId: string | null;
  totalGram: number | null;
  commentCount: number | null;
  viewCount: number | null;
  hashTag: string | null;
  description: string | null;
  title: string | null;
  asset: any;
  groupicon: File;
  groupname: string | null;
  vaultname: string | null;
  groupDetail: any;
}

const View: React.FC = () => {
  const api = UseAxios();
  const navigate = useNavigate();
  const [createdBy, setCreatedBy] = useState<string>();
  const [assets, setAssetsInfo] = useState<assetsInfo[]>([]);
  const [userFirstName, setUserFirstName] = useState<string>();
  const [roles, setRoles] = useState<string>();
  const [designation, setDesignation] = useState<string>();
  const [allBtnClass, setAllBtnClass] = useState("all_btn");
  const [readBtnClass, setReadBtnClass] = useState("");
  const [unReadBtnClass, setUnReadBtnClass] = useState("");
  const [isLoadingAllAsset, setLoadingAllAsset] = useState(true);
  const [isLoadingReadAsset, setLoadingReadAsset] = useState(false);
  const location = useLocation();
  const AssetStatus = location.state.assetStatus;
  const id = location.state.id;
  const readBoxRef = useRef<HTMLImageElement>(null);
  const size = 15;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [tabType, setTabType] = useState("all");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (AssetStatus === "unread") {
      setLoadingAllAsset(false);
    }
    dispatch(setItemName(""));
    const field = document.getElementById("unreadAsset");
    if (AssetStatus === "read" && readBoxRef.current) {
      userProfile(1);
      readBoxRef.current.click();
    } else if (AssetStatus === "unread" && field) {
      setHasMore(true);
      userProfileReadUnRead("unread", 1);
      setTabType("unread");

      const inputField1 = document.getElementsByClassName("tabclr2");
      inputField1?.[0]?.classList.add("all_btn");
      const inputField2 = document.getElementsByClassName("tabclr1");
      inputField2?.[0]?.classList.remove("all_btn");
    }
    setCreatedBy(id);
    fetchOrgData();
    if (createdBy !== undefined) {
      getUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createdBy,
    AssetStatus,
    setAllBtnClass,
    setReadBtnClass,
    setUnReadBtnClass,
  ]);

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  const fixedArrayLength = 7;
  const placeholderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  //Noimage
  const getFileNameFromImageUrl = (imageUrl: any) => {
    if (typeof imageUrl === "string" && imageUrl) {
      const url = new URL(imageUrl);
      const pathParts = url.pathname.split("/");
      return pathParts[pathParts.length - 1];
    }
    return "";
  };

  const expectedFileName = "20231024153135_no_image.jpg";

  //get asset and group details
  const userProfile = async (type: number) => {
    if (createdBy !== undefined) {
      let pageCount = page;
      if (type === 1) {
        pageCount = 0;
        setPage(0);
      }
      const inputFields = document.getElementsByClassName("tabclr1");
      inputFields?.[0]?.classList.add("all_btn");

      const inputFields1 = document.getElementsByClassName("tabclr2");
      inputFields1?.[0]?.classList.remove("all_btn");
      const inputFields2 = document.getElementsByClassName("tabclr3");
      inputFields2?.[0]?.classList.remove("all_btn");
      try {
        const response = await api.get(
          `member/assets?userId=${createdBy}&size=${size}&page=${pageCount}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status === true) {
          setLoadingAllAsset(false);
          const assetData = response.data.data;
          if (assetData.length > 0) {
            // Reorder the asset content for each element in assetData
            const typeOrder = ["Video", "Image", "Audio"];
            // Reorder the asset content for each element in assetData
            const customSort = (a: any, b: any) => {
              const typeAIndex = typeOrder.indexOf(a.fieldType);
              const typeBIndex = typeOrder.indexOf(b.fieldType);
              // Move types not in the order to the end
              if (typeAIndex === -1) return 1;
              if (typeBIndex === -1) return -1;
              return typeAIndex - typeBIndex;
            };
            assetData.forEach((assetElement: any) => {
              if (
                assetElement.asset.assetContent &&
                assetElement.asset.assetContent.length > 0
              ) {
                assetElement.asset.assetContent.sort(customSort);
              }
            });
          }
          if (pageCount === 0) {
            setAssetsInfo(assetData);
          } else {
            setAssetsInfo((prevAssets) => [...prevAssets, ...assetData]);
          }

          if (assetData.length < size) {
            setHasMore(false);
          }
          setPage(pageCount + 1);
        }
      } catch (error) {
        // Handle any errors here
        console.error("Error in userProfile request:", error);
      }
    }
  };

  const callScroll = () => {
    if (tabType === "all") {
      userProfile(0);
    } else if (tabType === "unread") {
      userProfileReadUnRead("unread", 0);
    } else {
      userProfileReadUnRead("read", 0);
    }
  };

  const userProfileReadUnRead = async (newData: string, type: number) => {
    let pageCount = page;
    if (type === 1) {
      pageCount = 0;
      setPage(0);
      setLoadingReadAsset(true);
    }
    if (newData === "read") {
      const inputFields = document.getElementsByClassName("tabclr3");
      inputFields?.[0]?.classList.add("all_btn");
      const inputFields1 = document.getElementsByClassName("tabclr1");
      inputFields1?.[0]?.classList.remove("all_btn");
      const inputFields2 = document.getElementsByClassName("tabclr2");
      inputFields2?.[0]?.classList.remove("all_btn");
    } else {
      const inputFields = document.getElementsByClassName("tabclr2");
      inputFields?.[0]?.classList.add("all_btn");
      const inputFields1 = document.getElementsByClassName("tabclr3");
      inputFields1?.[0]?.classList.remove("all_btn");
      const inputFields2 = document.getElementsByClassName("tabclr1");
      inputFields2?.[0]?.classList.remove("all_btn");
    }
    try {
      const response = await api.get(
        `member/assets-read-unread?userId=${createdBy}&type=${newData}&page=${pageCount}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setLoadingReadAsset(false);
        const assetData = response.data.data;
        if (assetData.length > 0) {
          // Reorder the asset content for each element in assetData
          const typeOrder = ["Video", "Image", "Audio"];
          // Reorder the asset content for each element in assetData
          const customSort = (a: any, b: any) => {
            const typeAIndex = typeOrder.indexOf(a.fieldType);
            const typeBIndex = typeOrder.indexOf(b.fieldType);
            // Move types not in the order to the end
            if (typeAIndex === -1) return 1;
            if (typeBIndex === -1) return -1;
            return typeAIndex - typeBIndex;
          };
          assetData.forEach((assetElement: any) => {
            if (
              assetElement.asset.assetContent &&
              assetElement.asset.assetContent.length > 0
            ) {
              assetElement.asset.assetContent.sort(customSort);
            }
          });
        }
        if (!pageCount) {
          setAssetsInfo(assetData);
        } else {
          setAssetsInfo((prevAssets) => [...prevAssets, ...assetData]);
        }

        if (assetData.length < size) {
          setHasMore(false);
        }
        setPage(pageCount + 1);
      }
    } catch (error) {
      console.error("Error in userProfile request:", error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await api.get(`member/get?userId=${createdBy}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        setUserFirstName(
          response.data.data.firstName + " " + response.data.data.lastName
        );
        setRoles(response.data.data.roles);
        setDesignation(response.data.data.designation);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error in userProfile request:", error);
    }
  };

  const getAssetId = async (
    id: any,
    assetId: any,
    title: any,
    hashTag: any
  ) => {
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };

  // set Org Image
  const [orgImageData, setOrgImageData] = useState(null);
  const fetchOrgData = async () => {
    try {
      const data = await getOrgImage();
      setOrgImageData(data[0].logo);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching organization image:", error);
    }
  };

  const handleImageHover = (index: number, image: number) => {
    const groupContent = document.getElementsByClassName(
      "groupContent" + image + index
    );
    groupContent?.[0]?.classList.remove("hideContent");
    if (image === 1) {
      const groupImage = document.getElementById("groupImage2" + index);
      groupImage?.classList.add("hideContent");
    } else {
      const groupImage = document.getElementById("groupImage1" + index);
      groupImage?.classList.add("hideContent");
    }
  };

  const handleImageLeave = (index: number, image: number) => {
    const groupContent = document.getElementsByClassName(
      "groupContent" + image + index
    );
    groupContent?.[0]?.classList.add("hideContent");
    if (image === 1) {
      const groupImage = document.getElementById("groupImage2" + index);
      groupImage?.classList.remove("hideContent");
    } else {
      const groupImage = document.getElementById("groupImage1" + index);
      groupImage?.classList.remove("hideContent");
    }
  };

  const navigateToHome = () => {
    navigate("/landingAssetView");
  };
  const headerSearch = (itemTitle: any) => {
    navigate("/landingAssetView");
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
  };
  return (
    <>
      {isLoadingAllAsset ? (
        <Box className="center-grid bg-right center_card_loader">
          <Grid
            item
            container
            className=" justify-content-between d-unset p-10"
          >
            <Grid container className="justify-content-between">
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={20}
                  height={20}
                  sx={{
                    ...SkeletonColor,
                    marginRight: "5px",
                    borderRadius: "5px",
                  }}
                />

                <Link
                  underline="none"
                  color="inherit"
                  className="d-flex"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={20}
                    height={20}
                    sx={{
                      ...SkeletonColor,
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                  />

                  <Typography
                    className="fs-16  smallBold hideDotMedium"
                    color="text.primary"
                    sx={{
                      paddingLeft: "5px",
                      border: "none",
                    }}
                  >
                    <Skeleton
                      variant="text"
                      width={60}
                      height={20}
                      sx={{
                        ...SkeletonColor,
                        marginRight: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  </Typography>
                </Link>
              </Grid>
              <div
                className="d-flex cur-pointer position-relative"
                style={{
                  ...SkeletonColor,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <Box ref={readBoxRef} id="allAsset">
                  <Skeleton
                    variant="rectangular"
                    width={20}
                    height={20}
                    sx={{
                      ...SkeletonColor,
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>

                <Box className="mlr-5">
                  <Skeleton
                    variant="rectangular"
                    width={20}
                    height={20}
                    sx={{
                      ...SkeletonColor,
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
                <Box className="mlr-5">
                  <Skeleton
                    variant="rectangular"
                    width={20}
                    height={20}
                    sx={{
                      ...SkeletonColor,
                      marginRight: "5px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
                <Box className="mlr-5">
                  <Link
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center"
                  >
                    <Skeleton
                      variant="rectangular"
                      width={7}
                      height={20}
                      sx={{
                        ...SkeletonColor,
                        marginRight: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  </Link>
                </Box>
              </div>

              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  padding: "10px",
                }}
                className="mt-15 sup_tab "
              >
                <div>
                  <Typography
                    style={{
                      marginTop: "-20px",
                    }}
                    className="colorBlackB"
                  >
                    <Skeleton
                      variant="text"
                      width={90}
                      height={23}
                      sx={{ ...SkeletonColor }}
                    />
                  </Typography>
                  <Typography className="smallBold">
                    <Skeleton
                      variant="text"
                      width={170}
                      height={23}
                      sx={{ ...SkeletonColor }}
                    />
                  </Typography>
                </div>
                <TableContainer
                  className="nowrap   height_scroll"
                  component={Paper}
                >
                  <Table
                    style={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {placeholderArray.map((result, index) => (
                        <TableRow key={index}>
                          <TableCell width="5%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={70}
                              height={40}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="5%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={40}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="35%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={230}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="20%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={160}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="10%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={50}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="10%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={50}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                          <TableCell width="10%" style={{ border: "none" }}>
                            <Skeleton
                              variant="rectangular"
                              width={50}
                              height={50}
                              sx={{ ...SkeletonColor, borderRadius: "6px" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="center-grid bg-right center_card">
          <Grid
            item
            container
            className=" justify-content-between d-unset p-10"
          >
            <Grid container className="justify-content-between">
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{
                  padding: "10px",
                }}
              >
                <img
                  onClick={navigateToHome}
                  className="logo-bread   cur-pointer"
                  src={`${imagehttp}${orgImageData}`}
                  alt="logo"
                ></img>
                {/* </Link> */}
                <Link
                  underline="hover"
                  style={{ textDecoration: "none" }}
                  color="inherit"
                  className="d-flex"
                >
                  <img
                    src={`..${subPath}/assets/images/group_img.svg`}
                    alt="logo"
                  ></img>
                  <Typography
                    className="fs-14 mt-5 smallBold hideDot-filename"
                    color="text.primary"
                    style={{ paddingLeft: "10px" }}
                  >
                    {userFirstName}
                  </Typography>
                </Link>
              </Breadcrumbs>
              <div className="d-flex cur-pointer position-relative">
                <Box
                  ref={readBoxRef}
                  onClick={() => {
                    setHasMore(true);
                    userProfile(1);
                    setTabType("all");
                  }}
                  className={`removeAll all tabclr1 ${allBtnClass} mlr-5 `}
                  id="allAsset"
                >
                  All
                </Box>
                <Box className="mlr-5">
                  <MdOutlineMarkEmailUnread
                    id="unreadAsset"
                    className={` tabclr2 mlr-5 mail_icon ${readBtnClass} `}
                    onClick={() => {
                      setHasMore(true);
                      userProfileReadUnRead("unread", 1);
                      setTabType("unread");
                    }}
                  />
                </Box>
                <Box className="mlr-5">
                  <DraftsOutlinedIcon
                    className={`mlr-5 tabclr3 mail_readIcon ${unReadBtnClass} `}
                    onClick={() => {
                      setHasMore(true);
                      userProfileReadUnRead("read", 1);
                      setTabType("read");
                    }}
                  />
                </Box>
                <Box className="mlr-5">
                  <Link
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center"
                  >
                    <img
                      alt="dot"
                      src={`..${subPath}/assets/images/menu_3.svg`}
                    ></img>
                  </Link>
                </Box>
              </div>

              <Box
                sx={{
                  width: "100%",
                  typography: "body1",
                  padding: "10px",
                }}
                className="mt-15 sup_tab "
              >
                <div>
                  <Typography
                    style={{
                      marginTop: "-25px",
                    }}
                    className="colorBlackB hideDot-filename"
                  >
                    {userFirstName}
                  </Typography>
                  <Typography className="smallBold">
                    {designation} /{" "}
                    <span className="caps fs-13 fw-600">{roles}</span>
                  </Typography>
                </div>
                <TableContainer
                  className="nowrap"
                  component={Paper}
                  id="height_scroll"
                >
                  <InfiniteScroll
                    dataLength={assets.length}
                    next={() => callScroll()}
                    hasMore={hasMore}
                    scrollableTarget="height_scroll"
                    className=""
                    loader={
                      <h6
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <CircularProgress
                          style={{
                            color: "#119BFF",
                          }}
                          size={20}
                        />
                      </h6>
                    }
                    endMessage={
                      <p className="noMore">
                        <b>No more data found...</b>
                      </p>
                    }
                  >
                    <Table
                      style={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      {isLoadingReadAsset ? (
                        <TableBody>
                          {placeholderArray.map((result, index) => (
                            <TableRow key={index}>
                              <TableCell width="5%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={70}
                                  height={40}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="5%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={40}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="35%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={230}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="20%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={160}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="10%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={50}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="10%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={50}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                              <TableCell width="10%" style={{ border: "none" }}>
                                <Skeleton
                                  variant="rectangular"
                                  width={50}
                                  height={50}
                                  sx={{ borderRadius: "6px" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <TableBody>
                          {assets.map((result: assetsInfo, index) => {
                            return (
                              <TableRow
                                className="cur-pointer row_hover_group"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onClick={(event) => {
                                  if (
                                    event.target instanceof HTMLElement &&
                                    event.target.id === "hashtag_id"
                                  ) {
                                    console.log(
                                      "Clicked on Video cell. Navigation prevented."
                                    );
                                  } else {
                                    getAssetId(
                                      result?.asset?.id,
                                      result?.asset?.assetId,
                                      result?.asset?.title,
                                      result?.asset?.hashTag
                                    );
                                  }
                                }}
                              >
                                <TableCell
                                  width="5%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                    color: "#708090",
                                    fontWeight: "500",
                                  }}
                                  component="th"
                                  scope="row"
                                >
                                  {" "}
                                  {result?.asset?.assetId}
                                </TableCell>
                                <TableCell
                                  width="5%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {result.asset.assetContent[0] ? (
                                    // If result.assetContent[0] is not empty
                                    <>
                                      {result.asset.assetContent[0]
                                        .fieldType === "Video" && (
                                        <img
                                          src={`..${subPath}/assets/images/User_light.svg`}
                                          alt="Video"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType === "Audio" && (
                                        <img
                                          src={`..${subPath}/assets/images/Audio_light.svg`}
                                          alt="Audio"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType === "Document" && (
                                        <img
                                          src={`..${subPath}/assets/images/Document_line.svg`}
                                          alt="Document"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType === "Contact" && (
                                        <img
                                          src={`..${subPath}/assets/images/User_face.svg`}
                                          alt="Contact"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType === "url" && (
                                        <img
                                          src={`..${subPath}/assets/images/link_light.svg`}
                                          alt="URL"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType === "Image" && (
                                        <img
                                          src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                          alt="Image"
                                        />
                                      )}
                                      {result.asset.assetContent[0]
                                        .fieldType !== "Video" &&
                                        result.asset.assetContent[0]
                                          .fieldType !== "Audio" &&
                                        result.asset.assetContent[0]
                                          .fieldType !== "Document" &&
                                        result.asset.assetContent[0]
                                          .fieldType !== "Contact" &&
                                        result.asset.assetContent[0]
                                          .fieldType !== "url" &&
                                        result.asset.assetContent[0]
                                          .fieldType !== "Image" && (
                                          <img
                                            src={`..${subPath}/assets/images/Document_line.svg`}
                                            alt="Document"
                                          />
                                        )}
                                    </>
                                  ) : (
                                    // If result.asset.assetContent[0]  is empty, render something else here
                                    <img
                                      src={`..${subPath}/assets/images/Document_line.svg`}
                                      alt="Document"
                                    />
                                  )}
                                </TableCell>

                                <TableCell
                                  // width="40%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                    paddingLeft: "5px",
                                    maxWidth: "250px",
                                  }}
                                >
                                  <span
                                    className="hideDotMedium colorBlackB"
                                    style={{ width: "100%" }}
                                    // title={result.title}
                                  >
                                    {result?.asset?.title}
                                  </span>
                                  {result?.asset?.description && (
                                    <span
                                      className="hideDotMedium"
                                      style={{
                                        width: "100%",
                                        marginTop: "5px",
                                        color: "#708090",
                                      }}
                                    >
                                      {result?.asset?.description}
                                    </span>
                                  )}

                                  <div
                                    className="hideDotTag"
                                    style={{ width: "100%" }}
                                  >
                                    {result.asset.hashTag
                                      .toString()
                                      .split(",")
                                      .map((hashTag: any, index: number) => {
                                        return (
                                          <span
                                            title={hashTag}
                                            className=" font_family"
                                            style={{
                                              color: "#008cff",
                                            }}
                                            id="hashtag_id"
                                            onClick={() =>
                                              headerSearch(hashTag)
                                            }
                                          >
                                            {hashTag}
                                          </span>
                                          // )}
                                        );
                                      })}
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                  width="15%"
                                >
                                  {result.groupDetail.length === 1 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {expectedFileName ===
                                      getFileNameFromImageUrl(
                                        result?.groupDetail?.[0]?.groupImage
                                      ) ? (
                                        <DefaultImg
                                          imageUrl={
                                            result.groupDetail?.[0]?.groupName
                                          }
                                        />
                                      ) : (
                                        <CardMedia
                                          component="img"
                                          sx={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "17px",
                                            verticalAlign: "middle",
                                          }}
                                          image={
                                            `${imagehttp}${result?.groupDetail?.[0]?.groupImage}` ||
                                            `..${subPath}/assets/images/Ellipse 3.svg`
                                          }
                                        />
                                      )}

                                      <div style={{ marginLeft: "10px" }}>
                                        <span
                                          className="colorBlackB"
                                          style={{ width: "100%" }}
                                        >
                                          {result?.groupDetail?.[0]?.groupName}
                                        </span>
                                        <span
                                          style={{
                                            color: "#708090",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <VaultIcon />{" "}
                                          {result?.groupDetail?.[0]?.vaultName}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {getFileNameFromImageUrl(
                                          result?.groupDetail?.[0]?.groupImage
                                        ) === expectedFileName ? (
                                          <div
                                            className="profile_imglist"
                                            onMouseEnter={() =>
                                              handleImageHover(index, 1)
                                            }
                                            onMouseLeave={() =>
                                              handleImageLeave(index, 1)
                                            }
                                            id={`${"groupImage1" + index}`}
                                          >
                                            <DefaultImg
                                              imageUrl={
                                                result.groupDetail?.[0]
                                                  ?.groupName
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <CardMedia
                                            onMouseEnter={() =>
                                              handleImageHover(index, 1)
                                            }
                                            onMouseLeave={() =>
                                              handleImageLeave(index, 1)
                                            }
                                            component="img"
                                            id={`${"groupImage1" + index}`}
                                            sx={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "17px",
                                              verticalAlign: "middle",
                                            }}
                                            image={
                                              `${imagehttp}${result?.groupDetail?.[0]?.groupImage}` ||
                                              `..${subPath}/assets/images/Ellipse 3.svg`
                                            }
                                          />
                                        )}

                                        <div
                                          style={{ marginLeft: "10px" }}
                                          className={`hideContent ${
                                            "groupContent1" + index
                                          }`}
                                        >
                                          <span
                                            className="colorBlackB"
                                            style={{ width: "100%" }}
                                          >
                                            {
                                              result?.groupDetail?.[0]
                                                ?.groupName
                                            }
                                          </span>
                                          <span
                                            style={{
                                              color: "#708090",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <VaultIcon />{" "}
                                            {
                                              result?.groupDetail?.[0]
                                                ?.vaultName
                                            }
                                          </span>
                                        </div>
                                        {getFileNameFromImageUrl(
                                          result.groupDetail?.[1]?.groupImage
                                        ) === expectedFileName ? (
                                          <div
                                            className="profile_imglist"
                                            onMouseEnter={() =>
                                              handleImageHover(index, 2)
                                            }
                                            onMouseLeave={() =>
                                              handleImageLeave(index, 2)
                                            }
                                            id={`${"groupImage2" + index}`}
                                          >
                                            <DefaultImg
                                              imageUrl={
                                                result.groupDetail?.[1]
                                                  ?.groupName
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <CardMedia
                                            onMouseEnter={() =>
                                              handleImageHover(index, 2)
                                            }
                                            onMouseLeave={() =>
                                              handleImageLeave(index, 2)
                                            }
                                            id={`${"groupImage2" + index}`}
                                            component="img"
                                            sx={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "17px",
                                              verticalAlign: "middle",
                                              marginLeft: "5px",
                                            }}
                                            image={
                                              `${imagehttp}${result?.groupDetail?.[1]?.groupImage}` ||
                                              `..${subPath}/assets/images/Ellipse 3.svg`
                                            }
                                          />
                                        )}

                                        <div
                                          style={{ marginLeft: "10px" }}
                                          className={`hideContent ${
                                            "groupContent2" + index
                                          }`}
                                        >
                                          <span
                                            className="colorBlackB"
                                            style={{ width: "100%" }}
                                          >
                                            {
                                              result?.groupDetail?.[1]
                                                ?.groupName
                                            }
                                          </span>
                                          <span
                                            style={{
                                              color: "#708090",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <VaultIcon />{" "}
                                            {
                                              result?.groupDetail?.[1]
                                                ?.vaultName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell
                                  width="10%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <img
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                    src={`..${subPath}/assets/images/GramIcon.svg`}
                                  ></img>
                                  <span
                                    style={{
                                      verticalAlign: "middle",
                                      marginLeft: "5px",
                                    }}
                                    className="colorBlackB"
                                  >
                                    {result?.asset?.totalGram}
                                  </span>
                                </TableCell>
                                <TableCell
                                  width="10%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <img
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                    src={`..${subPath}/assets/images/grayeye.svg`}
                                  ></img>{" "}
                                  <span
                                    style={{
                                      verticalAlign: "middle",
                                      marginLeft: "5px",
                                    }}
                                    className="colorBlackB"
                                  >
                                    {result?.asset?.viewCount}
                                  </span>
                                </TableCell>
                                <TableCell
                                  width="10%"
                                  style={{
                                    border: "none",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <img
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                    src={`..${subPath}/assets/images/oldchat.svg`}
                                  ></img>{" "}
                                  <span
                                    style={{
                                      verticalAlign: "middle",
                                      marginLeft: "5px",
                                    }}
                                    className="colorBlackB"
                                  >
                                    {result?.asset?.commentCount}
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* </Grid>
      </Box> */}
    </>
  );
};

export default View;
