import React, { useState, useEffect, useRef } from "react";
import "../../../Layout/sidebar.css";
import Grid from "@mui/material/Grid";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import "./asset.css";
import "../Homepage/Home.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eventEmitter from "../../../Constants/EventEmiter";
import { ReactFormGenerator } from "react-form-builder2";
import { imagehttp, subPath } from "../../../Constants/Global";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { InputBase } from "@mui/material";
import "./asset.css";
import * as Yup from "yup";
import getOrgImage from "../../Fetch/Organization";
import "./form.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName, setSearchValue } from "../../../Redux/Search/action";

interface ChildProps {
  value: string[];
}
interface DemobarState {
  assetDataParam: any[];
}
interface FormField {
  id: string;
  canHaveAlternateForm: boolean;
  canHaveAnswer: null;
  canHaveDisplayHorizontal: boolean;
  canHaveOptionCorrect: boolean;
  canHaveOptionValue: boolean;
  canHavePageBreakBefore: boolean;
  canPopulateFromApi: boolean;
  dirty: null;
  element: string;
  field_name: null;
  label: null;
  required: boolean;
  text: boolean;
  options: null;
}

interface AttchItem {
  label: string;
  fieldValue: string;
  fieldType: string;
  type: string;
  name: string;
  filename: string;
  content: File;
}

const quillStyle = {
  height: "200px",
};

const quillContainerData = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-4" }, { indent: "+4" }],
  ["link", "image", "video"],
  [{ color: [] }],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" },
  ],
  ["clean"],
];

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "align",
];

const EditAsset: React.FC<ChildProps & DemobarState> = (props) => {
  //Here State
  const navigate = useNavigate();
  const api = UseAxios();

  const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  //pdf
  const documentTypes = [
    ".pdf",
    ".doc",
    ".docx",
    ".rtf",
    ".txt",
    ".ppt",
    ".pptx",
    ".html",
    ".csv",
    ".xlsx",
    ".xls",
  ];

  //image
  const imageTypes = [
    ".jpeg",
    ".jpg",
    ".png",
    ".gif",
    ".bmp",
    ".ico",
    ".pdf",
    ".doc",
    ".docx",
    ".rtf",
    ".txt",
    ".ppt",
    ".pptx",
    ".html",
    ".csv",
    ".xlsx",
    ".xls",
    ".mp4",
    ".mov",
    ".mp3",
    ".wav",
    ".mpeg3",
    ".mpeg",
  ];
  //media feild
  const imageCheckTypes = [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".ico"];

  const acceptedMediaTypes = [".mp4", ".mov"];
  //audio feild
  const acceptedAudioTypes = [".mp3", ".wav", ".mpeg3"];
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValutSub, setSelectedvalutSub] = useState<string>();
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [vaultName, setVaultName] = useState<string>();
  const [textQuillStandart, setTextQuillStandart] = useState("");
  const [selectedVaultId, setSelectedVaultId] = useState<string>();
  const [selectedValue, setSelectedValue] = useState<string>();
  const [selectedVal, setSelectedVal] = useState<string>();
  const [formBuilderRequiredData, setFormBuilderRequiredData] = useState<
    FormField[]
  >([]);
  const [templateType, setTemplateType] = useState<string>();

  //attachment
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [hashTag, setHashTag] = useState<string>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setItemName(""));
    dispatch(setSearchValue(""));
    fetchOrgData();
  }, []);

  const [Attachment, setAttachment] = useState<AttchItem[]>([]);
  const [activeFileIndex, setActiveFileIndex] = useState<number>(0);
  const fileNameRef = useRef<string | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const validFiles = Array.from(files).filter((file) => {
        const fileExtension = file.name
          .substring(file.name.lastIndexOf("."))
          .toLowerCase();
        const fileType = file.type.toLowerCase();
        return (
          (fileType && imageTypes.includes(fileType)) ||
          (fileExtension && imageTypes.includes(fileExtension))
        );
      });
      // Check if the file is valid image file if not then throw error
      if (validFiles.length === 0) {
        toast.error("Please select valid file", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      setAttachment((prevAttachment) => [
        ...prevAttachment,
        ...validFiles.map((file: any) => ({
          label: file.name, // Replace with your actual values
          type: file.type,
          name: URL.createObjectURL(file),
          fieldValue: "yourFieldValue",
          fieldType: "yourFieldType",
          filename: URL.createObjectURL(file),
          content: file,
        })),
      ]);
      fileNameRef.current = validFiles[0] ? validFiles[0].name : null;
    }
  };

  const handleButtonClick = () => {
    navigate(-1);
  };

  const handleFileClick = (fileIndex: number) => {
    setActiveFileIndex(fileIndex);
    const selectedFile = Attachment[fileIndex];
    const checkfile = selectedFile?.name;
    if (checkfile) {
      fileNameRef.current = selectedFile?.label || null;
    }
  };

  //remove file
  const handleCloseIconClick = (fileIndex: number) => {
    const updatedAdditionalFiles = [...Attachment];
    updatedAdditionalFiles.splice(fileIndex, 1);
    setAttachment(updatedAdditionalFiles);
    if (!updatedAdditionalFiles[0]) {
      setActiveFileIndex(0);
      fileNameRef.current = null;
      setAttachment([]);
    } else {
      let checkfile = null;
      const indexVal = activeFileIndex - 1;
      if (activeFileIndex < fileIndex) {
        setActiveFileIndex(activeFileIndex);
        checkfile = updatedAdditionalFiles[activeFileIndex]?.label || null;
      } else if (activeFileIndex > fileIndex) {
        setActiveFileIndex(indexVal);
        checkfile = updatedAdditionalFiles[indexVal]?.label || null;
      } else {
        setActiveFileIndex(!fileIndex ? 0 : indexVal);
        checkfile =
          updatedAdditionalFiles[!fileIndex ? 0 : indexVal]?.label || null;
      }
      if (checkfile) {
        fileNameRef.current = checkfile || null;
      }
    }
  };

  // set Org Image
  const [orgImageData, setOrgImageData] = useState(null);
  const fetchOrgData = async () => {
    try {
      const data = await getOrgImage();
      setOrgImageData(data[0].logo);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching organization image:", error);
    }
  };

  const dynamicValueRef = useRef([]);
  const handleFormChange = (updatedData: any) => {
    dynamicValueRef.current = updatedData;
  };

  const handleChangeQuillStandart = (textQuillStandart: any) => {
    setTextQuillStandart(textQuillStandart);
  };

  const [assetDataArray, setAssetDataArrayState] = useState<any[]>([]);
  // Get template require data
  const getTemplateData = (assetDataArray: any) => {
    // Remove Required element using label-required class
    const requiredElements = document.getElementsByClassName("label-required");
    for (let i = 0; i < requiredElements.length; i++) {
      requiredElements[i].remove();
    }

    let newData = assetDataArray;

    newData.forEach((item: any) => {
      const fieldsName = document.getElementsByName(
        item.field_name
      )?.[0] as HTMLInputElement;
      const content = item.content;

      if (fieldsName) {
        if (item.text === "Multi-line Input") {
          fieldsName.innerHTML = content;
        } else if (item.text === "Multiple Choice") {
          item.options.forEach((option: any) => {
            const optionName = document.getElementById(
              "fid_preview_" + option.key
            );
            if (content === option.text) {
              (optionName as HTMLInputElement).checked = true;
            }
          });
        } else if (item.text === "Tags") {
          // const tags = content.split(",").map((items: string) => items.trim());
          // const classNames = fieldsName.parentNode?.parentNode?.firstChild
          //   ?.firstChild as HTMLElement;
          // if (tags.length > 0) {
          //   const elementsToRemove = document.querySelectorAll(
          //     ".css-1wa3eu0-placeholder"
          //   );
          //   elementsToRemove.forEach((element) => (element.innerHTML = ""));
          // }
          // tags.forEach((tag: string) => {
          //   const tagElement = document.createElement("input");
          //   tagElement.setAttribute("type", "hidden");
          //   tagElement.setAttribute("name", item.field_name);
          //   tagElement.setAttribute("value", tag);
          //   fieldsName?.appendChild(tagElement);
          //   const appendDiv =
          //     '<div class="css-1rhbuit-multiValue"><div class="css-12jo7m5">' +
          //     tag +
          //     '</div><div class="css-xb97g8"><svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-6q0nyr-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg></div></div>';
          //   classNames?.insertAdjacentHTML("afterbegin", appendDiv);
          //   // Reset the class name
          //   classNames?.classList.remove("css-yk16xz-control");
          // });
        } else {
          fieldsName.value = content;
        }
      } else {
        if (item.text === "Checkboxes") {
          const checked = content
            .split(",")
            .map((items: string) => items.trim());
          item.options.forEach((option: any) => {
            const optionName = document.getElementsByName(
              "option_" + option.key
            )?.[0];
            if (checked.includes(option.text)) {
              (optionName as HTMLInputElement).checked = true;
            }
          });
        }
      }
      if (
        item.field_name &&
        item.text !== "Tags" &&
        item.text !== "Checkboxes" &&
        item.text !== "Multiple Choice"
      ) {
        if (fieldsName && item.required.toString() == "true") {
          if (fieldsName.parentNode) {
            const firstChild = fieldsName.parentNode.firstChild;
            if (firstChild) {
              const span = document.createElement("span");
              span.innerHTML = "Required";
              span.classList.add("label-required");
              span.classList.add("badge");
              span.classList.add("badge-danger");
              firstChild.appendChild(span);
            }
          }
        }
      } else {
        if (item.text === "Tags") {
          const fieldsName = document.getElementsByName(item.field_name)?.[0];
          if (fieldsName && item.required.toString() == "true") {
            if (fieldsName.parentNode?.parentNode?.parentNode) {
              const firstChild =
                fieldsName.parentNode?.parentNode?.parentNode.firstChild;
              if (firstChild) {
                const span = document.createElement("span");
                span.innerHTML = "Required";
                span.classList.add("label-required");
                span.classList.add("badge");
                span.classList.add("badge-danger");
                firstChild.appendChild(span);
              }
            }
          }
        }
        if (item.text === "Multiple Choice" || item.text === "Checkboxes") {
          let fieldsName;
          if (item.text === "Multiple Choice") {
            fieldsName = document.getElementsByName(item.field_name)?.[0];
          } else {
            fieldsName = document.getElementsByName(
              "option_" + item.options[0].key
            )?.[0];
          }
          if (fieldsName && item.required.toString() == "true") {
            if (fieldsName.parentNode?.parentNode) {
              const firstChild = fieldsName.parentNode?.parentNode?.firstChild;
              if (firstChild) {
                const span = document.createElement("span");
                span.innerHTML = "Required";
                span.classList.add("label-required");
                span.classList.add("badge");
                span.classList.add("badge-danger");
                firstChild.appendChild(span);
              }
            }
          }
        }
      }
    });
  };

  const handleUpdate = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();

    let validattionOccur = false;

    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }

    const removeBorder = document.getElementsByClassName("form-control");
    for (let i = 0; i < removeBorder.length; i++) {
      removeBorder[i].classList.remove("borderRed");
    }

    // Click the btn-big button to submit the form

    if (templateType !== "default") {
      const submitButton = document.getElementsByClassName("btn-big");
      const submitButtonElement = submitButton[0] as HTMLButtonElement;
      submitButtonElement?.click();
    }

    // Dynamic form validation map the formBuilderRequiredData
    formBuilderRequiredData.map((item: any) => {
      const fieldsName = document.getElementsByName(item.field_name);
      const inputElement = fieldsName?.[0] as HTMLInputElement;
      if (
        fieldsName &&
        item.required.toString() == "true" &&
        item.text !== "Checkboxes"
      ) {
        if (!inputElement.value) {
          fieldsName?.[0]?.classList.add("borderRed");
          validattionOccur = true;
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");

          if (item.text === "Tags" && item.text !== "Multiple Choice") {
            (
              fieldsName?.[0]?.parentNode?.parentNode?.firstChild as Element
            )?.classList.add("borderRed");
            validattionOccur = true;
          } else {
            if (item.text !== "Multiple Choice") {
              fieldsName?.[0]?.classList.add("borderRed");
              validattionOccur = true;
            }
          }
          fieldsName?.[0]?.parentNode?.insertBefore(
            span,
            fieldsName[0].nextSibling
          );
        } else {
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");
          if (item.text === "Multiple Choice") {
            dynamicValueRef.current.map((item1: any) => {
              if (item1.name === item.field_name) {
                if (item1.value.length === 0) {
                  validattionOccur = true;
                  fieldsName?.[0]?.parentNode?.parentNode?.appendChild(span);
                }
              }
            });
          } else if (item.text === "Dropdown") {
            dynamicValueRef.current.map((item1: any) => {
              if (item1.name === item.field_name) {
                if (item1.value === null || item1.value === "") {
                  fieldsName?.[0]?.classList.add("borderRed");
                  validattionOccur = true;
                  fieldsName?.[0]?.parentNode?.insertBefore(
                    span,
                    fieldsName[0].nextSibling
                  );
                }
              }
            });
          }
        }
      } else {
        if (
          item.text === "Checkboxes" &&
          fieldsName &&
          item.required.toString() === "true"
        ) {
          const span = document.createElement("span");
          span.innerHTML = "This field is required.";
          span.classList.add("error");
          span.classList.add("text-danger");
          dynamicValueRef.current.map((item1: any) => {
            if (item1.name === item.field_name) {
              if (item1.value.length === 0) {
                const optionName = document.getElementsByName(
                  "option_" + item.options[0].key
                );
                validattionOccur = true;
                optionName?.[0]?.parentNode?.parentNode?.appendChild(span);
              }
            }
          });
        }
      }
    });

    if (selectedVal && !selectedValutSub) {
      toast.error("Please select a vault", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
    // If there is no group selected show error
    if (!selectedGroupId || !selectedVaultId) {
      toast.error("Please select a group and vault", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }

    // Validate Before Submitting
    const schema = Yup.object().shape({
      title: Yup.string().required(),
      description: Yup.string().required(),
    });

    const dataToValidate = {
      title,
      description,
    };

    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        // If group selected but no vault selected then show error

        const formData = new FormData();

        dynamicValueRef.current.forEach((item: any, index) => {
          formData.append(`dynamic[${index}].custom_name`, item.custom_name);
          formData.append(`dynamic[${index}].id`, item.id);
          formData.append(`dynamic[${index}].name`, item.name);
          if (typeof item.value === "string") {
            formData.append(`dynamic[${index}].value[0]`, item.value);
          } else if (Array.isArray(item.value)) {
            item.value.forEach((value: any, valueIndex: any) => {
              if (typeof value === "object" && value.key) {
                formData.append(
                  `dynamic[${index}].value[${valueIndex}]`,
                  value.key
                );
              } else {
                formData.append(
                  `dynamic[${index}].value[${valueIndex}]`,
                  value
                );
              }
            });
          } else if (typeof item.value === "object") {
            formData.append(`dynamic[${index}].value`, item.value);
          }
        });

        if (title) {
          formData.append("title", title);
        }
        if (assetId) {
          formData.append("assetId", assetId);
        }
        if (description) {
          formData.append("description", description);
        }
        if (hashTag) {
          formData.append("hashTag", hashTag);
        } else {
          formData.append("hashTag", "");
        }

        if (Attachment) {
          Attachment.forEach((file, index) => {
            // Check if file.label is a URL
            if (
              typeof file.label === "string" &&
              file.label.startsWith("http")
            ) {
              fetch(file.label)
                .then((response) => response.blob())
                .then((blob) => {
                  formData.append("uploads", blob, file.name);
                })
                .catch((error) => console.error("Error fetching URL:", error));
            } else {
              const blob = new Blob([file.content], { type: file.type });
              formData.append("uploads", blob, file.label);
            }
          });
        }

        if (textQuillStandart) {
          formData.append("textAreaValue", textQuillStandart);
        }
        if (
          (selectedGroupId &&
            selectedVaultId &&
            !selectedVal &&
            !selectedValutSub) ||
          (selectedGroupId &&
            selectedVaultId &&
            selectedVal &&
            selectedValutSub)
        ) {
          if (!validattionOccur) {
            try {
              const response = await api.post(`asset/edit`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              if (response.data.status === true) {
                setIsLoading(false);
                toast.success(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });

                setTimeout(() => {
                  window.location.href = subPath + "/landingAssetView";
                }, 1500);

                setTitle("");
                setDescription("");
              }
            } catch (error: any) {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((validationErrors) => {
        setIsLoading(false);
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "Error"
          );
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };

  //   Get the template id from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const assetId_ = urlParams.get("assetId");
  const assetId = assetId_?.replace(/"/g, "");

  useEffect(() => {
    fetchOrgData();
    fetchEditAssetPreviewData();
  }, []);

  const handleChangetitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = event.target.value;
    setTitle(newData);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
  };

  const [hashColor, setHashColor] = useState<string>("0");

  const handleChangeHashTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hashtag = event.target.value;
    setHashTag(hashtag);
    if (hashtag.trim().length > 0) {
      setHashColor("1");
    } else {
      setHashColor("0");
    }
  };

  // Fetch Preview data
  const fetchEditAssetPreviewData = async () => {
    try {
      const response = await api.get(`asset/preview?assetId=${assetId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const newData = response.data.data;
      setTitle(newData.title);
      setDescription(newData.description);

      const tags = newData.hashTag;
      let hashTagsAppend = "";
      tags.map((item: any, index: number) => {
        if (index === 0) {
          hashTagsAppend = hashTagsAppend + item;
        } else {
          hashTagsAppend = hashTagsAppend + item.trim();
        }
      });

      setHashTag(hashTagsAppend);

      if (hashTagsAppend.trim().length > 0) {
        setHashColor("1");
      } else {
        setHashColor("0");
      }

      setAssetDataArrayState(newData.templateContent);
      setFormBuilderRequiredData(newData.templateContent);
      setTemplateType(newData.type);

      // Set time for template data to load
      setTimeout(() => {
        getTemplateData(newData.templateContent);
      }, 100);

      // Set the group and vault values
      if (newData.groupsName[0]) {
        const { groupId, vaultId, groupName, vaultName } =
          newData.groupsName[0];
        setSelectedGroupId(groupId);
        setSelectedVaultId(vaultId);
        setSelectedValue(groupName);
        setVaultName(vaultName);
      }
      if (newData.groupsName[1]) {
        setIsContentVisible(true);
        const { groupName, vaultName } = newData.groupsName[1];
        setSelectedVal(groupName);
        setSelectedvalutSub(vaultName);
      }
      // Set the Rich text area value
      newData.richTextContent.map((item: any) => {
        if (item.fieldType === "richtextarea") {
          setTextQuillStandart(item.fieldValue);
        }
      });
      // Set the Attachment values
      if (Array.isArray(newData.assetContent)) {
        setAttachment(newData.assetContent);
        fileNameRef.current = newData.assetContent[0]?.label || null;
      }
    } catch (error) {}
  };

  const textColor = description.startsWith("#") ? "#66e13b" : "inherit";

  return (
    <div className="assetStyles mar-centre ">
      <Box className="Card_box_asset col-10 col-sm-8 col-md-7 col-lg-6 col-xl-5 h-cardbox">
        <Box className="pad-lt">
          <Grid className="a-title m-0" sx={{ marginBottom: "0px" }}>
            <InputBase
              placeholder="Add Asset Title"
              className="a-title m-0"
              name="title"
              sx={{ width: "100%" }}
              style={{
                color: "#353535",
                fontSize: "20px",
                fontWeight: "700",
              }}
              value={title}
              onChange={handleChangetitle}
            />
          </Grid>
          <span
            id="titleError"
            style={{ color: "red" }}
            className="error"
          ></span>

          <Grid>
            <Grid sx={{ marginBottom: "0px" }}>
              <TextField
                sx={{
                  background: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  // fontWeight: 700,

                  "&:hover": {
                    border: "1px solid transparent", // Remove the border on hover
                  },
                  color: textColor,
                }}
                fullWidth
                name="description"
                variant="standard"
                margin="normal"
                multiline
                rows={2}
                placeholder="Add Description "
                InputProps={{
                  disableUnderline: true,
                }}
                // className="p-0 m-0 description"
                className={`p-0 m-0 description `}
                value={description}
                onChange={handleChangeDescription}
              ></TextField>
              <span
                id="descriptionError"
                style={{ color: "red" }}
                className="error"
              ></span>
              <TextField
                sx={{
                  background: "#fff",
                  border: "1px solid #fff",
                  // padding: "10px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  // fontWeight: 700,

                  "&:hover": {
                    border: "1px solid transparent", // Remove the border on hover
                  },
                  color: textColor,
                }}
                fullWidth
                name="description"
                variant="standard"
                margin="normal"
                multiline
                placeholder="Add Tags "
                InputProps={{
                  disableUnderline: true,
                }}
                id={`hashtag_${hashColor}`}
                className={`p-0 m-0 description `}
                value={hashTag}
                title="Maximum 2 tags allowed"
                onChange={handleChangeHashTags}
              ></TextField>
              <span
                id="descriptionError"
                style={{ color: "red" }}
                className="error"
              ></span>
            </Grid>

            <Grid container className="justify-content-between mt-15">
              <Grid>
                <Typography className="fs-16 fw-600 color-00">
                  Share With
                </Typography>

                <Breadcrumbs
                  aria-label="breadcrumb"
                  sx={{ marginBottom: "0px" }}
                >
                  {/* <Link underline="hover" color="inherit" href="/"> */}
                  <img
                    alt="logo-bread"
                    className="logo-bread "
                    src={`${imagehttp}${orgImageData}`}
                  />
                  {/* </Link> */}
                  <Link
                    id="myLink"
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center "
                  >
                    <img
                      className="bread-link"
                      src={`..${subPath}/assets/images/group_img.svg`}
                      alt="img-bread-link"
                    />
                    <Typography
                      className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                        selectedValue ? "hideDotSideBar" : ""
                      }`}
                    >
                      {selectedValue ? `${selectedValue}` : "Select Group"}
                    </Typography>
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    className="d-flex  align-items-center  "
                  >
                    <img
                      className="bread-link"
                      src={`..${subPath}/assets/images/md_vault_img.svg`}
                    />
                    <Typography
                      className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                        vaultName ? "hideDotSideBar" : ""
                      }`}
                    >
                      {vaultName ? `${vaultName}` : "Select Vault"}
                    </Typography>
                  </Link>
                </Breadcrumbs>

                {isContentVisible ? (
                  <Grid>
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{ marginBottom: "0px" }}
                    >
                      <Link underline="hover" color="inherit" href="/">
                        <img
                          className="logo-bread"
                          src={`${imagehttp}${orgImageData}`}
                          alt="logo-bread"
                        />
                      </Link>
                      <Link
                        id="myLink"
                        underline="hover"
                        color="inherit"
                        className="d-flex  align-items-center "
                      >
                        <img
                          className="bread-link"
                          src={`..${subPath}/assets/images/group_img.svg`}
                          alt="bread-link"
                        />
                        <Typography
                          className={`fs-12 textdecoration_unterline pl-5 cur-pointer ${
                            selectedVal ? "hideDotSideBar" : ""
                          }`}
                        >
                          {selectedVal ? `${selectedVal}` : "Select Group"}
                        </Typography>
                      </Link>
                      <Link
                        underline="hover"
                        color="inherit"
                        className="d-flex  align-items-center "
                      >
                        <img
                          alt="bread-link"
                          className="bread-link"
                          src={`..${subPath}/assets/images/md_vault_img.svg`}
                        />
                        <Typography
                          className={`fs-12 textdecoration_unterline pl-5 cur-pointer  ${
                            selectedValutSub ? "hideDotSideBar" : ""
                          }`}
                        >
                          {selectedValutSub
                            ? `${selectedValutSub}`
                            : "Select Vault"}
                        </Typography>
                      </Link>
                    </Breadcrumbs>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {Attachment[0] ? (
              <>
                <Grid style={{ flexDirection: "column" }}>
                  <Grid className="attach-file " sx={{ paddingTop: "10px" }}>
                    <Grid>
                      <span className="fs-14 fw-500  color hideDot-filename">
                        {fileNameRef.current}
                      </span>
                    </Grid>
                    <Grid>
                      <span></span>
                      <span
                        className="createVaultBtn d-flex cur-pointer"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M6 12H18M12 18V6"
                            stroke="url(#paint0_linear_8999_6214)"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_8999_6214"
                              x1="19.3789"
                              y1="-12"
                              x2="-6.91182"
                              y2="-8.19899"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#119BFF" />
                              <stop offset="1" stopColor="#4CCE1F" />
                            </linearGradient>
                          </defs>
                        </svg>
                        &nbsp;{" "}
                        <span className="createVaultText fs-14">
                          Add More Attachment
                        </span>
                      </span>
                      <input
                        type="file"
                        multiple
                        name="upload[]"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                      />
                    </Grid>
                  </Grid>
                  <Box className="mt-5">
                    <Grid item className="preview-display cur-pointer">
                      <>
                        {Attachment &&
                          Attachment.map((file: AttchItem, index) => {
                            const fileExtension = file.label
                              .substring(file.label.lastIndexOf("."))
                              .toLowerCase();
                            const fileName = file.name;
                            if (index === activeFileIndex) {
                              if (
                                imageCheckTypes.includes(fileExtension) ||
                                imageCheckTypes.includes(file.type)
                              ) {
                                return (
                                  <img
                                    src={
                                      typeof fileName === "string"
                                        ? (fileName as string).startsWith(
                                            "http"
                                          )
                                          ? fileName
                                          : `${imagehttp}${file.filename}`
                                        : undefined
                                    }
                                    alt="Preview"
                                    style={{
                                      borderRadius: "16px 16px 16px 16px",
                                    }}
                                    key={index}
                                    width="100%"
                                    height="100%"
                                  />
                                );
                              } else if (
                                acceptedMediaTypes.includes(fileExtension)
                              ) {
                                return (
                                  // <div key={index} className="video_content">
                                  <video
                                    controls
                                    width="100%"
                                    height="100%"
                                    src={
                                      typeof fileName === "string"
                                        ? (fileName as string).startsWith(
                                            "http"
                                          )
                                          ? fileName
                                          : `${imagehttp}${file.filename}`
                                        : undefined
                                    }
                                  ></video>
                                  // </div>
                                );
                              } else if (
                                acceptedAudioTypes.includes(fileExtension)
                              ) {
                                return (
                                  <div key={index}>
                                    <audio
                                      controls
                                      src={
                                        typeof fileName === "string"
                                          ? (fileName as string).startsWith(
                                              "http"
                                            )
                                            ? fileName
                                            : `${imagehttp}${file.filename}`
                                          : undefined
                                      }
                                    ></audio>
                                  </div>
                                );
                              } else if (
                                documentTypes.includes(fileExtension) ||
                                documentTypes.some((type) =>
                                  file.name.toLowerCase().endsWith(type)
                                )
                              ) {
                                return (
                                  <div className="fileExtension">
                                    <CardActions key={index} className="org_bg">
                                      <CardMedia
                                        sx={{ borderRadius: "7px" }}
                                        component="img"
                                        image={`..${subPath}/assets/images/documentimage.svg`}
                                        alt=""
                                        className="CardMedia-width-height"
                                      />
                                      <Typography className="audioDoc hideDotSingleLine">
                                        {file.label}
                                      </Typography>
                                    </CardActions>
                                  </div>
                                );
                              }
                            }
                          })}
                      </>
                    </Grid>
                  </Box>
                  <Grid className="selectedDocsScroll pt-15">
                    {Attachment && (
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "25px",
                          marginBottom: "10px",
                        }}
                      >
                        {Attachment.map((file: any, index) => {
                          const fileExtension = file.label
                            .substring(file.label.lastIndexOf("."))
                            .toLowerCase();
                          const fileName = file.name;
                          if (imageCheckTypes.includes(fileExtension)) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="Assets Images"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index)}
                                >
                                  <img
                                    alt="Assets Images"
                                    src={
                                      typeof fileName === "string"
                                        ? (fileName as string).startsWith(
                                            "http"
                                          )
                                          ? fileName
                                          : `${imagehttp}${file.filename}`
                                        : undefined
                                    }
                                    height="45px"
                                    style={{
                                      borderRadius: "5px",
                                      margin: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          } else if (
                            acceptedMediaTypes.includes(fileExtension)
                          ) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="Assets Images"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px 8px 1px 8px" }}
                                  onClick={() => handleFileClick(index)}
                                >
                                  <video
                                    height="45px"
                                    style={{
                                      borderRadius: "5px",
                                    }}
                                    autoPlay
                                    muted
                                    src={
                                      typeof fileName === "string"
                                        ? (fileName as string).startsWith(
                                            "http"
                                          )
                                          ? fileName
                                          : `${imagehttp}${file.filename}`
                                        : undefined
                                    }
                                  ></video>
                                </div>
                              </div>
                            );
                          } else if (
                            acceptedAudioTypes.includes(fileExtension)
                          ) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  alt="Assets Images"
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index)}
                                >
                                  <img
                                    src={`..${subPath}/assets/images/Audio_light.svg`}
                                    height="55px"
                                    alt="Audio icon"
                                  ></img>
                                </div>
                              </div>
                            );
                          } else if (documentTypes.includes(fileExtension)) {
                            return (
                              <div
                                key={index}
                                style={{ position: "relative" }}
                                className={`file-preview ${
                                  activeFileIndex === index
                                    ? "active active-border"
                                    : ""
                                }`}
                              >
                                <img
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    cursor: "pointer",
                                    zIndex: 1,
                                  }}
                                  alt="Document icon"
                                  src={`..${subPath}/assets/images/simple-line-icons_close.png`}
                                  width={20}
                                  onClick={() => {
                                    handleCloseIconClick(index);
                                  }}
                                />
                                <div
                                  style={{ padding: "8px" }}
                                  onClick={() => handleFileClick(index)}
                                >
                                  <img
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    alt="Document icon"
                                    height="55px"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid>
                <div className="inputDnD">
                  <input
                    id="inputFile"
                    type="file"
                    // accept={imageTypes.join(",")}
                    multiple
                    name="upload[]"
                    onChange={handleImageUpload}
                    onClick={() => fileInputRef.current?.click()}
                    className="form-control-file font-weight-bold"
                    data-title="Choose Cover or Drop Files Here"
                    aria-label="Choose Cover or Drop Files Here"
                  />
                  <h4 className="dropHeader gradientText">
                    Choose Cover or Drop Files Here
                  </h4>
                  <p className="assetFileSub">Click here to add Attachment.</p>
                </div>
              </Grid>
            )}
          </Grid>

          {templateType === "default" ? (
            <Grid className="SortableItem rfb-item">
              <ReactQuill
                theme="snow"
                className="mb-4 asset_box_hgt"
                formats={quillFormats}
                modules={{
                  toolbar: {
                    container: quillContainerData,
                  },
                }}
                onChange={handleChangeQuillStandart}
                preserveWhitespace={true}
                placeholder="Start writing here...."
                style={quillStyle}
                value={textQuillStandart}
              />
            </Grid>
          ) : (
            <Grid className="addasset_Scroll">
              <ReactFormGenerator
                form_action="/"
                form_method="post"
                action_name="Update"
                answer_data={assetDataArray}
                onSubmit={handleFormChange}
                data={assetDataArray}
                skip_validations={true}
              />
            </Grid>
          )}

          {/* ))}  */}
        </Box>
        <Grid
          sx={{
            marginBottom: "5%",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <>
            <span
              className="sidebar-c-b smallAshBold cur-pointer"
              style={{ marginRight: "10px", marginTop: "6px" }}
              onClick={handleButtonClick}
            >
              Cancel
            </span>
            <span
              className="m-button sidebar-c-b "
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "auto",
              }}
              onClick={handleUpdate}
            >
              {isLoading ? "Loading..." : "Update Content"}
            </span>
          </>
        </Grid>
      </Box>
    </div>
  );
};
export default EditAsset;
