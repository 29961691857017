import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import GroupModal from "../Views/Modal/GroupModal";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";
import { Avatar } from "antd";
import { imagehttp, subPath, decodeToken } from "../Constants/Global";
import eventEmitter from "../Constants/EventEmiter";
import Link from "@mui/material/Link";
import CardMedia from "@mui/material/CardMedia";
import UseAxios from "../Constants/UseAxios";
import Stack from "@mui/material/Stack";
import { useAppDispatch } from "../Constants/hooks";
import { setItemName, setSearchValue } from "../Redux/Search/action";

interface GroupInfo {
  name: string;
  groupIcon: string | Blob | ArrayBuffer | File | null;
  id: string;
  imageUrl: string | Blob | ArrayBuffer | File | null;
}

const Sidebar: React.FC = () => {
  let api = UseAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const renderAfterCalled = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [group, setGroup] = useState([]);
  const [groupAccess, setGroupAccess] = useState<boolean>();
  const [hashTags, setHashTags] = useState<string[]>([]);
  const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(true);
  const [isLoadingTag, setIsLoadingTag] = useState<boolean>(true);
  const companyId = decodeToken().companyId;

  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  const refreshSidebarGroup = () => {
    fetchGroup();
  };
  //Groups Modal
  const closeModal = () => {
    setIsModalVisible(false);
  };
  const showModalGroup = (event: React.SyntheticEvent) => {
    setIsModalVisible(true);
    resetValidation();
    eventEmitter.emit("triggerViewMembers");
  };

  // Reset Group and Vault Validation
  const resetValidation = () => {
    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("field");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields?.[i]?.classList.remove("borderRed");
    }
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchGroup();
      TagList();
      userPermission();
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, []);

  const userPermission = async () => {
    try {
      const response = await api.get(`user/select-premission`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        const newData = response.data.data;
        setGroupAccess(newData.groupAccess);
      }
    } catch (error) {}
  };
  const handleTableRowClick = (newId: string) => {
    setTimeout(() => {
      navigate(`/groupsView`, { state: { id: newId } });
      eventEmitter.emit("idReceived", newId);
    }, 100);
  };

  const updateListenerGroup = () => {
    fetchGroup();
  };

  useEffect(() => {
    eventEmitter.on("groupUpdated", updateListenerGroup);
    eventEmitter.on("groupDeleted", updateListenerGroup);
    eventEmitter.on("addTourClass", addToTourClass);
    eventEmitter.on("removeTourClass", removeTourClass);
    eventEmitter.on("getHasgtags", () => {
      TagList();
    });

    return () => {
      eventEmitter.off("groupUpdated", updateListenerGroup);
      eventEmitter.off("groupDeleted", updateListenerGroup);
      eventEmitter.off("addTourClass", addToTourClass);
      eventEmitter.off("removeTourClass", removeTourClass);
      eventEmitter.off("getHasgtags", () => {
        TagList();
      });
    };
  }, []);

  const [tourClass, setTourClass] = useState("");
  const addToTourClass = () => {
    setTourClass("tourCreateGroup");
  };

  const removeTourClass = () => {
    setTourClass("");
  };

  const fetchGroup = async () => {
    try {
      const response = await api.get(
        `group/get-trending-group?companyId=${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        const newData = response.data.data;
        setGroup(newData);
      }
      setIsLoadingGroup(false);
    } catch (error) {}
  };

  // #tag In Sidebar
  const TagList = async () => {
    try {
      const response = await api.get(
        `asset/get-hashtags?companyId=${companyId?.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        setHashTags(response.data.data.hashtags);
      }
      setIsLoadingTag(false);
    } catch (error) {}
  };

  const handleDivuserClick = (itemTitle: any) => {
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
    // Set the  hash tag in the state
    navigate("/landingAssetView");
    dispatch(setSearchValue("Recent"));
  };

  // Groups Redirect
  const handleGroupClick = () => {
    dispatch(setItemName(""));
    dispatch(setSearchValue(""));
    navigate("/groupView");
  };

  return (
    <Stack spacing={2}>
      {isLoadingGroup ? (
        <Card
          className="sidebarcard loader_hgt"
          sx={{ backgroundColor: "#E6E6E6" }}
        >
          <CardContent
            className="sidecard-scroll GroupScroll b-l-div "
            sx={{ borderRadius: "8px" }}
          >
            <Grid
              container
              className="fav-d "
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                sx={{ display: "flex", alignItems: "self-end" }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "unset",
                    paddingBottom: "18px",
                  }}
                >
                  <Skeleton
                    variant="text"
                    width={50}
                    height={24}
                    sx={{ ...SkeletonColor, borderRadius: "8px" }}
                  />
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} sm={6} sx={{ paddingBottom: "18px" }}>
                <Skeleton
                  variant="text"
                  width={40}
                  height={12}
                  sx={{
                    ...SkeletonColor,
                    float: "right",
                    margin: "0",
                    padding: "5px 0 0 5px",
                    borderRadius: "7px",
                  }}
                />
              </Grid>
            </Grid>
            <Box className="groupScroll">
              <Grid container className="row_hover cur-pointer">
                <Grid item xl={12} lg={12} sm={12}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "25px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={70}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "25px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={30}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "25px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "26px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={80}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "26px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={80}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "4px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      style={{ ...SkeletonColor, marginRight: "8px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={120}
                      height={22}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <CardActions>
            <Skeleton
              variant="rectangular"
              width={225}
              height={25}
              sx={{
                ...SkeletonColor,
                marginLeft: "5px",
                borderRadius: "5px",
              }}
            />
          </CardActions>
        </Card>
      ) : (
        <Card className="sidebarcard">
          <CardContent
            className="sidecard-scroll GroupScroll b-l-div"
            sx={{ borderRadius: "8px" }}
          >
            <Grid
              container
              className="fav-d"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                sx={{ display: "flex", alignItems: "self-end" }}
              >
                <Grid
                  item
                  sx={{ display: "flex", alignItems: "unset" }}
                  className="big_center"
                >
                  <Typography
                    sx={{
                      color: "#119BFF",
                      fontFamily: "helvetica",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    className="c_t_ty"
                  >
                    Groups
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} sm={6}>
                <Link
                  // href={`${subPath}/groupView`}
                  underline="always"
                  className="cur-pointer bid_fs"
                  onClick={handleGroupClick}
                  sx={{
                    background:
                      "linear-gradient(to right, #4cce1f, #119bff) !important",
                    color: "transparent !important",
                    WebkitBackgroundClip: "text !important",
                    float: "right",
                    margin: "0",
                    fontWeight: "700",
                    fontSize: "11px",
                    padding: "5px 0 0 5px",
                    "&:hover": {
                      textDecoration: "none ! important", // Hide the underline on hover
                    },
                  }}
                >
                  Show all
                </Link>
              </Grid>
            </Grid>
            <Box className="groupScroll">
              {group.length > 0 ? (
                group.slice(0, 6).map((result: GroupInfo) => {
                  const imageUrl = result.groupIcon;
                  const expectedFileName = "20231024153135_no_image.jpg";

                  let fileName = "";
                  if (typeof imageUrl === "string" && imageUrl) {
                    const url = new URL(imageUrl);
                    const pathParts = url.pathname.split("/");
                    fileName = pathParts[pathParts.length - 1];
                  }

                  const randomColor = getRandomColor(result.name);
                  return (
                    <Grid
                      container
                      className="row_hover cur-pointer"
                      key={result.id}
                    >
                      <Grid item xl={12} lg={12} sm={12}>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          className=" br_5 padding_card"
                          onClick={() => handleTableRowClick(result.id)}
                        >
                          {fileName === expectedFileName ? (
                            <div className="imgHeight">
                              <Avatar
                                className="sidebarimg "
                                style={{
                                  backgroundColor: randomColor,
                                  width: "18px",
                                  height: "18px",
                                  borderRadius: "5%",
                                  fontSize: "12px",
                                }}
                              >
                                {result.name
                                  ? result.name
                                      .split(" ")
                                      .map((word: any, index: number) =>
                                        index < 2
                                          ? word.charAt(0).toUpperCase()
                                          : ""
                                      )
                                      .join("")
                                  : ""}
                              </Avatar>
                            </div>
                          ) : (
                            <div className="imgHeight">
                              <Box
                                component="img"
                                src={
                                  `${imagehttp}${result.groupIcon}` ||
                                  `..${subPath}/assets/images/p1.png`
                                }
                                alt=""
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginRight: "0px",
                                  borderRadius: "6%",
                                  paddingLeft: "0px",
                                }}
                              />
                            </div>
                          )}

                          <Typography
                            sx={{
                              color: "#000 !important",
                              fontSize: "13px !important",
                              paddingLeft: "10px !important",
                              fontWeight: "700 !important",
                              fontFamily: "Helvetica !important",
                            }}
                            title={result.name}
                            className="hideDotSideBar pad_sideContent"
                          >
                            {result.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  container
                  className="fav-d"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // Center content horizontally
                  }}
                >
                  <CardMedia
                    component="img"
                    image={`..${subPath}/assets/images/nodatafound.png`}
                    alt=""
                    sx={{ borderRadius: "7px", marginTop: "58px" }}
                    className="CardMedia-width-height"
                  />
                </Grid>
              )}
            </Box>
          </CardContent>

          {groupAccess === true && (
            <CardActions className="create_btn" onClick={showModalGroup}>
              <button
                id="createGroup"
                className={`btnRadius m-button sidebar-c-b ${tourClass}`}
              >
                Create Group
              </button>
            </CardActions>
          )}
        </Card>
      )}

      {isLoadingTag ? (
        <Card
          sx={{ mt: 2, backgroundColor: "#E6E6E6" }}
          className="sidecard-scroll-o-b sidecard-tag-load"
        >
          <CardContent className="sidecard-scroll hashTagScroll t-l-div">
            <Grid
              container
              className="fav-d"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                sx={{ display: "flex", alignItems: "self-end" }}
              >
                <Grid sx={{ display: "flex", alignItems: "unset" }}>
                  <Skeleton
                    variant="text"
                    width={45}
                    height={20}
                    sx={{ ...SkeletonColor, borderRadius: "8px" }}
                  />
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} sm={6}>
                <Skeleton
                  variant="text"
                  width={40}
                  height={12}
                  sx={{
                    ...SkeletonColor,
                    float: "right",
                    margin: "0",
                    padding: "5px 0 0 5px",
                    borderRadius: "7px",
                  }}
                />
              </Grid>
            </Grid>
            <Box className="hashTagScroll">
              <Grid container className="row_hover cur-pointer">
                <Grid item xl={12} lg={12} sm={12}>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "60px",
                      paddingTop: "35px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={170}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "23px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={170}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "23px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={170}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "23px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={90}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "15px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={190}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "15px",
                    }}
                    className=" br_5 "
                  >
                    <Skeleton
                      variant="text"
                      width={150}
                      height={18}
                      style={{ ...SkeletonColor, borderRadius: "7px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ mt: 2 }} className="sidecard-scroll-o-b">
          <CardContent className="sidecard-scroll hashTagScroll t-l-div">
            <Grid
              container
              className="fav-d"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xl={12}
                lg={12}
                sm={12}
                sx={{ display: "flex", alignItems: "self-end" }}
              >
                <Grid
                  sx={{ display: "flex", alignItems: "unset" }}
                  className="big_center"
                >
                  <img
                    src={`..${subPath}/assets/images/top-three 1.png`}
                    alt=""
                    width="21px"
                    height="21px"
                    style={{ marginTop: "4px" }}
                    className="big_img_top"
                  />
                  <Typography
                    sx={{
                      color: "#119BFF",
                      fontFamily: "helvetica",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    className="c_t_ty"
                  >
                    Top Topics
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xl={6} lg={6} sm={6}></Grid>
            </Grid>
            <Box className="hashTagScroll">
              {hashTags.length > 0 ? (
                hashTags
                  .slice(0, 6)
                  .map((resultHashtag: string, index: number) => (
                    <Grid
                      container
                      className="row_hover cur-pointer"
                      key={`item_${resultHashtag}`} // Fix: Generate a unique key using a unique identifier associated with the item
                    >
                      <Grid item xl={12} lg={12} sm={12}>
                        <Grid
                          sx={{ display: "flex", alignItems: "center" }}
                          className=" br_5 pad_card_box1 mt-15"
                          onClick={() =>
                            handleDivuserClick(resultHashtag.trim())
                          }
                        >
                          <Typography
                            sx={{
                              color: "#000 !important",
                              fontSize: "13px !important",
                              paddingLeft: "10px !important",
                              fontWeight: "700 !important",
                              fontFamily: "Helvetica !important",
                            }}
                            className="hideDotSideBar"
                          >
                            {resultHashtag}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <Grid
                  container
                  className="fav-d"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // Center content horizontally
                  }}
                >
                  <CardMedia
                    sx={{ borderRadius: "7px", marginTop: "58px" }}
                    component="img"
                    image={`..${subPath}/assets/images/nodatafound.png`}
                    alt=""
                    className="CardMedia-width-height"
                  />
                </Grid>
              )}
            </Box>
          </CardContent>
        </Card>
      )}

      <GroupModal
        isOpen={isModalVisible}
        closeModal={closeModal}
        refreshSidebarGroup={refreshSidebarGroup}
      ></GroupModal>
    </Stack>
  );
};

export default React.memo(Sidebar);
