import React, { useState, useEffect, useRef } from "react";
import "../ChatMessage/ChatView.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { MessageBox, ReplyMessage } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import { Badge, Button, Grid, InputBase } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import CryptoJS from "crypto-js";
import moment from "moment";
import {
  getChatView,
  getPinnedMessage,
  savePinnedMessage,
  deleteChatMessage,
  updateChatMessage,
  deletePinnedMessage,
} from "../../../../Redux/Chatview/action";
import ChatProfile from "./ChatProfile";
import ForwardMessageView from "./FarwardMessage/ForwardMessageView";
import ChatReply from "./ChatReply";
import ChatMenu from "./ChatMenu";
import ChatVideo from "./ChatVideo";
import MessageHover from "./MessageboxTooltip/MessageHover";
import ReplymsgTooltip from "./MessageboxTooltip/ReplymsgTooltip";
import ChatFile from "./ChatPdf";
import { getChatList, isMediaShow } from "../../../../Redux/chatList/action";
import PinnedMsgModel from "./MessageboxTooltip/PinnedMsgModel";
import axios from "axios";
import ThumbnailCarousel from "./ThumbnailCarousel";
import PreviewAttachment from "./PreviewAttachment";
import { sendMedia } from "../../../../Redux/circle/action";
interface ChatMessage {
  senderName: string;
  recipientName?: string;
  message: any;
  status: string;
  action: string;
  senderId: string;
  recipientId: string;
  groupId: string;
  groupName: string;
  type: string;
  senderProfilePicture: string;
  replyTo: string;
  replySenderName: string;
  replySenderId: string;
  deviceToken: string;
  groupProfilePicture: string;
  department: string;
  designation: string;
  colorCode: string;
}
const STRINGS = {
  new: "new",
  text: "TEXT",
};
interface DeletePinnedMessage {
  pinnedMessageId: string;
}
interface ChatvieProps {
  chatDetails: any;
}
const userIdSend: string | null = decodeToken()?.id ?? null;
const ChatView: React.FC<ChatvieProps> = (chatDetails) => {
  const [chatHover, setChatHover] = useState<null | HTMLElement>(null);
  const [empty, setEmpty] = useState<null | boolean>(false);
  const [anchorElPin, setAnchorElPin] = React.useState<null | HTMLElement>(
    null
  );

  const chatPageNumber: any = useRef(1);
  const [forwardMsg, setForwardMsg] = useState({});
  //update seenAt
  const [abortController, setAbortController] = useState(new AbortController());
  const dispatch = useAppDispatch();
  const newAbortController = new AbortController();
  const chatMessage: any = useAppSelector(
    (state: any) => state?.chatGetMessage?.chatView
  );


  const pinnedMessage: any = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  console.log(pinnedMessage, "pinnedMessage");
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );
  const [msgchat, setMsgChat] = useState({
    privateChatMessages: [],
    totalNumberOfPages: 0,
  });
  const pinnedChatMessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  const userProfilePicture =
    ".." + subPath + "/assets/images/images/PROFILE_PHOTO.png";
  const pinnedMessageData = {
    userId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    groupId: "",
  };
  const userDetails = {
    senderId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    pageNo: chatPageNumber.current, // Add this property
    rowPerPage: 10, // Add this property
  };

  useEffect(() => {
    setAbortController(newAbortController);

    // chatDetails.chatDetails
    //   ? dispatch(getChatView(userDetails, newAbortController))
    //   : "";
    // chatDetails.chatDetails
    //   ? dispatch(getPinnedMessage(pinnedMessageData, newAbortController))
    //   : "";
    setEmpty(chatDetails?.chatDetails?.empty);
    initWebSocket();
    changeChatView();

    // Cleanup function for socket closure
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [dispatch]);

  //Delete Private Chat

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setChatHover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setChatHover(null);
  };

  const open = Boolean(chatHover);

  //

  // const encrypt = (text: string) => {
  //   return CryptoJS.AES.encrypt(text, ENC_DEC_KEY).toString();
  // };
  function encrypt(value: string) {
    const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);
    const encryptedData = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  }
  const fetchChatList = () => {
    const data = {
      userId: userData.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getChatList(data, signal.signal));
  };

  function decrypt(value: string | CryptoJS.lib.CipherParams): string {
    if (value && value !== "") {
      try {
        const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

        // Decrypting the value
        const decrypted = CryptoJS.AES.decrypt(value, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // Converting decrypted value to UTF-8 string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Logging decrypted value for debugging

        // Return decrypted string
        return decryptedString;
      } catch (error) {
        console.error("Error during decryption:", error);
        return "";
      }
    }

    return "";
  }
  const [hoveredMessageId, setHoveredMessageId] = useState<null | number>(null);
  const [hoveredMessageReply, setHoveredMessageReply] = useState<null | number>(
    null
  );

  const [isPinShow, setIsPinShow] = useState(
    pinnedChatMessage.length != 0 ? true : false
  );
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  const handlePinShow = (message: any) => {
    const data = {
      userId: chatDetails?.chatDetails?.senderId,
      recipientId: chatDetails?.chatDetails?.recipientId,
      groupId: message.groupId,
      messageId: message.message.id,
      message: message,
    };
    dispatch(savePinnedMessage(data))
      .then(() => {
        handlePinnedMsg();
      })
      .catch((error) => {
        setIsPinShow(false);
        console.error("Failed to save pinned message:", error);
      });
  };
  const handlePinnedMsg = () => {
    dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
    setIsPinShow(true);
    setHighlightedMessageId(null);
  };
  const handleClickPinned = (pinnedMsg: any) => {
    setHighlightedMessageId(pinnedMsg.message.content);
    setTimeout(() => {
      setHighlightedMessageId(null);
    }, 3000);
  };

  // WebSocket
  const socketRef = useRef<WebSocket | null>(null);
  const [connectionState, setConnectionState] = useState<
    "CONNECTING" | "OPEN" | "CLOSED"
  >("CONNECTING");
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [isForwardMessageShow, setIsForwardMessageShow] = useState(false);

  const initWebSocket = () => {
    const newSocket = new WebSocket(
      `wss://trove-dev-gateway.citpl.info/chat-module/ws/` +
      chatDetails?.chatDetails?.senderId +
      `/goldfish_arm64`
    );
    // const newSocket = new WebSocket(
    //   `wss://trovekafkachat.ckdigital.in/api/ws/66750bb7a852b74175fb4c80/goldfish_arm64`
    // );
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
      setConnectionState("OPEN");
    };

    newSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionState("CLOSED");
      // Reopen WebSocket after a delay (optional)
      // setTimeout(() => {
      initWebSocket();
      // }, 1000); // Try reconnecting after 5 seconds
    };

    newSocket.onmessage = (event) => {
      console.log(event?.data, "event");
      if (event?.data?.senderId?.length > 0) {
        const receivedMessage = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      }
    };
  };

  const sendMessage = () => {
    if (
      socketRef.current &&
      connectionState === "OPEN" &&
      messageInput.trim() !== ""
    ) {
      const messageData: ChatMessage = {
        action: STRINGS.new,
        groupId: "",
        groupName: "",
        groupProfilePicture: "",
        message: JSON.stringify({
          id: generateUniqueId(),
          content: encrypt(messageInput),
          caption: "",
          type: STRINGS.text,
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        }),
        recipientId: chatDetails?.chatDetails?.recipientId,
        recipientName: chatDetails?.chatDetails?.recipientName,
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: chatDetails?.chatDetails?.senderId,
        senderName: chatDetails?.chatDetails?.senderName,
        senderProfilePicture: "",
        department: "",
        designation: "",
        colorCode: "",
        type: STRINGS.text,
        deviceToken: "",
        status: "sent",
      };
      console.log(messageData, "messageData");

      socketRef.current.send(JSON.stringify(messageData));

      fetchChatList();
      handleFetchPrevChat(true);
      clearInput();
    } else {
      initWebSocket();
      // console.error('WebSocket connection is not open or message input is empty.');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const clearInput = () => {
    setMessageInput("");
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const changeChatView = () => {
    if (empty == true) {
      setMessages([]);
      clearInput();
    }
  };
  const handleProfileClick = () => {
    setIsProfileShow(!isProfileShow);
  };

  const handleForwardMessageShow = (message: any) => {
    setIsForwardMessageShow(true);
    setForwardMsg(message);
  };
  const handleCloseForwardMessage = () => {
    setIsForwardMessageShow(false);
  };
  // pin

  const handleClickPin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPin(event.currentTarget);
  };

  const handleClosePin = () => {
    setAnchorElPin(null);
  };

  const handleDeleteMessage = (message: any) => {
    console.log("hello");
    dispatch(deleteChatMessage(message))
      .then(() => {
        const newArray: any = msgchat.privateChatMessages.filter(
          (obj) => obj !== message
        );
        setMsgChat((prevMsgChat: any) => ({
          ...prevMsgChat,
          privateChatMessages: newArray,
        }));
        handleFetchPrevChat(true);
      })
      .catch((error) => {
        console.error("Failed to delete Message:", error);
      });
  };
  const updateMessage = async (message: any) => {
    dispatch(updateChatMessage(message));
    const value = msgchat.privateChatMessages.map((obj: any) =>
      obj.message.id === message.message.id ? message : obj
    );
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: value,
    }));
    setTimeout(() => {
      fetchChatList();
      handleFetchPrevChat(true);
    }, 5000);
  };

  const unPinnedMessage = (id: any) => {
    const data: DeletePinnedMessage = {
      pinnedMessageId: id,
    };
    dispatch(deletePinnedMessage(data))
      .then(() => {
        dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
        setIsPinShow(false);
      })
      .catch((error) => {
        setIsPinShow(true);
        console.error("Failed to un pinned message:", error);
      });
  };

  // edit

  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  // const inputRef = useRef(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (divRef.current) {
      divRef.current.focus();
    }
  }, [editingMessageId]);

  const sendMessageEnter = (message: any) => {
    console.log(message, "chatDetails");
    const messageData: ChatMessage = {
      action: "edit",
      groupId: "",
      groupName: "",
      groupProfilePicture: "",
      recipientId: chatDetails?.chatDetails?.recipientId,
      recipientName: chatDetails?.chatDetails?.recipientName,
      replySenderId: "",
      replySenderName: "",
      replyTo: "",
      senderId: chatDetails?.chatDetails?.senderId,
      senderName: chatDetails?.chatDetails?.senderName,
      senderProfilePicture: "",
      department: chatDetails?.chatDetails?.recipientDepartment,
      designation: chatDetails?.chatDetails?.recipientDesignation,
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
      message: {
        id: message.message.id,
        content: encrypt(editedContent),
        caption: "",
        type: "TEXT",
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
    };
    updateMessage(messageData);
  };

  const handleEditClick = (messageId: any, currentContent: any) => {
    setEditingMessageId(messageId);
    setEditedContent(currentContent);
  };

  const handleContentChange = (event) => {
    setEditedContent(event.target.innerText);

  };

  useEffect(() => {
    if (divRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(divRef.current, divRef.current.childNodes.length);
      range.collapse(true);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [editedContent]);
  const handleInput = () => {
    if (divRef.current) {
      setEditedContent(divRef.current.innerHTML);
    }
  };
  const handleSaveClick = (messageId: any) => {
    console.log(`Message ID: ${messageId}, New Content: ${editedContent}`);
    // Save the edited content here (e.g., update the message in your state or send it to a server)
    setEditingMessageId(null);
  };

  const handleKeyPress = (event: any, message: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick(editingMessageId);
      sendMessageEnter(message);
    }
  };

  const closeButtonStyle = {
    fontSize: '24px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: 'black',
    position: 'relative',
    left: ''
  };

  const [isEditing, setIsEditing] = useState(false);
  const handleEditStart = () => {
    setIsEditing(true);
    // setEditedContent(decrypt(JSON.parse(message.message).content));
  };

  //reply
  const [isReplying, setIsReplying] = useState(false);
  const [replyingIndex, setReplyingIndex] = useState(null);
  const handleReplyClick = (index: any) => {
    setIsReplying(!isReplying); // Toggle reply box visibility
    setReplyingIndex(index);
    const profilePicture = chatDetails.chatDetails.recipientProfilePicture
      ? chatDetails.chatDetails.recipientProfilePicture
      : `..${subPath}/assets/images/images/PROFILE_PHOTO.png`;

    const generateMessageData = () => {
      const messageData = {
        action: "new",
        groupId: "",
        groupName: "",
        groupProfilePicture: "",
        message: {
          id: generateUniqueId(),
          content: encrypt(editedContent),
          caption: "",
          type: "TEXT",
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        },
        recipientId: chatDetails?.chatDetails?.recipientId,
        recipientName: chatDetails?.chatDetails?.recipientName,
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: chatDetails?.chatDetails?.senderId,
        senderName: chatDetails?.chatDetails?.senderName,
        senderProfilePicture: profilePicture, // Update this field with the profile picture
        department: "",
        designation: "",
        colorCode: "",
        type: "TEXT",
        deviceToken: "",
        status: "sent",
      };
      useEffect(() => {
        // Focus the input element when it is in the editing state
        if (divRef.current) {
          divRef.current.focus();
        }
      }, [ReplyMessage]);

      const sendMessageEnter = () => {
        console.log(`Send Message: ${ReplyMessage}`);
        const messageData = {
          action: "new",
          groupId: "",
          groupName: "",
          groupProfilePicture: "",
          message: {
            id: generateUniqueId(),
            content: encrypt(editedContent),
            caption: "",
            type: "TEXT",
            sendAt: new Date(),
            updatedAt: new Date(),
            deliveredAt: "",
            seenAt: "",
          },
          recipientId: chatDetails?.chatDetails?.recipientId,
          recipientName: chatDetails?.chatDetails?.recipientName,
          replySenderId: "",
          replySenderName: "",
          replyTo: "",
          senderId: chatDetails?.chatDetails?.senderId,
          senderName: chatDetails?.chatDetails?.senderName,
          senderProfilePicture: "",
          department: "",
          designation: "",
          colorCode: "",
          type: "TEXT",
          deviceToken: "",
          status: "sent",
        };
        console.log(messageData);
        updateMessage(messageData);
      };

      const handleEditClick = (messageId: any, currentContent: any) => {
        setEditingMessageId(messageId);
        setEditedContent(currentContent);
      };

      const handleContentChange = (event: any) => {
        setEditedContent(event.target.value);
      };

      const handleSaveClick = (messageId: any) => {
        console.log(`Message ID: ${messageId}, New Content: ${editedContent}`);
        // Save the edited content here (e.g., update the message in your state or send it to a server)
        setEditingMessageId(null);
      };

      const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSaveClick(editingMessageId);
          sendMessageEnter();
        }
      };

      const [isEditing, setIsEditing] = useState(false);
      const handleEditStart = () => {
        setIsEditing(true);
        // setEditedContent(decrypt(JSON.parse(message.message).content));
      };

      console.log(messageData);
      updateMessage(messageData);
    };

    // Call the function to generate and handle the new message data
    generateMessageData();

    // Call the function to generate and handle the new message data
    generateMessageData();
  };
  //scroll
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.privateChatMessages, messages]);
  useEffect(() => {
    const ChatData: any = [
      ...(msgchat?.privateChatMessages || []),
      ...(chatMessage?.privateChatMessages || []),
    ];
    ChatData.sort((a: any, b: any) => {
      const dateA = new Date(a.message.createdAt).getTime();
      const dateB = new Date(b.message.createdAt).getTime();

      return dateA - dateB;
    });
    const uniqueMessages = ChatData.filter(
      (message: any, index: any, self: any) =>
        index === self.findIndex((m: any) => m.id === message.id)
    );
    console.log("uniqueMessages", uniqueMessages)
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: uniqueMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatMessage, messages]);

  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [files, setFiles] = useState([]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Triggering input file selection
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {

      const files: any = Array.from(event.target.files).map((file, index) => ({
        file,
        id: `file_${index}`,
        previewUrl: URL.createObjectURL(file)
      }));
      setFiles(files);

      setImagePreview(true);
      if (files.length > 0) {
        setSelectedImage(files[0]);
      }
    }
  }

  const handleThumbnailClick = (image: File) => {
    setSelectedImage(image);
  };

  const onImageSelected = () => {
    const formData = new FormData();

    formData.append('senderId', chatDetails?.chatDetails?.senderId || '');
    formData.append('senderName', chatDetails?.chatDetails?.senderName || '');
    formData.append('recipientId', chatDetails?.chatDetails?.recipientId || '');
    formData.append('recipientName', chatDetails?.chatDetails?.recipientName || '');
    formData.append('department', '');
    formData.append('designation', '');
    formData.append('groupId', '');
    formData.append('groupName', '');
    formData.append('groupProfilePicture', '');
    formData.append('senderProfilePicture', '');
    formData.append('colorCode', '');
    formData.append('deviceToken', '');
    formData.append('replyTo', '');

    files.forEach((fileObj: { file: File }, index) => {
      const file = fileObj.file;
      if (file.type === 'image/png') {
        formData.append(`images[${index}].media`, file);
        formData.append(`images[${index}].id`, `file_${index}`);
        formData.append(`images[${index}].caption`, '');
      } else if (file.type === 'application/pdf') {
        formData.append(`documents[${index}].media`, file);
        formData.append(`documents[${index}].id`, `file_${index}`);
        formData.append(`documents[${index}].caption`, '');
      }
    });

    dispatch(sendMedia(formData))
      .then((res: any) => {
        console.log("res", res)
        const value: any = msgchat.privateChatMessages;
        res.data.data.forEach((imageData: any) => {
          value.push(imageData);
        });

        setMsgChat((prevMsgChat) => ({
          ...prevMsgChat,
          privateChatMessages: value,
        }));
        setImagePreview(false);
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  };


  const handleViewFile = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCloseView = () => {
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };


  useEffect(() => {
    handlePinnedMsg();
    setMsgChat(() => ({
      privateChatMessages: chatMessage.privateChatMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));
  }, [chatDetails]);

  const [isScrolledToTop, setIsScrolledToTop] = useState(false);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const scrollTop = chatContainerRef.current.scrollTop;
        const scrollHeight = chatContainerRef.current.scrollHeight;
        const clientHeight = chatContainerRef.current.clientHeight;

        if (
          scrollTop === 0 &&
          msgchat?.totalNumberOfPages !== chatPageNumber?.current
        ) {
          handleFetchPrevChat(false);
          setIsScrolledToTop(true);
        } else {
          setIsScrolledToTop(false);
        }
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [msgchat?.totalNumberOfPages, chatPageNumber?.current]);

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.privateChatMessages, messages]);
  const handleFetchPrevChat = (updateCall: any) => {
    chatPageNumber.current = updateCall ? 0 : chatPageNumber.current + 1;
    dispatch(
      getChatView(
        {
          senderId: chatDetails?.chatDetails?.senderId,
          recipientId: chatDetails?.chatDetails?.recipientId,
          pageNo: chatPageNumber.current,
          rowPerPage: updateCall ? msgchat.privateChatMessages.length : 10,
        },
        newAbortController
      )
    );
  };

  // pinned
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openModel = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  // const unPinnedMessage = (id: string) => {
  // Function to unpin a message, implement as per your logic
  // };
  // console.log(pinnedChatMessage, "privateChatMessages");
  // console.log("chat printmessage. ===>.   ", msgchat.privateChatMessages);
  const sendButtonStyle = {
    // Define your send button styles here
  };
  const handleSendClick = () => {
    // Add your send button functionality here
    console.log('Send button clicked!');
    // For example, you could send the selectedImage to a server or perform some action
  };
  const isMediaView = useAppSelector((state: any) => state?.chatListData?.isMediaView);


  const handleToggleMediaView = (imageUrl) => {

    setSelectedImage(imageUrl);
    dispatch(isMediaShow({ isMediaView: !isMediaView, imageURL: imageUrl }));

  };
  return (
    <Grid item>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          // position: "fixed",
        }}
      >
        {
          imagePreview ? (
            <>

              <div>
                <Grid item onClick={() => setImagePreview(false)} style={closeButtonStyle} mr={4} sx={{ display: 'flex', justifyContent: 'end' }}>
                  <img src={`..${subPath}/assets/images/images/Close_Icon.png`} alt="close" style={{ width: '17px', height: '17px' }} />
                </Grid>

              </div>
              {selectedImage && <PreviewAttachment image={selectedImage} onImageSelected={onImageSelected} />}

              <ThumbnailCarousel images={files} onThumbnailClick={handleThumbnailClick} />
              <Grid item mr={5} sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  variant="outlined"
                  onClick={onImageSelected}
                  style={sendButtonStyle}
                  sx={{
                    background: "linear-gradient(90.31deg, #4CCE1F -65.38%, #119BFF 100%)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "18px",
                    border: "1px solid #4CCE1F",
                  }}
                >
                  Send
                </Button>
              </Grid>

            </>
          ) : (
            <>
              <Grid
                item
                sx={{
                  height: "60px",
                  position: "sticky",
                  top: " 60px",
                  zIndex: 1,
                }}
              >
                <AppBar
                  position="static"
                  sx={{
                    backgroundColor: "#ffff",
                    boxShadow: "none",
                    color: "#000000",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                >
                  <Toolbar className="toolbar_chat">
                    <Grid container>
                      <Grid
                        item
                        xs={1.5}
                        md={0.8}
                        lg={0.6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <IconButton
                          onClick={handleProfileClick}
                          className="profile_icon"
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                        >
                          {chatDetails?.chatDetails?.online === true ? (
                            <Badge
                              className="chat_badge"
                              badgeContent={
                                <Grid
                                  item
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={`..${subPath}/assets/images/images/online_img.png`}
                                    alt="online"
                                    style={{ width: "10px", height: "10px" }}
                                  />
                                </Grid>
                              }
                              color="primary"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <Avatar
                                alt="Profile"
                                sx={{
                                  borderRadius: "25%",

                                  width: "32px",

                                  height: "32px",

                                  // backgroundColor:chatDetails.userList.userList.colorCode || "#000000",
                                }}
                                src={
                                  chatDetails.chatDetails.recipientProfilePicture
                                    ? chatDetails.chatDetails.recipientProfilePicture
                                    : chatDetails.chatDetails.recipientName
                                      .charAt(0)
                                      .toUpperCase()
                                }
                              >
                                {chatDetails.chatDetails.recipientName
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            </Badge>
                          ) : (
                            <Avatar
                              alt="Profile"
                              sx={{
                                borderRadius: "25%",

                                width: "32px",

                                height: "32px",

                                // backgroundColor:chatDetails.userList.userList.colorCode || "#000000",
                              }}
                              src={
                                chatDetails.chatDetails.recipientProfilePicture
                                  ? chatDetails.chatDetails.recipientProfilePicture
                                  : chatDetails.chatDetails.recipientName
                                    .charAt(0)
                                    .toUpperCase()
                              }
                            >
                              {chatDetails.chatDetails.recipientName
                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          )}
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        xs={9.3}
                        md={10.6}
                        lg={10.9}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            flexGrow: 1,
                            fontSize: "16px",
                            color: "#000000",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {chatDetails.chatDetails.recipientName
                            .charAt(0)
                            .toUpperCase() +
                            chatDetails.chatDetails.recipientName.slice(1)}
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              fontSize: "12px",
                              color: "#00000099",
                              fontWeight: "400",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {chatDetails.chatDetails.recipientDesignation}/{" "}
                            {chatDetails.chatDetails.recipientDepartment}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={0.5}
                        md={0.5}
                        lg={0.5}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <ChatMenu userData={userData} chatId={chatDetails?.chatDetails?.chatId} senderId={chatDetails.chatDetails.senderId} />
                      </Grid>
                    </Grid>
                  </Toolbar>
                </AppBar>
              </Grid>
              {isPinShow && pinnedChatMessage?.length > 0 && (
                <>
                  <Grid
                    container
                    sx={{
                      position: "sticky",
                      width: "100%",
                      top: "125px",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      padding: "4px, 8px, 4px, 8px",
                      borderRadius: "0px, 0px, 1px, 0px",
                      borderBottom: "1px solid #E5E5E5",
                      boxShadow: "0px 0px 2px 0px #0000001F",

                      zIndex: 1,
                    }}
                  >
                    <Grid
                      item
                      sm={0.6}
                      sx={{
                        display: "flex",
                        paddingLeft: "8px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={`..${subPath}/assets/images/images/Pin_img_chat.png`}
                        alt="pin chat"
                        width="17"
                        height="17"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={11.4}
                      lg={11}
                      onDoubleClick={handleClick}
                      // onClick={handleClick}
                      onClick={() => handleClickPinned(pinnedChatMessage[0])}
                      aria-describedby={id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        color: "#000000",
                        fontWeight: "400",
                      }}
                    >
                      {/* {pinnedChatMessage && pinnedChatMessage?.map((pinnedMsg: any) => (
                <p onClick={() => handlePinnedMsg(pinnedMsg)} key={pinnedMsg}>{decrypt(pinnedMsg?.message)?.substring(0, 6)} ,</p>
              ))} */}
                      {pinnedChatMessage &&
                        decrypt(pinnedChatMessage[0]?.message?.content)?.substring(
                          0,
                          10
                        )}
                      ... ,
                    </Grid>
                  </Grid>
                  <Grid item>
                    <PinnedMsgModel
                      unPinnedMessage={() => unPinnedMessage(pinnedChatMessage[0].id)}
                      handleClickPinned={() =>
                        handleClickPinned(pinnedChatMessage[0])
                      }
                      id={pinnedChatMessage[0]?.id}
                      open={openModel}
                      anchorEl={anchorEl}
                    />
                  </Grid>
                </>
              )}
              <Box
                sx={{
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  padding: 0,
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={8.5}
                    lg={8.5}
                    sx={{
                      gap: "10px",
                      height: "70vh",
                      overflow: "auto",
                      paddingTop: "25px",

                      paddingBottom: isPinShow ? "45px" : "13px",
                    }}
                    ref={chatContainerRef}
                  >
                    <Grid container>
                      {msgchat?.privateChatMessages?.map(
                        (message: any, index: number) => {
                          return (
                            <>
                              <Box
                                className={
                                  isProfileShow || isForwardMessageShow === true
                                    ? "Profile_show_cht"
                                    : "Profile_hide_cht"
                                }
                                sx={{
                                  padding: 0,
                                  margin: "auto",
                                  marginRight: "0px",
                                  paddingTop: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  key={index}
                                  className={
                                    isProfileShow || isForwardMessageShow === true
                                      ? "Profile_show_cht"
                                      : "Profile_hide_cht"
                                  }
                                  sx={{
                                    padding: 0,
                                    marginRight: "0px",
                                    display: "flex",
                                  }}
                                >
                                  <Avatar
                                    alt="Profile"
                                    sx={{
                                      borderRadius: "25%",
                                      width: "35px",
                                      height: "35px",
                                    }}
                                    src={message.senderProfilePicture}
                                  />
                                  <Box
                                    onMouseEnter={() => setHoveredMessageReply(index)}
                                    onMouseLeave={() => setHoveredMessageReply(null)}
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      onMouseEnter={() =>
                                        setHoveredMessageReply(index)
                                      }
                                      onMouseLeave={() =>
                                        setHoveredMessageReply(null)
                                      }
                                      aria-owns={
                                        hoveredMessageReply === index
                                          ? "mouse-over-popover"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      className="hide_Time"
                                    >
                                      <MessageBox
                                        id={index.toString()}
                                        className={
                                          message?.forwardMessage === true
                                            ? "forwardMessage_pad"
                                            : "chatbox_pad"
                                        }
                                        position={"left"}
                                        type={"text"}
                                        text={
                                          editingMessageId === index.toString() ? (
                                            <div
                                              ref={divRef}
                                              style={{
                                                cursor: "pointer",
                                                border: "none",
                                                width: "100%",
                                                outline: "none",
                                              }}
                                              contentEditable={true}
                                              onKeyPress={(event) =>
                                                handleKeyPress(event, message)
                                              }
                                              onInput={handleContentChange}
                                            >

                                              {editedContent}

                                            </div>
                                          ) : (
                                            <>
                                              {message?.message && decrypt(message?.message?.content)}
                                              {message?.type === "IMAGE" && (
                                                <img
                                                  src={message?.message?.content}
                                                  alt="attachment"
                                                  className="message-image"
                                                  style={{ width: '100px', height: '80px', borderRadius: '6px' }}
                                                  //  onClick={() => setImagePreview(message?.message?.content)}
                                                  onClick={() => handleToggleMediaView(message?.message?.content)}
                                                />
                                              )}
                                            </>
                                          )
                                        }
                                        title={
                                          <Grid
                                            container

                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 5
                                                  : 8
                                              }
                                              className="sender_lable"
                                            >
                                              {message.senderName
                                                .charAt(0)
                                                .toUpperCase() +
                                                message.senderName.slice(1)}
                                            </Grid>
                                            <Grid
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 7
                                                  : 4
                                              }
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                              }}
                                            >
                                              {message?.forwardMessage ===
                                                true ? (
                                                <Grid
                                                  item
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid item mr={1.5}>
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Group_forward.png`}
                                                      style={{
                                                        width: "14px",
                                                        height: "10px",
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item mr={1.5}>
                                                    <Typography
                                                      sx={{
                                                        fontFamily: "Montserrat",
                                                        fontSize: "11px",
                                                        fontWeight: "700",
                                                        color: "#848484",
                                                      }}
                                                    >
                                                      Forwarded
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item mr={1.5} sx={{ display: "flex", alignItems: "center" }}>
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Ellipse_Round.png`}
                                                      style={{
                                                        width: "6px",
                                                        height: "6px",
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                              <Grid
                                                item
                                                sx={{
                                                  display: "flex",
                                                  color: '#414B54',
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {moment(message.message.sendAt).format(
                                                  "hh:mm A"
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        }
                                        // message.senderName.charAt(0).toUpperCase() +
                                        // message.senderName.slice(1)

                                        focus={true}
                                        titleColor={"#000000"}
                                        replyButton={false}
                                        removeButton={false}
                                        // date={new Date(message.message.sendAt)}
                                        forwarded={message.forwardMessage}
                                        status={"sent"}
                                        notch={true}
                                        retracted={false}
                                      />



                                    </Typography>
                                    {hoveredMessageReply === index && (
                                      <ReplymsgTooltip
                                        forwardMessage={() =>
                                          handleForwardMessageShow(message)
                                        }
                                        DeleteMessage={() =>
                                          handleDeleteMessage(message)
                                        }
                                        PinClick={() => handlePinShow(message)}
                                        ReplyMessage={() => handleReplyClick(index)}
                                        MouseEnter={() =>
                                          setHoveredMessageReply(index)
                                        }
                                        EditClick={() =>
                                          handleEditClick(
                                            index.toString(),

                                            message?.message &&
                                            decrypt(message?.message?.content)
                                          )
                                        }
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </Box>
                              <div>
                                {isReplying && replyingIndex === index && (
                                  <ChatReply
                                    chatDetails={chatDetails}
                                    message={message}
                                    index={index}
                                    isProfileShow={isProfileShow}
                                    isForwardMessage={isForwardMessageShow}
                                  />
                                )}
                              </div>
                            </>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* <Box key={index} onMouseEnter={() => setHoveredMessageId(index)} onMouseLeave={() => setHoveredMessageId(null)} style={{ position: "relative" }}>
                <MessageBox
              //     position={message.senderId === userIdSend ? 'left' : 'right'}
              //     type={'text'}
              //     text={decrypt(JSON.parse(message.message).content)}
              //     title={message.senderName}
              //     date={new Date(JSON.parse(message.message).sendAt)}
              //     id={index.toString()} // Ensure a unique id if needed
              //     className="chatbox_pad"
              //     focus={true}
              //     titleColor={"#000000"}
              //     replyButton={false}
              //     removeButton={false}
              //     forwarded={false}
              //     status={"sent"}
              //     notch={true}
              //     retracted={false}
              //   />
              // </Box> */}

              <Grid item sx={{ position: "sticky", right: 0, top: "100px" }}>
                {isProfileShow && <ChatProfile chatData={chatDetails?.chatDetails} />}
                {isForwardMessageShow && (
                  <ForwardMessageView
                    isPinShow={isPinShow}
                    forwardMsg={forwardMsg}
                    chatData={chatDetails?.chatDetails}
                    forwardMessage={handleCloseForwardMessage}
                  />
                )}
              </Grid>
              <Grid
                container
                className={
                  isProfileShow || isForwardMessageShow
                    ? "profile_show_container"
                    : "profile_hide_container"
                }
                sx={{
                  height: "50px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #00000026",
                  borderLeft: "0px solid",
                  zIndex: 1,
                  position: "fixed",
                  bottom: 0,
                  width: () => ({
                    xs: isProfileShow || isForwardMessageShow ? "47.55%" : "70%",
                    sm: isProfileShow || isForwardMessageShow ? "49.55%" : "72%",

                    md: isProfileShow || isForwardMessageShow ? "50.%" : "73%",
                    lg: isProfileShow || isForwardMessageShow ? "51.3%" : "74%",
                    xl: isProfileShow || isForwardMessageShow ? "52%" : "75%",
                  }),
                }}
              >
                <Grid
                  item
                  xs={1}
                  md={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                  lg={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <img
                    src={`..${subPath}/assets/images/images/element-plus.png`}
                    alt="plus"
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                    onClick={handleImageClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                </Grid>
                <Grid
                  item
                  xs={10}
                  md={isProfileShow || isForwardMessageShow ? 10.7 : 10.7}
                  lg={isProfileShow || isForwardMessageShow ? 10.7 : 10.8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <InputBase
                    placeholder="Type a message"
                    className="ml-15 input_search"
                    sx={{
                      background: "#fffff",
                      color: "#708090",
                      boxSizing: "border-box",
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                    id="searchText"
                    autoComplete="off"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                  lg={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <img
                    src={`..${subPath}/assets/images/images/send_chat.png`}
                    alt="plus"
                    style={{ width: "24px", height: "24px" }}
                    onClick={sendMessage}
                  />
                </Grid>
              </Grid>
            </>
          )
        }
      </Box>
    </Grid>
  );
};

export default ChatView;