import React, { useState, useEffect, useRef } from "react";
import "../Homepage/Home.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import { Form, Input, Avatar } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import TableBody from "@mui/material/TableBody";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { getToken, imagehttp, subPath } from "../../../Constants/Global";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import GroupEditModal from "../../Modal/GroupEditModal";
import eventEmitter from "../../../Constants/EventEmiter";
import getOrgImage from "../../Fetch/Organization";
import InfiniteScroll from "react-infinite-scroll-component";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import {
  Popover,
  MenuList,
  Paper,
  ClickAwayListener,
  ListItemIcon,
  Breadcrumbs,
  CircularProgress,
  Stack,
} from "@mui/material";
import Vault from "../../Modal/Vault";
import UseAxios from "../../../Constants/UseAxios";
import { setItemName, setSearchValue } from "../../../Redux/Search/action";
import { useAppDispatch } from "../../../Constants/hooks";
import {
  DeleteIcon,
  EditIcon,
  MuteIcon,
  UnMuteIcon,
} from "../../../Utils/ProfilePage";
import {
  BronzeIcon,
  CreateVault,
  GoldIcon,
  SilverIcon,
  VaultSelected,
  VaultUnSelected,
} from "../../../Utils/GroupVIewPage";
import { AssetTab, AssetsTable, GroupView } from "../Skeleton/GroupView";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface GroupIdInfo {
  assetId: string;
  title: string;
  description: string;
  hashTag: string;
  totalGram: number;
  viewCount: number;
  commentCount: number;
  assetCount: number;
  name: string;
  roles: string;
  vaultId: string;
  vaultName: string;
  profilePicture: File;
  designation: string;
  firstName: string;
  lastName: string;
  id: string;
  assetContent: AssetContent[];
  badge: string;
}
interface AssetContent {
  label: string;
  fieldType: string;
  fieldValue: string;
  fileType: string | null;
}
interface commanInfo {
  groupName: string;
  description: string;
  assetCount: number;
  memberCount: number;
  vaultCount: number;
  muteStatus: boolean;
  groupIcon: string | null;
}
interface VaultIdInfo {
  id(id: any, assetId: number, title: string, hashTag: string): unknown;
  assetId: number;
  description: string;
  hashTag: string;
  title: string;
  profilePicture: File;
  roles: string;
  firstName: string;
  lastName: string;
}

const PackageID: React.FC = () => {
  let api = UseAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [name, setName] = useState<string>("");
  const [nameEdited, setNameEdited] = useState<string>("");
  const [vaultHasMore, setVaultHasMore] = useState(true);
  const [vaultPage, setVaultPage] = useState(0);
  const size = 15;
  const [assetHasMore, setAssetHasMore] = useState(true);
  const [assetPage, setAssetPage] = useState(0);
  const [memberHasMore, setMemberHasMore] = useState(true);
  const [memberPage, setMemberPage] = useState(0);
  const [assetCount, setAssetCount] = useState<number>(0);
  const [vaultAssetPage, setVaultAssetPage] = useState(0);
  const [vaultAssetHasMore, setVaultAssetHasMore] = useState(true);
  const [vaultTabType, setVaultTabType] = useState<string>("all");

  const [vName, setVaultName] = useState<string>("");
  const [groupIdAsset, setGroupIdAsset] = useState<GroupIdInfo[]>([]);
  const [groupIdVault, setGroupIdVault] = useState<GroupIdInfo[]>([]);
  const [groupIdMembers, setGroupIdMembers] = useState<GroupIdInfo[]>([]);
  const [vaultRowId, setVaultRowId] = useState<string>("");
  const [groupId, setGroupId] = useState<commanInfo>();
  const [vaultRowData, setVaultRowData] = useState<VaultIdInfo[]>([]);
  const [groupUserRole, setGroupUserRole] = useState<string>();
  const [createdBy, setCreatedBy] = useState<String>("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteVault, setOpenDeleteVault] = useState(false);
  const [isVaultAccess, setIsVaultAccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElVault, setAnchorElVault] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingAssets, setIsLoadingAssets] = useState<boolean>(false);
  const [tabType, setTabType] = useState<string>("all");
  const [isLoadingGroupAssets, setIsLoadingGroupAssets] =
    useState<boolean>(false);
  const [isModalVaultVisible, setIsModalVaultVisible] = useState(false);
  const getRandomColor = (name: string): string => {
    // Use the first letter of the name to generate a consistent random color
    const firstLetter = name.charAt(0).toLowerCase();
    const hash = firstLetter.charCodeAt(0);
    const hue = (hash * 137) % 360; // Use a prime number multiplier for more variety
    return `hsl(${hue}, 70%, 70%)`;
  };

  const assetArray = Array.from({ length: 6 }, (_, index) => index);

  const expectedFileName = "20231024153135_no_image.jpg";

  let fileName = "";
  if (typeof groupId?.groupIcon === "string" && groupId?.groupIcon) {
    const url = new URL(groupId?.groupIcon);
    const pathParts = url.pathname.split("/");
    fileName = pathParts[pathParts.length - 1];
  }
  const randomColor = getRandomColor(
    groupId?.groupName ? groupId.groupName : ""
  );

  const userRole = getToken().role ?? "";

  const loginUser = getToken().id;

  const location = useLocation();
  // Access the id from the location state
  const [grpId, setGrpId] = useState("");

  //Vault Modal
  const closeVaultModal = () => {
    setIsModalVaultVisible(false);
  };
  const showModalVault = (event: React.SyntheticEvent) => {
    setIsModalVaultVisible(true);
    eventEmitter.emit("triggerViewGroup");
  };

  const refreshSidebarVault = () => {
    fetchData();
  };

  const ownVaultAccess = useRef(true);

  const userProfile = async () => {
    try {
      const url = `user/select-premission`;
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        ownVaultAccess.current = response.data.data.vaultAccess;
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error in userProfile request:", error);
    }
  };

  // Check The Vault Access
  const [createVaultAccess, setCreateVaultAccess] = useState(true);

  // useEffect to fetch data when grpId changes
  useEffect(() => {
    const dataFromState = location.state.id;
    setGrpId(dataFromState);
    fetchOrgData();
    dispatch(setSearchValue(""));

    eventEmitter.on("idReceived", (receivedId: any) => {
      if (receivedId) {
        setVaultName("");
        setGrpId(receivedId);
      }
      setTimeout(() => {
        setActiveTab(1);
      }, 1000);
    });

    if (grpId) {
      fetchData();
      getVaultList(1);
      setGroupIdVault([]);
      setGroupIdAsset([]);
      setGroupIdMembers([]);

      eventEmitter.on("vaultinsert", vaultInseted);
      eventEmitter.on("groupUpdated", groupUpdated);
      eventEmitter.on("SidebarNavigation", fetchData);
      eventEmitter.on("mute", fetchData);
      return () => {
        eventEmitter.off("vaultinsert", vaultInseted);
        eventEmitter.on("groupUpdated", groupUpdated);
        eventEmitter.off("SidebarNavigation", fetchData);
        eventEmitter.off("mute", fetchData);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grpId]);

  const vaultInseted = () => {
    fetchData();
    getVaultList(1);
  };

  const groupUpdated = () => {
    fetchData();
    getVaultList(1);
    getMemberList(1);
  };

  // set Org Image
  const [orgImageData, setOrgImageData] = useState(null);
  const fetchOrgData = async () => {
    try {
      const data = await getOrgImage();
      setOrgImageData(data[0].logo);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching organization image:", error);
    }
  };

  const fetchData = async () => {
    userProfile();
    setVaultRowData([]);
    setVaultRowId("");
    if (abortController) {
      abortController.abort();
      setIsLoading(true); // Set isLoading to true when abortController is called
    }

    const url = `group/get?id=${grpId}`;
    try {
      setIsLoading(true);
      const res = await api.get(url);

      setGroupId(res.data.data);

      setIsMuted(res.data.data.muteStatus);
      setGroupUserRole(res.data.data.groupUserRole);
      setCreatedBy(res.data.data.createdBy);
      setIsLoading(false);
      setAssetCount(res.data.data.assetCount);

      if (userRole.toLowerCase() !== "admin") {
        if (
          res.data.data.groupUserRole?.toLowerCase() === "viewer" ||
          ownVaultAccess.current === false
        ) {
          setCreateVaultAccess(false);
        } else {
          setCreateVaultAccess(true);
        }
      }
    } catch (error) {
      navigate("/EmptyData", { state: location.state.message });
    }
  };
  const [allBtnClass, setAllBtnClass] = useState("all_btn");
  const [readBtnClass, setReadBtnClass] = useState("");
  const [unReadBtnClass, setUnReadBtnClass] = useState("");

  const assetScroll = () => {
    if (tabType === "all") {
      getAssetList(0);
    } else if (tabType === "unread") {
      assetUnReadData(0);
    } else if (tabType === "read") {
      assetReadData(0);
    }
  };

  const assetAllData = async () => {
    setAllBtnClass("all_btn");
    setReadBtnClass("");
    setUnReadBtnClass("");
    getAssetList(1);
  };
  const assetReadData = async (type: number) => {
    setAllBtnClass("");
    setReadBtnClass("");
    setUnReadBtnClass("all_btn");
    abortController.abort();
    let pageCount = assetPage;
    if (type === 1) {
      setAssetHasMore(true);
      setAssetPage(0);
      pageCount = 0;
      setIsLoadingGroupAssets(true);
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const url = `asset/asset-read-group?groupId=${grpId}&type=read&size=${size}&page=${pageCount}`;
    try {
      const res = await api.get(url, {
        signal: newAbortController.signal,
      });
      if (!pageCount) {
        setGroupIdAsset(res.data.data.assetList);
        setAssetCount(res.data.data.assetCount);
      } else {
        setGroupIdAsset((prevGroupIdAsset) => [
          ...groupIdAsset,
          ...res.data.data.assetList,
        ]);
      }
      if (res.data.data.assetList.length < size) {
        setAssetHasMore(false);
      }
      setAssetPage(pageCount + 1);
      setIsLoadingGroupAssets(false);
    } catch (error) {
      console.error(error);
    }
  };
  const assetUnReadData = async (type: number) => {
    setAllBtnClass("");
    setReadBtnClass("all_btn");
    setUnReadBtnClass("");
    abortController.abort();

    let pageCount = assetPage;
    if (type === 1) {
      setAssetPage(0);
      pageCount = 0;
      setIsLoadingGroupAssets(true);
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const url = `asset/asset-read-group?groupId=${grpId}&type=unread&size=${size}&page=${pageCount}`;
    try {
      const res = await api.get(url, {
        signal: newAbortController.signal,
      });

      if (!pageCount) {
        setGroupIdAsset(res.data.data.assetList);
        setAssetCount(res.data.data.assetCount);
      } else {
        setGroupIdAsset((prevGroupIdAsset) => [
          ...groupIdAsset,
          ...res.data.data.assetList,
        ]);
      }
      if (res.data.data.assetList.length < size) {
        setAssetHasMore(false);
      }
      setAssetPage(pageCount + 1);
      setIsLoadingGroupAssets(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenMenuVault = (event: any) => {
    setNameEdited(name);
    if (vaultRowId) {
      setAnchorElVault(event.currentTarget);
    } else {
      toast.error("Kindly Select the vault.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const handleCloseMenuVault = () => {
    setAnchorElVault(null);
  };

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const MuteandUnmute = async () => {
    eventEmitter.emit("mute");
    const requestBody = {
      groupId: grpId,
    };
    try {
      const res = await api
        .post("mail-notification/group-mute", requestBody, {})
        .then((res) => {
          toast.success(res.data.message, {
            // Access the 'data' property to retrieve the message
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 500,
          });
          togglememberVisibility();
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  //Groups Modal
  const [Valut, setValutOpen] = React.useState(false);

  const valutClickOpen = () => {
    if (vaultRowId) {
      setValutOpen(true);
      handleCloseMenuVault();
    } else {
      toast.error("Kindly Select the vault.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const ValutClose = () => {
    setValutOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseDeleteVault = () => {
    setOpenDeleteVault(false);
  };

  const deletePopOpen = () => {
    setOpenDelete(true);
    handleCloseMenu();
  };

  const deletePopOpenVault = () => {
    setOpenDeleteVault(true);
    handleCloseMenuVault();
  };

  const refreshSidebarGroup = () => {
    fetchGroup();
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const showModalGroup = (event: React.SyntheticEvent) => {
    setIsModalVisible(true);
    handleCloseMenu();
    eventEmitter.emit("triggerEditGrp", grpId);
  };

  const [isVisibleMember, setIsVisibleMember] = useState(false);

  const togglememberVisibility = () => {
    setTimeout(() => {
      setIsVisibleMember(!isVisibleMember);
    }, 1000);
  };

  //dropdown
  const [isOpenArray, setIsOpenArray] = useState(
    Array(groupIdMembers.length).fill(false)
  );
  const [anchorElArray, setAnchorElArray] = useState(
    Array(groupIdMembers.length).fill(null)
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = !newIsOpenArray[index];
    setIsOpenArray(newIsOpenArray);

    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClose = (index: number) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = false;
    setIsOpenArray(newIsOpenArray);

    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const token = getToken();

  // Get Vault data by id

  const fetchVaultData = (id: string) => {
    abortController.abort();

    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    try {
      api
        .get(`vault/get`, {
          params: { id: id },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const newData = response.data.data;
          setName(newData.name);
          setVaultName(newData.name);
          setIsVaultAccess(newData.vaultAccess);
        });
    } catch (error) {}
  };
  // Update Vault data by id
  const onFinish = async () => {
    // Yup validation
    const inputField = document.getElementById("EvName");
    const errorField = document.getElementById("nameEvError");
    if (errorField) {
      errorField.innerHTML = "";
    }
    inputField?.classList.remove("borderRed");
    const schema = Yup.object().shape({
      name: Yup.string().required("Name is a required field."),
    });
    schema
      .validate({ name })
      .then(async (valid) => {
        const formData = new FormData();
        formData.append("id", vaultRowId ?? "");
        formData.append("name", nameEdited ?? "");
        formData.append("groupId", grpId ?? "");

        try {
          const response = await api.put(`vault/update`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          setVaultHasMore(true);
          fetchData();
          getVaultList(1);
          setVaultName("");

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          ValutClose();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              var keyVal = errorArray[0].trim().split(":");
              if (errorField) {
                errorField.innerHTML =
                  keyVal[1].charAt(0).toUpperCase() + keyVal[1].slice(1) + ".";
              }
              inputField?.classList.add("borderRed");
            }
          } else {
            toast.error("Something went wrong, please check", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        if (errorField) {
          errorField.innerHTML = validationErrors.errors[0];
        }
        inputField?.classList.add("borderRed");
      });
  };

  const [abortController, setAbortController] = useState(new AbortController());
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  const handleTableRowVaultId = (
    id: string,
    type: string,
    loadtType: number
  ) => {
    abortController.abort();
    // Create a new abort controller for the current request
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    setVaultRowId(id);

    fetchVaultData(id);

    setVaultTabType(type);

    const fieldOne = document.getElementById("all");
    const fieldTwo = document.getElementById("unread");
    const fieldThree = document.getElementById("read");

    fieldOne?.classList.remove("all_btn");
    fieldTwo?.classList.remove("all_btn");
    fieldThree?.classList.remove("all_btn");

    const inputFields = document.getElementById(type);
    inputFields?.classList.add("all_btn");

    let url;

    let pageCount = vaultAssetPage;
    if (loadtType) {
      setIsLoadingAssets(true);
      setVaultAssetPage(0);
      pageCount = 0;
      setVaultAssetHasMore(true);
    }

    if (type === "all") {
      url = `asset/getgroup?vaultId=${id}&page=${pageCount}&size=${size}`;
    } else {
      const typeParam = type === "unread" ? "unread" : "read";
      url = `asset/read?vaultId=${id}&type=${typeParam}&page=${pageCount}&size=${size}`;
    }

    try {
      api
        .get(url, {
          signal: newAbortController.signal, // Pass the signal from the abort controller
        })
        .then((res) => {
          setIsLoadingAssets(false);
          if (!pageCount) {
            setVaultRowData(res.data.data);
          } else {
            setVaultRowData((prevVaultRowData) => [
              ...prevVaultRowData,
              ...res.data.data,
            ]);
          }
          if (res.data.data.length < size) {
            setVaultAssetHasMore(false);
          }
          setVaultAssetPage(pageCount + 1);
        })
        .catch(function (error) {});
    } catch (error) {}
  };
  const handleTableRowMemberInfo = (id: string, roles: string) => {
    const url = `group/update-member?id=${grpId}&userId=${id}&role=${roles}`;
    api
      .put(url, null)
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          getMemberList(1);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        fetchData();
      })
      .catch(function (error) {
        // toast.error(error.response.data.message, {
        //     position: toast.POSITION.TOP_RIGHT,
        // });
      });
  };

  const fetchGroup = async () => {};

  const getAssetId = async (
    id: any,
    assetId: any,
    title: any,
    hashTag: any
  ) => {
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };
  const handleNavigation = () => {
    navigate(`/landingAssetView`);
  };
  const [isMuted, setIsMuted] = useState(false); // Initial state is muted

  // Delete Group
  const handleDelete = async () => {
    const url = `group/delete?id=${grpId}`;
    try {
      const res = await api.get(url);
      if (res.data.status === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenDelete(false);
        navigate(`/landingAssetView`);
        eventEmitter.emit("groupDeleted");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleVaulteDelete = async () => {
    if (vaultRowId) {
      const url = `vault/delete?id=${vaultRowId}`;
      try {
        const res = await api.get(url);
        if (res.data.status === true) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchData();
          setVaultName("");
          setOpenDeleteVault(false);
          getVaultList(1);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Kindly Select the vault.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const maxLengthvalidation = 25;

  const headerSearch = (itemTitle: any) => {
    navigate("/landingAssetView");
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
  };

  // Get Valut List
  const getVaultList = async (type: number) => {
    let pageCount = vaultPage;
    if (type === 1) {
      setVaultHasMore(true);
      setVaultPage(0);
      pageCount = 0;
    }
    const url = `vault/group?groupId=${grpId}&page=${pageCount}&size=${size}`;
    try {
      const res = await api.get(url);
      if (!pageCount) {
        setVaultRowId(res.data.data[0] ? res.data.data[0].id : "");
        if (res.data.data[0]) {
          handleTableRowVaultId(res.data.data[0].id, "all", 1);
        }
        setGroupIdVault(res.data.data);
      } else {
        setGroupIdVault((prevGroupIdVault) => [
          ...groupIdVault,
          ...res.data.data,
        ]);
      }
      if (res.data.data.length < size) {
        setVaultHasMore(false);
      }

      setVaultPage(pageCount + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const getAssetList = async (type: number) => {
    let pageCount = assetPage;
    if (type === 1) {
      setAssetHasMore(true);
      setAssetPage(0);
      pageCount = 0;
    }
    const url = `asset/group-id?groupId=${grpId}&page=${pageCount}&size=${size}`;
    try {
      const res = await api.get(url);
      if (!pageCount) {
        setGroupIdAsset(res.data.data);
      } else {
        setGroupIdAsset((prevGroupIdAsset) => [
          ...groupIdAsset,
          ...res.data.data,
        ]);
      }
      if (res.data.data.length < size) {
        setAssetHasMore(false);
      }
      setAssetPage(pageCount + 1);
    } catch (error) {
      console.error(error);
    }
  };

  const getMemberList = async (type: number) => {
    let pageCount = memberPage;
    if (type === 1) {
      setMemberHasMore(true);
      setMemberPage(0);
      pageCount = 0;
    }
    const url = `member/view-group-member?groupId=${grpId}&page=${pageCount}&size=${20}`;
    try {
      const res = await api.get(url);

      if (!pageCount) {
        setGroupIdMembers(res.data.data);
      } else {
        setGroupIdMembers((prevGroupIdMembers) => [
          ...groupIdMembers,
          ...res.data.data,
        ]);
      }
      if (res.data.data.length < 18) {
        setMemberHasMore(false);
      }
      setMemberPage(pageCount + 1);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <GroupView />
      ) : (
        <Box className="center-grid group_card_view bg-right">
          <Grid
            item
            container
            className=" justify-content-between p-10 d-unset "
          >
            <Grid container className="justify-content-between">
              {/* Breadcrumbs */}
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Link underline="hover" color="inherit" href="/"> */}
                <img
                  className="logo-bread  cur-pointer"
                  onClick={handleNavigation}
                  src={`${imagehttp}${orgImageData}`}
                  alt=""
                ></img>
                {/* </Link> */}
                <Link
                  color="inherit"
                  className="d-flex align-items-center"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={`..${subPath}/assets/images/group_img.svg`}
                    alt=""
                  ></img>
                  <Typography
                    className=" margintop-3 smallBold hideDotSingleLine font_family"
                    color="text.primary"
                    sx={{ paddingLeft: "10px", width: "90%" }}
                    title={groupId?.groupName}
                  >
                    {groupId?.groupName}
                  </Typography>
                </Link>
              </Breadcrumbs>
              {activeTab === 2 && (
                <div className="d-flex align-items-center cur-pointer position-relative">
                  <Box
                    className={`removeAll  font_family ${allBtnClass} mlr-5 `}
                    id="allAsset"
                    onClick={() => {
                      setAssetHasMore(true);
                      assetAllData();
                      setTabType("all");
                    }}
                  >
                    All
                  </Box>
                  <Box className="  mlr-5">
                    <MdOutlineMarkEmailUnread
                      className={`removeAll mail_icon ${readBtnClass} `}
                      id="unReadAsset"
                      onClick={() => {
                        setAssetHasMore(true);
                        assetUnReadData(1);
                        setTabType("unread");
                      }}
                    />
                  </Box>
                  <Box className="  mlr-5">
                    <DraftsOutlinedIcon
                      className={`removeAll mail_readIcon ${unReadBtnClass} `}
                      id="ReadAsset"
                      onClick={() => {
                        setAssetHasMore(true);
                        assetReadData(1);
                        setTabType("read");
                      }}
                    />
                  </Box>
                  <Box className="  mlr-5">
                    <Link
                      underline="hover"
                      color="inherit"
                      className="d-flex  align-items-center cur-pointer"
                      onClick={handleOpenMenu}
                    >
                      <img
                        src={`..${subPath}/assets/images/menu_3.svg`}
                        alt=""
                      ></img>
                    </Link>
                  </Box>

                  <div>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                          <MenuList>
                            {groupUserRole?.toLowerCase() === "admin" ||
                            userRole?.toLowerCase() === "admin" ? (
                              <MenuItem
                                onClick={showModalGroup}
                                style={{
                                  color:
                                    "var(--components-switch-slide-fill, #000)",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                Edit Group
                              </MenuItem>
                            ) : null}
                            <MenuItem
                              onClick={() => {
                                MuteandUnmute();
                                handleCloseMenu();
                              }}
                              style={{
                                color:
                                  "var(--components-switch-slide-fill, #000)",
                                fontWeight: "bold",
                                fontSize: "13px",
                              }}
                            >
                              <ListItemIcon>
                                {isMuted ? <UnMuteIcon /> : <MuteIcon />}
                              </ListItemIcon>
                              {isMuted ? "Unmute Group" : "Mute Group"}
                            </MenuItem>
                            {groupUserRole?.toLowerCase() === "admin" ||
                            userRole?.toLowerCase() === "admin" ? (
                              <MenuItem
                                style={{
                                  color: "#EC174F",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                                onClick={deletePopOpen}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                Delete
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Popover>
                  </div>
                </div>
              )}
              {(activeTab === 1 || activeTab === 3) && (
                <div className="d-flex cur-pointer position-relative">
                  <Box
                    className="removeAll all_btn mlr-5"
                    style={{ visibility: "hidden" }}
                  >
                    All
                  </Box>
                  {/* <Box className="  mlr-5">
                      <img src="../beta/assets/images/Vector.png"></img>
                    </Box> */}
                  <Box className="  mlr-5" style={{ visibility: "hidden" }}>
                    <img
                      className="removeAll "
                      src={`..${subPath}/assets/images/fluent_mail-unread-48-regular.svg`}
                      alt=""
                    ></img>
                  </Box>
                  <Box className="  mlr-5" style={{ visibility: "hidden" }}>
                    <img
                      className="removeAll"
                      src={`..${subPath}/assets/images/fluent_mail-read-20-regular.svg`}
                      alt=""
                    ></img>
                  </Box>
                  <Box className="  mlr-5">
                    <Link
                      underline="hover"
                      color="inherit"
                      className="d-flex  align-items-center cur-pointer"
                      onClick={handleOpenMenu}
                    >
                      <img
                        src={`..${subPath}/assets/images/menu_3.svg`}
                        alt=""
                      ></img>
                    </Link>
                  </Box>

                  <div>
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                          <MenuList>
                            {groupUserRole?.toLowerCase() === "admin" ||
                            userRole?.toLowerCase() === "admin" ? (
                              <MenuItem
                                onClick={showModalGroup}
                                style={{
                                  color:
                                    "var(--components-switch-slide-fill, #000)",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                <ListItemIcon>
                                  <EditIcon />
                                </ListItemIcon>
                                Edit Group
                              </MenuItem>
                            ) : null}

                            <MenuItem
                              onClick={() => {
                                MuteandUnmute();
                                handleCloseMenu();
                              }}
                              style={{
                                color:
                                  "var(--components-switch-slide-fill, #000)",
                                fontWeight: "bold",
                                fontSize: "13px",
                              }}
                            >
                              <ListItemIcon>
                                {isMuted ? <UnMuteIcon /> : <MuteIcon />}
                              </ListItemIcon>
                              {isMuted ? "Unmute Group" : "Mute Group"}
                            </MenuItem>
                            {groupUserRole?.toLowerCase() === "admin" ||
                            userRole?.toLowerCase() === "admin" ? (
                              <MenuItem
                                onClick={deletePopOpen}
                                style={{
                                  color: "#EC174F",
                                  fontWeight: "bold",
                                  fontSize: "13px",
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon />
                                </ListItemIcon>
                                Delete
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Popover>
                  </div>
                </div>
              )}
            </Grid>
            <div>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "600",
                  fontSize: "18px",
                  width: "90%",
                }}
                className="hideDotSingleLine cur-pointer font_family"
                title={groupId?.groupName}
              >
                {groupId?.groupName}
              </Typography>
              <Typography
                title={groupId?.description}
                className="color_7080 fs-13 hideDotLong cur-pointer font_family"
              >
                {groupId?.description}
              </Typography>
            </div>
            <Box className="sup_tab w-100">
              <TabContext value={activeTab.toString()}>
                <Box
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  className="mt-5 mb-15 d-flex"
                >
                  <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab
                      onClick={() => {
                        getVaultList(1);
                      }}
                      label={`${groupId?.vaultCount ?? 0} Vault${
                        groupId?.vaultCount !== 1 ? "s" : ""
                      }`}
                      value={1}
                    />
                    <Tab
                      onClick={() => {
                        setTabType("all");
                        assetAllData();
                      }}
                      label={`${assetCount ?? 0} Asset${
                        assetCount !== 1 ? "s" : ""
                      }`}
                      value={2}
                    />
                    <Tab
                      onClick={() => {
                        getMemberList(1);
                      }}
                      label={`${groupId?.memberCount ?? 0} Member${
                        groupId?.memberCount !== 1 ? "s" : ""
                      }`}
                      value={3}
                    />
                  </Tabs>
                  {createVaultAccess && (
                    <span
                      className="createVaultBtn d-flex cur-pointer"
                      onClick={showModalVault}
                    >
                      <CreateVault />
                      &nbsp;{" "}
                      <span className="createVaultText fs-14">
                        Create Vault
                      </span>
                    </span>
                  )}
                </Box>

                <TabPanel value="1" className="p-0 pt-0">
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="box-shadow"
                    >
                      <TableContainer
                        className="nowrap bg_F5"
                        id="vaultTable"
                        component={Paper}
                      >
                        <InfiniteScroll
                          dataLength={groupIdVault.length}
                          next={() => getVaultList(0)}
                          hasMore={vaultHasMore}
                          scrollableTarget="vaultTable"
                          className=""
                          loader={
                            <h6
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <CircularProgress
                                style={{
                                  color: "#119BFF",
                                }}
                                size={20}
                              />
                            </h6>
                          }
                          endMessage={
                            groupIdVault.length !== 0 && (
                              <p className="noMore">
                                <b>No more data found...</b>
                              </p>
                            )
                          }
                        >
                          <Table aria-label="simple table">
                            <TableBody>
                              {groupIdVault.length > 0 ? (
                                <>
                                  {groupIdVault.map(
                                    (result: GroupIdInfo, index) => {
                                      return (
                                        <TableRow
                                          key={index}
                                          onClick={() =>
                                            handleTableRowVaultId(
                                              result.id,
                                              "all",
                                              1
                                            )
                                          }
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                            backgroundColor: `${
                                              vaultRowId === result.id
                                                ? "#A0D7FF"
                                                : ""
                                            }`,
                                          }}
                                          className="table-list cur-pointer row_hover_group"
                                        >
                                          <TableCell
                                            sx={{
                                              border: "none",
                                              color: `${
                                                vaultRowId === result.id
                                                  ? "color: var(--components-switch-slide-fill, #000)"
                                                  : "var(--3, #708090)"
                                              }`,
                                            }}
                                            className={`font_family ${
                                              vaultRowId === result.id
                                                ? "smallBold"
                                                : "smallAshBold"
                                            }`}
                                            component="th"
                                            scope="row"
                                          >
                                            {result.vaultId}
                                          </TableCell>

                                          <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                              alignItems: "center",
                                              justifyContent: "flex-start",
                                              display: "flex",
                                              border: "none",
                                              fontSize: "13.4px",
                                              color: `${
                                                vaultRowId === result.id
                                                  ? "color: var(--components-switch-slide-fill, #000)"
                                                  : "color: var(--components-switch-slide-fill, #000)"
                                              }`,
                                            }}
                                            title={result.name}
                                          >
                                            {vaultRowId === result.id ? (
                                              <VaultSelected />
                                            ) : (
                                              <VaultUnSelected />
                                            )}
                                            <span className="hideDotSingleLine fw-700  font_family">
                                              {result.name}
                                            </span>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              border: "none",
                                              color: `${
                                                vaultRowId === result.id
                                                  ? "color: var(--components-switch-slide-fill, #000)"
                                                  : ""
                                              }`,
                                            }}
                                            className={`font_family ${
                                              vaultRowId === result.id
                                                ? "smallBold"
                                                : "smallAshBold"
                                            }`}
                                          >
                                            Total Asset : {result.assetCount}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <Typography
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#708090",
                                    marginTop: "20%",
                                  }}
                                >
                                  No Data Found
                                </Typography>
                              )}
                            </TableBody>
                          </Table>
                        </InfiniteScroll>
                      </TableContainer>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      className="pi_right"
                    >
                      {/* Breadcrumbs */}
                      <Grid item className="d-flex">
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                          <Breadcrumbs
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            aria-label="breadcrumb"
                          >
                            <img
                              className="logo-bread "
                              src={`${imagehttp}${orgImageData}`}
                              alt=""
                              onClick={handleNavigation}
                            ></img>
                            {/* </Link> */}
                            <Link underline="none" color="inherit cur-pointer">
                              <img
                                src={`..${subPath}/assets/images/group_img.svg`}
                                alt=""
                              ></img>
                            </Link>
                            <Link
                              underline="none"
                              color="inherit"
                              className="d-flex"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                className="maxW20"
                                src={`..${subPath}/assets/images/sm_vault_img.svg`}
                                alt=""
                              ></img>
                              <Typography
                                className="fs-16  smallBold hideDotMedium font_family"
                                color="text.primary"
                                sx={{
                                  paddingLeft: "5px",
                                  border: "none",
                                  width: "100%",
                                }}
                                title={vName}
                              >
                                {vName}
                              </Typography>
                            </Link>
                          </Breadcrumbs>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          className="position-relative justifyContentCenter cur-pointer "
                          style={{ alignItems: "center" }}
                        >
                          <Box
                            onClick={() =>
                              handleTableRowVaultId(vaultRowId || "", "all", 1)
                            }
                            className=" all_btn "
                            id="all"
                          >
                            All
                          </Box>
                          {/* <Box>
                                <img src="../beta/assets/images/Vector.png"></img>
                              </Box> */}
                          <Box
                            onClick={() =>
                              handleTableRowVaultId(
                                vaultRowId || "",
                                "unread",
                                1
                              )
                            }
                          >
                            <MdOutlineMarkEmailUnread
                              className="mail_icon"
                              id="unread"
                            />
                          </Box>
                          <Box
                            onClick={() =>
                              handleTableRowVaultId(vaultRowId || "", "read", 1)
                            }
                          >
                            <DraftsOutlinedIcon
                              className="mail_readIcon"
                              id="read"
                            />
                          </Box>
                          {isVaultAccess && (
                            <Box>
                              <Link
                                underline="hover"
                                color="inherit"
                                className="d-flex  align-items-center cur-pointer"
                                onClick={handleOpenMenuVault}
                              >
                                <img
                                  src={`..${subPath}/assets/images/menu_3.svg`}
                                  alt=""
                                ></img>
                              </Link>
                            </Box>
                          )}

                          <div>
                            <Popover
                              open={Boolean(anchorElVault)}
                              anchorEl={anchorElVault}
                              onClose={handleCloseMenuVault}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={handleCloseMenuVault}
                                >
                                  <MenuList>
                                    {isVaultAccess ? (
                                      <MenuItem
                                        onClick={valutClickOpen}
                                        style={{
                                          color:
                                            "var(--components-switch-slide-fill, #000)",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                        }}
                                      >
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        Edit Vault
                                      </MenuItem>
                                    ) : null}
                                    {isVaultAccess ? (
                                      <MenuItem
                                        onClick={deletePopOpenVault}
                                        style={{
                                          color: "#EC174F",
                                          fontWeight: "bold",
                                          fontSize: "13px",
                                        }}
                                      >
                                        <ListItemIcon>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        Delete
                                      </MenuItem>
                                    ) : null}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Popover>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid className="box-shadow ">
                        <TableContainer
                          id="vaultAssetScroll"
                          className=" scrollable-container m-10 bg_F5  box-shadow"
                          component={Paper}
                        >
                          <InfiniteScroll
                            dataLength={vaultRowData.length}
                            next={() =>
                              handleTableRowVaultId(vaultRowId, vaultTabType, 0)
                            }
                            hasMore={vaultAssetHasMore}
                            scrollableTarget="vaultAssetScroll"
                            loader={
                              groupIdVault.length !== 0 && (
                                <h6
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {" "}
                                  <CircularProgress
                                    style={{
                                      color: "#119BFF",
                                    }}
                                    size={20}
                                  />
                                </h6>
                              )
                            }
                            endMessage={
                              vaultRowData.length !== 0 && (
                                <p className="noMore">
                                  <b>No more data found...</b>
                                </p>
                              )
                            }
                          >
                            {isLoadingAssets ? (
                              <AssetsTable />
                            ) : (
                              <Table aria-label="simple table ">
                                {vaultRowData.length !== 0 ? (
                                  <TableBody>
                                    {vaultRowData.map(
                                      (result: VaultIdInfo, index) => {
                                        return (
                                          <TableRow
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                            className="cur-pointer row-hover-vault"
                                          >
                                            {/* Asset ID */}
                                            <TableCell
                                              sx={{ border: "none" }}
                                              component="th"
                                              scope="row"
                                              className="cur-pointer color_7080 fs-12 font_family"
                                              onClick={() => {
                                                getAssetId(
                                                  result.id,
                                                  result.assetId,
                                                  result.title,
                                                  result.hashTag
                                                );
                                              }}
                                            >
                                              {result.assetId}
                                            </TableCell>

                                            {/* Title, Description, and Hash Tags */}
                                            <TableCell sx={{ border: "none" }}>
                                              <div>
                                                {/* Title */}
                                                <span
                                                  onClick={() => {
                                                    getAssetId(
                                                      result.id,
                                                      result.assetId,
                                                      result.title,
                                                      result.hashTag
                                                    );
                                                  }}
                                                  title={result.title}
                                                  className="hideDotGroup colorBlackB fs-13 font_family"
                                                >
                                                  {result.title}
                                                </span>

                                                {/* Description */}
                                                <span
                                                  title={result.description}
                                                  onClick={() => {
                                                    getAssetId(
                                                      result.id,
                                                      result.assetId,
                                                      result.title,
                                                      result.hashTag
                                                    );
                                                  }}
                                                  className="color_7080 hideDotGroup mt-5 smallAsh fs-11 font_family"
                                                >
                                                  {result.description}
                                                </span>

                                                {/* Hash Tags */}
                                                <span
                                                  style={{
                                                    color: "#008cff",
                                                    width: "220px",
                                                  }}
                                                  className="color_119B hideDotTag fs-13 fs-11 font_family"
                                                >
                                                  {result.hashTag !== "" &&
                                                    result.hashTag
                                                      .toString()
                                                      .split(",")
                                                      .map((hashTag: any) => (
                                                        <span
                                                          key={hashTag}
                                                          onClick={() =>
                                                            headerSearch(
                                                              hashTag
                                                            )
                                                          }
                                                        >
                                                          {hashTag}{" "}
                                                        </span>
                                                      ))}
                                                </span>
                                              </div>
                                            </TableCell>

                                            {/* Profile Picture, Name, and Roles */}
                                            <TableCell
                                              sx={{ border: "none" }}
                                              onClick={() => {
                                                getAssetId(
                                                  result.id,
                                                  result.assetId,
                                                  result.title,
                                                  result.hashTag
                                                );
                                              }}
                                            >
                                              <Stack
                                                spacing={2}
                                                direction="row"
                                                alignItems="center"
                                              >
                                                <Avatar
                                                  style={{
                                                    minWidth: "40px",
                                                    minHeight: "40px",
                                                    marginRight: "-10px",
                                                  }}
                                                  src={`${imagehttp}${result.profilePicture}`}
                                                ></Avatar>
                                                <Typography noWrap>
                                                  <span
                                                    className="colorBlackB hideDotSingleLine fs-13 font_family"
                                                    title={`${result.firstName} ${result.lastName}`}
                                                  >
                                                    {result.firstName}{" "}
                                                    {result.lastName}
                                                  </span>
                                                  <span className="color_7080 capitalize fs-12 font_family">
                                                    {result.roles}
                                                  </span>
                                                </Typography>
                                              </Stack>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                ) : (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          border: "none",
                                          textAlign: "center",
                                          marginTop: "80px",
                                        }}
                                        component="th"
                                        scope="row"
                                        className="cur-pointer color_7080"
                                      >
                                        No Data Found
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                )}
                              </Table>
                            )}
                          </InfiniteScroll>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2" className="p-0 table-mgroup box-shadow">
                  <TableContainer
                    className="nowrap mw-3 "
                    component={Paper}
                    id="assetTable"
                  >
                    <InfiniteScroll
                      dataLength={groupIdAsset.length}
                      next={() => assetScroll()}
                      hasMore={assetHasMore}
                      scrollableTarget="assetTable"
                      className=""
                      loader={
                        <h6
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress
                            style={{
                              color: "#119BFF",
                            }}
                            size={20}
                          />
                        </h6>
                      }
                      endMessage={
                        <p className="noMore">
                          <b>No more data found...</b>
                        </p>
                      }
                    >
                      <Table size="small" aria-label="a dense table">
                        {isLoadingGroupAssets ? (
                          <AssetTab assetArray={assetArray} />
                        ) : (
                          <TableBody>
                            {groupIdAsset.length > 0 ? (
                              <>
                                {groupIdAsset.map(
                                  (result: GroupIdInfo, index) => {
                                    return (
                                      <TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                          paddingBottom: "10px",
                                        }}
                                        className="cur-pointer row_hover_group"
                                        onClick={(event) => {
                                          if (
                                            event.target instanceof
                                              HTMLElement &&
                                            event.target.id === "hashtag_id"
                                          ) {
                                            console.log(
                                              "Clicked on Video cell. Navigation prevented."
                                            );
                                          } else {
                                            getAssetId(
                                              result.id,
                                              result.assetId,
                                              result.title,
                                              result.hashTag
                                            );
                                          }
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            color: "#708090",
                                          }}
                                          component="th"
                                          scope="row"
                                          className="font_family"
                                        >
                                          {result.assetId}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {result.assetContent[0] ? (
                                            // If result.assetContent[0] is not empty
                                            <>
                                              {result.assetContent[0]
                                                .fieldType === "Video" && (
                                                <img
                                                  src={`..${subPath}/assets/images/User_light.svg`}
                                                  alt="Video"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType === "Audio" && (
                                                <img
                                                  src={`..${subPath}/assets/images/Audio_light.svg`}
                                                  alt="Audio"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType === "Document" && (
                                                <img
                                                  src={`..${subPath}/assets/images/Document_line.svg`}
                                                  alt="Document"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType === "Contact" && (
                                                <img
                                                  src={`..${subPath}/assets/images/User_face.svg`}
                                                  alt="Contact"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType === "url" && (
                                                <img
                                                  src={`..${subPath}/assets/images/link_light.svg`}
                                                  alt="URL"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType === "Image" && (
                                                <img
                                                  src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                                  alt="Image"
                                                />
                                              )}
                                              {result.assetContent[0]
                                                .fieldType !== "Video" &&
                                                result.assetContent[0]
                                                  .fieldType !== "Audio" &&
                                                result.assetContent[0]
                                                  .fieldType !== "Document" &&
                                                result.assetContent[0]
                                                  .fieldType !== "Contact" &&
                                                result.assetContent[0]
                                                  .fieldType !== "url" &&
                                                result.assetContent[0]
                                                  .fieldType !== "Image" && (
                                                  <img
                                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                                    alt="Document"
                                                  />
                                                )}
                                            </>
                                          ) : (
                                            // If result.assetContent[0] is empty, render something else here
                                            <img
                                              src={`..${subPath}/assets/images/Document_line.svg`}
                                              alt="Document"
                                            />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          className="desc-gv"
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          <span
                                            className="hideDotMedium colorBlackB font_family"
                                            style={{ width: "100%" }}
                                            title={result.title}
                                          >
                                            {result.title}
                                          </span>
                                          <span
                                            title={result.description}
                                            className="hideDotMedium fs-13 mt-5 font_family"
                                            style={{
                                              color: "#708090",
                                            }}
                                          >
                                            {result.description}
                                          </span>
                                          <div
                                            className="hideDotTag"
                                            style={{ width: "200px" }}
                                          >
                                            {result.hashTag !== "" &&
                                              result.hashTag
                                                .toString()
                                                .split(",")
                                                .map((hashTag: any) => {
                                                  return (
                                                    <span
                                                      className=" fs-13 font_family"
                                                      style={{
                                                        color: "#008cff",
                                                      }}
                                                      id="hashtag_id"
                                                      title={hashTag}
                                                      onClick={() =>
                                                        headerSearch(hashTag)
                                                      }
                                                    >
                                                      {hashTag}
                                                    </span>
                                                  );
                                                })}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          className="vault-div-gv"
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          width="100%"
                                        >
                                          <img
                                            src={`..${subPath}/assets/images/big_vault_img.svg`}
                                            className="maxW30"
                                            alt=""
                                          ></img>{" "}
                                          <span
                                            className="colorBlackB hideDotSingleLine2 font_family"
                                            style={{
                                              marginLeft: "5px",
                                              paddingRight: "10px",
                                            }}
                                            title={result.vaultName}
                                          >
                                            {result.vaultName}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <img
                                            src={
                                              `${imagehttp}${result.profilePicture}` ||
                                              `..${subPath}/assets/images/Ellipse 3.svg`
                                            }
                                            alt=""
                                            style={{
                                              height: "35px",
                                              width: "35px",
                                              borderRadius: "50px",
                                              verticalAlign: "middle",
                                            }}
                                          ></img>{" "}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                            paddingLeft: "0px",
                                            minWidth: "100px",
                                          }}
                                        >
                                          <span
                                            className="hideDotSingleLine colorBlackB capitalize font_family"
                                            style={{ width: "80px" }}
                                            title={result.firstName}
                                          >
                                            {result.firstName}
                                          </span>
                                          <span
                                            style={{
                                              color: "#708090",
                                            }}
                                            className="capitalize font_family"
                                          >
                                            {result.roles}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <img
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                            src={`..${subPath}/assets/images/GramIcon.svg`}
                                            alt=""
                                          ></img>
                                          <span
                                            style={{
                                              verticalAlign: "middle",
                                              marginLeft: "5px",
                                            }}
                                            className="colorBlackB"
                                          >
                                            {result.totalGram}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <img
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                            src={`..${subPath}/assets/images/grayeye.svg`}
                                            alt=""
                                          ></img>{" "}
                                          <span
                                            style={{
                                              verticalAlign: "middle",
                                              marginLeft: "5px",
                                            }}
                                            className="colorBlackB"
                                          >
                                            {result.viewCount}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            border: "none",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <img
                                            style={{
                                              verticalAlign: "middle",
                                            }}
                                            src={`..${subPath}/assets/images/oldchat.svg`}
                                            alt=""
                                          ></img>{" "}
                                          <span
                                            style={{
                                              verticalAlign: "middle",
                                              marginLeft: "5px",
                                            }}
                                            className="colorBlackB"
                                          >
                                            {result.commentCount}
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </TableBody>
                        )}
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </TabPanel>
                <TabPanel id="memberTable" value="3" className="p-0  ">
                  <InfiniteScroll
                    dataLength={groupIdMembers.length}
                    next={() => getMemberList(0)}
                    hasMore={memberHasMore}
                    scrollableTarget="memberTable"
                    className=""
                    loader={
                      <h6
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <CircularProgress
                          style={{
                            color: "#119BFF",
                          }}
                          size={20}
                        />
                      </h6>
                    }
                    endMessage={
                      groupIdMembers.length !== 0 && (
                        <p className="noMore">
                          <b>No more data found...</b>
                        </p>
                      )
                    }
                  >
                    <Grid container className="text-center dropdown_pack p-0">
                      {groupIdMembers.map((result: GroupIdInfo, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={2}
                            xl={2}
                            sx={{ padding: "5px 10px 10px 10px" }}
                          >
                            <Card
                              className={`box_card  ${
                                groupUserRole?.toLowerCase() === "admin" ||
                                userRole?.toLowerCase() === "admin"
                                  ? "cardHWA"
                                  : "cardHW"
                              }`}
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "0px",
                              }}
                            >
                              <CardMedia
                                component="img"
                                className="member_img"
                                image={`${imagehttp}${result.profilePicture}`}
                              />
                              <Box className="m-batch-m-t">
                                {result.badge === "Gold" ? (
                                  <GoldIcon />
                                ) : result.badge === "Silver" ? (
                                  <SilverIcon />
                                ) : (
                                  <BronzeIcon />
                                )}
                                <Typography
                                  // If long text display as ellipsis
                                  style={{
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    color: "#353535",
                                    textTransform: "capitalize",
                                    marginTop: "-5px",
                                  }}
                                  className="hideDotName"
                                  title={result.firstName}
                                >
                                  {" "}
                                  <span
                                    className="font_family fs-12 fw-600"
                                    style={{ color: "#353535" }}
                                  >
                                    {result.firstName}
                                  </span>
                                </Typography>
                                <Typography
                                  className="font_family color_7080 fs-11 hideDotName"
                                  sx={{
                                    maxHeight: "20px",
                                    minHeight: "20px",
                                  }}
                                >
                                  {result.designation}
                                </Typography>
                                <Typography
                                  className="font_family hideDotName fs-11"
                                  sx={{
                                    color:
                                      "var(--components-switch-slide-fill, #000)",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {result.roles}
                                </Typography>
                              </Box>

                              <Box className="dropdown_pack mt-7">
                                {groupUserRole?.toLowerCase() === "admin" ||
                                userRole?.toLowerCase() === "admin" ? (
                                  <>
                                    <Button
                                      title="You can't change admin role"
                                      variant="contained"
                                      className="fs-11"
                                      disabled={
                                        result.id === createdBy &&
                                        loginUser === result.id &&
                                        result.roles.toLowerCase() === "admin"
                                      }
                                      aria-controls={`dropdown-menu-${index}`}
                                      aria-haspopup="true"
                                      onClick={(event) =>
                                        handleClick(event, index)
                                      }
                                      endIcon={
                                        isOpenArray[index] ? (
                                          <div className="cu-pointer">
                                            {" "}
                                            <img
                                              src={`..${subPath}/assets/images/drop_up.svg`}
                                              className="dropImg"
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            {" "}
                                            <img
                                              src={`..${subPath}/assets/images/drop_down.svg`}
                                              className="dropImg"
                                              alt=""
                                            />
                                          </div>
                                        )
                                      }
                                    >
                                      {result.roles}
                                    </Button>
                                  </>
                                ) : (
                                  <span className="color_7080 fs-11 capitalize roleText">
                                    {result.roles === "viewer"
                                      ? "View Only"
                                      : result.roles}
                                  </span>
                                )}
                              </Box>
                              <Menu
                                className="dropdown_font"
                                id={`dropdown-menu-${index}`}
                                anchorEl={anchorElArray[index]}
                                open={isOpenArray[index]}
                                onClose={() => handleClose(index)}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleTableRowMemberInfo(
                                      result.id,
                                      "contributor"
                                    );
                                    handleClose(index);
                                  }}
                                >
                                  Contributor
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleTableRowMemberInfo(
                                      result.id,
                                      "viewer"
                                    );
                                    handleClose(index);
                                  }}
                                >
                                  Viewer
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleTableRowMemberInfo(
                                      result.id,
                                      "admin"
                                    );
                                    handleClose(index);
                                  }}
                                >
                                  Admin
                                </MenuItem>
                              </Menu>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </InfiniteScroll>
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Box>
      )}

      {/* Add end */}
      {/* </Grid>
      </Box> */}

      {/* group modal  */}
      <div>
        <BootstrapDialog
          onClose={ValutClose}
          aria-labelledby="customized-dialog-title"
          open={Valut}
        >
          <Grid className="d-flex justify-content-between">
            <DialogTitle
              id="customized-dialog-title"
              className="fs-16 modal-headtext"
            >
              Edit Vault
            </DialogTitle>
            <DialogTitle className="cur-pointer">
              <img
                src={`..${subPath}/assets/images/close_b.svg`}
                width="20"
                onClick={ValutClose}
                alt=""
              />
            </DialogTitle>
          </Grid>

          <DialogContent dividers>
            <Form
              name="form-name"
              layout="vertical"
              onFinish={onFinish}
              className="c_vault_form"
            >
              <Form.Item name="selectGroup" className="m-text">
                <label htmlFor="selectGroup">
                  <span className="m-lbl">Select Group</span>
                  <span
                    className="lbl-man"
                    style={{ color: "var(--1, #119BFF)" }}
                  >
                    *
                  </span>
                </label>
                <Box className="custom-input-container">
                  {fileName === expectedFileName ? (
                    <Avatar
                      className="vaultAvatar"
                      style={{
                        backgroundColor: randomColor,
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        position: "absolute",
                        top: "58%",
                        left: "7px",
                        zIndex: "1",
                      }}
                    >
                      <span style={{ top: "-3px", fontSize: "14px" }}>
                        {groupId?.groupName
                          ? groupId?.groupName
                              .split(" ")
                              .map((word: any, index: number) =>
                                index < 2 ? word.charAt(0).toUpperCase() : ""
                              )
                              .join("")
                          : ""}
                      </span>
                    </Avatar>
                  ) : (
                    <img
                      src={groupId?.groupIcon || ""}
                      alt="Group Icon"
                      className="input-image"
                      style={{
                        width: "24px",
                        height: "24px",
                        position: "absolute",
                        top: "30px",
                        left: "7px",
                        zIndex: "1",
                      }}
                    />
                  )}
                  <Input
                    id="group"
                    className="mt-5 fs-14 fw-500 colorBlack input-text"
                    placeholder="Group"
                    value={groupId?.groupName}
                    title="Group"
                    readOnly
                    style={{ paddingLeft: "48px" }}
                  />
                </Box>

                {/* </div> */}
              </Form.Item>
              <Form.Item name="name" className="m-text mt-15">
                <label htmlFor="name">
                  <span className="m-lbl">Name of Vault</span>
                  <span
                    className="lbl-man"
                    style={{ color: "var(--1, #119BFF)" }}
                  >
                    *
                  </span>
                </label>
                <Input
                  id="EvName"
                  placeholder="Enter Vault Name"
                  title="Name"
                  className="field name mt-5  fs-14 fw-500 colorBlack"
                  value={nameEdited}
                  maxLength={maxLengthvalidation}
                  onChange={(e) => setNameEdited(e.target.value)}
                />
                <span id="nameEvError" style={{ color: "red" }}></span>
              </Form.Item>
              <DialogActions>
                <button
                  type="button"
                  onClick={ValutClose}
                  className="cancel_btn"
                >
                  Cancel
                </button>
                <button type="submit" className="m-button sidebar-c-b ">
                  Update Changes
                </button>
              </DialogActions>
            </Form>
          </DialogContent>
        </BootstrapDialog>
      </div>
      {/* member modal  */}
      <div>
        <GroupEditModal
          isOpen={isModalVisible}
          closeModal={closeModal}
          refreshSidebarGroup={refreshSidebarGroup}
        ></GroupEditModal>
      </div>

      {/*Group Delete Confirmation Model */}
      <div>
        <BootstrapDialog
          className="delete_modal"
          onClose={handleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDelete}
        >
          <DialogTitle
            id="customized-dialog-title"
            className="fs-16 modal-headtext"
          >
            Delete Confirmation
          </DialogTitle>
          <DialogContent dividers>
            <Typography className="fs-14">
              Are you sure you want to delete?
            </Typography>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={handleCloseDelete}
              className="cancel_btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="m-button"
              autoFocus
            >
              Delete
            </button>
          </DialogActions>
        </BootstrapDialog>
      </div>

      {/*Vault Delete Confirmation Model */}
      <div>
        <BootstrapDialog
          className="delete_modal"
          onClose={handleCloseDeleteVault}
          aria-labelledby="customized-dialog-title"
          open={openDeleteVault}
        >
          <DialogTitle
            id="customized-dialog-title"
            className="fs-16 modal-headtext"
          >
            Delete Confirmation
          </DialogTitle>
          <DialogContent dividers>
            <Typography className="fs-14">
              Are you sure you want to delete?
            </Typography>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={handleCloseDeleteVault}
              className="cancel_btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleVaulteDelete}
              className="m-button"
              autoFocus
            >
              Delete
            </button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <Vault
        isOpen={isModalVaultVisible}
        closeVaultModal={closeVaultModal}
        refreshSidebarVault={refreshSidebarVault}
        GrpId={grpId}
      ></Vault>
    </>
  );
};

export default PackageID;
