const initialState = {
  chatList: [],
  userInfo: [],
  isMediaView: false,
  imageURL: " ",
};

const chatListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "CHAT_LIST":
      return {
        ...state,
        chatList: action.payload,
      };
    case "USER_DATA":
      return {
        ...state,
        userInfo: action.payload,
      };
      case "MEDIA_VIEW":
        return{
          ...state,
          isMediaView: action.payload.isMediaView,
          imageURL: action.payload.imageURL, 
        }
    default:
      return state;
  }
};

export default chatListReducer;