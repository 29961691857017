import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Settings.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Grid,
  Button,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import eventEmitter from "../../../Constants/EventEmiter";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName } from "../../../Redux/Search/action";

//Componented Start
const Settings: React.FC = () => {
  const api = UseAxios();
  const dispatch = useAppDispatch();
  const [isGroupSwitchOn, setIsGroupSwitchOn] = useState<boolean>(true);
  const [isIndividualSwitchOn, setIsIndividualSwitchOn] =
    useState<boolean>(true);
  const [isGramsSwitchOn, setIsGramsSwitchOn] = useState<boolean>(true);
  const [isCommentsSwitchOn, setIsCommentsSwitchOn] = useState<boolean>(true);
  const [isMentionsSwitchOn, setIsMentionsSwitchOn] = useState<boolean>(true);

  // Fetch and set Notification Settings
  const getNotifySettings = async () => {
    try {
      await api
        .get(`mail-notification/get-settings`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.data;
            setIsGroupSwitchOn(
              data.groupNotification !== null ? data.groupNotification : true
            );
            setIsIndividualSwitchOn(
              data.individualNotification !== null
                ? data.individualNotification
                : true
            );
            setIsGramsSwitchOn(data.grams !== null ? data.grams : true);
            setIsCommentsSwitchOn(
              data.comments !== null ? data.comments : true
            );
            setIsMentionsSwitchOn(data.mention !== null ? data.mention : true);

            if (data.individualNotification === false) {
              setDisabled();
            } else {
              setDisabledOff();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getNotifySettings();
    dispatch(setItemName(""));
  }, []);

  const onToggleSwitch = () => {
    setIsGroupSwitchOn(!isGroupSwitchOn);
  };

  const onToggleIndividualSwitch = () => {
    setIsIndividualSwitchOn(!isIndividualSwitchOn);
    if (isIndividualSwitchOn !== false) {
      // Set Grams, Comments and Mentions to disabled using Id
      setDisabled();
    } else {
      setDisabledOff();
    }
  };

  const individualElements = (type: boolean) => {
    const gramsSwitch = document.getElementById(
      "grams-switch"
    ) as HTMLInputElement;
    gramsSwitch.disabled = type;
    const commentsSwitch = document.getElementById(
      "comments-switch"
    ) as HTMLInputElement;
    commentsSwitch.disabled = type;
    const mentionsSwitch = document.getElementById(
      "mentions-switch"
    ) as HTMLInputElement;
    mentionsSwitch.disabled = type;
  };

  const setDisabled = () => {
    setIsGramsSwitchOn(false);
    setIsCommentsSwitchOn(false);
    setIsMentionsSwitchOn(false);
    individualElements(true);
  };

  const setDisabledOff = () => {
    individualElements(false);
  };

  const onToggleGramsSwitch = () => {
    setIsGramsSwitchOn(!isGramsSwitchOn);
  };

  const onToggleCommentsSwitch = () => {
    setIsCommentsSwitchOn(!isCommentsSwitchOn);
  };

  const onToggleMentionsSwitch = () => {
    setIsMentionsSwitchOn(!isMentionsSwitchOn);
  };

  // Update Notification Settings
  const updateNotifySettings = async (type: string) => {
    let body = {
      groupNotification: isGroupSwitchOn,
      individualNotification: isIndividualSwitchOn,
      grams: isGramsSwitchOn,
      comments: isCommentsSwitchOn,
      mention: isMentionsSwitchOn,
    };
    switch (type) {
      case "Group":
        onToggleSwitch();
        body.groupNotification = !isGroupSwitchOn;
        break;
      case "Individual":
        onToggleIndividualSwitch();
        body.individualNotification = !isIndividualSwitchOn;
        break;
      case "Grams":
        onToggleGramsSwitch();
        body.grams = !isGramsSwitchOn;
        break;
      case "Comments":
        onToggleCommentsSwitch();
        body.comments = !isCommentsSwitchOn;
        break;
      case "Mentions":
        onToggleMentionsSwitch();
        body.mention = !isMentionsSwitchOn;
        break;
      default:
        break;
    }
    await api
      .post(`mail-notification/save-settings`, body)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          justifyContent="center"
          sx={{ padding: "12px 12px 0px 12px" }}
        >
          <Grid
            item
            xs={12}
            sm={2.5}
            md={2.5}
            lg={2.5}
            xl={2.5}
            className="sidebarH"
          >
            <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
              <CardContent>
                <Grid
                  item
                  container
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={12}
                    sx={{ display: "flex", alignItems: "self-end", mt: 1 }}
                  >
                    <Grid item sx={{ display: "flex", alignItems: "unset" }}>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        Settings
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box className="sidebar_scroll">
                  <Grid item container>
                    <Grid item xl={12} lg={12} sm={12} sx={{ mt: 1 }}>
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: "#708090",
                            fontSize: "15px",
                          }}
                        >
                          Manage your Settings
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                      <Grid item>
                        <Button
                          variant="contained"
                          className="settingActive text_small"
                          startIcon={<MailOutlineIcon />}
                          sx={{
                            fontSize: "16px",
                            width: "100%",
                          }}
                          size="large"
                        >
                          Email Notification
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
            <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
              <CardContent>
                <Grid container>
                  <Grid item container sx={{ mt: 1 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", alignItems: "unset" }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        Group Notification
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              onClick={() => updateNotifySettings("Group")}
                              checked={isGroupSwitchOn}
                            />
                          }
                          label=""
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item container sx={{ mt: 1 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", alignItems: "unset" }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                      >
                        Individual Notification
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              id="individual-switch"
                              onClick={() => updateNotifySettings("Individual")}
                              checked={isIndividualSwitchOn}
                            />
                          }
                          label=""
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                }}
                              >
                                On grams
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className="switch-toggler"
                            control={
                              <Switch
                                id="grams-switch"
                                onClick={() => updateNotifySettings("Grams")}
                                checked={isGramsSwitchOn}
                              />
                            }
                            label=""
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                }}
                              >
                                On comments
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className="switch-toggler"
                            control={
                              <Switch
                                id="comments-switch"
                                onClick={() => updateNotifySettings("Comments")}
                                checked={isCommentsSwitchOn}
                              />
                            }
                            label=""
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "18px",
                                  fontWeight: "700",
                                }}
                              >
                                On mentions
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            className="switch-toggler"
                            control={
                              <Switch
                                id="mentions-switch"
                                onClick={() => updateNotifySettings("Mentions")}
                                checked={isMentionsSwitchOn}
                              />
                            }
                            label=""
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
