import axios from "axios";
import {chat_http } from "./Global";

const baseURL = chat_http;
const createAxiosInstance = () => {
  return axios.create({
    baseURL,
  });
};

const ChatAxios = () => {


  // Create an axios instance
  const axiosInstance = createAxiosInstance();

  axiosInstance.interceptors.request.use(async (req: any) => {

    return req;
  });

  return axiosInstance;
};


export default ChatAxios;
