import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import LandingPage from "../Components/Skeleton/LandingPage";

const SuccessHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Parse URL parameters using query-string
    const urlParams = queryString.parse(window.location.search);

    const tokenstatus = urlParams.Token;
    let tokencode;
    if (typeof tokenstatus === "string") {
      const decoded = jwtDecode(tokenstatus);
      if ("code" in decoded) {
        tokencode = decoded.code;
      }
    }
    // const statusCode = new URLSearchParams(location.search).get("code");
    if (tokencode == 200) {
      localStorage.setItem("KM_Admin", JSON.stringify(urlParams));
      localStorage.setItem("KM_Tool", "true");
      localStorage.setItem("logout", "outlook");
      //   const adminLogin = adminLoginString ? JSON.parse(adminLoginString) : null;
      navigate("/landingAssetView");
    } else if (tokencode) {
      toast.error("User Not Found", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
      const rUrl = new URLSearchParams(location.search).get("redirectUrl");

      // After 5 seconds, redirect to the login page
      // setTimeout(() => {
      //   window.location.href = rUrl ? rUrl : "/";
      // }, 5000);

      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <LandingPage />
    </>
  );
};
export default SuccessHandler;
