
import React, { createContext, useContext, useState } from 'react';


interface MyContextType {
  data: string;
  setData: React.Dispatch<React.SetStateAction<string>>;
  
}

// Create the context
const MyContext = createContext<MyContextType | undefined>(undefined);

// Create a custom hook to access the context
export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

interface MyContextProviderProps {
    children: React.ReactNode; // Specify children prop
  }
// Create a provider component
export const MyContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const [data, setData] = useState<string>('');
 
  


  return (
    <MyContext.Provider value={{ data, setData}}>
      {children}
    </MyContext.Provider>
  );
};
