import { configureStore } from "@reduxjs/toolkit";
import searchReducer from "./Redux/Search/reducer";
import chatListReducer from "./Redux/chatList/reducer";
import ChatReducer from "./Redux/Chatview/reducer";
import GroupChatReducer from "./Redux/GroupChat/reducer";
import chatReducerCard from "./Redux/MessageBox/reducter";

const isDevelopment = process.env.NODE_ENV === "development";

export const store = configureStore({
  reducer: {
    search: searchReducer,
    chatListData: chatListReducer,
    chatGetMessage: ChatReducer,
    groupChat: GroupChatReducer,
    chatVideo: chatReducerCard,
  },
  // Enable Redux DevTools only in development
  devTools: isDevelopment,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
