import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Radio from "@mui/material/Radio";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import "../Homepage/Home.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decodeToken, subPath } from "../../../Constants/Global";
import { useNavigate } from "react-router-dom";
import "../../../Layout/sidebar.css";
import { InputBase, styled } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { ReactFormGenerator } from "react-form-builder2";
import UseAxios from "../../../Constants/UseAxios";

const Search = styled("div")(({ theme }) => ({
  justifyContent: "flex-end",
  borderRadius: "6px",
  background: "#F5F5F5",
  maxHeight: "29px",
}));
interface TemplateData {
  id: string;
  title: string;
  description: string;
  favotite: boolean;
}
interface StyledTableRowProps {
  isSelected: boolean;
}
const StyledTableRow = styled(TableRow)<StyledTableRowProps>(
  ({ theme, isSelected }) => ({
    backgroundColor: isSelected ? "#A0D7FF" : "inherit",
    color: isSelected ? "white" : "inherit",
    "&:last-child td, &:last-child th": {
      borderBottom: "0px",
    },
  })
);
const useStyles = makeStyles((theme) => ({
  dottedOutline: {
    border: "2px dotted #999",
  },
}));
const PreviewTemplate: React.FC = () => {
  const api = UseAxios();
  const renderAfterCalled = useRef(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [temData, setTemData] = useState<TemplateData[]>([]);
  const [addAsset, setAddAsset] = useState<string[]>([]);
  const [templateId, setTemplateId] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<TemplateData[]>([]);
  const userid = decodeToken().id;
  const companyId = decodeToken().companyId;
  const navigate = useNavigate();

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchTemplateData();
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, []);

  const handleTableRowClick = async (id: string) => {
    setTemplateId(id);
    setSelectedRow(id);
    try {
      const res = await api.get(`template/get?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAddAsset(res.data.data.formData);
    } catch (error) {}
  };

  const fetchTemplateData = async () => {
    try {
      const response = await api.get(`template/favorite?userId=${userid}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        const newData = response.data.data;
        setTemData(newData);
        setFilteredData(newData);
      }
    } catch (error) {}
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const [assetDataArray, setAssetDataArrayState] = useState<any[]>([]);
  console.log(assetDataArray, "assetDataArray");
  // Get template require data
  const getTemplateData = async (TemplateId: string) => {
    const requiredElements = document.getElementsByClassName("label-required");
    for (let i = 0; i < requiredElements.length; i++) {
      requiredElements[i].remove();
    }

    try {
      const response = await api.get(
        `template/get?id=${TemplateId}&companyId=${companyId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        let newData = response.data.data.formData;
        setAssetDataArrayState(newData);
        newData.forEach((item: any) => {
          if (
            item.field_name &&
            item.text !== "Tags" &&
            item.text !== "Checkboxes" &&
            item.text !== "Multiple Choice"
          ) {
            const fieldsName = document.getElementsByName(item.field_name)?.[0];
            if (fieldsName && item.required.toString() == "true") {
              if (fieldsName.parentNode) {
                const firstChild = fieldsName.parentNode.firstChild;
                if (firstChild) {
                  const span = document.createElement("span");
                  span.innerHTML = "Required";
                  span.classList.add("label-required");
                  span.classList.add("badge");
                  span.classList.add("badge-danger");
                  firstChild.appendChild(span);
                }
              }
            }
          } else {
            if (item.text === "Tags") {
              const fieldsName = document.getElementsByName(
                item.field_name
              )?.[0];
              if (fieldsName && item.required.toString() == "true") {
                if (fieldsName.parentNode?.parentNode?.parentNode) {
                  const firstChild =
                    fieldsName.parentNode?.parentNode?.parentNode.firstChild;
                  if (firstChild) {
                    const span = document.createElement("span");
                    span.innerHTML = "Required";
                    span.classList.add("label-required");
                    span.classList.add("badge");
                    span.classList.add("badge-danger");
                    firstChild.appendChild(span);
                  }
                }
              }
            }
            if (item.text === "Multiple Choice" || item.text === "Checkboxes") {
              let fieldsName;
              if (item.text === "Multiple Choice") {
                fieldsName = document.getElementsByName(item.field_name)?.[0];
              } else {
                fieldsName = document.getElementsByName(
                  "option_" + item.options[0].key
                )?.[0];
              }
              if (fieldsName && item.required.toString() == "true") {
                if (fieldsName.parentNode?.parentNode) {
                  const firstChild =
                    fieldsName.parentNode?.parentNode?.firstChild;
                  if (firstChild) {
                    const span = document.createElement("span");
                    span.innerHTML = "Required";
                    span.classList.add("label-required");
                    span.classList.add("badge");
                    span.classList.add("badge-danger");
                    firstChild.appendChild(span);
                  }
                }
              }
            }
          }
        });
      }
    } catch (error) {}
  };
  const handleCreateKnowledgeClick = () => {
    if (addAsset.length > 0 && templateId) {
      navigate(`/staticAsset?state2=${encodeURIComponent(templateId)}`);
    } else {
      toast.error("Please Select Template", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        closeOnClick: false,
      });
    }
  };
  const handleButtonClick = () => {
    navigate(-1);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchValue(searchTerm);

    // Use the filter method to filter the array
    const filteredArray = filteredData.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setTemData(filteredArray);
  };

  return (
    <Grid item container className="center_card p-0" sx={{ display: "block" }}>
      <Grid
        container
        className="font_family  borderbottom p-10 d-flex align-items-center justify-content-between"
        sx={{ maxHeight: "55px" }}
      >
        <Typography
          className="font_family"
          sx={{ color: "#000", fontWeight: "600", fontSize: "16px" }}
        >
          Templates
        </Typography>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Search>
            <div className="d-flex align-items-center ">
              <img
                src={`..${subPath}/assets/images/search.svg`}
                className="search mt-5 pl-55 font_family"
                width="10px"
                height="13px"
                style={{ position: "relative", top: "-3px", right: "45px" }}
              />
              <InputBase
                style={{ right: "90px" }}
                className="fs-12 pl-55 "
                placeholder="Search Template"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </Search>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => {
              setSelectedValue("");
              setAssetDataArrayState([]);
              setSelectedRow("");
              setTemplateId("");
            }}
          >
            <path
              d="M18.2968 5.70997C17.9068 5.31997 17.2768 5.31997 16.8868 5.70997L11.9968 10.59L7.1068 5.69997C6.7168 5.30997 6.0868 5.30997 5.6968 5.69997C5.3068 6.08997 5.3068 6.71997 5.6968 7.10997L10.5868 12L5.6968 16.89C5.3068 17.28 5.3068 17.91 5.6968 18.3C6.0868 18.69 6.7168 18.69 7.1068 18.3L11.9968 13.41L16.8868 18.3C17.2768 18.69 17.9068 18.69 18.2968 18.3C18.6868 17.91 18.6868 17.28 18.2968 16.89L13.4068 12L18.2968 7.10997C18.6768 6.72997 18.6768 6.08997 18.2968 5.70997Z"
              fill="#119BFF"
            />
          </svg>
        </Grid>
      </Grid>
      <Grid container className="mb-15">
        <Grid
          item
          xs={3.5}
          sx={{
            width: "100%",
          }}
          className="plr-25"
        >
          <TableContainer className="template_table">
            <Table sx={{ border: "none" }} aria-label="simple table">
              <TableBody sx={{ border: "none", cursor: "pointer" }}>
                {temData?.map((row: TemplateData, index) => (
                  <>
                    <StyledTableRow
                      sx={{ cursor: "pointer", border: "none" }}
                      key={row.id}
                      isSelected={selectedRow === row.id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="star"
                        sx={{
                          width: "1%",
                          border: "none",
                          margin: 0,
                          padding: "5px 24px",
                        }}
                      >
                        {row.favotite === false ? (
                          <img src={`..${subPath}/assets/images/star.svg`} />
                        ) : (
                          <img
                            src={`..${subPath}/assets/images/fav_star.svg`}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="font_family "
                        sx={{
                          border: "none",
                          width: "80% ",
                          fontSize: "14px",
                          fontFamily: 400,
                          margin: 0,
                          padding: "5px 24px",
                        }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className=" font_family "
                        sx={{ border: "none", margin: 0, padding: "5px 24px" }}
                      >
                        <Radio
                          {...controlProps(row.id)}
                          onClick={() => {
                            getTemplateData(row.id);
                            handleTableRowClick(row.id);
                          }}
                          sx={{
                            border: "none",
                            color: "#119BFF",
                            "&.Mui-checked": {
                              color: "#119BFF",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 16, // Adjust the font size to control the size of the radio button
                            },
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8.5} sx={{ marginTop: "1%" }}>
          <Grid className="inputtemplatepreviewDnD">
            <Grid
              style={{
                display: selectedValue ? "" : "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {selectedValue ? (
                <ReactFormGenerator
                  form_action="/"
                  form_method="POST"
                  action_name="Save"
                  data={assetDataArray}
                  skip_validations={true}
                />
              ) : (
                <span
                  className="  font-weight-bold"
                  style={{
                    fontStyle: "italic",
                    fontSize: "14px",
                    color: "#119BFF",
                  }}
                >
                  Select Template to Preview
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          marginRight: "7px",
          paddingBottom: "15px",
        }}
      >
        <>
          <span
            className="sidebar-c-b smallAshBold cur-pointer"
            style={{ marginRight: "35px", marginTop: "8px" }}
            onClick={handleButtonClick}
          >
            Cancel
          </span>
          <span
            className="m-button sidebar-c-b "
            style={{
              cursor: "pointer",
            }}
            onClick={handleCreateKnowledgeClick}
          >
            Select Template
          </span>
        </>
      </Grid>
    </Grid>
  );
};
export default PreviewTemplate;
