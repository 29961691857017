// import React from 'react';
import { createRoot } from "react-dom/client";
// import ReactDOM from 'react-dom';
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";

const rootElement = document.getElementById("form-builder");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
} else {
  console.error("Element with id 'form-builder' not found");
}

// ReactDOM.render(
//   <App />,
//   document.getElementById('form-builder'),
// );
