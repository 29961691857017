import { Avatar, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../../../Constants/hooks";

interface Member {
  fullname: string;
  profilePicture: string;
  role: string;
}

interface GroupList {
  members: Member[];
}
const ProfileMember: React.FC = () => {
  const [visibleMembers, setVisibleMembers] = useState<Member[]>([]);
  const [showAllMembers, setShowAllMembers] = useState(false); 

  const Grouplist: GroupList = useAppSelector(
    (state: any) => state?.groupChat?.groupDetails || { members: [] }
  );

  useEffect(() => {
    if (Grouplist.members) {
      if (showAllMembers) {
        setVisibleMembers(Grouplist.members);
      } else {
        setVisibleMembers(Grouplist.members.slice(0, 3)); 
      }
    }
  }, [Grouplist, showAllMembers]);

  const toggleMembers = () => {
    setShowAllMembers(!showAllMembers); 
  };
  return (
    <div>
      {visibleMembers && visibleMembers.length > 0 ? (
        <>
          {visibleMembers.map((memberList: Member, index: number) => (
            <Grid container sx={{ padding: "10px" }} key={index}>
              <Grid item xs={2} md={2} lg={2}>
                <Avatar
                  alt={memberList.fullname}
                  src={memberList.profilePicture}
                  sx={{
                    borderRadius: "6px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={6.5}
                lg={6.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography className="Person_Circle">
                  {memberList.fullname}
                </Typography>
              </Grid>
              {memberList.role === "ADMIN" && (
                <Grid
                  item
                  xs={4}
                  sm={3.5}
                  lg={3.5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button className="admin_btn_circle">
                    <span className="admin_label_circle">ADMIN</span>
                  </button>
                </Grid>
              )}
            </Grid>
          ))}
  
            <Grid
              item
              onClick={toggleMembers}
              sx={{
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontWeight: 500,
                color: "#119BFF",
                cursor: "pointer",
              }}
            >
            {showAllMembers ? "View less" : "View more"}  
            </Grid>
        </>
      ) : (
        <Typography>No members found</Typography>
      )}
    </div>
  );
};

export default ProfileMember;
