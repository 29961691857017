import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { subPath } from "../../Constants/Global";
import { useLocation } from "react-router-dom";

const EmptyAsset: React.FC = (any) => {
    const location = useLocation();
    let errorMessage = location.state;
    return (
        <Box
            className="center-grid center_card bg-right"
        >
            <Grid
                item
                container
                className="  d-unset p-10"
            >

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: "#708090",
                    }}
                >
                    <Box
                        component="img"
                        src={`..${subPath}/assets/images/no-data-empty.jpg`}
                    />
                    <h3>{errorMessage}</h3>
                </div>
            </Grid>
        </Box>
    );
};
export default EmptyAsset;