import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { subPath } from "../../../../Constants/Global";

const ChatProfile: React.FC<any> = (chatData) => {
  const userimg = [
    { src: "/assets/images/images/view_img1.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/user_profile_img.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/view_img2.png" },

    { src: "/assets/images/images/view_img2.png" },
  ];
  const filedata = [
    {
      filesrc: "/assets/images/images/File_icon.png",
      fileName: "CITPL Spotlight - Dec 2024.pdf",
      fileSubName: "635 KB pdf",
      arrowSrc: "/assets/images/images/lucide_forward.png",
    },
    {
      filesrc: "/assets/images/images/Music_icon.png",
      fileName: "CITPL Spotlight - Dec 2024.mp3",
      fileSubName: "00 : 35",
      arrowSrc: "/assets/images/images/lucide_forward.png",
    },
  ];
  console.log(chatData, "chatDataprofile");

  return (
    <>
      {chatData.chatData ? (
        <Grid
          item
          xs={2.48}
          md={2.5}
          lg={2.54}
          sx={{
            position: "fixed",
            right: 0,
            top: "125px",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "80%",
              overflowY: "auto",
              marginBottom: "10px",
            }}
          >
            <Grid item>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "55px",
                }}
              >
                <IconButton
                  className="profile_icon"
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <Avatar
                    alt="Profile"
                    style={{
                      borderRadius: "25%",
                      height: "100px",
                      width: "100px",
                    }}
                    src={chatData.chatData.recipientProfilePicture}
                  />
                </IconButton>
              </Grid>
              <Grid
                item
                sx={{
                  paddingTop: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#000000",
                    fontFamily: "Montserrat",
                    textAlign: " center",
                    lineHeight: "17.07px",
                  }}
                >
                  {chatData.chatData.recipientName} /&nbsp;{" "}
                  {chatData.chatData.recipientDepartment} / &nbsp;{" "}
                  {chatData.chatData.recipientDesignation}
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: "14px",
                      paddingTop: "10px",
                      textAlign: " center",

                      color: "#00000099",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                      lineHeight: "17.07px",
                    }}
                  >
                    {chatData.chatData.recipientDesignation} &nbsp; / &nbsp;
                    {chatData.chatData.recipientDepartment}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      textAlign: " center",
                      color: "#00000099",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                      lineHeight: "17.07px",
                    }}
                  >
                    6434
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "20px" }}>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Avatar
                  alt="Profile"
                  style={{
                    borderRadius: "25%",
                    height: "20px",
                    width: "20px",
                  }}
                  src={`..${subPath}/assets/images/images/call_chat.png`}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    lineHeight: "17.07px",
                  }}
                >
                  95848 38399
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: "20px" }}>
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Avatar
                  alt="Profile"
                  style={{
                    borderRadius: "25%",
                    height: "20px",
                    width: "20px",
                  }}
                  src={`..${subPath}/assets/images/images/sms_chat.png`}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className="email_lable_Chat"
                  title={chatData.chatData.recipientEmailId}
                >
                  {chatData.chatData.recipientEmailId}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "20px" }} />
            <Box sx={{ marginTop: "10px", padding: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className="Media_lable_Chat">Media</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="view_lable_chat">View All</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <Grid item sx={{ display: "flex", overflowX: "auto" }}>
                {userimg.map((item, index) => (
                  <Grid item xs={3} sx={{ marginRight: "10px" }} key={index}>
                    <Avatar
                      alt={`Profile ${index + 1}`}
                      style={{
                        borderRadius: "5%",
                        height: "50px",
                        width: "50px",
                      }}
                      src={item.src}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "10px" }}>
              <Grid item className="Files_lable_chat">
                Files
              </Grid>
              <Box sx={{ marginTop: "12px" }}>
                {filedata.map((fileItems, index) => (
                  <Grid
                    container
                    sx={{
                      backgroundColor: "#F6F6F6",
                      height: "48px",
                      borderRadius: "4px",
                      marginTop: "8px",
                    }}
                  >
                    <Grid
                      item
                      xs={2.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item className="file_icon_profile">
                        <Avatar
                          alt={`Profile ${index + 1}`}
                          style={{
                            borderRadius: "5%",
                            height: "24px",
                            width: "24px",
                          }}
                          src={fileItems.filesrc}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={7.7}>
                      <Grid item sx={{ marginTop: "5px" }}>
                        <Typography className="file_title_chat">
                          {fileItems.fileName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#708090",
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                            fontWeight: 400,
                            lineHeight: "14.63px",
                          }}
                        >
                          {fileItems.fileSubName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1.8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={`Profile ${index + 1}`}
                        style={{ width: "24px", height: "24px" }}
                        src={fileItems.arrowSrc}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
export default ChatProfile;
