import React from 'react';

interface ImagePreviewProps {
  image: File;
  onImageSelected: () => void;

}


const PreviewAttachment: React.FC<any> = ({ image, onImageSelected }) => {
  console.log("image in preview ", image)
  const imageUrl = image.previewUrl

  return (
    <>
    
    <div className="image-preview">
      <img
        src={imageUrl}
        alt="Preview"

      />

    </div>
      {/* <button onClick={onImageSelected}>Upload</button> */}
    </>


  );
};


export default PreviewAttachment;