import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Form, Input, Select, Skeleton } from "antd";
import axios, { AxiosError } from "axios";
import { FiUpload } from "react-icons/fi";
import { decodeToken, imagehttp, subPath } from "../../Constants/Global";
import * as Yup from "yup";
import "../Modal/Modal.css";
import { useLocation } from "react-router-dom";
import eventEmitter from "../../Constants/EventEmiter";
import UseAxios from "../../Constants/UseAxios";

//modal open props
interface GroupModalProps {
  isOpen: boolean;
  closeModal: () => void;
  refreshSidebarGroup: () => void;
}
//select member
interface MemberInfo {
  id: number | null;
  firstName: string;
  lastName: string;
  profilePicture: File;
}

const decoded = decodeToken();
const userRole = decoded?.role?.toLowerCase();

const userId = decoded?.id;

const GroupModal: React.FC<GroupModalProps> = ({
  isOpen,
  closeModal: closeModalFromProps,
  refreshSidebarGroup,
}) => {
  const api = UseAxios();
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [upload, setUpload] = useState<string | null>(null);
  const [selectMember, setSelectMember] = useState<MemberInfo[]>([]);
  const [previousMembers, setPreviousMembers] = useState<MemberInfo[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [updateImage, setUpdateImage] = useState(false);
  const maxSize = 2 * 1024 * 1024;
  const [errorMessage, setErrorMessage] = useState("");
  const [groupId, setGroupId] = useState<string | null>(null);
  const [removedMembers, setRemovedMembers] = useState<string[]>([]); // Array to store the removed members
  const [abortController, setAbortController] = useState(new AbortController());
  const location = useLocation(); // Access the location state
  let grpId = location?.state?.id; // Get the group id from the location state
  const [responseReceived, setResponseReceived] = useState(false);
  const [loading, setLoading] = useState(true);
  const companyId = decodeToken().companyId;
  const handleSearch = (value: string) => {
    fetchSelectUser(value);
  };
  const closeModal = () => {
    setUpload(null);
    setErrorMessage("");
    closeModalFromProps();
    stateEmpty();
  };
  const clearUpload = () => {
    // Function to clear the uploaded image
    setUpdateImage(true);
    setUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const { Option } = Select;

  useEffect(() => {
    eventEmitter.on("triggerEditGrp", (receivedId: any) => {
      if (receivedId) {
        grpId = receivedId;
        setGroupId(receivedId);
        setRemovedMembers([]);
      }
    });

    eventEmitter.on("triggerEditGrp", editGroup);
    return () => {
      eventEmitter.off("triggerEditGrp", editGroup);
    };
  }, []);

  const stateEmpty = () => {
    setName("");
    setDescription("");
    setSelectedMembers([]);
    setUpload(null);
    setResponseReceived(false);
  };

  const fetchSelectUser = async (value: any) => {
    abortController.abort();
    // Create a new abort controller for the current request
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    try {
      const response = await api.get(
        `member/view-members-search-edit?companyId=${companyId}&title=${value}&groupId=${grpId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal: newAbortController.signal,
        }
      );
      if (response.data.status === true) {
        const newData = response.data.data;
        // Set the previous members in the selectMember array without duplicate if they are in the setSelectedMembers array
        const newPreviousMembers = previousMembers.filter((e) =>
          selectedMembers.includes(e.id as number)
        );
        const newMembers = newPreviousMembers.filter(
          (e) => !newData.some((i: any) => i.id === e.id)
        );
        setSelectMember([...newMembers, ...newData]);
        // setSelectMember(newData);
      }
    } catch (erorr) {}
  };

  // Edit Group
  const editGroup = async () => {
    try {
      // fetchSelectUser("");
      setLoading(true);
      api
        .get(`group/fetch`, {
          params: { id: grpId },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const newData = response.data.data;
          setName(newData.name);
          setDescription(newData.description);
          const membersId = newData.members.map((e: any) => e.id);
          setSelectedMembers(membersId);
          setSelectMember(newData.members);
          setPreviousMembers(newData.members);
          setUpload(newData.groupIcon);
          setResponseReceived(true);
        })
        .finally(() => {
          setLoading(false); // Set loading to false regardless of success or error
        });
    } catch (error) {
      // Handle error if needed
    }
  };
  const onFinish = async () => {
    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("field");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields?.[i]?.classList.remove("borderRed");
    }
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      description: Yup.string().required(),
      members: Yup.array().min(1).required(),
    });
    const dataToValidate = {
      name,
      description,
      members: selectedMembers,
    };
    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        const formData = new FormData();
        formData.append("id", grpId ?? groupId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("members", selectedMembers.toString());
        formData.append("upload", fileInputRef.current?.files?.[0] || "");
        formData.append("imgStatus", updateImage.toString());
        try {
          const response = await api.put(`group/update`, formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            },
          });
          refreshSidebarGroup();
          eventEmitter.emit("groupUpdated");
          // Assuming the response contains a success message
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });

          closeModal();
          stateEmpty();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              for (const key in errorArray) {
                var keyVal = errorArray[key].trim().split(":");
                const errorField = document.getElementById(
                  keyVal[0] + "EgError"
                );
                const inputField = document.getElementsByClassName(
                  keyVal[0].trim() + "Eg"
                );
                inputField?.[0]?.classList.add("borderRed");
                if (errorField) {
                  errorField.innerHTML =
                    keyVal[1].charAt(0).toUpperCase() +
                    keyVal[1].slice(1) +
                    ".";
                }
              }
            } else {
              toast.error(error.response?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.error((error as AxiosError).message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "EgError"
          );
          const inputField = document.getElementsByClassName(
            validationErrors.inner[i].path + "Eg"
          );
          inputField?.[0]?.classList.add("borderRed");
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };

  // Run the function while click the remove button in members dropdown
  const handleDeselect = async (value: string) => {
    const formData = new FormData();
    formData.append("groupId", grpId ?? groupId);
    formData.append("memberId", value);
    await api
      .put(`group/update/member`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
        },
      })
      // if response is false then show error message
      .then((response) => {
        if (
          response.data.status === false &&
          userRole?.toLowerCase() !== "admin"
        ) {
          toast.error("You can't able to remove this member", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          //Add the value in selectedMembers array
          const newSelectedMembers = selectedMembers.filter(
            (e) => e !== parseInt(value)
          );
          setSelectedMembers(newSelectedMembers);
        }
      });

    // Check Atleast one admin is there in the group
    if (userRole?.toLowerCase() === "admin") {
      await api
        .get(
          `group/check-user-admin?groupId=${groupId}&userId=${value}&ignoreIds=${removedMembers}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.data === false) {
            toast.error("Atleast one admin is required in the group", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            //Add the value in selectedMembers array
            const newSelectedMembers = selectedMembers.filter(
              (e) => e !== parseInt(value)
            );
            setSelectedMembers(newSelectedMembers);
          } else if (response.data.data === true) {
            // Set the removed members in the array
            setRemovedMembers([...removedMembers, value]);
          }
        });
    }
  };

  const maxLengthvalidation = 25;
  return (
    <Modal
      width={500}
      title="Edit Group"
      open={isOpen}
      onCancel={closeModal}
      maskClosable={false}
      className="modal-border"
      footer={[
        <span
          style={{
            cursor: loading ? "not-allowed" : "pointer",
            opacity: loading ? 0.5 : 1,
            pointerEvents: loading ? "none" : "auto",
          }}
          key="submit"
          className={`m-button customButton ${loading ? "disabled" : ""}`}
          onClick={responseReceived ? form.submit : undefined}
        >
          {loading ? "Loading..." : "Update Group"}
        </span>,
      ]}
      centered={true}
    >
      {responseReceived ? (
        <Form
          form={form}
          name="form-name"
          layout="vertical"
          onFinish={onFinish}
          className="c_group_form"
        >
          <Form.Item name="name" className="m-text">
            <label htmlFor="name">
              <span className="m-lbl">Name</span>
              <span style={{ color: "var(--1, #119BFF)" }}>*</span>
            </label>
            <Input
              id="name"
              placeholder="Type Here"
              title="Name"
              className="field nameEg fs-14 fw-500 colorBlack"
              value={name}
              maxLength={maxLengthvalidation}
              onChange={(event) => setName(event.target.value)}
            />
            <span
              id="nameEgError"
              style={{ color: "red" }}
              className="error"
            ></span>
          </Form.Item>
          <Form.Item name="description" className="m-text  mt-5">
            <label htmlFor="description">
              <span className="m-lbl">Description</span>
              <span style={{ color: "var(--1, #119BFF)" }}>*</span>
            </label>
            <Input.TextArea
              id="description"
              placeholder="Type Here"
              className="field descriptionEg fs-14 fw-500 colorBlack"
              title="description"
              rows={4}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
            <span
              id="descriptionEgError"
              style={{ color: "red" }}
              className="error"
            ></span>
          </Form.Item>
          <Form.Item
            label={
              <span className="m-lbl">
                Members<span style={{ color: "var(--1, #119BFF)" }}>*</span>
              </span>
            }
            name="members"
            className="m-text mt-5"
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Search member"
              onChange={(values: number[]) => setSelectedMembers(values)}
              value={selectedMembers}
              onSearch={handleSearch}
              showSearch
              filterOption={false}
              className="field membersEg customSelect fs-14 fw-500 colorBlack"
              onDeselect={(value) => handleDeselect(value.toString())}
            >
              <Option value="" disabled className="fs-14 fw-500 colorBlack">
                <span role="img" aria-label="Member1">
                  Choose Member
                </span>
              </Option>

              {selectMember.map((member: MemberInfo, key) => {
                let e = member; // Set e to the current member
                let fullName = `${e.firstName} ${e.lastName}`;
                return (
                  <Option
                    key={key}
                    value={e.id}
                    disabled={e?.id?.toString() === userId}
                    className="fs-14 fw-500 colorBlack "
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="fs-14 fw-500 colorBlack"
                    >
                      <img
                        alt="Profile Picture"
                        src={`${imagehttp}${e.profilePicture}`}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                          borderRadius: "50%", // Change "70%" to "50%" for a circular image
                        }}
                      />
                      {/* {userId == e.id ? "You" : fullName} */}
                      {userId !== null && userId === e?.id?.toString()
                        ? "You"
                        : fullName}
                    </div>
                  </Option>
                );
              })}
            </Select>

            <span
              id="membersEgError"
              style={{ color: "red" }}
              className="error"
            ></span>
          </Form.Item>
          <Form.Item
            name="Add Picture"
            className="m-text m-lbl"
            label="Add Picture"
          >
            <div>
              {upload ? (
                <div>
                  <img
                    src={upload}
                    width="150px"
                    height="150px"
                    className="br_5"
                  />
                </div>
              ) : null}
            </div>

            <input
              type="file"
              // accept="image/*"
              accept=".jpeg, .png, .gif, .bmp, .heif, .svg, .hdr,.jpg"
              multiple
              name="upload"
              style={{ display: "none" }}
              onChange={() => {
                const selectedFile = fileInputRef.current?.files?.[0];
                const acceptedTypes = [
                  ".jpeg",
                  ".jpg",
                  ".png",
                  ".gif",
                  ".bmp",
                  ".heif",
                  ".svg",
                  ".hdr",
                ];
                const isValidFileType = acceptedTypes.some((type) =>
                  selectedFile?.name.toLowerCase().endsWith(type)
                );
                if (isValidFileType && selectedFile) {
                  if (isValidFileType && selectedFile.size <= maxSize) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setUpload(e.target?.result as string);
                    };
                    reader.readAsDataURL(selectedFile);
                    setErrorMessage("");
                  } else {
                    setErrorMessage(
                      `File size must be less than ${
                        maxSize / (1024 * 1024)
                      } MB`
                    );
                    fileInputRef.current && (fileInputRef.current.value = "");
                  }
                } else {
                  setErrorMessage(
                    !isValidFileType
                      ? `Accepted types are: ${acceptedTypes.join(", ")}`
                      : ""
                  );
                  fileInputRef.current && (fileInputRef.current.value = "");
                }
              }}
              ref={fileInputRef}
            />
            {upload ? (
              <img
                src={`..${subPath}/assets/images/solar_trash-bin-2-broken.png`}
                onClick={clearUpload}
              />
            ) : (
              <FiUpload
                color="#119BFF"
                size="23px"
                onClick={() => fileInputRef.current?.click()}
                style={{ cursor: "pointer" }}
              />
            )}
            {errorMessage ? (
              <>
                <div style={{ color: "red", marginTop: "8px" }}>
                  {errorMessage}
                </div>
              </>
            ) : (
              ""
            )}
          </Form.Item>
        </Form>
      ) : (
        <Form
          form={form}
          name="form-name"
          layout="vertical"
          className="c_group_form"
        >
          <Form.Item name="name" className="m-text">
            <label htmlFor="name">
              <span className="m-lbl">Name</span>
              <span style={{ color: "var(--1, #119BFF)" }}>*</span>
            </label>
            <div>
              <Skeleton.Input active className="m-text" />
            </div>
          </Form.Item>
          <Form.Item name="description" className="m-text  mt-5">
            <label htmlFor="description">
              <span className="m-lbl">Description</span>
              <span style={{ color: "var(--1, #119BFF)" }}>*</span>
            </label>
            <Skeleton active />
          </Form.Item>
          <Form.Item
            label={
              <span className="m-lbl">
                Members<span style={{ color: "var(--1, #119BFF)" }}>*</span>
              </span>
            }
            name="members"
            className="m-text mt-5"
          >
            <Skeleton active />
          </Form.Item>

          <Form.Item
            name="Add Picture"
            className="m-text m-lbl"
            label="Add Picture"
          >
            <Skeleton.Avatar
              active
              size={150}
              shape="square"
              className="br_5"
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default GroupModal;
