import React from "react";
import Drawer from "@mui/material/Drawer";
import { useAppDispatch, useAppSelector } from "../../../../../Constants/hooks";
import { Divider, Grid, InputBase, Typography } from "@mui/material";
import { subPath } from "../../../../../Constants/Global";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { theardshow } from "../../../../../Redux/GroupChat/action";

const CircleTheard: React.FC<any> = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const isTheardOpen = useAppSelector((state: any) => state?.groupChat?.isOpen);
  console.log(isTheardOpen, "isTheardOpen");
  const dispatch = useAppDispatch();
  const handleToggleMediaView = () => {
    dispatch(theardshow(!isTheardOpen));
  };

  const userDetails = [
    {
      src: "/assets/images/images/Recepient_photo.png",
      PersonName: "Prem",
      time: "10:00 AM",
      message:
        "That's understandable. Developing a cohesive color scheme can be tricky. How about we schedule some time to brainstorm together? We can bounce ideas off each other and maybe even do a quick mood board to visualize different options.",
    },
    {
      src: "/assets/images/images/Recepient_photo.png",
      PersonName: "Prem",
      time: "12:00 AM",

      message:
        "Absolutely, let's plan for that. In the meantime, feel free to keep experimenting. Don't hesitate to reach out if you need any guidance or feedback along the way.",
    },
  ];
  return (
    <div>
      <Drawer
        open={isTheardOpen}
        onClose={toggleDrawer(false)}
        anchor="right"
        className="theard_drawer"
      >
        <Grid container>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={`..${subPath}/assets/images/images/Theard_img.png`}
              alt="Theard_img"
              style={{ width: "55px", height: "55px", borderRadius: "4px" }}
            />
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Typography
            onClick={handleToggleMediaView}
              sx={{
                fontFamily: "Montserrat",
                fontSize: "13px",
                fontWeight: 600,
                color: "#44464C",
              }}
            >
              Thread
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ overflow: "auto", height: "100%", paddingBottom: "100px" }}
        >
          <Grid item >
            <Timeline position="alternate" className="timeline_connector">
              <TimelineItem>
                <TimelineSeparator>
                  <img
                    src={`..${subPath}/assets/images/images/Recepient_photo.png`}
                    style={{ width: "40px", height: "40px" }}
                    alt="Recepient_photo"
                  />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{ paddingRight: "0px", paddingBottom: "40px" }}
                >
                  <Grid container sx={{ paddingTop: "5px" }}>
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        Santhosh
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Typography
                        sx={{
                          color: "#414B54",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        08:37 AM
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    sx={{
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingTop: "5px",
                    }}
                  >
                    Hi! It's been pretty good, thanks for asking. I've been
                    working on that new project we discussed last week.
                  </Typography>
                </TimelineContent>
              </TimelineItem>
              {userDetails.map((user, index) => (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    marginLeft: "19px",
                    paddingBottom: "20px",
                    borderLeft:
                      index < userDetails.length - 1
                        ? "2px solid #d4d9dead"
                        : "none",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      borderBottom: "2px solid #d4d9dead",
                      borderRadius: " 0px 0px 0px 50px",
                      height: "20px",
                      marginLeft:
                        index < userDetails.length - 1 ? "-2px" : "0px",

                      width: "8%",
                      borderLeft: "2px solid #D4D9DE",
                    }}
                  ></Grid>
                  <Grid item xs={2} md={1.8} lg={1.2}>
                    <img
                      src={user.src}
                      alt="Recepient_photo"
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                  <Grid container sx={{ paddingTop: "6px" }}>
                    <Grid
                      item
                      xs={8}
                      md={9}
                      lg={10}
                      sx={{ display: "flex", alignItems: "center", }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {user.PersonName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={3}
                      lg={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#414B54",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        {user.time}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: 400,
                          paddingTop: "4px",
                        }}
                      >
                        {user.message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <TimelineItem className="timeline_item"></TimelineItem>
            </Timeline>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ position: "fixed", bottom: 0, height: "50px" }}
          className="theard_drawer_footer"
        >
          <Grid
            item
            xs={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`..${subPath}/assets/images/images/Reply_theard.png`}
              alt="Reply_theard"
              style={{ width: "16px", height: "16px" }}
            />
          </Grid>
          <Grid item xs={11.5} sx={{ display: "flex", alignItems: "center" }}>
            <InputBase
              type="text"
              placeholder="Reply thread"
              sx={{
                color: "#708090",
                fontSize: "14px",
                fontStyle: "italic",
                fontWeight: 400,
              }}
            />
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};
export default CircleTheard;
