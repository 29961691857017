// Row.tsx
import React from 'react';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Avatar, Tooltip } from "antd";

interface RowProps {
  // Define your prop types here
}

export const Row: React.FC<RowProps> = (props) => {
  return (
    <div className="row_skeleton_table table_scroll">
      <Skeleton variant="rectangular" width={40} height={40} className='skeleton' sx={{marginTop: "10px"}}> </Skeleton>
      <Skeleton variant="rectangular" width={50} height={50} className='skeleton' sx={{ borderRadius: "60px" }}></Skeleton>
      <div className="second-columnorg">
      <Skeleton variant="text" width={350} height={35} className='skeleton'> </Skeleton>
      <Skeleton variant="text" width={150} height={35} className='skeleton'></Skeleton>
      </div>
      <Skeleton variant="rectangular" width={40} height={40} className='skeleton'></Skeleton>
      <Skeleton variant="rectangular" width={40} height={40} className='skeleton'></Skeleton>
      <Skeleton variant="text" width={90} height={40} className='skeleton' ></Skeleton>
      <Skeleton variant="rectangular" width={10} height={40} className='skeleton'></Skeleton>
    </div>
  );
};


export const RowGroup: React.FC<RowProps> = (props) => {
  const renderAvatars = () => {
    const avatars = [];

    for (let i = 0; i < 4; i++) {
      avatars.push(
        <Skeleton key={i} variant="circular" width={40} height={40} className='skeleton_Group' />
      );
    }

    return avatars;
  };
  return (
    <div className="row_skeleton_Group_table table_scroll">
      <Skeleton variant="text" width={130} height={35} className='skeleton_Group'> </Skeleton>
      <Skeleton variant="rectangular" width={50} height={50} className='skeleton_Group' sx={{ borderRadius: "10px" }}></Skeleton>
      <div className="second-column">
      <Skeleton variant="text" width={200} height={35} className='skeleton_Group'> </Skeleton>
      <Skeleton variant="text" width={150} height={35} className='skeleton_Group'></Skeleton>
      </div>
      <Skeleton variant="text" width={90} height={35} className='skeleton_Group'></Skeleton>
      <Avatar.Group maxCount={4} maxPopoverTrigger="click" size="large">
        {renderAvatars()}
      </Avatar.Group>
      <Skeleton variant="text" width={10} height={50} className='skeleton_Group'></Skeleton>
    </div>
  );
};
export const RowProfile: React.FC<RowProps> = (props) => {

  return (
    <div className="row_skeleton_Profile_table table_scroll">
      <Skeleton variant="text" width={130} height={35} className='skeleton_Profile'> </Skeleton>
      <Skeleton variant="rectangular" width={50} height={50} className='skeleton_Profile' sx={{ borderRadius: "10px" }}></Skeleton>
      <div className="second-column">
      <Skeleton variant="text" width={200} height={35} className='skeleton_Profile'> </Skeleton>
      <Skeleton variant="text" width={150} height={35} className='skeleton_Profile'></Skeleton>
      </div>
      <Skeleton variant="rectangular" width={50} height={50} className='skeleton_Profile' sx={{ borderRadius: "10px" }}></Skeleton>
      <div className="second-column">
      <Skeleton variant="text" width={200} height={35} className='skeleton_Profile'> </Skeleton>
      <Skeleton variant="text" width={150} height={35} className='skeleton_Profile'></Skeleton>
      </div>
      <Skeleton variant="text" width={50} height={50} className='skeleton_Profile'></Skeleton>
      <Skeleton variant="text" width={50} height={50} className='skeleton_Profile'></Skeleton>
      <Skeleton variant="text"  width={50} height={50} className='skeleton_Profile'></Skeleton>

    </div>
  );
};

