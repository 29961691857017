import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useMyContext } from "../../MyContext";
import eventEmitter from "../../../Constants/EventEmiter";
import { MentionsInput, Mention } from "react-mentions";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { imagehttp, decodeToken, subPath } from "../../../Constants/Global";
import CardMedia from "@mui/material/CardMedia";
import { Avatar } from "antd";
import getOrgImage from "../../Fetch/Organization";
import {
  CircularProgress,
  ClickAwayListener,
  ListItemIcon,
  MenuList,
  Popover,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import "./asset.css";
import InfiniteScroll from "react-infinite-scroll-component";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName, setSearchValue } from "../../../Redux/Search/action";
import { EditIcon } from "../../../Utils/ProfilePage";
import { RemoveIcon } from "../../../Utils/VIewAssetPage";
import { ViewSimilar } from "../Skeleton/ViewAssetRightBar";

interface ViewDetails {
  title: string;
  description: string;
  hashTag: string;
  commentCount: string;
  viewCount: string;
  roles: string;
  firstName: string;
  lastName: string;
  profilePicture: File;
  totalGram: number;
  gram: number;
  // Add other properties from viewDetails
}
interface ViewSimilarKm {
  title: string;
  description: string;
  hashTag: string;
  id: string;
  assetId: string;
  assetContent: { fieldType: string }[];
}

interface MentionObject {
  id: string;
  display: string;
}
interface CommentInfo {
  time: String;
  comment: string;
  profilePicture: string; // Use 'string' for the URL of the profile picture
  width: number;
  height: number;
  firstName: string;
  lastName: string;
}
interface assetDetails {
  fieldType: File | null | string;
  fieldValue: string | File | null;
  fileType: File | null | string;
  label: null | string;
}
interface templateDetails {
  fieldType: string | null;
  fieldValue: string | null;
  fileType: string | null;
  label: string | null;
}
interface breadcrumbs {
  groupName: string | null;
  vaultName: string | null;
  groupId: string | null;
  vaultId: string | null;
}

interface KnowledgeFrom {
  title: string;
  description: string;
  hashTag: string;
  id: string;
  assetId: string;
  assetContent: { fieldType: string }[];
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const View: React.FC = () => {
  const api = UseAxios();
  const location = useLocation();
  const { data } = useMyContext();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [comment, setComment] = useState<any>();
  const [viewDetails, setViewDetails] = useState<ViewDetails | null>(null);
  const fullName = `${viewDetails?.firstName} ${viewDetails?.lastName}`;
  const [viewSimilar, setViewSimilar] = useState<ViewSimilarKm[]>([]);
  const [createdBy, setCreatedBy] = useState<string>();
  const [assetContent, setassetContent] = useState<assetDetails[]>([]);
  const [templateContent, setTemplateContent] = useState<templateDetails[]>([]);
  const mentionUsersRef = useRef<any[]>([]);
  const [isLoadingSimilar, setIsLoadingSimilar] = useState<boolean>(true);
  const [isLoadingGroupKnowledge, setIsLoadingGroupKnowledge] =
    useState<boolean>(true);
  const [isLoadingVaultKnowledge, setIsLoadingVaultKnowledge] =
    useState<boolean>(true);
  const [isLoadingAsset, setIsLoadingAsset] = useState<boolean>(true);
  const [bCrumbNames, setbCrumbNames] = useState<breadcrumbs[]>([]);
  const [assetCreatedBy, setAssetCreatedBy] = useState<string>();
  const [commentView, setCommentView] = useState<CommentInfo[]>([]);
  const [profileImage, setProfileImage] = useState<File | null>();
  const idParam = new URLSearchParams(location.search).get("id");
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const HiddenGram = createdBy === assetCreatedBy;

  const [knowledgeFrom, setKnowledgeFrom] = useState<KnowledgeFrom[]>([]);

  const [knowledgeFromVault, setKnowledgeFromVault] = useState<KnowledgeFrom[]>(
    []
  );

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(1);

  const [firstNameRegex, setFirstNameRegex] = useState<string>("");

  const [templateId, setTemplateId] = useState<string>();

  const [grpName, setGrpName] = useState<string>();
  const [vaultName, setVaultName] = useState<string>();

  const decodedId = idParam ? decodeURIComponent(idParam) : null; // Decode the URL-encoded parameter or set to null if it's null
  const id = decodedId ? decodedId.replace(/"/g, "") : null;
  const [orgImageData, setOrgImageData] = useState(null);

  const [dataUrl, setDataUrl] = useState<any>();
  const [dataUrlActive, setDataUrlActive] = useState<number>(0);
  const [assetDeleteEdit, setAssetDeleteEdit] = useState<any>(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const size = 8;
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useAppDispatch();

  const deletePopOpen = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const changeDataUrl = (url: any) => {
    setDataUrl(url);
  };

  useEffect(() => {
    dispatch(setItemName(""));
    const userId = decodeToken().id ?? undefined;
    setCreatedBy(userId);
    setHasMore(true);
    view();
    assetView();
    fetchCommentData(1);
    userProfile();
    fetchOrgData();
    getMentionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const SkeletonColor = {
    backgroundColor: "#fff",
  };

  // Get mention data
  const getMentionData = async () => {
    try {
      const response = await api.get(
        `asset/mention?assetId=${id}&firstNameRegex=${firstNameRegex}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === true) {
        const data = response.data.data;
        // Map the data to the format required by react-mentions
        const formattedData = data.map((item: any) => ({
          id: item.id,
          display: item.firstName + " " + item.lastName,
          profile: item.profilePicture,
        }));
        mentionUsersRef.current = formattedData;
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error in getMentionData request:", error);
    }
  };

  const renderSuggestion = (
    suggestion: any,
    search: any,
    highlightedDisplay: any
  ) => (
    <div className="mention-suggestion">
      <ul className="mention-list">
        <div className="mention-div">
          <img
            src={suggestion.profile} // Assuming there is a profilePicture property in your data
            alt={`${suggestion.display}'s Profile`}
            className="profilepicture_div"
          />
          <span className="mention-item">{highlightedDisplay}</span>
        </div>
      </ul>
    </div>
  );

  const CommentWithMentions: React.FC<{ comment: CommentInfo }> = ({
    comment,
  }) => {
    // Regular expression to match mentions
    const mentionRegex = /@\[([^[\]]+)\]\(([^)]+)\)/g;
    // Extract mentions and convert to mention objects
    const mentions: MentionObject[] = [];
    let match;
    while ((match = mentionRegex.exec(comment.comment)) !== null) {
      const display = match[1];
      const id = match[2];
      mentions.push({ id, display });
    }

    // Replace mentions with Mention components
    const processedComment: React.ReactNode[] = comment.comment
      .split(mentionRegex)
      .map((part, index) => {
        if (index % 3 === 1) {
          const mention = mentions[(index - 1) / 3];
          return (
            <span
              key={mention.id}
              onClick={() => handleMentionClick(mention.id)}
              className="getMentionId"
              style={{
                color: "#119bff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              @{mention.display}
            </span>
          );
        }
        // remove the mention id from the comment
        else if (index % 3 === 2) {
          return null;
        }
        return part;
      });

    return <div>{processedComment}</div>;
  };

  const handleMentionClick = (id: string) => {
    const assetStatus = "read";
    navigate(`/profileAsset`, { state: { id, assetStatus } });
  };

  // viewSimilar navigate
  const handleItemClick = (index: number) => {
    const selectedItem = viewSimilar[index];
    const { id, assetId, title, hashTag } = selectedItem;
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };
  // set Org Image
  const fetchOrgData = async () => {
    try {
      const data = await getOrgImage();
      setOrgImageData(data[0].logo);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching organization image:", error);
    }
  };

  const enterComment = (e: any) => {
    setComment(e.target.value);
    // if @ is typed, get the mention data
    if (e.target.value.includes("@")) {
      setFirstNameRegex(firstNameRegex);
      getMentionData();
    }
  };

  const view = async () => {
    const requestBody = {
      assetId: id,
      type: "view",
      userId: createdBy,
    };
    try {
      await api.post("asset/save-activity", requestBody).then((res) => {
        assetView();
        setComment("");
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
    // setComment('');
  };

  //Get Assets by group Id
  const getAssetsByGroupId = async (id: any, assetId: any) => {
    try {
      await api
        .get(`group/getasset?id=${id}&assertId=${assetId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const assetData = res.data.data;
          if (assetData.length > 0) {
            // Reorder the asset content for each element in assetData
            const typeOrder = ["Video", "Image", "Audio"];
            // Reorder the asset content for each element in assetData
            const customSort = (a: any, b: any) => {
              const typeAIndex = typeOrder.indexOf(a.fieldType);
              const typeBIndex = typeOrder.indexOf(b.fieldType);
              // Move types not in the order to the end
              if (typeAIndex === -1) return 1;
              if (typeBIndex === -1) return -1;
              return typeAIndex - typeBIndex;
            };
            assetData.forEach((assetElement: any) => {
              if (
                assetElement.assetContent &&
                assetElement.assetContent.length > 0
              ) {
                assetElement.assetContent.sort(customSort);
              }
            });
          }
          setKnowledgeFrom(assetData);
          setIsLoadingGroupKnowledge(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  //Get Assets by group Id
  const getAssetsByVaultId = async (VaultId: any, id: any) => {
    try {
      await api
        .get(
          `asset/getgroup?vaultId=${VaultId}&referAssetId=${id}&page=${0}&size=${4}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const assetData = res.data.data;
          if (assetData.length > 0) {
            // Reorder the asset content for each element in assetData
            const typeOrder = ["Video", "Image", "Audio"];
            // Reorder the asset content for each element in assetData
            const customSort = (a: any, b: any) => {
              const typeAIndex = typeOrder.indexOf(a.fieldType);
              const typeBIndex = typeOrder.indexOf(b.fieldType);
              // Move types not in the order to the end
              if (typeAIndex === -1) return 1;
              if (typeBIndex === -1) return -1;
              return typeAIndex - typeBIndex;
            };
            assetData.forEach((assetElement: any) => {
              if (
                assetElement.assetContent &&
                assetElement.assetContent.length > 0
              ) {
                assetElement.assetContent.sort(customSort);
              }
            });
          }
          setKnowledgeFromVault(assetData);
          setIsLoadingVaultKnowledge(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  //profile asset view
  const assetView = async () => {
    // view();
    try {
      const response = await api.get(`asset/get?id=${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const newData = response.data.data;

      const groupId = newData.groupsName[0].groupId;
      const VaultId = newData.groupsName[0].vaultId;
      getAssetsByGroupId(groupId, id);
      getAssetsByVaultId(VaultId, id);

      const attachmentContent = response.data.data.assetContent;
      const templateContent = response.data.data.templateContent;
      setTemplateContent(templateContent);
      const breadcrumbName = response.data.data.groupsName;
      setbCrumbNames(breadcrumbName);
      setassetContent(attachmentContent);
      setAssetCreatedBy(response.data.data.createdBy);
      setViewDetails(newData);

      fetchViewSimilar(
        newData ? newData.hashTag : "",
        newData ? newData.title : ""
      );

      const videos = attachmentContent.filter(
        (item: any) => item.fieldType === "Video"
      );
      if (videos.length > 0) {
        setSelectedItemIndex(1);
      } else {
        setSelectedItemIndex(2);
      }

      setTemplateId(newData.templateId);

      setGrpName(newData.groupsName[0].groupName);
      setVaultName(newData.groupsName[0].vaultName);
      setIsLoadingAsset(false);
    } catch (error) {
      navigate("/EmptyData", { state: "Asset Removed" });
    }
  };
  // user profile
  const userProfile = async () => {
    const url = `user/select-premission`;
    try {
      const response = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.status === true) {
        setProfileImage(response.data.data.profilePicture);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error in userProfile request:", error);
    }
  };

  //Fetching CommentData
  const fetchCommentData = async (type: number) => {
    let pageCount = page;
    if (type === 1) {
      setPage(0);
      pageCount = 0;
    }

    try {
      const response = await api.get(
        `asset/comment?assetId=${id}&page=${pageCount}&size=${size}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!pageCount) {
        setCommentView(response.data.comments);
      } else {
        setCommentView((prevCommentView) => [
          ...prevCommentView,
          ...response.data.comments,
        ]);
      }

      if (response.data.comments.length < size) {
        setHasMore(false);
      }

      setPage(pageCount + 1);
    } catch (error) {}
  };

  //send comment
  const submitComment = async (e: React.FormEvent) => {
    e.preventDefault();
    setHasMore(true);
    const requestBody = {
      assetId: id,
      type: "comment",
      comment: comment,
      userId: createdBy,
    };
    try {
      await api.post("asset/save-activity", requestBody).then((res) => {
        assetView();
        fetchCommentData(1);
        if (formRef.current) {
          setComment(comment);
          formRef.current.reset();
        }
        toast.success("Comment Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
        setComment("");
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const AddGram = async (incrementValue: number) => {
    const requestBody = {
      assetId: id,
      type: "gram",
      gram: incrementValue,
    };
    try {
      await api.post("asset/save-activity", requestBody).then((res) => {
        assetView();
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
    // setComment('');
  };
  const fetchViewSimilar = async (hashTag: any, titles: any) => {
    const requestBody = {
      title: titles,
      hashTag: hashTag,
      id: id,
    };
    try {
      await api
        .post(`asset/view-similar?assetId=${id}`, requestBody)
        .then((res) => {
          const assetData = res.data.data;
          if (assetData.length > 0) {
            // Reorder the asset content for each element in assetData
            const typeOrder = ["Video", "Image", "Audio"];
            // Reorder the asset content for each element in assetData
            const customSort = (a: any, b: any) => {
              const typeAIndex = typeOrder.indexOf(a.fieldType);
              const typeBIndex = typeOrder.indexOf(b.fieldType);
              // Move types not in the order to the end
              if (typeAIndex === -1) return 1;
              if (typeBIndex === -1) return -1;
              return typeAIndex - typeBIndex;
            };
            assetData.forEach((assetElement: any) => {
              if (
                assetElement.assetContent &&
                assetElement.assetContent.length > 0
              ) {
                assetElement.assetContent.sort(customSort);
              }
            });
          }
          setViewSimilar(assetData);
          setIsLoadingSimilar(false);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const videos = assetContent.filter((item) => item.fieldType === "Video");

  const images = assetContent.filter((item) => item.fieldType === "Image");

  const audios = assetContent.filter((item) => item.fieldType === "Audio");

  const hasVideo = videos.length > 0;

  const hasImage = images.length > 0;

  const hasAudio = audios.length > 0;

  const hasUrl = assetContent.some((item) => item.fieldType === "url");

  const hasPpt = assetContent.some((item) => item.fieldType === "Document");
  const hasContact = assetContent.some((item) => item.fieldType === "Contact");

  const [showDiv, setShowDiv] = useState(false);

  const toggleDiv = () => {
    setShowDiv(true);
    // Set All the hovered states to false
    setIsHovered(false);
    setIsHovered1(false);
    setIsHovered2(false);
  };
  const toggleDivCount = () => {
    setShowDiv(false);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  // Define initial and hover states for the image and text
  const initialImage = `..${subPath}/assets/images/star1.png`;
  const hoverImage = `..${subPath}/assets/images/star2.png`;
  const initialImage1 = `..${subPath}/assets/images/star1.png`;
  const hoverImage1 = `..${subPath}/assets/images/star2.png`;
  const initialImage2 = `..${subPath}/assets/images/star1.png`;
  const hoverImage2 = `..${subPath}/assets/images/star2.png`;

  // Function to handle hover events
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  // const toggleExpansion = () => {
  //   setIsExpanded(!isExpanded);
  // };
  const handleTableRowClick = (groupId: string) => {
    setTimeout(() => {
      navigate(`/groupsView`, { state: { id: groupId } });
    }, 100);
  };

  const getAssetId = async (
    id: any,
    assetId: any,
    title: any,
    hashTag: any
  ) => {
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };

  const handleVedioClick = (index: number) => {
    setSelectedItemIndex(1);
  };

  const handleImageClick = (index: number) => {
    setSelectedItemIndex(2);
    // Implement the logic for what should happen when an image is clicked in the first grid
  };

  const navigateToHome = () => {
    navigate(`/landingAssetView`);
  };

  const handleOpenMenuAsset = (event: any) => {
    setAssetDeleteEdit(event.currentTarget);
  };

  const handleCloseMenuAsset = () => {
    setAssetDeleteEdit(null);
  };

  const editAssetRedirect = () => {
    handleCloseMenuAsset();
    navigate(
      `/editAsset?assetId=${encodeURIComponent(
        JSON.stringify(id)
      )}&templateId=${encodeURIComponent(JSON.stringify(templateId))}`
    );
  };

  const removeAssetRedirect = async () => {
    const url = `asset/delete?assetId=${id}`;
    try {
      const res = await api.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.status === true) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenDelete(false);
        eventEmitter.emit("getHasgtags");
        navigate(`/landingAssetView`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const headerSearch = (itemTitle: any) => {
    navigate("/landingAssetView");
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
  };

  // Audio pause
  const handleAudioClick = (index: number) => {
    // Pause all the other audio elements
    const audioElements = document.querySelectorAll("audio");
    audioElements.forEach((element, i) => {
      if (i !== index) {
        (element as HTMLAudioElement).pause();
      }
    });
  };

  return (
    <>
      <Box className="center-grid ">
        <Grid container>
          {isLoadingAsset ? (
            <Grid
              className="view_center_card_loading p-10"
              item
              xs={12}
              sm={12}
              md={7.7}
              lg={8.8}
            >
              <Grid>
                <Grid item xs={12} sm={12} md={10} lg={12} xl={12}>
                  <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{
                        ...SkeletonColor,
                        marginRight: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "5px" }}
                    />
                    <span
                      className="pl-1 fs-13 smallBold cur-pointer hideDotSingleLine "
                      style={{ maxWidth: "15%", marginRight: "5px" }}
                    >
                      <Skeleton
                        variant="text"
                        width={80}
                        height={20}
                        sx={{ ...SkeletonColor }}
                      />
                    </span>
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "5px" }}
                    />
                    <span
                      className="pl-1 fs-13 smallBold cur-pointer hideDotSingleLine"
                      style={{ maxWidth: "15%", marginRight: "5px" }}
                    >
                      <Skeleton
                        variant="text"
                        width={80}
                        height={20}
                        sx={{ ...SkeletonColor }}
                      />
                    </span>
                    <Skeleton
                      variant="rectangular"
                      width={20}
                      height={20}
                      sx={{ ...SkeletonColor, borderRadius: "5px" }}
                    />
                    <span
                      className="pl-1 fs-13 smallBold hideDotSingleLine"
                      style={{ maxWidth: "40%" }}
                    >
                      <Skeleton
                        variant="text"
                        width={80}
                        height={20}
                        sx={{ ...SkeletonColor }}
                      />
                    </span>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex align-items-center"
                >
                  <Grid xs={12} sm={12} md={8} lg={9} xl={9} item>
                    <Typography
                      variant="h5"
                      style={{ width: "90%" }}
                      className="mediumBold color_35 mt-10 hideDotSingleLine"
                    >
                      <Skeleton
                        variant="text"
                        width={140}
                        height={20}
                        sx={{ ...SkeletonColor }}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={3}
                    sx={{
                      float: "right",
                      position: "relative",
                      marginTop: "-30px",
                      marginRight: "25px",
                    }}
                    className="addgram_btn "
                    item
                  >
                    <Grid item sm={10} md={10}></Grid>
                    <Grid item sm={2} md={2}>
                      <Skeleton
                        variant="rectangular"
                        width={5}
                        height={20}
                        sx={{ ...SkeletonColor }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                style={{ height: "42px" }}
                className="bg_f5 d-flex align-items-center mt-10 br_5"
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Grid className="d-flex align-items-center justify-center">
                    <Skeleton variant="circular" width={30} height={30} />
                    <Grid
                      item
                      className="color_00 p-1 fs-14"
                      sx={{ fontWeight: "500" }}
                    >
                      <Skeleton variant="text" width={50} height={20} />
                    </Grid>

                    <Grid item className="color_7080 p-1 fs-14 capitalize">
                      <Skeleton variant="text" width={50} height={20} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Skeleton variant="text" width={170} height={20} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="g3">
                  <Grid item className="d-flex align-items-center ">
                    <Skeleton
                      variant="rectangular"
                      width={25}
                      height={25}
                      sx={{ borderRadius: "8px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    className="d-flex "
                    sx={{ alignItems: "flex-start" }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={25}
                      height={25}
                      sx={{ borderRadius: "8px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    className="d-flex"
                    sx={{ alignItems: "flex-start" }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={25}
                      height={25}
                      sx={{ borderRadius: "8px" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="as-height_scroll" sx={{ height: "512px" }}>
                <Box className="mt-5">
                  <Grid item className="d-flex justify-content-around ">
                    <>
                      <Grid
                        item
                        xs={1.5}
                        sm={1.5}
                        md={1.5}
                        lg={1.5}
                        xl={1.5}
                      ></Grid>
                      <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={300}
                          sx={{ ...SkeletonColor, borderRadius: "8px" }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sm={1.5}
                        md={1.5}
                        lg={1.5}
                        xl={1.5}
                      ></Grid>
                    </>
                  </Grid>
                </Box>
                <Box
                  className="bg_f5 br_5"
                  sx={{ margin: "10px 0", padding: "16px" }}
                >
                  <Grid item className="d-flex justify-content-between ">
                    <Skeleton variant="text" width={80} height={20} />
                    <Skeleton
                      variant="text"
                      width={40}
                      height={20}
                      style={{ marginRight: "15px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    className=" d-flex align-items-center"
                    sx={{ marginTop: "25px" }}
                  >
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                      <Skeleton variant="circular" width={50} height={50} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                      <Skeleton
                        variant="rectangular"
                        width="90%"
                        height={110}
                        sx={{ borderRadius: "8px" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}></Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid
              className="view_center_card p-10"
              item
              xs={12}
              sm={12}
              md={7.7}
              lg={8.8}
              sx={{ lineHeight: "normal" }}
            >
              <Grid item>
                <Grid item xs={12} sm={12} md={10} lg={12} xl={12}>
                  {bCrumbNames.map((result, index) => {
                    return (
                      <>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          <Box
                            component="img"
                            src={`${imagehttp}${orgImageData}`}
                            onClick={navigateToHome}
                            className="cur-pointer logo-bread"
                          />
                          <span className="color_119B p-1">/</span>
                          <Box
                            component="img"
                            src={`..${subPath}/assets/images/group_img.svg`}
                          />
                          <span
                            className="pl-1 fs-13 smallBold cur-pointer hideDotSingleLine"
                            style={{ maxWidth: "15%" }}
                            onClick={() =>
                              result.groupId &&
                              handleTableRowClick(result.groupId)
                            }
                          >
                            {result.groupName?.trim()}
                          </span>
                          <span className="color_119B p-1">/</span>
                          <Box
                            component="img"
                            src={`..${subPath}/assets/images/md_vault_img.svg`}
                          />
                          <span
                            className="pl-1 fs-13 smallBold cur-pointer hideDotSingleLine"
                            style={{ maxWidth: "15%" }}
                            onClick={() =>
                              result.groupId &&
                              handleTableRowClick(result.groupId)
                            }
                          >
                            {result.vaultName?.trim()}
                          </span>
                          <span className="color_119B p-1">/</span>
                          <Box
                            component="img"
                            src={`..${subPath}/assets/images/User_light.svg`}
                            width={24}
                            height={24}
                          />
                          <span
                            style={{ width: "20%" }}
                            className="pl-1 fs-13 smallBold hideDotSingleLine"
                            title={viewDetails?.title}
                          >
                            {viewDetails?.title}
                          </span>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="d-flex align-items-center"
                >
                  <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
                    <Typography
                      variant="h5"
                      style={{ width: "90%" }}
                      className="mediumBold color_35 mt-10 hideDotSingleLine"
                      title={viewDetails?.title}
                    >
                      {/* Marketing Strategy To increase YOY revenue  */}
                      {viewDetails?.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={3}
                    xl={3}
                    sx={{
                      float: "right",
                      position: "relative",
                      marginTop: "-30px",
                      marginRight: "25px",
                    }}
                    className="addgram_btn "
                  >
                    <Grid sm={10} md={10} item>
                      {HiddenGram ? (
                        null || undefined
                      ) : (
                        <Button sx={{ height: "0", display: "contents" }}>
                          {!showDiv ? (
                            <div>
                              <button className="add_grams" onClick={toggleDiv}>
                                {viewDetails?.gram
                                  ? viewDetails?.gram === 1000
                                    ? `1K Grams`
                                    : `${viewDetails?.gram} Grams`
                                  : "Add Grams"}
                              </button>
                              {/* ) : null} */}
                            </div>
                          ) : (
                            <div className="three_addgram ">
                              <Button className="">
                                {" "}
                                <div
                                  className="hover-container h-0"
                                  onMouseEnter={handleMouseEnter}
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() => {
                                    AddGram(10);
                                    toggleDivCount();
                                  }}
                                >
                                  {viewDetails?.gram === 10 ? (
                                    <>
                                      <img
                                        src={hoverImage}
                                        className="gramBorderImg"
                                        alt="gram"
                                      />
                                      <span
                                        className={`number_asset gram10Left color_fff
                              `}
                                      >
                                        10
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="gramBorderImg"
                                        src={
                                          isHovered ? hoverImage : initialImage
                                        }
                                        alt=""
                                      />
                                      <span
                                        className={`number_asset gram10Left ${
                                          isHovered
                                            ? "color_fff"
                                            : "color_0ae477"
                                        }`}
                                      >
                                        10
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Button>
                              <Button className="">
                                {" "}
                                <div
                                  className="hover-container h-0"
                                  onMouseEnter={handleMouseEnter1}
                                  onMouseLeave={handleMouseLeave1}
                                  onClick={() => {
                                    AddGram(100);
                                    toggleDivCount();
                                  }}
                                >
                                  {viewDetails?.gram === 100 ? (
                                    <>
                                      <img
                                        src={hoverImage1}
                                        className="gramBorderImg"
                                        alt="gram100"
                                      />
                                      <span
                                        className={`number_asset gram100Left color_fff`}
                                      >
                                        100
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="gramBorderImg"
                                        src={
                                          isHovered1
                                            ? hoverImage1
                                            : initialImage1
                                        }
                                        alt="gram100"
                                      />
                                      <span
                                        className={`number_asset gram100Left ${
                                          isHovered1
                                            ? "color_fff"
                                            : "color_0ae477"
                                        }`}
                                      >
                                        100
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Button>
                              <Button className="">
                                {" "}
                                <div
                                  className="hover-container h-0"
                                  onMouseEnter={handleMouseEnter2}
                                  onMouseLeave={handleMouseLeave2}
                                  onClick={() => {
                                    AddGram(1000);
                                    toggleDivCount();
                                  }}
                                >
                                  {viewDetails?.gram === 1000 ? (
                                    <>
                                      {" "}
                                      <img
                                        src={hoverImage2}
                                        className="gramBorderImg"
                                        alt="gram1000"
                                      />
                                      <span
                                        className={`number_asset gram1000Left color_fff
                                `}
                                      >
                                        1K
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="gramBorderImg"
                                        src={
                                          isHovered2
                                            ? hoverImage2
                                            : initialImage2
                                        }
                                        alt="gram1000"
                                      />
                                      <span
                                        className={`number_asset gram1000Left ${
                                          isHovered2
                                            ? "color_fff"
                                            : "color_0ae477"
                                        }`}
                                      >
                                        1K
                                      </span>
                                    </>
                                  )}
                                </div>
                              </Button>
                            </div>
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Grid item sm={2} md={2}>
                      {HiddenGram && (
                        <>
                          <Box
                            component="img"
                            className="cur-pointer"
                            style={{ height: "20px" }}
                            src={`..${subPath}/assets/images/menu_3.svg`}
                            onClick={handleOpenMenuAsset}
                          />
                          <div>
                            <Popover
                              open={Boolean(assetDeleteEdit)}
                              anchorEl={assetDeleteEdit}
                              onClose={handleCloseMenuAsset}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={handleCloseMenuAsset}
                                >
                                  <MenuList>
                                    <MenuItem
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                      className="colorBlack"
                                      onClick={() => {
                                        editAssetRedirect();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <EditIcon />
                                      </ListItemIcon>
                                      Edit Asset
                                    </MenuItem>
                                    <MenuItem
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}
                                      className="colorBlack"
                                      onClick={() => {
                                        handleCloseMenuAsset();
                                        deletePopOpen();
                                      }}
                                    >
                                      <ListItemIcon>
                                        <RemoveIcon />
                                      </ListItemIcon>
                                      Remove
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Popover>
                          </div>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                style={{ height: "42px" }}
                className="bg_f5 d-flex align-items-center mt-10 br_5"
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Grid className="d-flex align-items-center justify-center">
                    <Box
                      component="img"
                      src={
                        `${imagehttp}${viewDetails?.profilePicture}` ||
                        `..${subPath}/assets/images/frame2.svg`
                      }
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        marginRight: "5px",
                      }}
                    />

                    <Grid
                      className="color_00 p-1 fs-14"
                      sx={{ fontWeight: "500" }}
                    >
                      <span
                        style={{ maxWidth: "100px" }}
                        className="hideDotSingleLine"
                        title={fullName}
                      >
                        {fullName}
                      </span>
                    </Grid>
                    <Grid className="fs-11 dot"></Grid>
                    <Grid className="color_7080 p-1 fs-14 capitalize">
                      {viewDetails?.roles}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{ lineHeight: "normal" }}
                >
                  <p
                    title={viewDetails?.description}
                    style={{ width: "100%" }}
                    className="m-0 fs-14 color_7080 hideDotSingleLine cur-pointer"
                  >
                    {viewDetails?.description}
                    {/* Retail marketing on an omnichannel level has ..... */}
                  </p>
                  <div
                    className="hideDotTag"
                    title={viewDetails?.hashTag}
                    style={{ width: "100%" }}
                  >
                    {viewDetails?.hashTag
                      .toString()
                      .split(",")
                      .map((hashTag: any, index: number) => (
                        <>
                          <span
                            key={index}
                            className="color_119B fs-13  m-0 inlineBlock"
                            onClick={() => headerSearch(hashTag)}
                          >
                            {hashTag}
                          </span>{" "}
                        </>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className="g3">
                  <Grid className="d-flex align-items-center ">
                    <Box
                      component="img"
                      style={{ height: "23px" }}
                      src={`..${subPath}/assets/images/GramIcon.svg`}
                    />
                    <Grid
                      className="color_00 align-items-center fs-13"
                      sx={{ margin: "0 0 0px 5px", fontWeight: "500" }}
                    >
                      {viewDetails?.totalGram}
                    </Grid>
                  </Grid>
                  <Grid className="d-flex " sx={{ alignItems: "flex-start" }}>
                    <Box
                      component="img"
                      style={{ height: "23px" }}
                      src={`..${subPath}/assets/images/eye_img.svg`}
                    />
                    <Grid
                      className="color_00 fs-13"
                      sx={{ margin: "3px 0 0px 5px", fontWeight: "500" }}
                    >
                      {viewDetails?.viewCount}
                    </Grid>
                  </Grid>
                  <Grid className="d-flex" sx={{ alignItems: "flex-start" }}>
                    <Box
                      style={{ height: "23px" }}
                      component="img"
                      src={`..${subPath}/assets/images/chat_msg.svg`}
                    />
                    <Grid
                      className="color_00 fs-13"
                      sx={{ margin: "3px 0 0px 5px", fontWeight: "500" }}
                    >
                      {viewDetails?.commentCount}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                id="commentScroll"
                sx={{ height: "512px", lineHeight: "normal" }}
              >
                <Box className="mt-5">
                  <Grid className="d-flex justify-content-around ">
                    {hasVideo && selectedItemIndex === 1 && (
                      <>
                        <Grid
                          item
                          xs={1.5}
                          sm={1.5}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                        ></Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                          <Grid item className="ast-vid-div">
                            {/* {videos.map((videoItem, index) => ( */}
                            <div>
                              <video
                                src={`${imagehttp}${
                                  dataUrl || videos[0].fieldValue
                                }`}
                                width="100%"
                                style={{
                                  borderRadius: "16px 16px 16px 16px",
                                }}
                                controls
                              />
                            </div>
                            {/* ))} */}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          sm={1.5}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                        ></Grid>
                      </>
                    )}

                    {hasImage && selectedItemIndex === 2 && (
                      <>
                        <Grid
                          item
                          xs={1.5}
                          sm={1.5}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                        ></Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                          <Grid item>
                            <div>
                              <img
                                src={`${imagehttp}${
                                  dataUrl || images[0].fieldValue
                                }
                              `}
                                style={{
                                  borderRadius: "16px 16px 16px 16px",
                                }}
                                width="100%"
                                alt="Asset Images"
                              />
                            </div>
                            {/* ))} */}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          sm={1.5}
                          md={1.5}
                          lg={1.5}
                          xl={1.5}
                        ></Grid>
                      </>
                    )}
                  </Grid>
                </Box>

                {assetContent.length > 0 && (
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      padding: "20px",
                      marginLeft: "70px",
                    }}
                    className="assetOverflow "
                  >
                    {((hasVideo && hasImage) || videos.length > 1) && (
                      <Grid item sx={{ display: "flex", alignItems: "center" }}>
                        {videos.map((videoItem, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              setDataUrlActive(index);
                              changeDataUrl(videoItem.fieldValue as string);
                              handleVedioClick(index);
                            }}
                            style={{
                              margin: "0px 20px",
                              border:
                                selectedItemIndex === 1
                                  ? dataUrlActive === index
                                    ? "2px solid #119BFF"
                                    : "none"
                                  : "none",
                              borderRadius: "16px",
                            }}
                            className="cur-pointer"
                          >
                            <video
                              src={`${imagehttp}${videoItem.fieldValue}`}
                              height="36px"
                              style={{
                                borderRadius: "5px",
                                margin: "5px 5px 0px 5px",
                                // width: "-webkit-fill-available",
                              }}
                              autoPlay
                              muted
                            />
                          </div>
                        ))}
                      </Grid>
                    )}

                    {((hasVideo && hasImage) || images.length > 1) && (
                      <>
                        {images.map((imageItem, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              changeDataUrl(imageItem.fieldValue as string);
                              setDataUrlActive(index);
                              handleImageClick(index);
                            }}
                            style={{
                              margin: "0px 20px",
                              border:
                                selectedItemIndex === 2
                                  ? dataUrlActive === index
                                    ? "2px solid #119BFF"
                                    : "none"
                                  : "none",
                              borderRadius: "16px",
                            }}
                            className="cur-pointer"
                          >
                            <img
                              src={`${imagehttp}${imageItem.fieldValue}`}
                              height="36px"
                              style={{
                                borderRadius: "5px",
                                margin: "5px 5px 0px 5px",
                              }}
                              alt="Asset Images"
                            />
                          </div>
                        ))}
                      </>
                    )}

                    {hasPpt ? (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 20px",
                          marginTop: "-14px",
                        }}
                      >
                        {assetContent
                          .filter((item) => item.fieldType === "Document")
                          .map((docItem, index) => {
                            const fieldValue = docItem.label;
                            if (typeof fieldValue === "string") {
                              const longText =
                                fieldValue
                                  .split("/")
                                  .pop()
                                  ?.split("\\")
                                  .pop() || "";
                              const maxLength = 15;
                              const shouldTruncate =
                                longText.length > maxLength;
                              const truncatedText = shouldTruncate
                                ? `${longText.slice(0, maxLength)}...`
                                : longText;
                              return (
                                <>
                                  <Box
                                    component="img"
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    className="menu_padd"
                                  />
                                  <a
                                    key={index}
                                    href={`${imagehttp}${docItem.fieldValue}`} // Replace with the URL of your PDF document
                                    target="_blank" // Open in a new tab
                                    rel="noopener noreferrer" // Security best practice
                                    className="color_00 fs-16 fw-600 mt-13 truncate-text"
                                    style={{
                                      maxWidth: "200px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textDecoration: "none", // Remove underline
                                      cursor: "pointer",
                                      marginLeft: "8px",
                                    }}
                                    title={truncatedText}
                                  >
                                    {truncatedText}
                                  </a>
                                </>
                              );
                            }
                            return null; // Handle non-string fieldValue, e.g., if it's not a string
                          })}
                      </Grid>
                    ) : null}
                    {hasAudio && (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 20px",
                          marginTop: "-14px",
                        }}
                      >
                        {audios.map((audioItem, index) => (
                          <div key={index} className="d-flex">
                            <Box
                              component="img"
                              src={`..${subPath}/assets/images/Audio_light.svg`}
                              className="menu_padd"
                            />
                            <audio
                              style={{ marginLeft: "8px" }}
                              src={`${imagehttp}${audioItem.fieldValue}`}
                              id={`audio${index}`}
                              controls
                              onPlay={() => handleAudioClick(index)}
                              className="view_audio color_00 fs-16 fw-600 mt-13"
                            />
                          </div>
                        ))}
                      </Grid>
                    )}
                    {hasUrl ? (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 20px",
                          marginTop: "-14px",
                        }}
                      >
                        {assetContent
                          .filter((item) => item.fieldType === "url")
                          .map((urlItem, index) => (
                            <div key={index} className="d-flex">
                              {typeof urlItem.fileType === "string" ? (
                                <>
                                  <Box
                                    component="img"
                                    src={`..${subPath}/assets/images/link_light.svg`}
                                    className="menu_padd"
                                  />
                                  <Typography className="color_00 fs-16 fw-600 mt-13">
                                    <a
                                      href={urlItem.fileType}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="color_00"
                                      style={{
                                        textDecoration: "auto",
                                        marginLeft: "8px",
                                      }}
                                    >
                                      {(() => {
                                        try {
                                          const url = new URL(urlItem.fileType);
                                          return url.hostname;
                                        } catch (error) {
                                          console.error(
                                            "Invalid URL:",
                                            urlItem.fileType
                                          );
                                          return "Invalid URL";
                                        }
                                      })()}
                                    </a>
                                  </Typography>
                                </>
                              ) : (
                                // Handle non-string values (e.g., File or ReactElement) here
                                <Typography className="color_00 fs-16 fw-600 mt-13">
                                  Invalid URL
                                </Typography>
                              )}
                            </div>
                          ))}
                      </Grid>
                    ) : null}

                    {hasContact ? (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0px 20px",
                          marginTop: "-14px",
                        }}
                      >
                        {assetContent
                          .filter((item) => item.fieldType === "Contact")
                          .map((contactItem, index) => (
                            <div
                              key={index}
                              className="contact-item d-flex"
                              onMouseEnter={() => setHoveredIndex(index)}
                              onMouseLeave={() => setHoveredIndex(null)}
                            >
                              <Box
                                component="img"
                                src={`..${subPath}/assets/images/User_face.svg`}
                                className="menu_padd"
                              />
                              {typeof contactItem.label === "string" ? (
                                <div
                                  style={{
                                    position: "relative",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <Typography
                                    className={`color_00 fs-16 cur-pointer fw-600 mt-13 ${
                                      index === hoveredIndex ? "adjust" : ""
                                    }`}
                                    style={{ paddingRight: "10px" }}
                                  >
                                    {contactItem.label}
                                  </Typography>
                                  <Typography
                                    className={`color_7080 mt-2 fs-14 fw-500 ${
                                      index === hoveredIndex ? "show" : "hide"
                                    }`}
                                  >
                                    {contactItem.fileType &&
                                      ` ${contactItem.fileType}`}
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                          ))}
                      </Grid>
                    ) : null}
                  </Grid>
                )}
                {templateContent.map(
                  (result: templateDetails, index: number) => (
                    <React.Fragment key={index}>
                      {result?.fileType === "LineBreak" ? (
                        <>
                          <hr />
                        </>
                      ) : (
                        <>
                          {result?.fileType ? (
                            <>
                              <Typography className={`color_00 fw-600 mt-13`}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: result.label!,
                                  }}
                                />
                              </Typography>
                              <Typography
                                className={`${
                                  result.fieldType === "Header"
                                    ? "fw-700 f20"
                                    : "viewAsset_content"
                                }`}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: result.fileType!,
                                  }}
                                />
                              </Typography>
                            </>
                          ) : null}
                        </>
                      )}
                    </React.Fragment>
                  )
                )}

                <InfiniteScroll
                  dataLength={commentView?.length ? commentView?.length : 0}
                  next={() => fetchCommentData(0)}
                  hasMore={hasMore}
                  scrollableTarget="commentScroll"
                  className=""
                  loader={
                    <h6
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <CircularProgress
                        style={{
                          color: "#119BFF",
                        }}
                        size={20}
                      />
                    </h6>
                  }
                  endMessage={
                    commentView?.length !== 0 && (
                      <p className="noMore">No more data found...</p>
                    )
                  }
                >
                  <form ref={formRef} onSubmit={submitComment}>
                    <Box
                      className="padding-5 bg_f5 br_5"
                      sx={{ margin: "10px 0" }}
                    >
                      <Grid className="d-flex justify-content-between ">
                        <p className="color_7080">Comments</p>
                      </Grid>
                      <Grid className=" d-flex align-items-center ">
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                          <CardMedia
                            component="img"
                            src={
                              data
                                ? `${imagehttp}${data}`
                                : `${imagehttp}${profileImage}`
                            }
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              marginLeft: "1px",
                            }}
                            className=""
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                          <MentionsInput
                            value={comment}
                            onChange={(e: any) => {
                              enterComment(e);
                            }}
                            placeholder="What’s your comment on this Knowledge ?"
                            className="bg-fff command_box"
                            style={{ zIndex: 999 }}
                            required
                            rows={6}
                            allowSpaceInQuery={true}
                          >
                            <Mention
                              trigger="@"
                              data={mentionUsersRef.current}
                              renderSuggestion={renderSuggestion}
                            />
                          </MentionsInput>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                          <Button type="submit">
                            <Box
                              component="img"
                              src={`..${subPath}/assets/images/send.svg`}
                              className="send_arrow"
                            />
                          </Button>
                        </Grid>
                      </Grid>

                      {commentView?.map((result: CommentInfo , index : number) => {
                        return (
                          <Grid key={index} className="d-flex align-items-center mt-5 mBottom-5">
                            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                              <Avatar
                                src={
                                  `${imagehttp}${result.profilePicture}` ||
                                  `..${subPath}/assets/images/user.png`
                                }
                                style={{ width: 40, height: 40 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                              <Grid className="d-flex align-items-center ">
                                <Grid className="color_00  smallBold">
                                  {result.firstName + " " + result.lastName}
                                </Grid>
                                <Grid className="d-flex align-items-center p-10">
                                  <Grid className=" dot"></Grid>
                                  <Grid className="color_7080 fs-12 ">
                                    {result.time}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid className="d-flex">
                                <Grid className="fs-13 pre-com-av">
                                  {/* Your existing Grid and Avatar components */}
                                  <CommentWithMentions comment={result} />
                                  {/* Your existing Grid components */}
                                </Grid>
                                <Grid className="color_119B cur-pointer"></Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                  </form>
                </InfiniteScroll>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={3.9}
            lg={3}
            className="right_view view-similar-scroll "
          >
            <Card className="view_right">
              {isLoadingSimilar ? (
                <ViewSimilar />
              ) : (
                <CardContent className="as-r-card">
                  <Grid container>
                    <Grid item xl={12} lg={12} sm={12}>
                      <Box>
                        <Grid
                          container
                          item
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xl={12} lg={12} sm={12}>
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "13px",
                                //   fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              Similar Knowledge
                            </Typography>
                          </Grid>
                        </Grid>
                        {viewSimilar
                          .slice(0, 3)
                          .map((result: ViewSimilarKm, index: number) => (
                            <Grid key={index} container className="av-r-sk-div ">
                              {/* Image Column */}
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {/* Adjust the width for different screen sizes */}
                                {result.assetContent[0] ? (
                                  // If result.assetContent[0] is not empty
                                  <>
                                    {result.assetContent[0].fieldType ===
                                      "Video" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_light.svg`}
                                        alt="Video"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Audio" && (
                                      <img
                                        src={`..${subPath}/assets/images/Audio_light.svg`}
                                        alt="Audio"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Document" && (
                                      <img
                                        src={`..${subPath}/assets/images/Document_line.svg`}
                                        alt="Document"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Contact" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_face.svg`}
                                        alt="Contact"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "url" && (
                                      <img
                                        src={`..${subPath}/assets/images/link_light.svg`}
                                        alt="URL"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Image" && (
                                      <img
                                        src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                        alt="Image"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType !==
                                      "Video" &&
                                      result.assetContent[0].fieldType !==
                                        "Audio" &&
                                      result.assetContent[0].fieldType !==
                                        "Document" &&
                                      result.assetContent[0].fieldType !==
                                        "Contact" &&
                                      result.assetContent[0].fieldType !==
                                        "url" &&
                                      result.assetContent[0].fieldType !==
                                        "Image" && (
                                        <img
                                          src={`..${subPath}/assets/images/Document_line.svg`}
                                          alt="Document"
                                        />
                                      )}
                                  </>
                                ) : (
                                  // If result.assetContent[0] is empty, render something else here
                                  <img
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    alt="Document"
                                  />
                                )}
                              </Grid>

                              {/* Text Column */}
                              <Grid
                                item
                                xs={12}
                                sm={9}
                                // onClick={() => handleItemClick(index)}
                                onClick={(event) => {
                                  if (
                                    event.target instanceof HTMLElement &&
                                    event.target.id === "hashtag_id"
                                  ) {
                                    console.log(
                                      "Clicked on Video cell. Navigation prevented."
                                    );
                                  } else {
                                    handleItemClick(index);
                                  }
                                }}
                              >
                                {" "}
                                {/* Adjust the width for different screen sizes */}
                                <Typography
                                  sx={{
                                    color: "#000",
                                    fontSize: "15px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <p className="m-0 color_000 fs-13 fw-700  hideDotSideBarAsset">
                                    {result.title}
                                  </p>
                                  <p
                                    title={result.description}
                                    className="m-0 fs-11 fw300 color_7080 hideDotSideBarAsset mt-5 cur-pointer"
                                    style={{}}
                                  >
                                    {result.description}
                                  </p>

                                  <div
                                    className="hideDotTag"
                                    style={{ width: "100%" }}
                                  >
                                    {result.hashTag
                                      .toString()
                                      .split(",")
                                      .map((hashTag: any, index: number) => {
                                        return (
                                          <>
                                            {" "}
                                            <p
                                              className="hashtag_id color_119B m-0 fs-12 inlineBlock"
                                              id="hashtag_id"
                                              onClick={() =>
                                                headerSearch(hashTag)
                                              }
                                            >
                                              {hashTag}{" "}
                                            </p>
                                          </>
                                        );
                                      })}
                                  </div>
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        {/* No data found */}
                        {viewSimilar.length === 0 && (
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontSize: "13px",
                                  //   fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                No Similar Knowledge Found
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
            <Card className="mt-10 view_right">
              {isLoadingGroupKnowledge ? (
                <ViewSimilar />
              ) : (
                <CardContent className="as-r-card">
                  <Grid container>
                    <Grid item xl={12} lg={12} sm={12}>
                      <Box>
                        <Grid
                          container
                          item
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xl={12} lg={12} sm={12}>
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "13px",
                                //   fontSize: "16px",
                                fontWeight: "700",
                                width: "100%",
                              }}
                              className="hideDotSingleLine"
                              title={grpName}
                            >
                              Knowledge from {grpName}
                            </Typography>
                          </Grid>
                        </Grid>

                        {knowledgeFrom
                          .slice(0, 3)
                          .map((result: KnowledgeFrom, index: number) => (
                            <Grid container className="av-r-sk-div">
                              {/* Image Column */}
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <img
                                  src="../beta/assets/images/Asset Type.png"
                                  alt="Asset Type"
                                /> */}

                                {result.assetContent[0] ? (
                                  // If result.assetContent[0] is not empty
                                  <>
                                    {result.assetContent[0].fieldType ===
                                      "Video" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_light.svg`}
                                        alt="Video"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Audio" && (
                                      <img
                                        src={`..${subPath}/assets/images/Audio_light.svg`}
                                        alt="Audio"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Document" && (
                                      <img
                                        src={`..${subPath}/assets/images/Document_line.svg`}
                                        alt="Document"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Contact" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_face.svg`}
                                        alt="Contact"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "url" && (
                                      <img
                                        src={`..${subPath}/assets/images/link_light.svg`}
                                        alt="URL"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Image" && (
                                      <img
                                        src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                        alt="Image"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType !==
                                      "Video" &&
                                      result.assetContent[0].fieldType !==
                                        "Audio" &&
                                      result.assetContent[0].fieldType !==
                                        "Document" &&
                                      result.assetContent[0].fieldType !==
                                        "Contact" &&
                                      result.assetContent[0].fieldType !==
                                        "url" &&
                                      result.assetContent[0].fieldType !==
                                        "Image" && (
                                        <img
                                          src={`..${subPath}/assets/images/Document_line.svg`}
                                          alt="Document"
                                          className="hw50"
                                        />
                                      )}
                                  </>
                                ) : (
                                  // If result.assetContent[0] is empty, render something else here
                                  <img
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    alt="Document"
                                    className="hw50"
                                  />
                                )}
                              </Grid>
                              {/* Text Column */}
                              <Grid
                                item
                                xs={12}
                                sm={9}
                                onClick={(event) => {
                                  if (
                                    event.target instanceof HTMLElement &&
                                    event.target.id === "hashtag_grp"
                                  ) {
                                    console.log(
                                      "Clicked on Video cell. Navigation prevented."
                                    );
                                  } else {
                                    getAssetId(
                                      result.id,
                                      result.assetId,
                                      result.title,
                                      result.hashTag
                                    );
                                  }
                                }}
                                className="cur-pointer"
                              >
                                <Typography
                                  sx={{
                                    color: "#000",
                                    fontSize: "15px",
                                    verticalAlign: "middle",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <p className="m-0 color_000 fs-13 fw-700  hideDotSideBarAsset">
                                    {result.title}
                                  </p>
                                  <p
                                    title={result.description}
                                    className="m-0 fs-11 fw300 color_7080 hideDotSideBarAsset mt-5  cur-pointer"
                                  >
                                    {result.description}
                                  </p>
                                  <div
                                    className="hideDotTag"
                                    style={{ width: "100%" }}
                                  >
                                    {result.hashTag
                                      .toString()
                                      .split(",")
                                      .map((hashTag: any, index: number) => {
                                        return (
                                          <>
                                            <p
                                              className="hashtag_grp marginRight4 color_119B m-0 fs-12 inlineBlock"
                                              id="hashtag_grp"
                                              onClick={() =>
                                                headerSearch(hashTag)
                                              }
                                            >
                                              {hashTag}{" "}
                                            </p>
                                          </>
                                        );
                                      })}
                                  </div>
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}

                        {knowledgeFrom.length === 0 && (
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontSize: "13px",
                                  //   fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                No Data Found
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
            <Card className="mt-10 view_right">
              {isLoadingVaultKnowledge ? (
                <ViewSimilar />
              ) : (
                <CardContent className="as-r-card">
                  <Grid container>
                    <Grid item xl={12} lg={12} sm={12}>
                      <Box>
                        <Grid
                          item
                          container
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xl={12} lg={12} sm={12}>
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "13px",
                                //   fontSize: "16px",
                                fontWeight: "700",
                                width: "100%",
                              }}
                              className="hideDotSingleLine"
                              title={vaultName}
                            >
                              Knowledge from {vaultName}
                            </Typography>
                          </Grid>
                        </Grid>

                        {knowledgeFromVault
                          .slice(0, 3)
                          .map((result: KnowledgeFrom, index: number) => (
                            <Grid container className="av-r-sk-div">
                              {/* Image Column */}
                              <Grid
                                item
                                xs={12}
                                sm={2}
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <img
                                  src="../beta/assets/images/Asset Type.png"
                                  alt="Asset Type"
                                /> */}

                                {result.assetContent[0] ? (
                                  // If result.assetContent[0] is not empty
                                  <>
                                    {result.assetContent[0].fieldType ===
                                      "Video" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_light.svg`}
                                        alt="Video"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Audio" && (
                                      <img
                                        src={`..${subPath}/assets/images/Audio_light.svg`}
                                        alt="Audio"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Document" && (
                                      <img
                                        src={`..${subPath}/assets/images/Document_line.svg`}
                                        alt="Document"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Contact" && (
                                      <img
                                        src={`..${subPath}/assets/images/User_face.svg`}
                                        alt="Contact"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "url" && (
                                      <img
                                        src={`..${subPath}/assets/images/link_light.svg`}
                                        alt="URL"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType ===
                                      "Image" && (
                                      <img
                                        src={`..${subPath}/assets/images/Img_box_line_hover.svg`}
                                        alt="Image"
                                        className="hw50"
                                      />
                                    )}
                                    {result.assetContent[0].fieldType !==
                                      "Video" &&
                                      result.assetContent[0].fieldType !==
                                        "Audio" &&
                                      result.assetContent[0].fieldType !==
                                        "Document" &&
                                      result.assetContent[0].fieldType !==
                                        "Contact" &&
                                      result.assetContent[0].fieldType !==
                                        "url" &&
                                      result.assetContent[0].fieldType !==
                                        "Image" && (
                                        <img
                                          src={`..${subPath}/assets/images/Document_line.svg`}
                                          alt="Document"
                                          className="hw50"
                                        />
                                      )}
                                  </>
                                ) : (
                                  // If result.assetContent[0] is empty, render something else here
                                  <img
                                    src={`..${subPath}/assets/images/Document_line.svg`}
                                    alt="Document"
                                    className="hw50"
                                  />
                                )}
                              </Grid>
                              {/* Text Column */}
                              <Grid
                                item
                                xs={12}
                                sm={9}
                                onClick={(event) => {
                                  if (
                                    event.target instanceof HTMLElement &&
                                    event.target.id === "hashtag_vault"
                                  ) {
                                    console.log(
                                      "Clicked on Video cell. Navigation prevented."
                                    );
                                  } else {
                                    getAssetId(
                                      result.id,
                                      result.assetId,
                                      result.title,
                                      result.hashTag
                                    );
                                  }
                                }}
                                className="cur-pointer"
                              >
                                <Typography
                                  sx={{
                                    color: "#000",
                                    fontSize: "15px",
                                    verticalAlign: "middle",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <p className="m-0 color_000 fs-13 fw-700  hideDotSideBarAsset">
                                    {result.title}
                                  </p>
                                  <p
                                    title={result.description}
                                    className="m-0 fs-11 fw300 color_7080 hideDotSideBarAsset mt-5  cur-pointer"
                                  >
                                    {result.description}
                                  </p>

                                  <div
                                    className="hideDotTag"
                                    style={{ width: "100%" }}
                                  >
                                    {result.hashTag
                                      .toString()
                                      .split(",")
                                      .map((hashTag: any, index: number) => {
                                        return (
                                          <>
                                            <p
                                              className="hashtag_vault marginRight4 color_119B m-0 fs-12 inlineBlock"
                                              id="hashtag_vault"
                                              onClick={() =>
                                                headerSearch(hashTag)
                                              }
                                            >
                                              {hashTag}{" "}
                                            </p>
                                          </>
                                        );
                                      })}
                                  </div>
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}

                        {knowledgeFromVault.length === 0 && (
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                }}
                              >
                                No Data Found
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <div>
        <BootstrapDialog
          className="delete_modal"
          onClose={handleCloseDelete}
          aria-labelledby="customized-dialog-title"
          open={openDelete}
        >
          <DialogTitle
            id="customized-dialog-title"
            className="fs-16 modal-headtext"
          >
            Delete Confirmation
          </DialogTitle>
          <DialogContent dividers>
            <Typography className="fs-14">
              Are you sure you want to delete?
            </Typography>
          </DialogContent>
          <DialogActions>
            <button
              type="button"
              onClick={handleCloseDelete}
              className="cancel_btn"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={removeAssetRedirect}
              className="m-button"
              autoFocus
            >
              Delete
            </button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default View;
