export const GroupIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "8px" }}
      className="assetcard_big_icon"
      width="15"
      height="15"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.45952 14.1185L8.45952 14.1185L8.46307 14.1169C9.5794 13.6208 10.9504 13.25 12.5 13.25C14.0489 13.25 15.4203 13.6204 16.5386 14.1076C17.4322 14.5056 18 15.4029 18 16.38V17.5H7V16.39C7 15.4005 7.56964 14.5057 8.45952 14.1185ZM4.5 14.5C4.62433 14.5 4.74703 14.5045 4.86911 14.5127C4.63165 15.0951 4.5 15.7279 4.5 16.39V17.5H1V16.43C1 15.8219 1.35924 15.2808 1.91846 15.0389L1.91956 15.0384C2.70911 14.6948 3.58135 14.5 4.5 14.5ZM23.0804 15.0385L23.0815 15.0389C23.6408 15.2808 24 15.8219 24 16.43V17.5H20.5V16.39C20.5 15.7279 20.3684 15.0951 20.1309 14.5127C20.253 14.5045 20.3757 14.5 20.5 14.5C21.4186 14.5 22.2909 14.6948 23.0804 15.0385ZM6 11C6 11.8239 5.32386 12.5 4.5 12.5C3.67614 12.5 3 11.8239 3 11C3 10.1761 3.67614 9.5 4.5 9.5C5.32386 9.5 6 10.1761 6 11ZM22 11C22 11.8239 21.3239 12.5 20.5 12.5C19.6761 12.5 19 11.8239 19 11C19 10.1761 19.6761 9.5 20.5 9.5C21.3239 9.5 22 10.1761 22 11ZM12.5 6.5C13.8839 6.5 15 7.61614 15 9C15 10.3839 13.8839 11.5 12.5 11.5C11.1161 11.5 10 10.3839 10 9C10 7.61614 11.1161 6.5 12.5 6.5Z"
        stroke="#708090"
      />
    </svg>
  );
};

export const VaultIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "8px" }}
      className="assetcard_big_icon"
      width="15"
      height="15"
      viewBox="0 0 25 24"
      fill="none"
    >
      <rect x="4" y="4.5" width="17" height="15" rx="0.5" stroke="#708090" />
      <rect
        x="5.99902"
        y="6.50195"
        width="13"
        height="11"
        rx="0.5"
        stroke="#708090"
      />
      <circle
        cx="12.499"
        cy="12.001"
        r="3.625"
        stroke="#708090"
        strokeWidth="0.75"
      />
      <circle cx="12.499" cy="12.001" r="1" fill="#708090" />
      <path
        d="M9.5 11.627H9.125V12.377H9.5V11.627ZM10 12.377C10.2071 12.377 10.375 12.2091 10.375 12.002C10.375 11.7948 10.2071 11.627 10 11.627V12.377ZM9.5 12.377H10V11.627H9.5V12.377Z"
        fill="#708090"
      />
      <path
        d="M12.875 9.03418L12.875 8.65918L12.125 8.65918L12.125 9.03418L12.875 9.03418ZM12.125 9.53418C12.125 9.74129 12.2929 9.90918 12.5 9.90918C12.7071 9.90918 12.875 9.74129 12.875 9.53418L12.125 9.53418ZM12.125 9.03418L12.125 9.53418L12.875 9.53418L12.875 9.03418L12.125 9.03418Z"
        fill="#708090"
      />
      <path
        d="M12.125 15.002L12.125 15.377L12.875 15.377L12.875 15.002L12.125 15.002ZM12.875 14.502C12.875 14.2948 12.7071 14.127 12.5 14.127C12.2929 14.127 12.125 14.2948 12.125 14.502L12.875 14.502ZM12.875 15.002L12.875 14.502L12.125 14.502L12.125 15.002L12.875 15.002Z"
        fill="#708090"
      />
      <path
        d="M15.5 12.377L15.875 12.377L15.875 11.627L15.5 11.627L15.5 12.377ZM15 11.627C14.7929 11.627 14.625 11.7948 14.625 12.002C14.625 12.2091 14.7929 12.377 15 12.377L15 11.627ZM15.5 11.627L15 11.627L15 12.377L15.5 12.377L15.5 11.627Z"
        fill="#708090"
      />
      <path
        d="M10.6558 9.61765L10.3906 9.35248L9.86029 9.88281L10.1255 10.148L10.6558 9.61765ZM10.479 10.5015C10.6255 10.648 10.8629 10.648 11.0093 10.5015C11.1558 10.3551 11.1558 10.1176 11.0093 9.9712L10.479 10.5015ZM10.1255 10.148L10.479 10.5015L11.0093 9.9712L10.6558 9.61765L10.1255 10.148Z"
        fill="#708090"
      />
      <path
        d="M14.8745 10.1714L15.1397 9.90625L14.6094 9.37592L14.3442 9.64108L14.8745 10.1714ZM13.9907 9.99464C13.8442 10.1411 13.8442 10.3785 13.9907 10.525C14.1371 10.6714 14.3745 10.6714 14.521 10.525L13.9907 9.99464ZM14.3442 9.64108L13.9907 9.99464L14.521 10.525L14.8745 10.1714L14.3442 9.64108Z"
        fill="#708090"
      />
      <path
        d="M10.1255 13.8628L9.86029 14.1279L10.3906 14.6583L10.6558 14.3931L10.1255 13.8628ZM11.0093 14.0395C11.1558 13.8931 11.1558 13.6557 11.0093 13.5092C10.8629 13.3628 10.6255 13.3628 10.479 13.5092L11.0093 14.0395ZM10.6558 14.3931L11.0093 14.0395L10.479 13.5092L10.1255 13.8628L10.6558 14.3931Z"
        fill="#708090"
      />
      <path
        d="M14.3676 14.3931L14.6328 14.6583L15.1631 14.1279L14.898 13.8628L14.3676 14.3931ZM14.5444 13.5092C14.398 13.3628 14.1605 13.3628 14.0141 13.5092C13.8676 13.6557 13.8676 13.8931 14.0141 14.0395L14.5444 13.5092ZM14.898 13.8628L14.5444 13.5092L14.0141 14.0395L14.3676 14.3931L14.898 13.8628Z"
        fill="#708090"
      />
      <rect x="5.49902" y="19" width="5" height="1.5" fill="#708090" />
      <rect x="14.499" y="19" width="5" height="1.5" fill="#708090" />
      <rect x="4.49805" y="9.00195" width="1" height="1" fill="#708090" />
      <rect x="4.49805" y="14.001" width="1" height="1" fill="#708090" />
    </svg>
  );
};
