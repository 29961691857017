import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { getToken, http } from "./Global";

const baseURL = http;
let isRefreshing = false;
let refreshSubscribers: any[] = [];

const createAxiosInstance = (token: string) => {
  return axios.create({
    baseURL,
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  });
};

const isTokenExpired = (exp: any) => {
  return dayjs.unix(exp || 0).diff(dayjs()) < 1;
};

const isRefreshTokenExpired = (decoded: any, refreshToken: string) => {
  const isRefreshExpired = isTokenExpired(decoded?.exp);
  if (isRefreshExpired || !refreshToken) {
    logoutSession();
  }
};

const refreshToken = async () => {
  const authTokens = getToken();
  const refreshToken = authTokens.RefreshToken;
  const token = authTokens.Token;

  try {
    const response = await axios.post(
      `${baseURL}refresh/tokens?refreshToken=${refreshToken}&accessToken=${token}`
    );
    if (response.status === 200) {
      const newToken = response.data.data.Token;
      onRefreshed(newToken);

      response.data.data.RefreshToken = refreshToken;
      localStorage.setItem("KM_Admin", JSON.stringify(response.data.data));
      localStorage.setItem("KM_Tool", "true"); // Parse the JSON response
      const logoutState = localStorage.getItem("logout");
      if (logoutState !== null) {
        localStorage.setItem("logout", logoutState); // set the previous logout state
      }
      return newToken;
    } else {
      logoutSession();
    }
  } catch (error) {
    logoutSession();
  }
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const addSubscriber = (callback: any) => {
  refreshSubscribers.push(callback);
};

const UseAxios = () => {
  let authTokens = getToken();
  let token = authTokens.Token;
  let decoded = token ? jwtDecode(token) : ({} as any);
  const refresDecoded = authTokens.RefreshToken
    ? jwtDecode(authTokens.RefreshToken)
    : ({} as any);

  const doRefreshToken = async () => {
    if (!isRefreshing) {
      isRefreshing = true;
      const newToken = await refreshToken();
      isRefreshing = false;
      return newToken;
    }
    return null;
  };

  // Create an axios instance
  const axiosInstance = createAxiosInstance(token);

  axiosInstance.interceptors.request.use(async (req: any) => {
    if (req.url.includes("user/signin")) return req;
    const refreshToken = authTokens.RefreshToken;
    // Check if the refresh token is expired
    isRefreshTokenExpired(refresDecoded, refreshToken);
    // Check if the access token is expired
    if (!isTokenExpired(decoded.exp)) return req;
    // If the access token is expired and a refresh is already in progress,
    const newToken = await doRefreshToken();
    if (newToken) {
      req.headers.Authorization = `Bearer ${newToken}`;
    } else {
      // If a refresh is already in progress, queue the request until the token is refreshed
      await new Promise((resolve) => addSubscriber(resolve));
      req.headers.Authorization = `Bearer ${getToken().Token}`;
    }
    return req;
  });

  return axiosInstance;
};

const logoutSession = () => {
  window.localStorage.clear();
  localStorage.removeItem("KM_Admin");
  localStorage.removeItem("KM_Tool");
  localStorage.clear();
  window.location.href = "/";
};

export default UseAxios;
