import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputBase,
  Grid,
  Badge,
} from "@mui/material";
import { getChatList } from "../../../../Redux/chatList/action";
import { getChatSeen } from "../../../../Redux/Chatview/action";
import {
  getGroups,
  getMessagesGroupChat,
} from "../../../../Redux/GroupChat/action";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import CryptoJS from "crypto-js";
import _ from "lodash";
import CircleView from "./CircleView";
import CircleCreate from "./CreateCircle/CircleCreate";
// Define an interface for your chat data
interface LastMessage {
  content: string;
  createdAt: string;
  read: boolean;
  senderId: string | null;
  senderName: string | null;
  senderProfilePicture: string | null;
  type: string;
  updatedAt: string;
  unreadMessageCount: number;
}

interface Group {
  colorCode: string | null;
  groupId: string | null;
  groupName: string;
  groupProfilePicture: string | null;
  userId: string;
  userName: string;
  userProfilePicture: string | null;
  lastMessage: LastMessage;
  pinnedGroup: boolean;
  mutedGroup: boolean;
}
interface GroupChatData {
  colorCode: string | null;
  groupId: string | null;
  groupName: string;
  groupProfilePicture: string | null;
  userId: string;
  userName: string;
  userProfilePicture: string | null;
  lastMessage: LastMessage;
  empty: boolean;
}
const userId: string | null = decodeToken()?.id ?? null;

// truncat the word set max word to show, other words make three dots
const truncateContent = (content: string, maxChars: number) => {
  if (content.length > maxChars) {
    return content.slice(0, maxChars) + "...";
  }
  return content;
};

const getMessageContent = (type: string, content: string) => {
  switch (type) {
    case "TEXT":
      return truncateContent(content, 25);
    case "IMAGE":
      return "Image";
    case "VIDEO":
      return "Video";
    default:
      return "";
  }
};

//Decrypt encrypted text
export function decrypt(value: string | CryptoJS.lib.CipherParams): string {
  if (value && value !== "") {
    try {
      const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

      // Decrypting the value
      const decrypted = CryptoJS.AES.decrypt(value, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Converting decrypted value to UTF-8 string
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

      // Logging decrypted value for debugging
      console.log("Decrypted value:", decryptedString);

      // Return decrypted string
      return decryptedString;
    } catch (error) {
      console.error("Error during decryption:", error);
      return "";
    }
  }

  return "";
}

const CircleList: React.FC<any> = (userList) => {
  const dispatch = useAppDispatch();
  const [group, setGroup] = useState<GroupChatData | null>(null);
  const [activeChat, setActiveChat] = useState<Group | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const newAbortController = new AbortController();
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );
  const fetchChatList = useCallback(
    (searchValue = "") => {
      if (!userList || !userList.userList || !userList.userList.id) {
        return;
      }
      const data = {
        userId: userList.userList.id,
        groupName: searchValue,
        pageNo: 1,
        rowPerPage: 10,
      };
      const signal = new AbortController();
      dispatch(getGroups(data, signal.signal));
    },
    [dispatch, userList]
  );

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      fetchChatList(value);
    }, 300),
    [fetchChatList]
  );

  useEffect(() => {
    if (userList && userList.userList) {
      fetchChatList();
    }
  }, [fetchChatList, userList]);

  useEffect(() => {
    if (searchValue) {
      debouncedSearch(searchValue);
    }
  }, [searchValue, debouncedSearch]);

  const groupChatList = useAppSelector((state: any) => state.groupChat.groups);
  console.log(groupChatList, "groupChatList");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChatClick = (chat: any) => {
    const userDetails = {
      senderId: userList.userList.id,
      recipientId: chat.userId,
    };
    const signal = new AbortController();
    dispatch(getChatSeen(userDetails, signal));
    const data: GroupChatData = {
      colorCode: chat.colorCode,
      groupId: chat.groupId,
      groupName: chat.groupName,
      groupProfilePicture: chat.groupProfilePicture,
      userId: chat.userId,
      userName: chat.userName,
      userProfilePicture: chat.userProfilePicture,
      lastMessage: chat.lastMessage,
      empty: true,
    };

    const groupDetails = {
      groupId: chat?.groupId,
      userId: userData.id,
      pageNo: 1, // Add this property
      rowPerPage: 10, // Add this property
    };
    console.log(userData, "userData", chat);
    chat
      ? dispatch(getMessagesGroupChat(groupDetails, newAbortController))
      : "";
    setGroup(data);
    setActiveChat(chat);
  };
console.log( userData,"userData")

  return (
    <Grid
      container
      sx={{
        position: "fixed",

        width: { xs: "88.6%", sm: "93%", md: "95%", lg: "96%" },
      }}
    >
      <Grid
        item
        xs={5}
        sm={2.85}
        lg={2.85}
        sx={{
          backgroundColor: "white",
          width: "102px",
          borderRight: "1px solid #E5E5E5",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            height: "100vh",
            backgroundColor: "#ffffff",
          }}
        >
          <Grid
            item
            sx={{
              height: "100px",
              backgroundColor: "#ffffff",
              position: "sticky",
              top: "60px",
              zIndex: 1,
            }}
          >
            <Grid container sx={{ padding: "10px", paddingTop: "25px" }}>
              <Grid item xs={6}>
                <Typography
                  variant="h3"
                  sx={{
                    background:
                      "linear-gradient(87.09deg, #35BA79 4.84%, #119BFF 95.16%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Circles
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "11px",
                }}
              >
                <CircleCreate />
              </Grid>
            </Grid>

            <Box
              className=" "
              style={{ cursor: "text", padding: "10px", paddingTop: "4px" }}
            >
              <div
                className="search_header_list d-flex"
                style={{ alignItems: "center" }}
              >
                <img
                  //   src="../beta/assets/images/search.svg"
                  src={`..${subPath}/assets/images/images/searchIocn_chart.png`}
                  alt="Search"
                  style={{ width: "18px", height: "18px" }}
                />
                <InputBase
                  placeholder="Search members"
                  className="ml-15 input_search"
                  sx={{
                    background: "#fffff",
                    boxSizing: "border-box",
                    height: "35px",
                    borderRadius: "5px",
                  }}
                  id="searchText"
                  autoComplete="off"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </Box>
          </Grid>
          <List
            sx={{
              // paddingTop: "12px",
              width: "100%",
              height: "80%",
              overflowY: "auto",
              paddingBottom: "0px",
              marginTop: "15px",
            }}
          >
            {Array.isArray(groupChatList.chatGroupsList) &&
              groupChatList.chatGroupsList.map(
                (group: Group, index: number) => (
                  <ListItem
                    className={`chat_list_hover ${
                      activeChat === group ? "active_chat" : ""
                    }`}
                    alignItems="flex-start"
                    key={index}
                    sx={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingBottom: "6px",
                      paddingTop: "0px",
                      backgroundColor:
                        activeChat === group ? "#f0f0f0" : "inherit",
                    }}
                    onClick={() => handleChatClick(group)}
                  >
                    <ListItemAvatar sx={{ width: "50px", minWidth: "50px" }}>
                      <Badge
                        className="Circle_chat_badge"
                        badgeContent={
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={`..${subPath}/assets/images/images/Group_active_icon.png`}
                              alt="online"
                              style={{ width: "12px", height: "12px" }}
                            />
                          </Grid>
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar
                          alt="Profile"
                          sx={{
                            borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                          }}
                          src={group.groupProfilePicture || undefined}
                        >
                          {!group.groupProfilePicture &&
                            group.groupName.charAt(0).toUpperCase()}
                        </Avatar>
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ marginTop: "9px", marginBottom: "0px" }}
                      primary={
                        <React.Fragment>
                          <Grid container>
                            <Grid item xs={6} md={7} lg={8.5} xl={9}>
                              <Grid item>
                                <Typography
                                  variant="body1"
                                  className="name_title_chat"
                                  sx={{
                                    fontWeight: group.lastMessage.read
                                      ? "500"
                                      : "bold",
                                  }}
                                >
                                  {truncateContent(
                                    group.groupName.charAt(0).toUpperCase() +
                                      group.groupName.slice(1),
                                    18
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className="name_Subtit_chat"
                                  variant="body2"
                                  sx={{ display: "inline", color: "#708090" }}
                                >
                                  <span className="Sender_name">
                                    {group.lastMessage.senderId ===
                                    userData.id ? (
                                    // {group.lastMessage.senderName ===
                                    // group.lastMessage.senderName? (
                                      <Grid
                                        item
                                        sx={{
                                          display: "inline",
                                          color: "#119BFF",
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                        }}
                                      >
                                        You&nbsp;:&nbsp;
                                      </Grid>
                                    ) : group.lastMessage.senderName ? (
                                   <span>{group.lastMessage.senderName}&nbsp;:&nbsp;</span>
                                    ): (<Grid item>Group created</Grid>)
                                    
                                    }
                                  </span>
                                  <span>
                                    {getMessageContent(
                                      group.lastMessage.type,
                                      decrypt(group.lastMessage.content) || ""
                                    )}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={6} md={5} lg={3.5} xl={3}>
                              <Grid item sx={{ textAlign: "end" }}>
                                <Typography
                                  className="time_lable"
                                  variant="body2"
                                  sx={{
                                    fontWeight: group.lastMessage.read
                                      ? "normal"
                                      : "bold",
                                  }}
                                >
                                  {new Date(
                                    group.lastMessage.updatedAt
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "center",
                                }}
                              >
                                
                                {group.lastMessage.unreadMessageCount > 0 && <Grid item>
                                  <Typography
                                    className="Chat_message_count"
                                    // sx={{ fontSize: "10px", fontWeight: 500, fontFamily:'Montserrat' }}
                                  >
                                    {group.lastMessage.unreadMessageCount}
                                  </Typography>
                                </Grid>}

                                {group.mutedGroup && <Grid
                                  item
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    //   src="../beta/assets/images/search.svg"
                                    src={`..${subPath}/assets/images/images/notification_chat.png`}
                                    alt="Search"
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                      marginLeft: "9px",
                                    }}
                                  />
                                </Grid>}

                                { group.pinnedGroup && <Grid
                                  item
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "9px",
                                  }}
                                >
                                  <img
                                    //   src="../beta/assets/images/search.svg"
                                    src={`..${subPath}/assets/images/images/pin_angle_chat.png`}
                                    alt="Search"
                                    style={{ width: "15px", height: "15px" }}
                                  />
                                </Grid>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                )
              )}
          </List>
        </Box>
      </Grid>
      <Grid item xs={7} sm={9.15} lg={9.15}>
        {group && <CircleView groupDetails={group} />}
      </Grid>
    </Grid>
  );
};

export default CircleList;
