import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Form, Input, Select } from "antd";
import axios, { AxiosError } from "axios";
import { FiUpload } from "react-icons/fi";
import { decodeToken, imagehttp, subPath } from "../../Constants/Global";
import * as Yup from "yup";
import "../Modal/Modal.css";
import { useNavigate } from "react-router-dom";
import eventEmitter from "../../Constants/EventEmiter";
import UseAxios from "../../Constants/UseAxios";

//modal open props
interface GroupModalProps {
  isOpen: boolean;
  closeModal: () => void;
  refreshSidebarGroup: () => void;
}
//select member
interface MemberInfo {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture: File;
}

const GroupModal: React.FC<GroupModalProps> = ({
  isOpen,
  closeModal: closeModalFromProps,
  refreshSidebarGroup,
}) => {
  const api = UseAxios();
  const companyId = decodeToken().companyId;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [upload, setUpload] = useState<string | null>(null);
  const maxSize = 2 * 1024 * 1024;
  const [errorMessage, setErrorMessage] = useState("");
  const [selectMember, setSelectMember] = useState<MemberInfo[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuffixIcon, setShowSuffixIcon] = useState(true);

  const [abortController, setAbortController] = useState(new AbortController());

  const CustomSuffixIcon = () => (
    <img
      src={`..${subPath}/assets/images/search_member.svg`}
      alt="Custom Icon"
      style={{ width: "16px", height: "16px" }}
    />
  );

  const closeModal = () => {
    setErrorMessage("");
    form.resetFields();
    setUpload(null);
    setSelectedMembers([]);
    closeModalFromProps();
  };
  const clearUpload = () => {
    // Function to clear the uploaded image
    setUpload(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  const { Option } = Select;

  const handleSearch = (value: string) => {
    fetchSelectUser(value);
    if (selectedMembers.length == 0 || value.trim() == "") {
      setShowSuffixIcon(true);
    } else {
      setShowSuffixIcon(false);
    }
  };

  useEffect(() => {
    eventEmitter.on("triggerViewMembers", createGroupCall);
    return () => {
      eventEmitter.off("triggerViewMembers", createGroupCall);
    };
  }, []);

  const createGroupCall = () => {
    fetchSelectUser("");
  };

  const fetchSelectUser = async (value: any) => {
    abortController.abort();
    // Create a new abort controller for the current request
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    try {
      const response = await api.get(
        `member/view-members-search?companyId=${companyId}&title=${value}`,
        {
          signal: newAbortController.signal,
        }
      );
      if (response.data.status === true) {
        const newData = response.data.data;
        setSelectMember(newData);
      }
    } catch (error) {}
  };
  const onFinish = async () => {
    setErrorMessage("");
    setIsLoading(true);
    // Empty the error messages and red borders
    const errorFields = document.getElementsByClassName("error");
    for (let i = 0; i < errorFields.length; i++) {
      errorFields[i].innerHTML = "";
    }
    const inputFields = document.getElementsByClassName("field");
    for (let i = 0; i < inputFields.length; i++) {
      inputFields?.[i]?.classList.remove("borderRed");
    }

    // Validate Before Submitting
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      description: Yup.string().required(),
      members: Yup.array().min(1).required(),
    });

    // Data to validate
    const dataToValidate = {
      name,
      description,
      members: selectedMembers,
    };

    // Validate the data against the schema
    schema
      .validate(dataToValidate, { abortEarly: false })
      .then(async () => {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("members", selectedMembers.toString());
        formData.append("upload", fileInputRef.current?.files?.[0] || "");
        if (companyId) {
          formData.append("companyId", companyId.toString());
        }
        try {
          const response = await api.post("group/create", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
            },
          });

          refreshSidebarGroup();
          eventEmitter.emit("groupinsert");
          eventEmitter.emit("sidebar");
          eventEmitter.emit("groupList");
          // Assuming the response contains a success message
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });

          navigate("/groupView");
          form.resetFields();
          setUpload(null);
          setName("");
          setDescription("");
          setSelectedMembers([]);
          closeModal();
          clearUpload();
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          if (axios.isAxiosError(error)) {
            //Set the Validation error under the fields
            if (error.response?.status === 422) {
              var errors = error.response?.data.message;
              const errorArray = errors.split(";");
              for (const key in errorArray) {
                var keyVal = errorArray[key].trim().split(":");
                const errorField = document.getElementById(keyVal[0] + "Error");
                // Add Red Border to the field
                const inputField = document.getElementsByClassName(
                  keyVal[0].trim()
                );
                inputField?.[0]?.classList.add("borderRed");
                if (errorField) {
                  errorField.innerHTML =
                    keyVal[1].charAt(0).toUpperCase() +
                    keyVal[1].slice(1) +
                    ".";
                }
              }
            } else {
              // Set the timeout to 3 seconds, after that, remove the error message
              toast.error(error.response?.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              });
            }
          } else {
            toast.error((error as AxiosError).message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      })
      .catch((validationErrors) => {
        setIsLoading(false);
        for (let i = 0; i < validationErrors.inner.length; i++) {
          const errorField = document.getElementById(
            validationErrors.inner[i].path + "Error"
          );
          const inputField = document.getElementsByClassName(
            validationErrors.inner[i].path
          );
          inputField?.[0]?.classList.add("borderRed");
          if (errorField) {
            errorField.innerHTML =
              validationErrors.inner[i].message.charAt(0).toUpperCase() +
              validationErrors.inner[i].message.slice(1) +
              ".";
          }
        }
      });
  };
  const handleSelectChange = (values: number[]) => {
    setSelectedMembers(values);
    setShowSuffixIcon(values.length === 0);
  };
  const maxLengthvalidation = 25;

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };
  return (
    <Modal
      width={500}
      title="Create Group"
      open={isOpen}
      onCancel={closeModal}
      maskClosable={false}
      className="modal-border"
      footer={[
        <span
          key="submit"
          className="m-button customButton"
          style={{
            cursor: isLoading ? "not-allowed" : "pointer",
            opacity: isLoading ? 0.5 : 1,
            pointerEvents: isLoading ? "none" : "auto",
          }}
          onClick={form.submit}
        >
          {isLoading ? "Loading..." : "Create Group"}
        </span>,
      ]}
      centered={true}
    >
      <Form
        form={form}
        name="form-name"
        layout="vertical"
        onFinish={onFinish}
        className="c_group_form"
      >
        <Form.Item name="name" className="m-text">
          <label htmlFor="name">
            <span className="m-lbl">Name</span>
            <span className="lbl-man" style={{ color: "var(--1, #119BFF)" }}>
              *
            </span>
          </label>
          <Input
            id="name"
            placeholder="Type Here"
            title="Name"
            className="field name fs-14 fw-500 colorBlack"
            // onChange={(event) => setName(event.target.value)}
            maxLength={maxLengthvalidation}
            onChange={handleNameChange}
          />
          <span
            id="nameError"
            style={{ color: "red" }}
            className="error"
          ></span>
        </Form.Item>
        <Form.Item name="description" className="m-text mt-5">
          <label htmlFor="description">
            <span className="m-lbl">Description</span>
            <span className="lbl-man" style={{ color: "var(--1, #119BFF)" }}>
              *
            </span>
          </label>
          <Input.TextArea
            id="description"
            placeholder="Type Here"
            className="field description fs-14 fw-500 colorBlack"
            title="description"
            rows={4}
            onChange={(event) => setDescription(event.target.value)}
          />
          <span
            id="descriptionError"
            style={{ color: "red" }}
            className="error"
          ></span>
        </Form.Item>
        <Form.Item
          label={
            <span className="m-lbl">
              Add Members
              <span className="lbl-man" style={{ color: "var(--1, #119BFF)" }}>
                *
              </span>
            </span>
          }
          name="members"
          className="m-text mt-5"
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Search member"
            onChange={handleSelectChange}
            value={selectedMembers}
            onSearch={handleSearch}
            showSearch
            className="field members searchicon seach_pad"
            filterOption={false}
            // suffixIcon={<CustomSuffixIcon />}
            suffixIcon={showSuffixIcon ? <CustomSuffixIcon /> : null}
          >
            <Option value="" disabled className="fs-14 fw-500 colorBlack">
              <span
                role="img"
                aria-label="Member1"
                className="fs-14 fw-500 colorBlack"
              >
                Choose Member
              </span>
            </Option>

            {selectMember.map((e: MemberInfo, key) => {
              const fullName = `${e.firstName} ${e.lastName}`;
              return (
                // If the user is the logged in user, don't show it in the list
                // e.id === userId ? null : (
                <Option
                  key={key}
                  value={e.id}
                  className="fs-14 fw-500 colorBlack"
                >
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="fs-14 fw-500 colorBlack"
                  >
                    <img
                      src={`${imagehttp}${e.profilePicture}`}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "8px",
                        borderRadius: "50%",
                      }}
                    />
                    {fullName}
                  </div>
                </Option>
                // )
              );
            })}
          </Select>

          <span
            id="membersError"
            style={{ color: "red" }}
            className="error"
          ></span>
        </Form.Item>
        <Form.Item
          name="Add Picture"
          className="m-lbl m-text mt-5"
          label="Add Picture"
        >
          <div>
            {upload ? (
              <div>
                <img
                  src={upload}
                  width="150px"
                  height="150px"
                  className="br_5"
                />
              </div>
            ) : null}
          </div>

          <input
            type="file"
            // accept="image/*"
            accept=".jpeg, .png, .gif, .bmp, .heif, .svg, .hdr,.jpg"
            multiple
            name="upload"
            style={{ display: "none" }}
            onChange={() => {
              const selectedFile = fileInputRef.current?.files?.[0];
              const acceptedTypes = [
                ".jpeg",
                ".jpg",
                ".png",
                ".gif",
                ".bmp",
                ".heif",
                ".svg",
                ".hdr",
              ];
              const isValidFileType = acceptedTypes.some((type) =>
                selectedFile?.name.toLowerCase().endsWith(type)
              );
              if (isValidFileType && selectedFile) {
                // Check if the selected file is an image (e.g., image/jpeg, image/png, etc.)
                if (isValidFileType && selectedFile.size <= maxSize) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setUpload(e.target?.result as string);
                  };
                  reader.readAsDataURL(selectedFile);
                  setErrorMessage(" ");
                } else {
                  setErrorMessage(
                    `File size must be less than ${maxSize / (1024 * 1024)} MB`
                  );
                  setUpload("");
                }
              } else {
                if (!isValidFileType) {
                  setErrorMessage(
                    `Accepted types are: ${acceptedTypes.join(", ")}`
                  );
                }

                setUpload("");
              }
            }}
            ref={fileInputRef}
          />
          {upload ? (
            <img
              src={`..${subPath}/assets/images/solar_trash-bin-2-broken.png`}
              onClick={clearUpload}
              className="cur-pointer"
            />
          ) : (
            <FiUpload
              color="#119BFF"
              size="23px"
              onClick={() => fileInputRef.current?.click()}
              style={{ cursor: "pointer" }}
            />
          )}
          {errorMessage ? (
            <>
              <div style={{ color: "red", marginTop: "8px" }}>
                {errorMessage}
              </div>
            </>
          ) : (
            ""
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GroupModal;
