import {
    Box,
    Button,
    Grid,
    InputBase,
    Typography,
    createTheme,
  } from "@mui/material";
  import React from "react";
  import { subPath } from "../../../../../Constants/Global";
import CircleSelectBox from "./CircleSelectBox";
  
  
  interface ForwardMessageProps {
    forwardMessage: () => void;
  }
  const CircleForwardMessage = ({
    forwardMessage,
    forwardMsg,
    chatData,
    isPinShow,
  }: any) => {
    return (
      <>
        <Grid
          item
          xs={2.48}
          md={2.5}
          lg={2.54}
          sx={{
            position: "fixed",
            right: 0,
            width: "100%",
            top: isPinShow ? "155px" : "125px",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              width: "100%",
              height: "80%",
              overflowY: "auto",
            }}
          >
            <Grid container sx={{ padding: "10px", paddingTop: "20px" }}>
              <Grid
                item
                sm={8}
                lg={10}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography className="ForwardMessage_lable">
                  Forward Message
                </Typography>
              </Grid>
              <Grid
                item
                sm={4}
                lg={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <img
                  onClick={forwardMessage}
                  src={`..${subPath}/assets/images/images/Cancle_icon.png`}
                  alt="Search"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
  
            <Grid item sx={{ padding: "10px" }}>
              <CircleSelectBox
                forwardMessage={forwardMessage}
                forwardMsg={forwardMsg}
                chatData={chatData}
              />
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };
  export default CircleForwardMessage;
  