import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { subPath } from "../../../../../Constants/Global";
import CircleSelectPerson from "./CircleSelectPerson";
interface AddMemberProps {
  handleAddMember: () => void;
}
const AddMember: React.FC<AddMemberProps> = ({ handleAddMember }) => {
  return (
    <Box sx={{ padding: "10px" }}>
      <Grid container mt={2}>
        <Grid item xs={10}>
          <Typography
            className="add_member"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Add Member
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          <img
            onClick={handleAddMember}
            className="Cancle_icon_circle"
            src={`..${subPath}/assets/images/images/Cancle_icon.png`}
            alt="cancle"
          />
        </Grid>
      </Grid>

      <Grid item>
        <CircleSelectPerson />
      </Grid>
    </Box>
  );
};
export default AddMember;
