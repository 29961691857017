import { Dispatch } from "redux";
import ChatAxios from "../../Constants/ChatAxios";
import axios from "axios";
const api = ChatAxios();

const GET_REPLIES_FOR_MESSAGE = "GET_REPLIES_FOR_MESSAGE";
const GET_MESSAGES = "GET_MESSAGES";
const GET_MEDIA_MESSAGES = "GET_MEDIA_MESSAGES";
const GET_GROUPS = "GET_GROUPS";
const GET_GROUP_MEMBERS = "GET_GROUP_MEMBERS";
const GET_GROUP_DETAILS = "GET_GROUP_DETAILS";
const GET_CIRCLE_GROUP_DETAILS = "GET_CIRCLE_GROUP_DETAILS";
const GET_CIRCLE_MEDIA = "GET_CIRCLE_MEDIA";
const THEARD_SHOW = "THEARD_SHOW";
interface getRepliesForMessage {
  groupId: string | null;
  messageId: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface getMessages {
  groupId: string | null;
  userId: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface getMediaMessages {
  groupId: string | null;
  userId: string | null;
  type: object | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface deleteFroupChat {
  groupId: string | null;
}
interface getGroups {
  userId: string | null;
  groupName: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface getGroupMembers {
  groupId: string | null;
  searchValue: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface getGroupDetails {
  userId: string | null;
  groupId: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}

interface getCircleMedia {
  groupId: string | null;
  userId: string | null;
  type: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface updateGroupDetails {
  groupId: string | null;
  groupName: string | null;
  description: string | null;
}
interface updateGroupMemberRole {
  groupId: string | null;
  userId: string | null;
  isAdmin: string | null;
}
interface removeUserFromGroup {
  groupId: string | null;
  userId: string | null;
}
interface createGroup {}
interface addGroupMember {}

interface getCircleGrpDetails {
  groupId: string | null;
  userId: string | null;
}


export interface GetRepliesForMessage {
  type: typeof GET_REPLIES_FOR_MESSAGE;
  payload: any;
}
export interface GetMessages {
  type: typeof GET_MESSAGES;
  payload: any;
}
export interface GetMediaMessages {
  type: typeof GET_MEDIA_MESSAGES;
  payload: any;
}
export interface Groups {
  type: typeof GET_GROUPS;
  payload: any;
}
export interface GroupMembers {
  type: typeof GET_GROUP_MEMBERS;
  payload: any;
}
export interface GroupDetails {
  type: typeof GET_GROUP_DETAILS;
  payload: any;
}

export interface CircleGrpDetails {
  type: typeof GET_CIRCLE_GROUP_DETAILS;
  payload: any;
}

export interface CircleMedia {
  type: typeof GET_CIRCLE_MEDIA;
  payload: any;
}
export interface TheardShow{
  type: typeof THEARD_SHOW;
  payload: boolean;
}
//Circle theard
export const theardshow = (isOpen: boolean) => {
  return (dispatch: Dispatch<TheardShow>) => {
    dispatch({
      type: THEARD_SHOW,
      payload: isOpen,
    });
  };
}; 
//Delete Group Chat
export const deletePrivateChat = (data: deleteFroupChat) => {
  return async () => {
    const response = await api.post(
      `/groupChat/deleteGroupChat?groupId=${data.groupId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  };
};
//Get Replies for Message
export const getRepliesForMessageGroupChat = (
  data: getRepliesForMessage,
  signal: any
) => {
  return (dispatch: Dispatch<GetRepliesForMessage>) => {
    api
      .get(`/groupChat/getRepliesForMessage`, {
        params: data,
        signal: signal?.signal,
      })
      .then((res) => {
        dispatch({
          type: GET_REPLIES_FOR_MESSAGE,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};
//Get Messages
export const getMessagesGroupChat = (data: getMessages, signal: any) => {
  return (dispatch: Dispatch<GetMessages>) => {
    api
      .get(`/groupChat/getMessages`, { params: data, signal: signal?.signal })
      .then((res) => {
        dispatch({
          type: GET_MESSAGES,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};
//Get Media Messsages
export const getMediaMessagesGroupChat = (
  data: getMediaMessages,
  signal: any
) => {
  return (dispatch: Dispatch<GetMediaMessages>) => {
    api
      .get(`/groupChat/getMediaMessages`, {
        params: data,
        signal: signal?.signal,
      })
      .then((res) => {
        dispatch({
          type: GET_MEDIA_MESSAGES,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};
//Update Group
export const updateGroupInGC = (data: updateGroupDetails) => {
  return async () => {
    const response = await api.post(`/group/updateGroup`, {
      param: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//Update Group Member Role
export const updateGroupMemberRoleInGC = (data: updateGroupMemberRole) => {
  return async () => {
    const response = await api.post(`/group/updateGroupMemberRole`, {
      param: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//Remove User From Group
export const removeUserFromGroupInGC = (data: removeUserFromGroup) => {
  return async () => {
    const response = await api.post(`/group/removeUserFromGroup`, {
      param: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//Delete group {groupId}
export const deleteGroup = (data: deleteFroupChat) => {
  return async () => {
    const response = await api.post(`/group/deleteGroupChat/${data.groupId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//create Group
export const createGroupInGC = (data: createGroup) => {
  return async () => {
    const response = await api.post(`/group/createGroup`, {
      param: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//Add Group Member
export const addGroupMemberInGC = (data: addGroupMember) => {
  return async () => {
    const response = await api.post(`/group/addGroupMember`, {
      param: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  };
};
//Get Groups
export const getGroups = (data: getGroups, signal: any) => {
  return (dispatch: Dispatch<Groups>) => {
    api
      .get(`/group/getGroups`, { params: data, signal: signal?.signal })
      .then((res) => {
        dispatch({
          type: GET_GROUPS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};
//Get Group Members
export const getGroupMembers = (data: getGroupMembers, signal: any) => {
  return (dispatch: Dispatch<GroupMembers>) => {
    api
      .get(`/group/getGroupMembers`, { params: data, signal: signal?.signal })
      .then((res) => {
        dispatch({
          type: GET_GROUP_MEMBERS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};
//Get Group Details
export const getGroupDetails = (data: getGroupDetails, signal: any) => {
  return (dispatch: Dispatch<GroupDetails>) => {
    api
      .get(`/group/getGroupDetails`, { params: data, signal: signal?.signal })
      .then((res) => {
        const groupDetails = res?.data?.data
        ;
console.log("hello",groupDetails);

        dispatch({
          type: GET_GROUP_DETAILS,
          payload: groupDetails,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error("Error:", error);
        }
      });
  };
};



// Get Circle image and video



export const getCircleMedia = (data: getCircleMedia, signal: any) => {
  return (dispatch: Dispatch<CircleMedia>) => {
    api
      .get(`groupChat/getMediaMessages`, {
        params: data,
        signal: signal?.signal,
      })
      .then((res) => {
        dispatch({
          type: GET_CIRCLE_MEDIA,
          payload: res.data.data,
        });
      });
  };
};


