import React from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  InputBase,
  Stack,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import "../../../Layout/sidebar.css";
import "../LandingPage/NewCss.css";
import Masonry from "react-masonry-css";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Sidebar from "./Sidebar";
import { StylesProvider } from "@material-ui/core";
import { subPath } from "../../../Constants/Global";

const SkeletonColor = {
  backgroundColor: "#fff",
};

const LandingPage: React.FC = () => {
  const isLoadingAsset = true;
  const fixedArrayLength = 9;
  const placeholderArray = Array.from(
    { length: fixedArrayLength },
    (_, index) => index
  );

  const buttonStyle = { fontFamily: "Helvetica Neue, sans-serif" };

  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
  });

  return (
    <>
      <Skeleton
        width="100%"
        sx={{
          minHeight: "100px",
          marginBottom: "-20px",
          marginTop: "-22px",
        }}
      >
        <StylesProvider injectFirst>
          <AppBar className="header_bg headerBlur" position="sticky">
            <StyledToolbar className="header_row"></StyledToolbar>
          </AppBar>
        </StylesProvider>
      </Skeleton>

      <Box sx={{ flexGrow: 1, mt: 5 }} className="home_center">
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          justifyContent="center"
          sx={{ padding: "20px" }}
          className="page-grd"
        >
          <Sidebar />
          <Grid
            item
            container
            direction="column"
            className="center-grid"
            xs={12}
            sm={7}
            md={9}
            lg={9}
            xl={10}
            spacing={2}
          >
            {isLoadingAsset ? (
              <Grid container justifyContent="space-between">
                <Skeleton
                  variant="text"
                  width={80}
                  height={28}
                  style={{
                    marginLeft: "9px",
                    marginRight: "9px",
                    borderRadius: "8px",
                  }}
                />
                <Grid
                  className="FF sort-hp"
                  textAlign="right"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1px 0px 1px 5px",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={80}
                    height={20}
                    style={{ marginRight: "3px" }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {isLoadingAsset ? (
              <Grid className="warpper_card_div">
                <Masonry
                  breakpointCols={{ default: 3, 1000: 2 }}
                  className="my-masonry-grid warpper_card  card-container"
                  columnClassName="my-masonry-grid_column"
                >
                  {placeholderArray.map((index: number) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                        className="cardDesgin"
                      >
                        <Card
                          style={{ display: "flex", flexDirection: "column" }}
                          className="cardDesgin"
                          sx={{ backgroundColor: "#E6E6E6" }}
                        >
                          <CardHeader
                            sx={{ padding: "3px 15px 5px 14px" }}
                            avatar={
                              <>
                                <Skeleton
                                  variant="circular"
                                  width={28}
                                  height={28}
                                  style={{
                                    ...SkeletonColor,
                                    marginRight: "8px",
                                  }}
                                />
                                <Grid>
                                  <Skeleton
                                    variant="text"
                                    width={34}
                                    height={15}
                                    style={{
                                      ...SkeletonColor,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width={45}
                                    height={15}
                                    style={{
                                      ...SkeletonColor,
                                      marginRight: "8px",
                                    }}
                                  />
                                </Grid>
                              </>
                            }
                            action={
                              <IconButton>
                                <Typography
                                  className="asset-dsgn"
                                  sx={{
                                    paddingRight: "10px",
                                    display: "flex",
                                  }}
                                >
                                  <Skeleton
                                    variant="circular"
                                    width={20}
                                    height={20}
                                    style={{
                                      ...SkeletonColor,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <Skeleton
                                    variant="text"
                                    width={50}
                                    style={{ ...SkeletonColor }}
                                  />
                                </Typography>
                                <>
                                  <Typography
                                    color="#119BFF"
                                    className="asset-dsgn"
                                    sx={{
                                      paddingRight: "10px",
                                      display: "flex",
                                    }}
                                  >
                                    <Skeleton
                                      variant="circular"
                                      width={20}
                                      height={20}
                                      style={{
                                        ...SkeletonColor,
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width={50}
                                      style={{ ...SkeletonColor }}
                                    />
                                  </Typography>
                                </>
                              </IconButton>
                            }
                          />
                          <CardActions className="">
                            <>
                              <Skeleton
                                variant="rectangular"
                                width={380}
                                height={170}
                                sx={{ ...SkeletonColor, borderRadius: "7px" }}
                              />
                            </>
                          </CardActions>

                          <CardContent sx={{ padding: "0px 0px 0px 9px" }}>
                            <IconButton>
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                                style={{
                                  ...SkeletonColor,
                                  marginRight: "8px",
                                }}
                              />
                              <Skeleton
                                variant="text"
                                width={60}
                                height={15}
                                style={{
                                  ...SkeletonColor,
                                  marginRight: "8px",
                                }}
                              />
                              <Skeleton
                                variant="text"
                                width={5}
                                height={20}
                                style={{
                                  ...SkeletonColor,
                                  marginRight: "8px",
                                }}
                              />
                              <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                                style={{
                                  ...SkeletonColor,
                                  marginRight: "8px",
                                }}
                              />
                              <Skeleton
                                variant="text"
                                width={60}
                                height={15}
                                style={{
                                  ...SkeletonColor,
                                  marginRight: "8px",
                                }}
                              />
                            </IconButton>
                          </CardContent>

                          <CardContent sx={{ padding: "0px 16px 0px 16px" }}>
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "60%",
                                height: "25px",
                                borderRadius: "8px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "80%",
                                height: "25px",
                                marginBottom: "12px",
                                borderRadius: "8px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "70%",
                                height: "16px",
                                color: "#119BFF",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "60%",
                                height: "16px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "80%",
                                height: "16px",
                                marginBottom: "12px",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{
                                ...SkeletonColor,
                                width: "70%",
                                height: "16px",
                                color: "#119BFF",
                              }}
                            />
                          </CardContent>

                          <CardActions disableSpacing>
                            <IconButton>
                              <Skeleton
                                variant="circular"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor }}
                              />
                              <Skeleton
                                variant="text"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor, marginLeft: "6px" }}
                              />
                            </IconButton>

                            <IconButton>
                              <Skeleton
                                variant="circular"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor }}
                              />
                              <Skeleton
                                variant="text"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor, marginLeft: "6px" }}
                              />
                            </IconButton>

                            <IconButton>
                              <Skeleton
                                variant="circular"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor }}
                              />
                              <Skeleton
                                variant="text"
                                width={15}
                                height={15}
                                sx={{ ...SkeletonColor, marginLeft: "6px" }}
                              />
                            </IconButton>

                            <IconButton>
                              <Skeleton
                                variant="text"
                                width={90}
                                height={25}
                                sx={{
                                  ...SkeletonColor,
                                  marginLeft: "99px",
                                  borderRadius: "6px",
                                }}
                              />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                </Masonry>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default LandingPage;
