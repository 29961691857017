import { jwtDecode } from "jwt-decode";
import AssetView from "../src/Views/Components/LandingPage/AssetView";
import Login from "../src/Views/Auth/Login";
import groupView from "../src/Views/Components/LandingPage/GroupView";
import MembersView from "../src/Views/Components/LandingPage/MemberView";
import Template from "./Views/Components/Template/TemplateList";
import Support from "./Views/Help/Support";
import staticAsset from "./Views/Components/Template/staticAsset";
import DynamicForm from "./Views/Components/Template/DynamicForm";
import groupsView from "./Views/Components/Homepage/GroupView";
import ViewAsset from "./Views/Components/Template/ViewAsset";
import Supervisor from "./Views/Profile/Supervisor";
import orgz from "./Views/Profile/Organisation";
import Settings from "./Views/Components/Settings/Settings";
import organisationSettings from "./Views/Components/Settings/Organisation_Settings";
import ListofDynamicTemplate from "./Views/Components/Template/ListDynamicTemplate";
import PreviewTemplate from "./Views/Components/Template/PreviewTemplate";
import profileAsset from "./Views/Components/Template/ProfileAsset";
import HelpandSupport from "./Views/Auth/HelpSupport";
import NotFound from "./Views/Auth/NotFound";
import EmptyAsset from "./Views/Auth/EmptyAsset";
import editAsset from "./Views/Components/Template/EditAsset";
import DirectMessage from "../src/Views/Components/Chat/directMessage";
import CircleMessage from "../src/Views/Components/Chat/circleMessage";
interface JwtPayload {
  role: string; // adjust the type accordingly
}
interface RouteConfig {
  path: string;
  component: React.ComponentType<any>;
  hideSidebar?: boolean;
  classNameHide?: boolean;
}

let role;
const adminLoginString = localStorage.getItem("KM_Admin");
if (adminLoginString) {
  const adminLogin = JSON.parse(adminLoginString);
  const decoded: JwtPayload = jwtDecode(adminLogin.Token);
  role = decoded.role;
}
role = role || "admin";

const routes1: RouteConfig[] = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/landingAssetView",
    component: AssetView,
  },
  {
    path: "/groupView",
    component: groupView,
  },
  {
    path: "/editAsset",
    component: editAsset,
    hideSidebar: true,
  },
  {
    path: "/membersView",
    component: MembersView,
  },
  {
    path: "/staticAsset",
    component: staticAsset,
    hideSidebar: true,
  },
  {
    path: "/groupsView",
    component: groupsView,
  },

  {
    path: "/template",
    component: Template,
    // exact: true,
  },
  {
    path: "/previewTemplate",
    component: PreviewTemplate,
    hideSidebar: true,
  },
  {
    path: "/view",
    component: ViewAsset,
  },
  {
    path: "/Profile",
    component: Supervisor,
  },

  {
    path: "/settings",
    component: Settings,
    hideSidebar: true,
  },
  {
    path: "/profileAsset",
    component: profileAsset,
    classNameHide: true,
  },
  {
    path: "/Support",
    component: Support,
    hideSidebar: true,
  },

  {
    path: "/HelpandSupport",
    component: HelpandSupport,
    hideSidebar: true,
  },
  {
    path: "*",
    component: NotFound,
    hideSidebar: true, // Customize as needed
  },
  {
    path: "/EmptyData",
    component: EmptyAsset,
  },
  {
    path: "/directMessage",
    component: DirectMessage,
  },
  {
    path: "/circleMessage",
    component: CircleMessage,
  },
];

const AdminRoutes: RouteConfig[] = [
  // Non-admin routes go here
  {
    path: "/orgz",
    component: orgz,
    classNameHide: true,
  },
  {
    path: "/dynamicForm",
    component: DynamicForm,
    hideSidebar: true,
  },
  {
    path: "/listofDynamicTemplate",
    component: ListofDynamicTemplate,
    hideSidebar: true,
  },
  {
    path: "/org-settings",
    component: organisationSettings,
    hideSidebar: true,
  },
];
const defaultRole = role || "admin";
const mergedRoutes = defaultRole === "admin" ? AdminRoutes : routes1;
const routes: RouteConfig[] = [...mergedRoutes, ...routes1];

export default routes;
