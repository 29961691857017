import React, { useState } from "react";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/material/styles";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { subPath } from "../../../../../Constants/Global";

const PopupBody = styled("div")(
  ({ theme }) => `
  width: 20%;
  padding: 12px 6px;
  margin: 2px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color:  #fff};
  box-shadow: ${
    theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  z-index: 1;
`
);
const StyledPopup = styled(BasePopup)(
  ({ anchor }) => `
  position: absolute;
  top: ${
    anchor ? `${anchor.getBoundingClientRect().bottom + window.scrollY}px` : "0"
  };
  Right: ${
    anchor ? `${anchor.getBoundingClientRect().left + window.scrollX}px` : "0"
  };
  transform: translateY(8px);
  z-index: 1;
`
);

interface PinnedMsgModelProps {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClickPinned: () => void;
  unPinnedMessage: () => void;
}

const PinnedMsgModel: React.FC<PinnedMsgModelProps> = ({
  id,
  open,
  anchorEl,
  handleClickPinned,
  unPinnedMessage,
}) => {
  const [checkedState, setCheckedState] = useState({
    selectAll: false,
    chat1: false,
    chat2: false,
  });

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setCheckedState({
      selectAll: checked,
      chat1: checked,
      chat2: checked,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: checked,
      selectAll:
        name === "selectAll" ? checked : prevState.selectAll && checked,
    }));
  };

  return (
    <StyledPopup
      id={id}
      open={open}
      anchor={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <PopupBody
        sx={{
          fontFamily: "Montserrat",
          fontSize: "13px",
          fontWeight: 400,
        }}
      >
        <Grid
          container
          sx={{ marginBottom: "10px" , cursor: "pointer"}}
          onClick={handleClickPinned}
        >
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "20px", height: "20px" }}
              src={`..${subPath}/assets/images/images/Direct_Messages_Icon.png`}
              alt="Message view"
            />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              fontSize: "13px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            View in Chat
          </Grid>
        </Grid>
        <Grid container onClick={unPinnedMessage} sx={{ cursor: "pointer" }}>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              src={`..${subPath}/assets/images/images/pin-angle.png`}
              alt="Pin message"
            />
          </Grid>
          <Grid
            item
            xs={9}
            sx={{
              fontSize: "13px",
              fontFamily: "Montserrat",
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            Unpin
          </Grid>
        </Grid>
      </PopupBody>
    </StyledPopup>
  );
};

export default PinnedMsgModel;
