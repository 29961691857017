import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Avatar,
  InputAdornment,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { decodeToken, subPath } from "../../../../../Constants/Global";
import { useAppDispatch, useAppSelector } from "../../../../../Constants/hooks";
import { createCircleGroup } from "../../../../../Redux/circle/action";
import { handleFindMemberFW } from "../../../../../Redux/Search/action";
import { handleForwardMessage } from "../../../../../Redux/Chatview/action";
import { getChatList } from "../../../../../Redux/chatList/action";

interface User {
  id: string;
  userName: string;
  src: string;
}

const CircleSelectBox = ({ forwardMessage, chatData, forwardMsg }: any) => {
  const dispatch = useAppDispatch();
  const userId: string | null = decodeToken()?.id ?? null;
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );

  const memberList = useAppSelector(
    (state: any) => state.search.memberSearchFw
  );

  const chatListData = useAppSelector(
    (state: any) => state.chatListData.chatList
  );

  const [unselectedPersons, setUnselectedPersons] = useState<User[]>([]);
  // useEffect(() => {

  //   setUnselectedPersons(chatListData);
  // }, [chatListData]);
  const [inputValue, setInputValue] = useState("");
  const [abortController, setAbortController] = useState(new AbortController());

  const [inputFields, setInputFields] = useState({
    messages: [forwardMsg.message],
    recipientIds: [],
    ...chatData,
  });

  const handleUserSelection = (event: any, newValues: User[]) => {
    setInputFields({
      ...inputFields,
      recipientIds: newValues,
    });
  };

  const handleRemoveUser = (selectedUser: User) => {
    const newSelectedUsers = inputFields.recipientIds.filter(
      (user: any) => user.id !== selectedUser.id
    );
    setInputFields({
      ...inputFields,
      recipientIds: newSelectedUsers,
    });

    setUnselectedPersons([...unselectedPersons, selectedUser]);
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const { signal } = newAbortController;
    console.log(newInputValue, "newInputValue");
    const searchData: any = {
      userId: userId,
      searchValue: newInputValue,
      pageNo: "1",
      rowPerPage: "40",
    };
    setInputValue(newInputValue);
    // Trigger API call after 3 characters
    dispatch(handleFindMemberFW(searchData, signal));
  };

  const handleSubmit = () => {
    dispatch(handleForwardMessage(inputFields))
    const data = {
      userId: userData.id,
      searchValue: '',
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();


    setTimeout(() => {
      dispatch(getChatList(data, signal.signal));
    }, 5000);
    forwardMessage()
  };

  const handleSelectPerson = (person: User) => {
    const updatedSelected = [...inputFields.recipientIds, person];
    setInputFields({
      ...inputFields,
      recipientIds: updatedSelected,
    });

    const updatedUnselected = unselectedPersons.filter(
      (p) => p.id !== person.id
    );
    setUnselectedPersons(updatedUnselected);
  };

  console.log("state", chatData, forwardMsg);

  return (
    <Grid item>
      <Grid item xs={12}>
        <div>
          <Autocomplete
            className="search_box_mem d-flex"
            multiple
            options={memberList || []}
            value={inputFields.recipientIds}
            onChange={handleUserSelection}
            onInputChange={handleInputChange}
            getOptionLabel={(option: any) => option?.userName}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search members"
                variant="outlined"
                size="small"
                sx={{
                  background: "#F5F5F5",
                  boxSizing: "border-box",
                  height: "35px",
                  borderRadius: "5px",
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={`${subPath}/assets/images/images/searchIocn_chart.png`}
                        alt="Search"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      </Grid>
      <Grid container sx={{ padding: "10px", paddingTop: "20px" }}>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            {inputFields.recipientIds.length} Selected
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
        >
          <Button className="Send_button_circle" onClick={() => handleSubmit()}>
            Send
          </Button>
        </Grid>
      </Grid>
      {inputFields.recipientIds.map((person: User) => (
        <Grid container sx={{ marginTop: "10px" }} key={person.id}>
          <Grid
            item
            xs={3}
            lg={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Avatar
              alt="profile"
              style={{ width: "33px", height: "33px", borderRadius: "6px" }}
              src={person.src}
            />
          </Grid>
          <Grid
            item
            xs={6}
            lg={7}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography className="forward_username" title={person?.userName}>
              {person?.userName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            lg={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div className="CheckBox_container">
              <div
                className="round_box"
                onClick={() => handleRemoveUser(person)}
              >
                <input
                  className="checkBox_input"
                  type="checkbox"
                  checked
                  id="checkbox"
                />
                <label htmlFor="checkbox"></label>
              </div>
            </div>
          </Grid>
        </Grid>
      ))}

      <Grid item className="Recents_lable" sx={{ margin: "10px 0px 10px 0px" }}>
        Recents
      </Grid>
      {unselectedPersons.map((list) => (
        <Grid item sx={{ marginTop: "10px" }} key={list.id}>
          <Grid container>
            <Grid
              item
              xs={3}
              lg={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                alt="profile"
                style={{ width: "33px", height: "33px", borderRadius: "6px" }}
                src={list.src}
              />
            </Grid>
            <Grid
              item
              xs={6}
              lg={7}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography className="forward_username" title={list.userName}>
                {list.userName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              lg={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <div
                className="CheckBox_container"
                onClick={() => handleSelectPerson(list)}
              >
                <div className="roundBox_unselect">
                  <input
                    className="checkBoxinput_unselect"
                    type="checkbox"
                    checked
                    id="checkbox"
                  />
                  <label
                    className="Checkboxinput_lable"
                    htmlFor="checkbox"
                  ></label>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CircleSelectBox
