import { Dispatch } from "redux";
import ChatAxios from "../../Constants/ChatAxios";
import axios from "axios";
const api = ChatAxios();

const CHAT_LIST = "CHAT_LIST";
const USER_DATA = "USER_DATA";
const MEDIA_VIEW="MEDIA_VIEW"
interface chatList {
  userId: string | null;
  searchValue: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface userData {
  userName: string | null;
  emailId: string | null;
  designation: string | null;
  department: string | null;
}
interface mediaView {
    isMediaView:boolean;
    imageURL:string
}
export interface ChatList {
  type: typeof CHAT_LIST;
  payload: any;
}
export interface UserData {
  type: typeof USER_DATA;
  payload: any;
}
export interface MeidaView{
  type: typeof MEDIA_VIEW
  payload: mediaView
}
export const isMediaShow = (mediaView: mediaView) => {
  return (dispatch: Dispatch<MeidaView>) => {
    dispatch({
      type: MEDIA_VIEW,
      payload: mediaView,
    });
  };
};

export const getChatList = (data: chatList, signal: any) => {
  return (dispatch: Dispatch<ChatList>) => {
    api.get(`/findRecipientsForUser`, { params: data, signal: signal?.signal })
      .then((res) => {
        dispatch({
          type: CHAT_LIST,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};
export const getUserList = (data: userData, signal: any) => {
  return (dispatch: Dispatch<UserData>) => {
    api.get(`/findUserByEmail`, { params: data, signal: signal?.signal })
      .then((res) => {
        dispatch({
          type: USER_DATA,
          payload: res.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};