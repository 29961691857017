import React, { useState } from "react";
import { subPath } from "../../../../Constants/Global";
import { Button, Grid } from "@mui/material";

interface ImageFile {
  file: File;
  previewUrl: string;
}

interface ThumbnailCarouselProps {
  images: ImageFile[];
  onThumbnailClick: (image: ImageFile) => void;
}

const ThumbnailCarousel: React.FC<ThumbnailCarouselProps> = ({
  images,
  onThumbnailClick,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onPrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      onThumbnailClick(images[currentIndex - 1]);
    }
  };

  const onNextClick = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
      onThumbnailClick(images[currentIndex + 1]);
    }
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    onThumbnailClick(images[index]);
  };

  return (
    <div>
      <div className="thumbnail-carousel">
        <Button
          className="prev-icon"
          onClick={onPrevClick}
          disabled={currentIndex === 0}
        >
          {/* &#8249;  */}
          <img
            src={`..${subPath}/assets/images/images/Back_Button.png`}
            alt="back Arrow"
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
          />
        </Button>
        {images.map((image, index) => (
          <Grid
            item
            key={index}
            className={`thumbnail-container ${
              index === currentIndex ? "" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          >
            <img
              src={image.previewUrl}
              alt={`Thumbnail ${index}`}
              className="thumbnail"
            />
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={` ${
                  index === currentIndex
                    ? `..${subPath}/assets/images/images/Line_image.png`
                    : ` `
                }`}
              />
            </Grid>
          </Grid>
        ))}
        <Button
          className="next-icon"
          onClick={onNextClick}
          disabled={currentIndex === images.length - 1}
        >
          <img
            src={`..${subPath}/assets/images/images/next_Button.png`}
            alt="Next Arrow"
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
          />
        </Button>
      </div>

      {images[currentIndex] && (
        <Grid item className="modal">
          <span className="close" onClick={() => setCurrentIndex(-1)}>
            &times;
          </span>
          <img
            className="modal-content"
            src={images[currentIndex].previewUrl}
            alt="Selected"
          />
        </Grid>
      )}
    </div>
  );
};

export default ThumbnailCarousel;
