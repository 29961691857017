import React, { useEffect, useRef, useState } from "react";
import Joyride from "react-joyride";
import "./appTour.css";
import { decodeToken } from "../../Constants/Global";
import eventEmitter from "../../Constants/EventEmiter";
import UseAxios from "../../Constants/UseAxios";

const Welcome: React.FC = () => {
  const api = UseAxios();
  const [run, setRun] = useState(false);
  const decode_Token = decodeToken();
  const id = decode_Token.id;

  const updateTourStatus = async () => {
    setRun(false);
    eventEmitter.emit("headerRemoveBlur");
    await api.put(`user/update?userId=` + id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    callSideBarRemove();
  };

  let vaultAccess = useRef(true);

  const welcome = async () => {
    try {
      await api
        .get(`user/get?id=` + id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.data.tourStatus === false) {
            setRun(true);
            vaultAccess.current = res.data.data.vaultAccess;
            eventEmitter.emit("headerBlur");
          } else {
            setRun(false);
            eventEmitter.emit("headerRemoveBlur");
          }
        })
        .catch((err) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const callSideBarAdd = () => {
    eventEmitter.emit("addTourClass");
  };

  const callSideBarRemove = () => {
    eventEmitter.emit("removeTourClass");
  };

  const addAssetClass = () => {
    eventEmitter.emit("addAssetClass");
  };

  const removeAssetClass = () => {
    eventEmitter.emit("removeAssetClass");
  };

  useEffect(() => {
    welcome();
  }, []);

  const [steps] = useState(() => [
    {
      content: (
        <div>
          <p className="welcomeHead">Let’s start with a quick</p>
          <p className="welcomeHead">product tour.</p>
          <p className="welcomeContent">
            We’ll have you up and running in no time.
          </p>
        </div>
      ),
      placement: "center", // Change placement to "center"
      target: "body",
      title: <p className="welcomeTitle">WELCOME!</p>,
      styles: {
        buttonClose: {
          color: "#119BFF",
          fontSize: "12px",
          padding: "22px",
        },
      },
      locale: {
        next: <span onClick={callSideBarAdd}>Get Started</span>,
        skip: (
          <span
            key="back"
            style={{
              color: "var(--3, #708090)",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={updateTourStatus}
          >
            Skip
          </span>
        ),
      },
      // showProgress: false,
    },
    {
      content: (
        <p className="popContent">
          You can create vaults into the groups to store the assets.
        </p>
      ),
      placement: "right",
      target: "#createVault",
      title: <h2 className="popTitle">Create Vault</h2>,
      locale: {
        next: (
          <span
            onClick={callSideBarRemove}
            className="cur-pointer"
            style={{ verticalAlign: "text-top" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" rx="4" fill="white" />
              <path
                d="M9 6L15 12L9 18"
                stroke="url(#paint0_linear_5468_21075)"
                stroke-width="1.33333"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5468_21075"
                  x1="12"
                  y1="6"
                  x2="11.4539"
                  y2="18.3601"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#119BFF" />
                  <stop offset="1" stopColor="#4CCE1F" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        ),
        skip: (
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={updateTourStatus}
          >
            Skip
          </span>
        ),
        back: (
          <div>
            <span
              onClick={callSideBarRemove}
              className="cur-pointer"
              style={{ verticalAlign: "text-top" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="4" fill="white" />
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_5468_21073)"
                  stroke-width="1.33333"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5468_21073"
                    x1="12"
                    y1="6"
                    x2="12.5461"
                    y2="18.3601"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#119BFF" />
                    <stop offset="1" stopColor="#4CCE1F" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span style={{ margin: "1px 0px 0px 10px" }}>
              <b style={{ fontSize: "12px", fontWeight: "bold" }}>01</b>
              <span style={{ fontSize: "12px" }}>/ 05</span>
            </span>
          </div>
        ),
      },
      styles: {
        tooltip: {
          background:
            "linear-gradient(262deg, #119bff -28.99%, #4cce1f 166.43%)",
          color: "white",
          borderRadius: "0px 30px 30px 30px",
        },
        buttonNext: {
          background: "white",
          border: "none",
          padding: "0px",
          fontSize: "1px",
        },
        buttonClose: {
          color: "white",
        },
      },
    },
    {
      content: (
        <span className="popContent">
          You can create groups in the organisation to store the assets.
        </span>
      ),
      placement: "right",
      target: "#createGroup",
      title: <h2 className="popTitle">Create Group</h2>,
      locale: {
        next: (
          <span
            onClick={() => {
              callSideBarRemove();
              addAssetClass();
            }}
            className="cur-pointer"
            style={{ verticalAlign: "text-top" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" rx="4" fill="white" />
              <path
                d="M9 6L15 12L9 18"
                stroke="url(#paint0_linear_5468_21075)"
                stroke-width="1.33333"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5468_21075"
                  x1="12"
                  y1="6"
                  x2="11.4539"
                  y2="18.3601"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#119BFF" />
                  <stop offset="1" stopColor="#4CCE1F" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        ),
        skip: (
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={updateTourStatus}
          >
            Skip
          </span>
        ),
        back: (
          <div>
            <span
              onClick={callSideBarRemove}
              className="cur-pointer"
              style={{ verticalAlign: "text-top" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="4" fill="white" />
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_5468_21073)"
                  stroke-width="1.33333"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5468_21073"
                    x1="12"
                    y1="6"
                    x2="12.5461"
                    y2="18.3601"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#119BFF" />
                    <stop offset="1" stopColor="#4CCE1F" />
                  </linearGradient>
                </defs>
              </svg>
            </span>

            <span style={{ margin: "1px 0px 0px 10px" }}>
              <b style={{ fontSize: "12px", fontWeight: "bold" }}>01</b>
              <span style={{ fontSize: "12px" }}> / 04</span>
            </span>
          </div>
        ),
      },
      styles: {
        tooltip: {
          background:
            "linear-gradient(262deg, #119bff -28.99%, #4cce1f 166.43%)",
          color: "white",
          borderRadius: "30px 30px 30px 0px",
        },
        buttonNext: {
          background: "white",
          border: "none",
          padding: "0px",
          fontSize: "1px",
        },
        buttonClose: {
          color: "white",
        },
      },
    },
    {
      content: (
        <span className="popContent">
          You can add asset in groups from the templates given in Portal.
        </span>
      ),
      placement: "left",
      target: "#addAsset",
      title: <h2 className="popTitle">Add Asset</h2>,
      locale: {
        next: (
          <span
            className="cur-pointer"
            onClick={removeAssetClass}
            style={{ verticalAlign: "text-top" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" rx="4" fill="white" />
              <path
                d="M9 6L15 12L9 18"
                stroke="url(#paint0_linear_5468_21075)"
                stroke-width="1.33333"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5468_21075"
                  x1="12"
                  y1="6"
                  x2="11.4539"
                  y2="18.3601"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#119BFF" />
                  <stop offset="1" stopColor="#4CCE1F" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        ),
        skip: (
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={updateTourStatus}
          >
            Skip
          </span>
        ),
        back: (
          <div>
            <span
              onClick={() => {
                callSideBarAdd();
                removeAssetClass();
              }}
              className="cur-pointer"
              style={{ verticalAlign: "text-top" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="4" fill="white" />
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_5468_21073)"
                  stroke-width="1.33333"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5468_21073"
                    x1="12"
                    y1="6"
                    x2="12.5461"
                    y2="18.3601"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#119BFF" />
                    <stop offset="1" stopColor="#4CCE1F" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span style={{ margin: "1px 0px 0px 10px" }}>
              <b style={{ fontSize: "12px", fontWeight: "bold" }}>
                {vaultAccess.current ? "02" : "01"}
              </b>
              <span style={{ fontSize: "12px" }}>
                {" "}
                {vaultAccess.current ? " / 04" : "/ 02"}
              </span>
            </span>
          </div>
        ),
      },
      styles: {
        tooltip: {
          background:
            "linear-gradient(262deg, #119bff -28.99%, #4cce1f 166.43%)",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
        },
        buttonNext: {
          background: "white",
          border: "none",
          padding: "0px",
          fontSize: "1px",
        },
        buttonClose: {
          color: "white",
        },
      },
    },
    {
      content: (
        <span className="popContent">
          Notification like Grams Received, Comments from asset can be viewed
          here.
        </span>
      ),
      placement: "left",
      target: "#notification",
      title: <h2 className="popTitle">Notifications</h2>,
      locale: {
        next: (
          <span className="cur-pointer" style={{ verticalAlign: "text-top" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" rx="4" fill="white" />
              <path
                d="M9 6L15 12L9 18"
                stroke="url(#paint0_linear_5468_21075)"
                stroke-width="1.33333"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5468_21075"
                  x1="12"
                  y1="6"
                  x2="11.4539"
                  y2="18.3601"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#119BFF" />
                  <stop offset="1" stopColor="#4CCE1F" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        ),
        skip: (
          <span
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: 500,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={updateTourStatus}
          >
            Skip
          </span>
        ),
        back: (
          <div onClick={addAssetClass}>
            <span className="cur-pointer" style={{ verticalAlign: "text-top" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="4" fill="white" />
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_5468_21073)"
                  stroke-width="1.33333"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5468_21073"
                    x1="12"
                    y1="6"
                    x2="12.5461"
                    y2="18.3601"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#119BFF" />
                    <stop offset="1" stopColor="#4CCE1F" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span style={{ margin: "1px 0px 0px 10px" }}>
              <b style={{ fontSize: "12px", fontWeight: "bold" }}>
                {vaultAccess.current ? "03" : "01"}
              </b>
              <span style={{ fontSize: "12px" }}>
                {" "}
                {vaultAccess.current ? " / 04" : "/ 02"}
              </span>
            </span>
          </div>
        ),
      },
      styles: {
        tooltip: {
          background:
            "linear-gradient(262deg, #119bff -28.99%, #4cce1f 166.43%)",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
        },
        buttonNext: {
          background: "white",
          border: "none",
          padding: "0px",
          fontSize: "1px",
        },
        buttonClose: {
          color: "white",
        },
      },
    },
    {
      content: (
        <span className="popContent">
          You can view your assets and your groups in this Organisation.
        </span>
      ),
      placement: "left",
      target: "#profile",
      title: <h2 className="popTitle">Profile</h2>,
      locale: {
        last: (
          <span
            onClick={updateTourStatus}
            className="cur-pointer"
            style={{ verticalAlign: "text-top" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect width="24" height="24" rx="4" fill="white" />
              <path
                d="M9 6L15 12L9 18"
                stroke="url(#paint0_linear_5468_21075)"
                stroke-width="1.33333"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_5468_21075"
                  x1="12"
                  y1="6"
                  x2="11.4539"
                  y2="18.3601"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#119BFF" />
                  <stop offset="1" stopColor="#4CCE1F" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        ),
        back: (
          <div>
            <span className="cur-pointer" style={{ verticalAlign: "text-top" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect width="24" height="24" rx="4" fill="white" />
                <path
                  d="M15 6L9 12L15 18"
                  stroke="url(#paint0_linear_5468_21073)"
                  stroke-width="1.33333"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5468_21073"
                    x1="12"
                    y1="6"
                    x2="12.5461"
                    y2="18.3601"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#119BFF" />
                    <stop offset="1" stopColor="#4CCE1F" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            <span style={{ margin: "1px 0px 0px 10px" }}>
              <b style={{ fontSize: "12px", fontWeight: "bold" }}>
                {vaultAccess.current ? "04 " : "02"}
              </b>
              <span style={{ fontSize: "12px" }}>
                {" "}
                {vaultAccess.current ? " / 04" : "/ 02"}
              </span>
            </span>
          </div>
        ),
      },

      styles: {
        tooltip: {
          background:
            "linear-gradient(262deg, #119bff -28.99%, #4cce1f 166.43%)",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
        },
        buttonNext: {
          background: "white",
          border: "none",
          padding: "0px",
          fontSize: "1px",
        },
        buttonClose: {
          color: "white",
        },
      },
    },
  ]);

  return (
    <div>
      <div>
        <Joyride
          continuous
          callback={() => {}}
          run={run}
          steps={steps.map((step) => ({
            ...step,
            placement: step.placement === "center" ? "center" : "right",
          }))}
          // scrollToFirstStep
          showSkipButton
          // showProgress
        />
      </div>
    </div>
  );
};

export default React.memo(Welcome);
