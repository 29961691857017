const initialState = {
  repliesForMessage: [],
  messages: [],
  mediaMessages: [],
  groups: [],
  groupMember: [],
  groupDetails: [],
  isOpen: false
};
const groupChatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "GET_REPLIES_FOR_MESSAGE":
      return {
        ...state,
        repliesForMessage: action.payload,
      };
      case "THEARD_SHOW":
        return {
          ...state,
          isOpen: action.payload,
        }
    case "GET_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      };
    case "GET_MEDIA_MESSAGES":
      return {
        ...state,
        mediaMessages: action.payload,
      };
    case "GET_GROUPS":
      return {
        ...state,
        groups: action.payload,
      };
    case "GET_GROUP_MEMBERS":
      return {
        ...state,
        groupMember: action.payload,
      };
    case "GET_GROUP_DETAILS":
      return {
        ...state,
        groupDetails: action.payload,
      };
    default:
      return state;
  }
};

export default groupChatReducer;