import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "../Views/Auth/Login.css";
import { useNavigate } from "react-router-dom";
import { subPath } from "../Constants/Global";
const Footer: React.FC = () => {
  const navigate = useNavigate();
  const navigates = () => {
    navigate(`/Support`);
  };

  return (

      <Grid className="footer footer-setting">
        <Paper
          sx={{
            marginTop: "calc(10% + 60px)",
            position: "fixed",
            bottom: 0,
            width: "100%",
            background: "transparent",
            border: "none",
          }}
          component="footer"
          square
          variant="outlined"
        >
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
                gap: "12px",
              }}
              className="big-left-div"
            >
              <Grid className="f-im-d">
                <Box
                  component="img"
                  // src="..bea/assets/images/cavin-info.png"
                   src={`..${subPath}/assets/images/cavin-info.png`} 
                  style={{ paddingTop: "6px", marginRight: "0px" }}
                  width={"40px"}
                  alt=""
                  className="footer_big_img"
                />
              </Grid>
              <Grid>
                <Typography
                  className="f-cr-d big-fn"
                  sx={{ color: "#fff", fontSize: "13px" }}
                >
                  {" "}
                  All Right © Cavin Infotech
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="">
              <Typography
                variant="h5"
                className="f-l-d big-fn"
                sx={{
                  float: "right",
                  color: "#fff",
                  fontSize: "14px",
                  padding: "20px 50px",
                }}
              >
                About | FAQ |{" "}
                <span className="cur-pointer" onClick={() => navigates()}>
                  Help & Support
                </span>{" "}
                | Contact Us
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
  );
};
export default Footer;
