import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Settings.css";
import OrgSidebar from "../../../Layout/OrgSidebar";
import { Grid, Button, ButtonGroup } from "@mui/material";
import { decodeToken } from "../../../Constants/Global";
import eventEmitter from "../../../Constants/EventEmiter";
import UseAxios from "../../../Constants/UseAxios";
import { useAppDispatch } from "../../../Constants/hooks";
import { setItemName } from "../../../Redux/Search/action";

//Componented Start
const Settings: React.FC = () => {
  const api = UseAxios();
  const dispatch = useAppDispatch();
  const [activeButton, setActiveButton] = useState("Daily");

  const companyId = decodeToken().companyId;

  const handleButtonClick = async (value: React.SetStateAction<string>) => {
    setActiveButton(value);
    // Update the active value in backend api
    await api
      .put(
        `mail-notification/update-notification-frequency?companyId=${companyId}`,
        {
          notificationFrequency: value,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };

  // Get the active value from backend api
  const getActiveValue = async () => {
    try {
      await api
        .get(
          `mail-notification/get-notification-frequency?companyId=${companyId}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data.data === "Daily" ||
              response.data.data === "Weekly" ||
              response.data.data === "Monthly"
            ) {
              setActiveButton(response.data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  useEffect(() => {
    getActiveValue();
    dispatch(setItemName(""));
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          // spacing={1}
          alignItems="baseline"
          justifyContent="center"
          style={{ padding: "12px 12px 0px 12px" }}
        >
          <OrgSidebar />

          <Grid item xs={12} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
            <Card sx={{ pl: 1, height: "80vh", borderRadius: "12px" }}>
              <CardContent>
                <Grid container>
                  <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                      <Grid
                        item
                        style={{ display: "flex", alignItems: "unset" }}
                      >
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "18px",
                            fontWeight: "700",
                          }}
                        >
                          Notification Frequency
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          className="setting-notification colorBlack"
                          sx={{ fontSize: "15px" }}
                        >
                          Set the frequency period
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      {" "}
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ButtonGroup
                          sx={{
                            backgroundColor: "rgb(245, 245, 245)",
                            borderRadius: "6px",
                          }}
                        >
                          <Button
                            className="text_small"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              border: "none",
                              backgroundColor:
                                activeButton === "Daily"
                                  ? "#119BFF"
                                  : "default.main",
                              color:
                                activeButton === "Daily" ? "white" : " #119BFF",
                              "&:hover": {
                                color: "#119BFF",
                                border: "none",
                              },
                            }}
                            onClick={() => handleButtonClick("Daily")} // Make sure 'value' is passed here
                          >
                            Daily
                          </Button>
                          <Button
                            className="text_small"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              border: "none",
                              backgroundColor:
                                activeButton === "Weekly"
                                  ? "#119BFF"
                                  : "default.main",
                              color:
                                activeButton === "Weekly"
                                  ? "white"
                                  : " #119BFF",
                              "&:hover": {
                                color: "#119BFF",
                                border: "none",
                              },
                            }}
                            onClick={() => handleButtonClick("Weekly")} // Make sure 'value' is passed here
                          >
                            Weekly
                          </Button>
                          <Button
                            className="text_small"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              border: "none",
                              backgroundColor:
                                activeButton === "Monthly"
                                  ? "#119BFF"
                                  : "default.main",
                              color:
                                activeButton === "Monthly"
                                  ? "white"
                                  : " #119BFF",
                              "&:hover": {
                                color: "#119BFF",
                                border: "none",
                              },
                            }}
                            onClick={() => handleButtonClick("Monthly")} // Make sure 'value' is passed here
                          >
                            Monthly
                          </Button>
                        </ButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
